import { observable } from 'mobx';

class GlobalDataStore {
  constructor(data = {}) {
    for (const [key, value] of Object.entries(data)) {
      this[key] = value;
    }
  }

  @observable clientData = {};
  @observable serverData = {};
  @observable analyticsEvents = {};
  @observable experiments = {};
  @observable clientDataHolder = {};
  @observable history = {};
}

export default GlobalDataStore;
