import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import App from 'material-ui/App';

import WuiTheme from '@willing-shared/WuiTheme';
import Grid from '@wui/layout/grid';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';

import ImgDoctor from 'material-ui/assets/images/heroes-doctor.png';
import LogoMetLife from '@willing-shared/assets/images/logo-metlife.png';
import LogoWilling from '@willing-shared/assets/images/logo-willing.png';
import withIsMobileDisplay from 'willing-shared/src/hocs/withIsMobileDisplay';

const styles = theme => ({
  container: {
    width: '100%',
    maxWidth: 465,
    padding: [[32]],
  },
  covid: {
    flexGrow: 1,
  },
  heading: {
    width: '100%',
    maxWidth: 550,
    alignSelf: 'center',
  },
  hero: {
    flexGrow: 1,
    padding: [[32]],
    background: `#b7e0ff url(${ImgDoctor}) no-repeat center bottom`,
    backgroundSize: 'auto 60%',
  },
  auth: {
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
    justifyContent: 'center',
  },
});

class AuthLayout extends React.Component {
  render() {
    const { children, classes, covid } = this.props;

    return (
      <App showHeader={false} showFooter={false}>
        <WuiTheme>
          {covid && (
            <Grid>
              <Grid container className={classes.covid} spacing={0} direction="row" wrap="wrap">
                <Grid container item className={classes.hero} md={6} sm={12}>
                  <Grid container direction="row">
                    <Grid item xs={6}>
                      <img alt="" src={LogoWilling} height={this.isPhoneDisplay ? 24 : 32} />
                      <Typography
                        style={{
                          fontSize: this.isPhoneDisplay ? '12px' : '16px',
                          lineHeight: '1em',
                        }}
                      >
                        A <strong>MetLife</strong> company.
                      </Typography>
                    </Grid>
                    <Grid item xs={6} align="right">
                      <img
                        alt=""
                        src={LogoMetLife}
                        width="auto"
                        height={this.isPhoneDisplay ? 24 : 32}
                      />
                    </Grid>
                  </Grid>
                  <Spacer v={64} />
                  <Typography className={classes.heading} variant="h1" align="center">
                    Free digital estate planning for healthcare heroes.
                  </Typography>
                  <Spacer v={320} />
                </Grid>
                <Grid container item className={classes.auth} md={6} sm={12} alignItems="center">
                  <Grid container item className={classes.container}>
                    {children}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {!covid && (
            <Grid container direction="column" alignItems="center" wrap="nowrap">
              <Spacer v={32} />
              <Spacer v={60} xsDown />
              <a href="//willing.com">
                <img
                  alt="home page"
                  src={LogoWilling}
                  width={this.isPhoneDisplay ? 110 : 156}
                  height="100%"
                />
              </a>
              <Spacer v={32} />
              <Panel className={classes.container} style={{ marginBottom: '48px' }}>
                {children}
              </Panel>
              <Spacer v={92} />
            </Grid>
          )}
        </WuiTheme>
      </App>
    );
  }
}

AuthLayout.propTypes = {
  classes: PropTypes.shape({}).isRequired,
  covid: PropTypes.bool,
};

export default withStyles(styles)(withIsMobileDisplay(AuthLayout));
