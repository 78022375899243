import React from 'react';
import PropTypes from 'prop-types';

export default class ClientDataReferer extends React.Component {
  static contextTypes = {
    clientDataHolder: PropTypes.object,
    experiments: PropTypes.object,
    testator: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.readProps(props, context);

    this.clientDataCallback = () => {
      this.mounted && this.forceUpdate();
    };
  }

  get clientData() {
    return this.clientDataHolder.clientData;
  }

  get updateClientData() {
    return this.clientDataHolder.updateClientData.bind(this.clientDataHolder);
  }

  get serverData() {
    return this.clientDataHolder.serverData;
  }

  get analyticsEvents() {
    return this.clientDataHolder.analyticsEvents;
  }

  componentWillReceiveProps(props, context) {
    this.readProps(props, context);
  }

  componentDidMount() {
    this.mounted = true;
    this.clientDataHolder.clientDataCallbacks.subscribe(this.clientDataCallback);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.clientDataHolder.clientDataCallbacks.unsubscribe(this.clientDataCallback);
  }

  readProps(props, context) {
    this.clientDataHolder = context.clientDataHolder;
    this.testator = context.testator;
  }

  triggerValidation(
    showValidationErrors = true,
    returnEvent = false,
    forceShowValidationErrors = false,
  ) {
    // forceShowValidationErrors can be used to force the components to either turn on or off their validation
    //   displays regardless of current state. Formerly you could only turn them on and not off, which we
    //   still want to be the default.
    const event = {
      showValidationErrors,
      forceShowValidationErrors,
      isValid: true,
      firstControlWithError: null,
    };
    this.clientDataHolder.validateAllCallbacks.emit(event);

    if (returnEvent) {
      return event;
    }

    return event.isValid;
  }

  firstControlWithError() {
    return this.triggerValidation(false, true).firstControlWithError;
  }

  firstUrlForSection(section) {
    return section.firstUrl(this.clientData, this.serverData, this.context.experiments);
  }
}
