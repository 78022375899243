import React from 'react';
import PropTypes from 'prop-types';
import { joinClass } from 'utils';

export default class Card extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    cardTitle: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    active: true,
  };

  renderInactive() {
    return <h1 className="gray-text card-spacing">{this.props.cardTitle}</h1>;
  }

  renderActive() {
    const className = joinClass('card', this.props.className);

    return (
      <div className={className}>
        {this.props.cardTitle && <h1>{this.props.cardTitle}</h1>}
        {this.props.children}
      </div>
    );
  }

  render() {
    if (this.props.active) {
      return this.renderActive();
    } else {
      return this.renderInactive();
    }
  }
}
