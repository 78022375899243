import React from 'react';
import PropTypes from 'prop-types';

import Panel from '@wui/layout/panel';
import PanelTitle from '@wui/layout/panelTitle';
import Spacer from '@wui/layout/spacer';
import Grid from '@wui/layout/grid';
import CloseButton from '@wui/basics/closeButton';
import ActionButton from './ActionButton';
import { useToggledState } from 'hooks';

const Updater = ({ component: Component, title, children, startOpen, onClick, ...props }) => {
  const [open, toggle] = useToggledState(startOpen);

  return (
    <Panel tableRow lessPadding>
      {title && <PanelTitle>{title}</PanelTitle>}

      {open ? (
        <Panel special="force" lessPadding>
          <CloseButton onClick={toggle} position="top" />
          <Spacer v={16} smUp />
          <Component onSuccess={toggle} />
        </Panel>
      ) : (
        <Grid container direction="row" alignItems="center">
          {children}

          <ActionButton onClick={onClick || toggle} {...props} />
        </Grid>
      )}
    </Panel>
  );
};

Updater.propTypes = {
  title: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  children: PropTypes.node,
  startOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

Updater.defaultProps = {
  title: '',
  children: null,
  startOpen: false,
  onClick: null,
};

export default Updater;
