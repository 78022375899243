function isProductionBuild() {
  return process.env.NODE_ENV === 'production';
}

function willingEnvironment() {
  return process.env.DJANGO_ENVIRONMENT;
}

function isProductionEnvironment() {
  return willingEnvironment() === 'willing-prod';
}

export const googleTagIdentifiers = {
  adwords: 'AW-1012942534',
  analytics: 'UA-54012025-1',
  mlpAnalytics: 'UA-21170686-9',
  getOptimize: () => window.WillingConfig.googleOptimizeId,
};

const params = new URLSearchParams(window.location.search);

export const trustService = params.get('service') === 'trust';

export const digitalEstatePlanningService = params.get('service') === 'digitalEstatePlanning';

export default {
  isProductionBuild,
  willingEnvironment,
  isProductionEnvironment,
};
