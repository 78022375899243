import React from 'react';
import PropTypes from 'prop-types';
import { GenericPerson } from 'models/client-data/main';
import { testName } from 'utils';
import { capitalizeFirstAndLast } from '@willing-shared/utils/text';
import { QUICK_OPTION_GROUPS } from 'material-ui/sections/assets/BequestInterface/helpers';
import PureTextField from '@wui/input/textbox';
import Combobox from '@wui/input/combobox';

import { useScreenSize } from 'hooks';

const PurePersonChooser = ({
  // The initial list of eligible people for this entry
  initialPeople,

  // The currently selected person
  selectedPerson,

  // The method used to select a person
  onSelect,

  // Helper text to display for the field
  helperText,

  // Placeholder/top-of-the-box label
  label,

  // The total list of all names to validate against. If the user tries to enter a name that is in
  //   this list and not in the initialPeople array, it is an invalid choice here (i.e. self)
  allNames,

  // Error text to display for the control
  error,

  // Ref for the bound version to bind to
  containerRef,

  // Component to use for the text field
  TextFieldComponent,

  onBlur,
  onFocus,
  style,
  optional,
}) => {
  const { isTabletOrSmallerDisplay } = useScreenSize();
  const getOptionsByInputValue = val => {
    // Short circuit here to avoid a frustrating mobile experience
    if (!val && isTabletOrSmallerDisplay) {
      return [];
    }

    const testingName = val.trim().toLowerCase();
    const allPeople = [...initialPeople];

    // Normalize the initial names for case insensitive testing
    const initialPeopleNames = initialPeople.map(person => person.name.toLowerCase());

    // We need to gather a few conditions to tell whether or not the user is allowed to instantiate a new person
    const nameIsNotCurrentlySelected =
      !selectedPerson || selectedPerson.name.toLowerCase() !== testingName;
    const nameIsNotInInitialPeople = !initialPeopleNames.includes(testingName);
    const nameHasValidFormat = testName(val.trim());
    const nameIsNotInOverallCollection = !allNames
      .filter(p => p) // Ensure they have a name attached
      .map(p => p.toLowerCase()) // Make them lowercase to test against
      .includes(testingName); // Test our inputValue against the collection

    if (
      nameHasValidFormat &&
      nameIsNotCurrentlySelected &&
      nameIsNotInInitialPeople &&
      nameIsNotInOverallCollection
    ) {
      // If the name is valid and cannot be found in any possible collection, present it as a selectable
      //   option which will create the person in the onSelect prop.
      allPeople.push(new GenericPerson({ name: val.trim() }));
    }

    // Filter for display
    //   - Never show quick option groups
    //   - EmptyString matches all people
    //   - Otherwise, do a case insensitive match against the input string and the person name
    //     and any name containing that series of characters is shown
    //   - The person is not already selected
    //     Only used for pre-generating the list before focus, to avoid flickering
    return allPeople
      .filter(
        p =>
          !QUICK_OPTION_GROUPS.includes(p.name) &&
          (!val || p.name.toLowerCase().includes(testingName)) &&
          (!selectedPerson || selectedPerson.name.toLowerCase().trim() !== testingName),
      )
      .map(p => ({ label: p.name, value: p }));
  };

  // Auto select an eligible option on focus out
  const onFocusOut = val => {
    if (!val) {
      return;
    }

    const people = getOptionsByInputValue(val);

    if (people.length) {
      onSelect(people[0].value);
    }
  };

  const selected = { label: selectedPerson ? selectedPerson.name : '' };

  return (
    <div ref={containerRef} style={style}>
      <Combobox
        getOptionsByInputValue={getOptionsByInputValue}
        onFocusOut={onFocusOut}
        selected={selected}
        onSelect={onSelect}
        error={Boolean(error)}
        label={label}
        helperText={error || helperText}
        onBlur={onBlur}
        onFocus={onFocus}
        mutateInputValue={capitalizeFirstAndLast}
        TextFieldComponent={TextFieldComponent}
        dataPathOverride="selectPerson"
        optional={optional}
        focusOpensMenu={false}
      />
    </div>
  );
};

PurePersonChooser.propTypes = {
  initialPeople: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedPerson: PropTypes.shape({}),
  onSelect: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  label: PropTypes.string,
  allNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  error: PropTypes.string,
  containerRef: PropTypes.object.isRequired,
  TextFieldComponent: PropTypes.elementType,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  optional: PropTypes.bool,
  style: PropTypes.shape({}),
};

PurePersonChooser.defaultProps = {
  selectedPerson: null,
  helperText: '',
  label: '',
  error: '',
  TextFieldComponent: PureTextField,
  onBlur: () => null,
  onFocus: () => null,
  optional: false,
  style: {},
};

export default PurePersonChooser;
