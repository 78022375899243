import React from 'react';
import WizardPage from 'ui/components/wizard/WizardPage';
import { API } from 'API';
import { pad, pascalCaseToSnakeCaseObject, getPhoneNumber } from 'utils';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';
import KBALocked from 'ui/KBALocked';

import PureButton from 'ui/components/pure-controls/PureButton';
import PureRadio from 'ui/components/pure-controls/PureRadio';
import WizardLabel from 'ui/components/WizardLabel';

import './KBAQuestions.scss';

export default class KBAQuestions extends NotarizationWizardPageMixin(WizardPage) {
  static baseUrl = 'kba/questions/';
  static showPrevNextButtons = false;
  static notarizationSection = NotarizationStatus.KBA;
  static enterNavigation = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      questions: null,
      score: null,
      answers: {},
      selectedQuestion: 0,
      mode: 'questions',
      timeoutAt: 0,
      clock: '',
      loading: false,
    };
  }

  tick = () => {
    const { timeoutAt } = this.state;
    const now = Date.now() / 1000,
      delta = Math.floor(timeoutAt / 1000 - now);
    const minutes = Math.floor(delta / 60),
      seconds = Math.floor(delta - minutes * 60);

    if (delta <= 0) {
      this.setState({ mode: 'failure', clock: '0:00' });
      this.stopTicking();
    } else {
      this.setState({ clock: `${minutes}:${pad(seconds, 2, '0')}` });
    }
  };

  startTicking = () => {
    this.stopTicking();
    this.tick();
    this.intervalId = window.setInterval(this.tick, 1000);
  };

  stopTicking = () => {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  getNewQuestions = () => {
    const lockedCodes = ['kba_locked', 'insufficient_questions', 'insufficient_new_questions'];
    API.getNotarizationKBAQuestions(this.testator.clientRelationship === 'spouse').then(
      response => {
        this.setState({
          questions: response.data.questions,
          timeoutAt: Math.floor(response.data.expires) * 1000,
          mode: 'questions',
          selectedQuestion: 0,
          answers: {},
        });
        this.startTicking();
      },
      err => {
        if (lockedCodes.includes(err.errorCode)) {
          this.clientDataHolder.props.history.push(KBALocked.url);
        } else {
          this.setState({ mode: 'failure' });
        }
      },
    );
  };

  componentDidMount() {
    super.componentDidMount();
    this.getNewQuestions();
  }

  componentWillUnmount() {
    this.stopTicking();
    super.componentWillUnmount();
  }

  handleSubmitAnswers = () => {
    if (this.state.loading) {
      return;
    }

    this.stopTicking();
    this.setState({ loading: true });

    const formattedAnswers = [];
    for (const [questionId, answerId] of Object.entries(this.state.answers)) {
      formattedAnswers.push(pascalCaseToSnakeCaseObject({ questionId, answerId }));
    }
    API.postNotarizationKBAAnswers(
      formattedAnswers,
      this.testator.clientRelationship === 'spouse',
    ).then(
      () => {
        this.setState({ mode: 'success', loading: false });
      },
      () => this.setState({ mode: 'failure', loading: false }),
    );
  };

  handleClick = (questionId, answerId) => () => {
    this.setState({
      answers: Object.assign({}, this.state.answers, {
        [questionId]: answerId,
      }),
    });
  };

  renderQuestion = question => (
    <React.Fragment key={question.id}>
      <WizardLabel label={question.question} />
      {question.answers.map(answer => (
        <PureRadio
          checked={this.state.answers[question.id] === answer.id}
          onClick={this.handleClick(question.id, answer.id)}
          key={answer.id}
        >
          {answer.answer}
        </PureRadio>
      ))}
    </React.Fragment>
  );

  advanceQuestion = () => {
    const { selectedQuestion, questions, answers } = this.state;
    if (selectedQuestion === questions.length - 1) {
      this.handleSubmitAnswers();
    } else if (answers[questions[selectedQuestion].id]) {
      this.setState({ selectedQuestion: selectedQuestion + 1 });
    }
  };

  goToNext = () => {
    super.goToNext();
  };

  renderStatus = () => {
    const { mode } = this.state;

    switch (mode) {
      case 'success':
        return (
          <div className="vvcenter center info-container">
            <div className="intro-check" />
            <h1>Identity confirmed!</h1>
            <p>Now it's time to create your electronic signature.</p>
            <PureButton onClick={this.goToNext} className="button--primary">
              Continue
            </PureButton>
          </div>
        );
      case 'failure':
        return (
          <div className="vvcenter center info-container">
            <div className="intro-error" />
            <h1>Failed ID Validation.</h1>
            <p>
              We were unable to verify your identity. Please try again or give us a call at{' '}
              {getPhoneNumber(this.clientData, this.serverData)}.
            </p>
            <PureButton onClick={() => this.props.goToPrev()} className="button--primary">
              Try again
            </PureButton>
          </div>
        );
      default:
        return null;
    }
  };

  renderPage() {
    const { selectedQuestion, questions, mode, clock, loading } = this.state;

    if (['success', 'failure'].includes(mode)) {
      return this.renderStatus();
    }

    if (!questions) {
      return null;
    }

    const displayedQuestions = questions.map(this.renderQuestion);

    return (
      <div>
        <div className="kba-questions">{displayedQuestions[selectedQuestion]}</div>
        <div className="wizard-bottom kba__footer">
          <div className="label-box">
            <div className="label">
              <span className="bold">Question: </span>
              {selectedQuestion + 1} of {questions.length}
            </div>

            <div className="label">
              <span className="bold">Time remaining: </span>
              {clock}
            </div>
          </div>

          <PureButton disabled={loading} onClick={this.advanceQuestion} className="button--primary">
            Next
          </PureButton>
        </div>
      </div>
    );
  }
}
