import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@wui/basics/typography';

const styles = {
  root: {
    '& + &': {
      marginTop: 32,
    },
  },
  content: {
    maxWidth: 400,
  },
};

class Tip extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h6">{this.props.title}</Typography>
        <div className={classes.content}>{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(Tip);
