import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Panel from '@wui/layout/panel';
import Button from '@wui/input/button';

import { useGlobalContext } from 'hooks';

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: 40,

    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
  },
}));

const StartPlanBox = ({ text }) => {
  const [processing, setProcessing] = useState(false);
  const classes = useStyles();
  const { clientData, clientDataHolder } = useGlobalContext();

  const onClick = () => {
    clientData.setStatusValid('sawMlpBundle');
    setProcessing(true);
    clientDataHolder.persistClientData().catch(setProcessing(false));
  };

  return (
    <Panel active paddingless>
      <Grid container direction="row" className={classes.wrap} justify="space-between" spacing={2}>
        <Grid item>
          <Typography variant="h3" style={{ lineHeight: 1.23 }}>
            {text}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            size="large"
            noMinWidth
            onClick={onClick}
            style={{ paddingLeft: 18, paddingRight: 18 }}
            disabled={processing}
          >
            Start my plan
          </Button>
        </Grid>
      </Grid>
    </Panel>
  );
};

StartPlanBox.propTypes = {
  text: PropTypes.string.isRequired,
};

export default observer(StartPlanBox);
