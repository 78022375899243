import React from 'react';
import { Nav } from './Nav.jsx';
import { ZendeskHolder } from 'ui/components/ZendeskHolder';
import { SnackbarProvider } from 'notistack';

export const withRoot = (Component, outerProps) => props => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    domRoot={document.body}
  >
    <React.Fragment>
      <Nav />
      <ZendeskHolder />
      <Component {...outerProps} {...props} />
    </React.Fragment>
  </SnackbarProvider>
);
