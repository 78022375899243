import React from 'react';
import IntroPage from 'ui/components/wizard/IntroPage';

import WuiApp from '@/WuiApp';
import MaterialWelcomeBack from '@s/about-you/WelcomeBack';

export default class WelcomeBack extends IntroPage {
  static baseUrl = 'welcome-back/';

  componentDidMount() {
    this.clientData.setStatusValid('sawWelcomeBack');
  }

  static customDisplay() {
    return () => (
      <WuiApp wrapContent={false}>
        <MaterialWelcomeBack />
      </WuiApp>
    );
  }
}
