import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Trash from '@a/images/trash.svg';

import Typography from '@wui/basics/typography';

const styles = {
  root: {
    display: 'flex',
    textAlign: 'right',
    flexDirection: 'column',
  },
  deleteButton: {
    display: 'flex',
    color: 'inherit',
    alignItems: 'center',
    textDecoration: 'none',

    '& img': {
      marginRight: 8,
    },

    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

class ListItemSideTitle extends React.Component {
  static propTypes = {
    list: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  get nOfM() {
    const { list, item } = this.props;
    const index = Math.max(
      0,
      list.findIndex(i => i === item),
    );
    return `${index + 1} of ${Math.max(1, list.length)}`;
  }

  render() {
    const { classes, list, onDelete } = this.props;

    const deleteClicked = event => {
      event.preventDefault();
      this.context.clientDataHolder.updateClientData(onDelete);
    };

    // If there is more than one item displayed,
    //   then items can be deleted. Otherwise,
    //   removal of the last item can occur when
    //   the user chooses that they don't have any
    //   of the item type.
    const showDelete = list.length > 1;

    return (
      <Typography variant="body2" component="span" className={classes.root}>
        <span>{this.nOfM}</span>
        {showDelete && (
          <a href="#" onClick={deleteClicked} className={classes.deleteButton}>
            <img height={12} src={Trash} alt="Delete" />
            Delete
          </a>
        )}
      </Typography>
    );
  }
}

export default withStyles(styles)(ListItemSideTitle);
