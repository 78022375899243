import React from 'react';
import WizardPage from 'ui/components/wizard/WizardPage';

import WuiApp from '@/WuiApp';
import { slugs } from 'urls';
import Family from '@s/about-you/Family';

export default class AboutYourFamily extends WizardPage {
  static baseUrl = slugs.family;

  static customDisplay() {
    return () => (
      <WuiApp wrapContent={false}>
        <Family />
      </WuiApp>
    );
  }
}
