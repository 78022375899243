import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { noOp } from 'utils';
import Form from '@wui/layout/form';
import Button from '@wui/input/button';
import BoundTextField from '@c/BoundTextField';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { genericServerError } from 'utils/constants/errors';
import { useGlobalContext } from 'hooks';

const ChangePhoneNumber = ({ onSuccess }) => {
  const { clientData, clientDataHolder, triggerValidation } = useGlobalContext();
  const [serverError, setServerError] = useState(false);

  const onSubmit = async event => {
    event.preventDefault();

    if (!triggerValidation()) {
      return;
    }

    try {
      await clientDataHolder.persistClientData();
    } catch {
      setServerError(true);
    }

    onSuccess();
  };

  return (
    <Form onSubmit={onSubmit} error={serverError && genericServerError}>
      <DimensionLimiter h={400}>
        <BoundTextField path="profile.phoneNumber" obj={clientData} label="Phone Number" />
      </DimensionLimiter>
      <div>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          processing={clientDataHolder.isLoading}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

ChangePhoneNumber.propTypes = {
  onSuccess: PropTypes.func,
};

ChangePhoneNumber.defaultProps = {
  onSuccess: noOp,
};

export default observer(ChangePhoneNumber);
