import React from 'react';

const QUICK_GROUP_MY_CHILD = 'My Child';
const QUICK_GROUP_MY_CHILDREN = 'My Children';

export const QUICK_GROUPS = [QUICK_GROUP_MY_CHILD, QUICK_GROUP_MY_CHILDREN];

export const QUICK_OPTION_GROUPS_DISPLAY = {
  'His or Her Children': 'his or her children',
};

export const QUICK_OPTION_GROUPS = Object.values(QUICK_OPTION_GROUPS_DISPLAY);

// Generate quick options groups for adding beneficiaries to an asset.
export function getQuickGroups(testator, excludedNames = []) {
  const groups = {};
  const peopleMap = {};
  const names = new Set();

  function addPerson(person, list) {
    if (!person.name || person.disinherited) {
      return;
    }

    list.add(person.name);

    // Only update the map of people if they
    //   are not already in the map, or the
    //   person instance being added has an
    //   address and the existing one does
    //   not. This ensures that a person with
    //   an address in one place has that address
    //   for any bequests.
    const addToMap =
      !peopleMap[person.name] || (!peopleMap[person.name].hasAddress && person.hasAddress);

    if (addToMap) {
      peopleMap[person.name] = person;
    }
  }

  function deletePerson(name) {
    names.delete(name);
    delete peopleMap[name];
    Object.values(groups).forEach(g => g.delete(name));
  }

  function convertPersonToGroup(person, groupName, deleteFromOthers = true) {
    if (deleteFromOthers) {
      deletePerson(person.name);
    }

    const group = new Set();
    addPerson(person, group);
    groups[groupName] = group;
  }

  // This will be converted to a quick option below if
  //   it is not removed by the excluded names.
  addPerson(testator.spouse, names);

  testator.executors.forEach(e => addPerson(e, names));
  testator.guardians.forEach(g => addPerson(g, names));
  testator.healthcareAgents.forEach(h => addPerson(h, names));
  testator.ownChildren.forEach(c => addPerson(c, names));
  [...testator.ownAssets, testator.residualAsset].forEach(a => {
    a.primaryBeneficiaries.forEach(p => {
      addPerson(p, names);
      p.alternateBeneficiaries.forEach(alt => addPerson(alt, names));
    });
  });

  // Remove people who should not be quick options.
  deletePerson(testator.name);
  excludedNames.forEach(deletePerson);

  // These special strings might have been stored in the
  //   database, so they should be removed since we will
  //   add in the appropriate groups below.
  QUICK_OPTION_GROUPS.forEach(deletePerson);

  // If the spouse is still in the list of names,
  //   convert it to a special button.
  if (names.has(testator.spouse.name)) {
    convertPersonToGroup(testator.spouse, 'My Spouse');
  }

  // If the testator has no children, or a child has already
  //   been removed (or never added) to the group of names,
  //   then don't create child groups.
  const createChildrenGroups =
    testator.ownChildren.length && testator.ownChildren.every(c => names.has(c.name));

  if (createChildrenGroups) {
    if (testator.ownChildren.length === 1) {
      convertPersonToGroup(testator.ownChildren[0], QUICK_GROUP_MY_CHILD);
    } else {
      const childrenGroup = new Set();
      groups[QUICK_GROUP_MY_CHILDREN] = childrenGroup;
      testator.ownChildren.forEach(c => addPerson(c, childrenGroup));
    }
  }

  // Convert remaining individual people into groups with one
  //   person in them.
  names.forEach(name => convertPersonToGroup(peopleMap[name], name, false));

  // Convert names in groups to the actual people.
  Object.entries(groups).forEach(([groupName, group]) => {
    groups[groupName] = Array.from(group).map(name => peopleMap[name]);
  });

  return groups;
}
