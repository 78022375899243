import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { andList, getCompanyName } from 'utils';
import Modal from '@wui/basics/modal';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';
import TabDivider from '@wui/layout/tabDivider';
import Typography from '@wui/basics/typography';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { NotifiedPersonPermissions } from 'models/client-data/enums';

import { ReactComponent as WillingLogo } from '@willing-shared/assets/images/logo.svg';

const styles = theme => ({
  background: {
    [theme.breakpoints.up('md')]: {
      padding: [[24, 64]],
    },
    background: theme.palette.background.default,
  },
});

class EmailPreview extends React.Component {
  static propTypes = {
    roles: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    person: PropTypes.object.isRequired,
    testator: PropTypes.object.isRequired,
    classes: PropTypes.shape({}).isRequired,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  renderAccess() {
    const { roles, testator } = this.props;

    return (
      <React.Fragment>
        {testator.firstName} has named you to be {testator.hisHer} {andList(roles)}. This says a lot
        about {testator.hisHer} trust and confidence in you, and many people feel honored to play
        this role for a loved one.
        <br />
        <br />
        Click on the link below to confirm for {testator.firstName} that you received this message
        and to learn more. (This does not obligate you to serve.)
      </React.Fragment>
    );
  }

  renderNotify() {
    const { roles, testator } = this.props;

    const roleString = roles.length
      ? `has named you to be ${testator.hisHer} ${andList(roles)} and`
      : null;
    const companyName = getCompanyName(this.context.clientDataHolder.serverData);

    return (
      <React.Fragment>
        {testator.firstName} {roleString} wanted to notify you so that you're aware {testator.heShe}{' '}
        has a plan in place. Awareness among loved ones is a key part of a good estate plan.
        <br />
        <br />
        If you haven't already done so, now could be a good time to start your own will. Click on
        the link below to learn more about {companyName}.
      </React.Fragment>
    );
  }

  render() {
    const { person, classes, onClose, testator } = this.props;

    const bodyContent =
      person.permissions === NotifiedPersonPermissions.ACCESS
        ? this.renderAccess()
        : this.renderNotify();

    const companyName = getCompanyName(this.context.clientDataHolder.serverData);
    return (
      <Modal open onClose={onClose} title={`Preview of Message to ${person.name}`}>
        <div className={classes.background}>
          <DimensionLimiter h={400}>
            <Panel>
              <WillingLogo />
              <TabDivider noMargin />
              <Spacer v={20} />

              <Typography component="div">
                Dear {person.firstName},
                <br />
                <br />
                This message is to let you know that {testator.name} has created {testator.hisHer}{' '}
                will and estate plan with {companyName}.
                <br />
                <br />
                {bodyContent}
              </Typography>
            </Panel>
          </DimensionLimiter>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(EmailPreview);
