import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup';
import AgentPersonChooser from 'material-ui/components/AgentPersonChooser';
import { AgentType } from 'models/client-data/enums';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@wui/basics/typography';
import Fab from '@wui/input/fab';

import { useGlobalContext, useToggledState } from 'hooks';

const PersonPage = ({
  agentName,
  agentClass,
  peopleBuilderMethod,
  primaryPath,
  alternatePath,
  title,
  caption,
  children,
}) => {
  const { clientData, testator } = useGlobalContext();
  const [showAlternateField, toggleAlternateField] = useToggledState(false);
  const generateExtractFunc = oppositeAgent => newClientData =>
    peopleBuilderMethod(newClientData, testator, oppositeAgent);

  const testatorHasAlternate = testator.getValue(alternatePath);

  useEffect(() => {
    if (testatorHasAlternate && !showAlternateField) {
      toggleAlternateField();
    }
  }, [testatorHasAlternate, showAlternateField, toggleAlternateField]);

  const alternateChooser = showAlternateField ? (
    <AgentPersonChooser
      agentClass={agentClass}
      agentType={AgentType.ALTERNATE}
      extractInitialPeople={generateExtractFunc(testator.getValue(primaryPath))}
      allowNull={true}
      optional={true}
      path={alternatePath}
      label={`Backup ${agentName} (optional)`}
    />
  ) : (
    <Fab
      variant="outlined"
      color="primary"
      disabled={!testator.getValue(primaryPath)}
      onClick={toggleAlternateField}
      data-path="wantsAlternate"
      size="small"
      icon={AddIcon}
      label={`Add a backup ${agentName.toLowerCase()}`}
    />
  );

  const primaryValue = testator.getValue(primaryPath);
  const primaryName = (primaryValue && primaryValue.name) || 'your first choice';

  const masterHeading = title || `Naming ${agentName}s`;
  const subHeading = clientData.isPlanningForSpouse ? testator.name : '';

  return (
    <SpacedFieldGroup masterHeading={masterHeading} subHeading={subHeading} caption={caption}>
      <AgentPersonChooser
        agentClass={agentClass}
        agentType={AgentType.PRIMARY}
        extractInitialPeople={generateExtractFunc(testator.getValue(alternatePath))}
        path={primaryPath}
        label={`Primary ${agentName}`}
      />
      <Typography variant="body1">
        If {primaryName} is unwilling or unable to serve, who would you like to name instead?
      </Typography>
      {alternateChooser}
      {children}
    </SpacedFieldGroup>
  );
};

PersonPage.propTypes = {
  // What to refer to the agent class as - "Executor" or "Guardian" or so on
  agentName: PropTypes.string.isRequired,

  // Actual class used to build the agent
  agentClass: PropTypes.func.isRequired,

  // The extractor method to use from the peopleBuilder
  peopleBuilderMethod: PropTypes.func.isRequired,

  // Path to the primary agent
  primaryPath: PropTypes.string.isRequired,

  // Path to the alternate agent
  alternatePath: PropTypes.string.isRequired,

  // Title of the page, if not automatically generated
  title: PropTypes.string,

  // Caption for our layout component
  caption: PropTypes.string,

  // Optional extra fields
  children: PropTypes.node,
};

PersonPage.defaultProps = { children: null, caption: '', title: '' };

export default observer(PersonPage);
