import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dot: {
    display: 'inline-block',
    height: 8,
    width: 8,
    borderRadius: 4,
    marginRight: 8,
    background: theme.palette.grey.placeholderText,

    '&:last-child': {
      marginRight: 0,
    },
  },
});

const PasswordIndicator = ({ classes }) => (
  <React.Fragment>
    {Array(8)
      .fill()
      .map((_, i) => (
        <span className={classes.dot} key={i} />
      ))}
  </React.Fragment>
);

PasswordIndicator.propTypes = {
  classes: PropTypes.shape({}),
};

export default withStyles(styles)(PasswordIndicator);
