import React from 'react';
import PropTypes from 'prop-types';
import { BoundControl } from 'ui/components/BoundControl';
import { PureSelect } from 'ui/components/pure-controls/PureSelect';

export class Select extends BoundControl {
  static propTypes = Object.assign(
    {
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      error: PropTypes.bool,
    },
    BoundControl.propTypes,
  );

  constructor(props, context) {
    super(props, context);

    this.state = {
      active: false,
    };
  }

  render() {
    const className = this.hasValidationErrors() || this.props.error ? 'select-input--error' : '';

    return (
      <div className="inline-block">
        <PureSelect
          onChange={value => this.setRawValue(value)}
          value={this.getRawValue()}
          onClick={this.toggleActive}
          dataPath={this.props.path}
          className={className}
        >
          {this.props.children}
        </PureSelect>
        {this.renderValidation()}
      </div>
    );
  }
}
