import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';

class SideBySide extends React.Component {
  static propTypes = {
    left: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    right: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  };

  getPropsForString = item => {
    const { left } = this.props;

    if (item === left) {
      return { variant: 'h3' };
    }

    return { variant: 'h6', bold: false };
  };

  wrapOrRender(item) {
    if (!item) {
      return null;
    } else if (React.isValidElement(item)) {
      return item;
    }

    return <Typography {...this.getPropsForString(item)}>{item}</Typography>;
  }

  render() {
    const { left, right, children } = this.props;

    // If you know both children will be nodes, you can just drop them in, otherwise props suffice to wrap
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        alignItems="center"
        alignContent="center"
        style={{ marginBottom: 20 }}
      >
        {children}
        {this.wrapOrRender(left)}
        {this.wrapOrRender(right)}
      </Grid>
    );
  }
}

export default SideBySide;
