import WizardSection from 'ui/components/wizard/WizardSection';
import NotaryDetails from 'ui/execution/details/pages/NotaryDetails';
import { Urls } from 'urls';

export default class SectionNotaryDetails extends WizardSection {
  static url = Urls.notaryDetails;
  static title = 'Notary Details';
  static description = 'Edit your personal details';
  static finalUrl = '/notary/';
  static fallbackUrl = '/notary/';

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(NotaryDetails);
  }
}
