/** Contains all the information for visiting a WizardPage - section, page, key and can form the final URL for it */
export default class PageVisit {
  constructor(sectionClass, pageClass, key) {
    this.sectionClass = sectionClass;
    this.pageClass = pageClass;
    this.key = key;
    this.url =
      this.sectionClass.url + this.pageClass.url.replace(':key', encodeURIComponent(this.key));
  }

  equals(o) {
    return (
      this.sectionClass === o.sectionClass && this.pageClass === o.pageClass && this.key === o.key
    );
  }
}
