import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Help from '@c/Help';
import Summary from '@c/Summary';
import Tabs from '@wui/layout/tabs';
import { WuiProgressPlaceholder } from '@c/progress';
import Theme from '@willing-shared/components/Theme';

class TipsAndSummarySidebar extends React.Component {
  static propTypes = {
    borderless: PropTypes.bool,
    includeProgress: PropTypes.bool,
    includeSummary: PropTypes.bool,
  };

  static defaultProps = {
    borderless: false,
    includeProgress: false,
    includeSummary: true,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
  };

  state = {
    tab: 0,
  };

  render() {
    const { includeProgress, includeSummary, borderless } = this.props;
    const { tab } = this.state;
    const noPaddingSections = ['progress', 'summary'];

    const tabs = [];

    if (includeProgress) {
      tabs.push({
        id: 'progress',
        name: 'Progress',
        content: <WuiProgressPlaceholder />,
        special: true,
      });
    }

    if (includeSummary) {
      tabs.push({
        id: 'summary',
        name: 'Summary',
        content: (
          <MuiThemeProvider theme={Theme}>
            <Summary />
          </MuiThemeProvider>
        ),
        special: true,
      });
    }

    tabs.push({
      id: 'help',
      name: 'Help',
      content: <Help />,
      special: true,
    });

    return (
      <Tabs
        tabs={tabs}
        selected={tab}
        onSelect={id => this.setState({ tab: id })}
        noContentPadding={noPaddingSections.includes(tabs[tab].id)}
        smallTabs
        tabsFillWidth={false}
        borderless={borderless}
        // TODO: we need to update wui to allow other props to be passed to the tabs like so
        //...props so we can pass extra props
        // selectionFollowsFocus
      />
    );
  }
}

export default TipsAndSummarySidebar;
