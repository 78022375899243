import React from 'react';
import PropTypes from 'prop-types';
import { matchPath, Redirect } from 'react-router-dom';
import Card from 'ui/components/Card';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { Urls } from 'urls';
import PageVisit from 'ui/components/wizard/PageVisit';
import Footer from 'ui/Footer';
import { createErrorFromData } from 'API';
import { debugLog } from 'debug';
import { joinClass } from 'utils';
import sentry from '@willing-shared/utils/sentry';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';

function excessChangeError(field) {
  return `Looks like your ${field} has been changed too many times. Please contact us so we can help you change it.`;
}

export default class WizardSection extends ClientDataReferer {
  static url;
  static title;
  static description;
  static dashboardEditText = 'Edit';
  static mobileDescription;
  static finalUrl = Urls.planDashboard;
  static showCloseButton = true;
  static showFooter = true;
  static isPlanSection = false;

  // By default, an invalid page in a section will
  //   fallback to the start of the section. If this
  //   variable is overridden, the invalid page will
  //   fallback here.
  static fallbackUrl = '';

  static propTypes = {
    finalUrl: PropTypes.string,
    title: PropTypes.string,
  };

  static childContextTypes = {
    section: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    const locationState = this.clientDataHolder.props.location.state;
    this.referredByReview = locationState && locationState.referredByReview;

    this.state = { nextPending: false };
  }

  // Override this if you like, but make sure to include
  //   anything that this passes by calling the `super`
  //   method.
  getSectionContext() {
    return {
      persist: this.persist,
      url: this.constructor.url,
      canVisitPath: this.canVisitPath,
      nextPending: this.state.nextPending,
      postErrorMessage: this.state.errorMessage,
    };
  }

  getChildContext() {
    return {
      section: this.getSectionContext(),
    };
  }

  /** Calls getPageFlow and returns an array of PageVisit objects */
  static getPageVisits(clientData, serverData, experimentContext) {
    const pageVisits = [];
    const visit = (page, key) => {
      // If the page has no user actions and the
      //   experiment is running, don't visit the
      //   page.
      if (page.noUserActions && experimentContext.actionPagesOnly) {
        return;
      }

      pageVisits.push(new PageVisit(this, page, key));
    };

    this.getPageFlow(clientData, serverData, experimentContext, visit);
    return pageVisits;
  }

  // Returns the first URL that is enabled for this section.
  static firstUrl(clientData, serverData, experimentContext) {
    return this.getPageVisits(clientData, serverData, experimentContext)[0].url;
  }

  /** Finds the current visit in getPageVisits and returns the URL of the next page (or finalUrl if not found). */
  getNextUrl = currentPageVisit => {
    const pageVisits = this.pageVisits;
    for (const [i, pageVisit] of pageVisits.entries()) {
      // Don't try to go past the last page
      if (pageVisit.equals(currentPageVisit) && i < pageVisits.length - 1) {
        return pageVisits[i + 1].url;
      }
    }

    // If someone was sent to this section by the review
    //   page, we send them back there instead of the
    //   dashboard once the section is complete
    if (this.referredByReview && this.clientData.isPlanValid()) {
      return SectionDocuments.reviewUrl;
    }

    let url = this.constructor.finalUrl;

    // support dynamic finalUrls
    if (this.finalUrl) {
      url = this.finalUrl;
    } else if (
      this.props.finalUrl &&
      !this.clientData._isKeyValid(`section:${this.props.finalUrl}`)
    ) {
      url = this.props.finalUrl;
    }

    return {
      pathname: url,
      state: {
        referredByReview: this.referredByReview,
      },
    };
  };

  goToPrev() {
    this.props.history.goBack();
  }

  updateInvalidations = beforeInvalidation => {
    if (beforeInvalidation) {
      beforeInvalidation();
    }

    this.clientDataHolder.updateInvalidations(this.constructor);
  };

  persist = async (triggerValidation = true, beforeInvalidation = null) => {
    this.setState({ nextPending: true });

    // Send a signal for all controls on the page to report validation errors. Bail from the next if there are any.
    if (triggerValidation && !this.triggerValidation()) {
      this.setState({ nextPending: false });
      return false;
    }

    this.updateClientData(() => {
      this.updateInvalidations(beforeInvalidation);
    });

    const failure = error => {
      if (error.getErrorMessage) {
        this.setState({
          postError: true,
          errorMessage: error.getErrorMessage({
            email_mismatch: 'You may have logged in as a different user. Please refresh the page.',
            invalid_zip_code: `${error.zipCode} is not a valid US zip code.`,
            excess_name_changes: excessChangeError('name'),
            excess_zip_changes: excessChangeError('state'),
          }),
        });
      } else {
        sentry.captureException(error);
        this.setState({
          postError: true,
          errorMessage: 'Unknown error, please contact support.',
        });
      }
      return false;
    };

    // Persist the information and go to the next page on success.
    try {
      const response = await this.clientDataHolder.persistClientData();

      // For some reason, these popup errors return an HTTP status of
      //   200, but we want to treat them as an error.
      if (response.popup && !response.success) {
        return failure(createErrorFromData(response));

        // At other times, the HTTP status doesn't match the `success`
        //   field, and the error code is unusable, so we need to
        //   throw a generic error.
      } else if (!response.success) {
        return failure(new Error('HTTP Status Code and response in JSON do not match.'));
      }

      this.setState({ postError: false, errorMessage: '' });
      return true;
    } catch (error) {
      return failure(error);
    } finally {
      this.setState({ nextPending: false });
    }
  };

  async goToNext(pageVisits, currentPageVisit) {
    const { redirectToReview } = this.context.experiments;
    const wasValid = this.clientData.isPlanValid();

    const persisted = await this.persist(true, () => {
      this.clientData.setPageVisitValid(currentPageVisit);
    });

    if (persisted) {
      if (redirectToReview && !wasValid && this.clientData.isPlanValid()) {
        this.props.history.push(SectionDocuments.reviewUrl);
      } else {
        this.props.history.push(this.getNextUrl(currentPageVisit));
      }
    }
  }

  get pageVisits() {
    return this.constructor.getPageVisits(
      this.clientData,
      this.serverData,
      this.context.experiments,
    );
  }

  canVisitPath = path => Boolean(this.currentPageVisit(null, path));

  // Return the current page visit. If there is an invalid page visit,
  //   return false. This can happen if the previous pages in the section
  //   are not valid, or the page name in the URL is invalid.
  currentPageVisit(pageVisits, path = null) {
    const pathname = path || this.props.location.pathname;
    pageVisits = pageVisits || this.pageVisits;

    // If the path is exactly the section route, return the first url
    if (
      matchPath(pathname, {
        path: decodeURI(this.constructor.url),
        exact: true,
      })
    ) {
      return pageVisits[0];
    }

    // Otherwise, match the current URL against the visits to find the correct page to render
    for (const pageVisit of pageVisits) {
      window.matchPath = matchPath;
      if (
        matchPath(pathname, {
          path: decodeURI(pageVisit.url),
          exact: true,
        })
      ) {
        return pageVisit;
      }

      // Only allow the current page (above) to be invalid. Otherwise send the user to
      //   the beginning of the section.
      if (!this.clientData.isPageVisitValid(pageVisit)) {
        debugLog(`Falling back because previous pages are invalid: ${pathname}`, false);
        return false;
      }
    }

    debugLog(`Falling back because of no current page visit: ${pathname}`, false);
    return false;
  }

  render() {
    let pageVisits = this.pageVisits;
    let currentPageVisit = this.currentPageVisit(pageVisits);
    if (!currentPageVisit) {
      return (
        <Redirect to={this.constructor.fallbackUrl || this.firstUrlForSection(this.constructor)} />
      );
    }

    if (this.constructor.isPlanSection && this.serverData.hasNotarizedOnline) {
      return <Redirect to={Urls.file} />;
    }

    const renderedPage = (
      <div
        data-wizardpage={currentPageVisit.url}
        className={currentPageVisit.pageClass.wizardPageClass}
      >
        <currentPageVisit.pageClass
          key={currentPageVisit.url}
          fullUrl={currentPageVisit.url}
          keyParam={currentPageVisit.key}
          goToPrev={this.goToPrev.bind(this)}
          showCloseButton={this.constructor.showCloseButton}
          closeUrl={this.constructor.fallbackUrl || this.clientDataHolder.dashboardUrl}
          goToNext={this.goToNext.bind(this, pageVisits, currentPageVisit)}
          appLocation={this.constructor.appLocation}
        />
      </div>
    );

    const { isMaterial, cardClass, cardwrapperClass, hideNav } = currentPageVisit.pageClass;
    const hasCustomDisplay = currentPageVisit.pageClass.customDisplay(this.context.experiments);

    if (hasCustomDisplay || isMaterial) {
      return renderedPage;
    }

    return (
      <div
        className={joinClass('cardwrapper', cardwrapperClass, hideNav && 'cardwrapper--hide-nav')}
      >
        <Card className={cardClass}>
          {renderedPage}

          {this.state.postError && <div className="error-display">{this.state.errorMessage}</div>}

          {this.constructor.showFooter && currentPageVisit.pageClass.showFooter && <Footer />}
        </Card>
      </div>
    );
  }

  static getPaths(clientData, serverData, experimentContext, props) {
    const paths = this.getPageVisits(clientData, serverData, experimentContext).map(visit => [
      visit.url,
      this,
      props,
      this.url,
    ]);

    if (!paths.some(p => p[0] === this.url)) {
      paths.push([this.url, this, props, this.url]);
    }

    return paths;
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }

  static isComplete() {
    // default to incomplete, but allow individual sections to override
    return false;
  }
}
