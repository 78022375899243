import { useObjOrTestator, useGlobalContext } from 'hooks';

const useDataBindings = ({ obj: externObj, path, raw = true, onChange = () => null }) => {
  const { updateClientData } = useGlobalContext();
  const obj = useObjOrTestator(externObj);

  const setWithUpdate = (method, value) => {
    updateClientData(() => {
      obj[method](path, value);
    });

    obj.validate();
  };

  const setValue = value => setWithUpdate('setValue', value);
  const setRawValue = value => setWithUpdate('setRawValue', value);

  const getValue = () => obj.getValue(path);
  const getRawValue = () => obj.getRawValue(path);

  const [getter, setter] = raw ? [getRawValue, setRawValue] : [getValue, setValue];

  const updateValue = value => {
    const previousValue = getter();
    setter(value);
    onChange(previousValue, value);
  };

  return [getter(), updateValue];
};

export default useDataBindings;
