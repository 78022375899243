import React from 'react';
import EducationPage from 'ui/components/wizard/EducationPage';

import WuiApp from '@/WuiApp';
import MaterialPlanNotice from '@s/about-you/PlanNotice';

export default class PlanNotice extends EducationPage {
  static baseUrl = 'plan-notice/';
  ctaText = 'Continue';

  static customDisplay() {
    return () => (
      <WuiApp wrapContent={false}>
        <MaterialPlanNotice />
      </WuiApp>
    );
  }
}
