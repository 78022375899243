import PageVisit from 'ui/components/wizard/PageVisit';
import { WArray, WObject, WString } from 'models/types';
import { ClientDataObject } from 'models/type-system';

class ValidWizardItems extends ClientDataObject {
  static keyFieldName = 'url';

  static fields = {
    url: new WString(),
    markedValidAt: new WString(),
  };
}

export const ValidWizardItemsMixin = superclass =>
  class extends superclass {
    static fields = {
      validWizardItems: new WArray(new WObject(ValidWizardItems)),
    };

    static planKey = 'plan';
    static fileKey = 'file';
    static appKey = 'app';
    static statusKey = 'status';

    getPageVisitKey(visit) {
      return `page:${visit.url}`;
    }

    getSectionKey(section) {
      return `section:${section.url}`;
    }

    _setKeyValid(key) {
      for (const wizardPage of this.validWizardItems) {
        if (wizardPage.url === key) {
          wizardPage.markedValidAt = new Date().toISOString();
          return;
        }
      }
      this.validWizardItems.push(
        new ValidWizardItems({
          url: key,
          markedValidAt: new Date().toISOString(),
        }),
      );
    }

    _setKeyInvalid(key) {
      for (const [i, wizardPage] of this.validWizardItems.entries()) {
        if (wizardPage.url === key) {
          this.validWizardItems.splice(i, 1);
          return;
        }
      }
    }

    _isKeyValid(key) {
      return this.validWizardItems.some(i => i.url === key);
    }

    setPageVisitValid(visit) {
      this._setKeyValid(this.getPageVisitKey(visit));
    }

    setPageVisitInvalid(visit) {
      this._setKeyInvalid(this.getPageVisitKey(visit));
    }

    isPageVisitValid(visit) {
      return this._isKeyValid(this.getPageVisitKey(visit));
    }

    isPageValid(section, page) {
      return this.isPageVisitValid(new PageVisit(section, page));
    }

    setSectionValid(section) {
      this._setKeyValid(this.getSectionKey(section));
    }

    setSectionInvalid(section) {
      this._setKeyInvalid(this.getSectionKey(section));
    }

    isSectionValid(section) {
      // the second condition allows sections to define custom logic for completion
      return this._isKeyValid(this.getSectionKey(section)) || section.isComplete(this);
    }

    isSectionInProgress(section) {
      return (
        !this.isSectionValid(section) &&
        this.validWizardItems.some(i => i.url.startsWith(`page:${section.url}`))
      );
    }

    setSectionPagesInvalid(section) {
      this.setSectionInvalid(section);

      // Remove pages with the section prefix.
      // Iterate in reverse so that we can splice while iterating safely.
      const prefix = `page:${section.url}`;
      for (let i = this.validWizardItems.length - 1; i >= 0; i--) {
        if (this.validWizardItems[i].url.startsWith(prefix)) {
          this.validWizardItems.splice(i, 1);
        }
      }
    }

    setPlanValid() {
      this._setKeyValid(this.constructor.planKey);
    }

    setPlanInvalid() {
      this._setKeyInvalid(this.constructor.planKey);
    }

    isPlanValid() {
      return this._isKeyValid(this.constructor.planKey);
    }

    setFileValid() {
      this._setKeyValid(this.constructor.fileKey);
    }

    setFileInvalid() {
      this._setKeyInvalid(this.constructor.fileKey);
      this.setAppInvalid();
    }

    isFileValid() {
      return this._isKeyValid(this.constructor.fileKey);
    }

    setAppValid() {
      this._setKeyValid(this.constructor.appKey);
    }

    setAppInvalid() {
      this._setKeyInvalid(this.constructor.appKey);
    }

    isAppValid() {
      return this._isKeyValid(this.constructor.appKey);
    }

    // e.g. sawWelcomeBack
    setStatusValid(key) {
      return this._setKeyValid(`${this.constructor.statusKey}:${key}`);
    }

    setStatusInvalid(key) {
      return this._setKeyInvalid(`${this.constructor.statusKey}:${key}`);
    }

    isStatusValid(key) {
      return this._isKeyValid(`${this.constructor.statusKey}:${key}`);
    }
  };
