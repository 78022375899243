import axios from 'axios';
import cookies from 'browser-cookies';

import tokenFetcher from '@u/tokens';
import standardizeErrorResponse, { registerErrorCodes } from './errors';

const http = axios.create({
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
});

// Add the CSRF token to the headers for each request. This
//   is needed because or API is not fully RESTfull and we
//   use cookies for authentication.
http.interceptors.request.use(async config => {
  config.headers['X-CSRFToken'] = cookies.get('csrftoken');

  if ('formData' in config) {
    const data = new FormData();
    Object.entries(config.formData).forEach(([key, value]) => {
      data.set(key, value);
    });
    config.data = data;
  }

  if (!config.skipAuthentication) {
    const accessToken = await tokenFetcher.accessToken;
    // If it is available, set the access token to authorize the user
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return config;
});

// Standardize responses and errors so they return the
//   data we are interested in and nothing else.
http.interceptors.response.use(
  response => {
    const { data } = response;

    // If the request is marked as returning success, then
    //   only return the data when successful. Otherwise,
    //   throw an error so the promise's `catch` can handle
    //   the problem instead of relying on its `then` call.
    if (response.config.withSuccess) {
      if (data.success) {
        return data;
      }

      // This is not caught by the error handling
      //   below, so we need to standardize the
      //   data here as well.
      throw standardizeErrorResponse(data);
    }

    // If not marked as returning success, only return the
    //   data and not all of the underlying response
    //   information.
    return data;
  },
  // If an error occurs, make sure the data returned by
  //   the promise is always in the same format, with a
  //   minimum of an `errorCode` field set in the data.
  error => {
    let errorData = {};

    // We got data back from the backend. If this is not
    //   the case, there was likely a timeout.
    if (error.response) {
      errorData = error.response.data || {};
    }

    return Promise.reject(standardizeErrorResponse(errorData));
  },
);

function addWithSuccessToConfig(config) {
  return {
    ...config,
    withSuccess: true,
  };
}

// Requests that use these methods will look at the `success`
//   key of the response to determine if an error occurred or
//   not (in addition to standard error handling).
http.get.success = (url, config) => http.get(url, addWithSuccessToConfig(config));
http.post.success = (url, data, config) => http.post(url, data, addWithSuccessToConfig(config));
http.patch.success = (url, data, config) => http.patch(url, data, addWithSuccessToConfig(config));

export default function createApi(modules) {
  const methods = {};

  Object.entries(modules).forEach(([module, definition]) => {
    methods[module] = definition.methods(http);
    registerErrorCodes(definition.errors || {});
  });

  return methods;
}
