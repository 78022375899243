import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { isWidthDown } from '@material-ui/core/withWidth';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Link as RouterLink } from 'react-router-dom';

import WuiApp from '@/WuiApp';
import Link from '@wui/basics/link';
import Form from '@wui/layout/form';
import Panel from '@wui/layout/panel';
import Button from '@wui/input/button';
import WuiGrid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Textbox from '@wui/input/textbox';
import CustomIcon from '@wui/basics/customIcon';
import BoundTextField from '@c/BoundTextField';
import Typography from '@wui/basics/typography';
import BorderedBox from '@wui/layout/borderedBox';
import MaxPageWidth from '@wui/layout/maxPageWidth';
import TitleWithIcon from '@wui/layout/titleWithIcon';
import { genericErrorText } from '@wui/basics/genericError';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { formatPhoneNumber } from '@willing-shared/utils/text';
import EmailWithWrapping from '@willing-shared/components/emailWithWrapping';

import Sign from '@a/images/sign.png';
import Share from '@a/images/share.png';
import FileAndStore from '@a/images/file-and-store.png';
import KeepPlanUpdated from '@a/images/keep-plan-updated.png';
import HelpLovedOnes from '@willing-shared/assets/images/help-loved-ones.png';
import Footer from './Footer';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';

const keepPlanUpdatedContent = () => (
  <React.Fragment>If your circumstances or wishes change, update your plan.</React.Fragment>
);

const steps = [
  [
    'Sign',
    'Follow the instructions on each document to properly sign it with two witnesses and/or a notary.',
    Sign,
  ],
  [
    'File and Store',
    'Some documents must be filed, while others are stored privately. Follow the instructions for each one.',
    FileAndStore,
  ],
  [
    'Share',
    'Tell your agents and loved ones where everything is stored, so they can find what they need when the time comes.',
    Share,
  ],
  [
    'Help Loved Ones',
    'Make sure your agents and loved ones understand how your plan works.',
    HelpLovedOnes,
  ],
  ['Keep Plan Updated', keepPlanUpdatedContent, KeepPlanUpdated],
];

export default class Success extends React.Component {
  static url = '/checkout/success';
  static propTypes = {
    title: PropTypes.string,
  };
  static contextTypes = {
    experiments: PropTypes.shape({}),
    clientDataHolder: PropTypes.shape({}),
  };

  constructor(...args) {
    super(...args);

    this.state = {
      error: false,
      loading: false,
      phoneNumberPanelClosed: Boolean(this.savedPhoneNumber),
    };
  }

  get savedPhoneNumber() {
    return this.context.clientDataHolder.clientDataPristine.profile.phoneNumber;
  }

  get phoneFormInvalid() {
    return this.context.clientDataHolder.clientData.profile.validationErrors.phoneNumber.length > 0;
  }

  togglePhoneNumberPanel = event => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const { phoneNumberPanelClosed } = this.state;
    this.setState({ phoneNumberPanelClosed: !phoneNumberPanelClosed });
  };

  skipPhoneNumber = () => {
    this.context.clientDataHolder.updateClientData(() => {
      this.context.clientDataHolder.clientData.profile.setRawValue(
        'phoneNumber',
        this.savedPhoneNumber,
      );
    });

    this.togglePhoneNumberPanel();
  };

  submitPhoneNumber = event => {
    event.preventDefault();

    if (this.phoneFormInvalid) {
      return;
    }

    this.setState({ error: false, loading: true });
    this.context.clientDataHolder
      .persistClientData()
      .then(this.togglePhoneNumberPanel)
      .catch(() => this.setState({ error: true }))
      .finally(() => this.setState({ loading: false }));
  };

  renderCheck(green = false) {
    const colorPicker = theme =>
      green ? theme.palette.green.success : theme.palette.grey.textboxBorder;

    return <CustomIcon color={colorPicker} src={CheckCircleIcon} />;
  }

  renderPaymentPanel() {
    return (
      <Panel>
        <TitleWithIcon variant="h5" title="Payment Successful" icon={this.renderCheck(true)}>
          <Typography variant="intro">
            A receipt has been sent to&nbsp;
            <EmailWithWrapping email={this.context.clientDataHolder.clientData.email} />.
          </Typography>
        </TitleWithIcon>
      </Panel>
    );
  }

  renderPhoneDisplay(title, description, firstItemProps, secondItemProps, content) {
    return (
      <Panel>
        <Grid container spacing={2} alignItems="center" justify="space-between">
          <Grid item md="auto" {...firstItemProps}>
            <TitleWithIcon
              variant="h5"
              title={title}
              icon={this.renderCheck(this.savedPhoneNumber)}
            >
              <Typography variant="intro">{description}</Typography>
            </TitleWithIcon>
          </Grid>
          <Grid item {...secondItemProps}>
            {content}
          </Grid>
        </Grid>
      </Panel>
    );
  }

  renderPhoneStatus() {
    const content = (
      <Typography component="div">
        <BorderedBox>
          <Grid container justify="space-between">
            <Grid item>{formatPhoneNumber(this.savedPhoneNumber)}</Grid>
            <Grid item>
              <Link href="#" onClick={this.togglePhoneNumberPanel}>
                Edit
              </Link>
            </Grid>
          </Grid>
        </BorderedBox>
      </Typography>
    );

    return this.renderPhoneDisplay(
      'Phone number saved!',
      "We'll call if we need to reach you.",
      { sm: 12 },
      { md: 5, xs: 12 },
      content,
    );
  }

  renderPhoneSkipped() {
    const content = (
      <Button
        variant="outlined"
        onClick={this.togglePhoneNumberPanel}
        fullWidth={width => isWidthDown('xs', width)}
      >
        Add phone number
      </Button>
    );

    return this.renderPhoneDisplay(
      'Add your phone number',
      'In case our team needs to contact you regarding your plan.',
      { sm: 7 },
      { sm: 'auto', xs: 12 },
      content,
    );
  }

  componentDidMount() {
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }

  renderPhoneForm() {
    const { error, loading } = this.state;

    return (
      <Panel special maxContentWidth={360} indicatorMovesContent={false}>
        <TitleWithIcon
          variant="h5"
          hideIconOnPhone
          icon={this.renderCheck()}
          title="We're here to help"
        >
          <Typography variant="intro">We'll call if we need to reach you.</Typography>
        </TitleWithIcon>

        <Form onSubmit={this.submitPhoneNumber} error={error && genericErrorText}>
          <BoundTextField
            path="profile.phoneNumber"
            component={Textbox}
            label="Phone Number"
            obj={this.context.clientDataHolder.clientData}
          />
        </Form>

        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Button
              fullWidth
              noMinWidth
              color="primary"
              variant="contained"
              processing={loading}
              onClick={this.submitPhoneNumber}
              disabled={this.phoneFormInvalid}
            >
              Save
            </Button>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              fullWidth
              noMinWidth
              variant="outlined"
              disabled={loading}
              onClick={this.skipPhoneNumber}
            >
              Skip
            </Button>
          </Grid>
        </Grid>
      </Panel>
    );
  }

  renderPhonePanel() {
    const { phoneNumberPanelClosed } = this.state;

    if (phoneNumberPanelClosed) {
      if (this.savedPhoneNumber) {
        return this.renderPhoneStatus();
      }

      return this.renderPhoneSkipped();
    }

    return this.renderPhoneForm();
  }

  renderStep(title, content, image, imageOnLeft = true) {
    const [direction, GridComponent, gridProps] = imageOnLeft
      ? ['row', Grid, {}]
      : ['row-reverse', WuiGrid, { reverseDirectionOnPhone: true }];

    const renderedContent = typeof content === 'function' ? content() : content;

    return (
      <React.Fragment key={title}>
        <Spacer v={64} smUp />

        <GridComponent
          container
          spacing={2}
          alignItems="center"
          alignContent="center"
          direction={direction}
          {...gridProps}
        >
          <Grid item sm={6}>
            <DimensionLimiter h="100%">
              <img alt="" src={image} />
            </DimensionLimiter>
          </Grid>
          <Grid item sm={6}>
            <Typography variant="h4">{title}</Typography>

            <Typography variant="intro">{renderedContent}</Typography>
          </Grid>
        </GridComponent>
      </React.Fragment>
    );
  }

  renderNextSteps() {
    return (
      <Panel extraVerticalPadding>
        <Typography variant="h2">Important Next Steps</Typography>

        <Panel borderless paddingless maxContentWidth={690}>
          <Typography variant="superhero">
            Here are the remaining steps to make sure your plan is done right and stays right.
          </Typography>
        </Panel>

        <Spacer v={64} xsDown />

        {steps.map((step, i) => this.renderStep(...step, i % 2 === 0))}
        <Spacer v={64} />
        <Button
          id="checkout-success-continue"
          color="primary"
          variant="contained"
          component={RouterLink}
          to={this.context.clientDataHolder.dashboardUrl}
          fullWidth={width => isWidthDown('xs', width)}
        >
          Continue
        </Button>
      </Panel>
    );
  }

  render() {
    return (
      <WuiApp>
        <MaxPageWidth>
          {this.renderPaymentPanel()}
          {this.renderPhonePanel()}
          {this.renderNextSteps()}

          <Footer />
        </MaxPageWidth>
      </WuiApp>
    );
  }
}
