const MUI_FIXED_CLASS = 'mui-fixed';
const ENABLE_MUI_STYLES_CLASS = 'mui-enabled';
const ENABLE_STANDARD_STYLES_CLASS = 'standard-styles';

function withZopim(callback) {
  // Zopim can take a while to load, so we
  //   only try to do this stuff after a
  //   timeout. This is okay for our usage
  //   since it is unlikely that the class
  //   we are adding is needed right away.
  setTimeout(() => {
    if (window.$zopim) {
      window.$zopim(callback);
    }
  }, 2000);
}

function changeZopimClassList(modifier) {
  withZopim(() => {
    Array.from(document.getElementsByClassName('zopim')).forEach(e => {
      modifier(e.classList);
    });
  });
}

export function rootClassList() {
  return document.getElementsByTagName('html')[0].classList;
}

export function disableStandardStyles() {
  rootClassList().add(ENABLE_MUI_STYLES_CLASS);
  rootClassList().remove(ENABLE_STANDARD_STYLES_CLASS);
  changeZopimClassList(list => list.add(MUI_FIXED_CLASS));
}

export function enableStandardStyles() {
  rootClassList().remove(ENABLE_MUI_STYLES_CLASS);
  rootClassList().add(ENABLE_STANDARD_STYLES_CLASS);

  // This class is needed to prevent the widget from jumping
  //   when a MUI modal is opened.
  changeZopimClassList(list => list.remove(MUI_FIXED_CLASS));
}
