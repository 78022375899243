import React from 'react';
import Typography from '@wui/basics/typography';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage.jsx';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup.jsx';
import { getEmail } from 'utils';

export default class Info extends MuiWizardPage {
  static baseUrl = '';
  static layoutProps = { hideRight: true };
  static wide = true;

  get showTestatorSwitcher() {
    return false;
  }

  get numDeeds() {
    return Object.values(this.serverData.assets).filter(asset => asset.qcdCreationMethod).length;
  }

  get deedString() {
    return this.numDeeds > 1 ? 'Home Transfer Deeds' : 'Home Transfer Deed';
  }

  renderPage() {
    const email = getEmail();
    const multipleDeeds = this.numDeeds > 1;

    return (
      <SpacedFieldGroup>
        <Typography variant="h3">Funding your trust.</Typography>

        <Typography variant="h6">What is this?</Typography>
        <Typography>
          Now that you have created your trust, we will need to have your {this.deedString}{' '}
          prepared, which {multipleDeeds ? 'are' : 'is'} used to "fund the trust." This step is
          necessary in order for your {multipleDeeds ? 'homes' : 'home'} to be covered by the trust
          and kept out of probate.
        </Typography>

        <Typography variant="h6">How do I do this?</Typography>

        <Typography>
          We must prepare the {this.deedString} on our end, but we need some additional
          actions/information from you before we can do so. The next steps to receive your{' '}
          {this.deedString} are as follows:
        </Typography>
        <Typography>1 - Print, sign, and notarize your "Revocable Living Trust"</Typography>
        <Typography>
          2 - Once your revocable living trust is signed and notarized, send an email to{' '}
          <a href={`mailto:${email}`}>{email}</a> with the date on which you signed and notarized
          your Revocable Living Trust document as indicated on the signature page/notary block.
        </Typography>
        <Typography>
          Once we have this information, we will prepare your {this.deedString} on our end and
          notify you via email when the {multipleDeeds ? 'documents are' : 'document is'} ready for
          you to print, sign, and return to us for recording.
        </Typography>
      </SpacedFieldGroup>
    );
  }
}
