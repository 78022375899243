import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@wui/basics/typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    '& + &': {
      marginTop: 48,
    },
  },
  masterHeading: {
    marginBottom: 24,
  },
  fieldContainer: {
    marginTop: 24,

    '&:last-child': {
      marginBottom: 32,
    },
  },
};

class SpacedFieldGroup extends React.Component {
  static propTypes = {
    masterHeading: PropTypes.string,
    subHeading: PropTypes.string,
    caption: PropTypes.string,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
  };

  render() {
    const { masterHeading, subHeading, caption, children, classes } = this.props;

    return (
      <div className={classes.root}>
        {masterHeading && (
          <Typography variant="h2" className={classes.masterHeading} component="h1">
            {masterHeading}
          </Typography>
        )}

        {subHeading && (
          <Typography variant="h5" component="h2">
            {subHeading}
          </Typography>
        )}

        {caption && <Typography variant="body1">{caption}</Typography>}

        {React.Children.map(children, (child, index) => (
          <div className={classes.fieldContainer} key={index}>
            {child}
          </div>
        ))}
      </div>
    );
  }
}

export default withStyles(styles)(SpacedFieldGroup);
