import React from 'react';
import PropTypes from 'prop-types';
import { joinClass } from 'utils';

import './Popup.scss';

export default class Popup extends React.Component {
  static propTypes = {
    onCloseClick: PropTypes.func,
    className: PropTypes.string,
    showCloseIcon: PropTypes.bool,
    disableBodyScrolling: PropTypes.bool,
  };

  static defaultProps = {
    showCloseIcon: true,
    disableBodyScrolling: false,
  };

  componentDidMount() {
    if (this.props.disableBodyScrolling) {
      document.body.classList.add('no-scroll');
    }
  }

  componentWillUnmount() {
    if (this.props.disableBodyScrolling) {
      document.body.classList.remove('no-scroll');
    }
  }

  render() {
    const className = joinClass('popup', this.props.className);
    const { showCloseIcon, onCloseClick } = this.props;

    return (
      <div className={className}>
        {showCloseIcon && <div className="close-icon" onClick={onCloseClick} />}
        {this.props.children}
      </div>
    );
  }
}
