import React from 'react';
import PropTypes from 'prop-types';

import './WizardLabel.scss';

export default class WizardLabel extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    pageTypes: PropTypes.object,
    subtext: PropTypes.string,
  };

  static defaultProps = {
    pageTypes: { inputPage: true },
  };

  render() {
    return (
      <div className="wandalabel">
        <div className="wandaheaders">
          <h2>{this.props.label}</h2>
          {this.props.subtext && <p>{this.props.subtext}</p>}
        </div>
      </div>
    );
  }
}
