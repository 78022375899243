import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import App from '@/App';
import WuiTheme from '@willing-shared/WuiTheme';

const WuiApp = ({ children, wrapContent, ...props }) => (
  <App wrapContent={wrapContent} showFooter={false} {...props}>
    <WuiTheme>{children}</WuiTheme>
  </App>
);

WuiApp.propTypes = {
  children: PropTypes.node.isRequired,
  wrapContent: PropTypes.bool,
};

WuiApp.defaultProps = {
  wrapContent: true,
};

export default observer(WuiApp);
