import React from 'react';
import PropTypes from 'prop-types';
import { BoundControl } from 'ui/components/BoundControl';
import { Select } from 'ui/components/controls/Select';
import AutoValidationDisplay from 'ui/components/wizard/AutoValidationDisplay';

import './DateSelect.scss';

export default class DateSelect extends BoundControl {
  static renderMonthOptions() {
    return [
      <option value="" hidden key="">
        Month
      </option>,
      <option value="1" key={1}>
        January
      </option>,
      <option value="2" key={2}>
        February
      </option>,
      <option value="3" key={3}>
        March
      </option>,
      <option value="4" key={4}>
        April
      </option>,
      <option value="5" key={5}>
        May
      </option>,
      <option value="6" key={6}>
        June
      </option>,
      <option value="7" key={7}>
        July
      </option>,
      <option value="8" key={8}>
        August
      </option>,
      <option value="9" key={9}>
        September
      </option>,
      <option value="10" key={10}>
        October
      </option>,
      <option value="11" key={11}>
        November
      </option>,
      <option value="12" key={12}>
        December
      </option>,
    ];
  }

  static renderDaysOptions() {
    let days = [
      <option key="" value="" hidden>
        Day
      </option>,
    ];
    for (let i = 1; i <= 31; i++) {
      days.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return days;
  }

  renderYearsOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = this.props.future ? currentYear + 20 : currentYear;
    const endYear = this.props.future ? currentYear : 1900;
    let years = [
      <option key="" value="" hidden>
        Year
      </option>,
    ];
    for (let i = startYear; i >= endYear; i--) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>,
      );
    }
    return years;
  };

  static propTypes = {
    className: PropTypes.string,
  };

  render() {
    const obj = this.getValue();
    const error = this.hasValidationErrors();

    return (
      <div>
        <div className={`dateselect ${this.props.className}`}>
          <Select obj={obj} path="month" showValidationErrors={false} error={error}>
            {this.constructor.renderMonthOptions()}
          </Select>

          <Select obj={obj} path="day" showValidationErrors={false} error={error}>
            {this.constructor.renderDaysOptions()}
          </Select>

          <Select obj={obj} path="year" showValidationErrors={false} error={error}>
            {this.renderYearsOptions()}
          </Select>
        </div>
        <AutoValidationDisplay obj={this.obj} path={this.props.path} />
      </div>
    );
  }
}
