import React from 'react';
import Typography from '@material-ui/core/Typography';

import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import SummaryPanel from 'material-ui/components/Summary/SummaryPanel';
import AboutYouSummary from 'material-ui/components/Summary/AboutYouSummary';

class BasePanel extends ClientDataReferer {
  static section;
  static title;
  static descriptor;

  renderSegment(testator) {
    const alternate = this.getAlternate(testator);
    const { descriptor } = this.constructor;

    return (
      <React.Fragment>
        {testator.name.toUpperCase()}
        <br />
        <strong>Primary {descriptor}:</strong> {this.getName(this.getPrimary(testator))}
        {alternate && (
          <React.Fragment>
            <br />
            <strong>Alternate {descriptor}:</strong> {this.getName(alternate)}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  getPrimary = () => null;
  getAlternate = () => null;

  getName = item => (item ? item.name : null);

  get extraContent() {
    return null;
  }

  render() {
    return (
      <SummaryPanel title={this.constructor.title} {...this.props}>
        <Typography variant="body1">
          {this.renderSegment(this.clientData)}
          {this.clientData.isPlanningForSpouse && (
            <React.Fragment>
              <br />
              <br />
              {this.renderSegment(this.clientData.spouse)}
            </React.Fragment>
          )}
        </Typography>

        {this.extraContent}
      </SummaryPanel>
    );
  }
}

export class AboutYouPanel extends React.Component {
  render() {
    return (
      <SummaryPanel title="About You" {...this.props}>
        <AboutYouSummary />
      </SummaryPanel>
    );
  }
}

export class GuardiansPanel extends BasePanel {
  static title = 'Guardians';
  static descriptor = 'Guardian';

  getPrimary = testator => testator.primaryGuardian;
  getAlternate = testator => testator.alternateGuardian;
}

export class ExecutorsPanel extends BasePanel {
  static title = 'Executors';
  static descriptor = 'Executor';

  getPrimary = testator => testator.primaryExecutor;
  getAlternate = testator => testator.alternateExecutor;
}

export class HealthcarePanel extends BasePanel {
  static title = 'Healthcare';
  static descriptor = 'Agent';

  getPrimary = testator => testator.primaryHealthcareAgent;
  getAlternate = testator => testator.alternateHealthcareAgent;
}
