import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { API } from 'API';
import { noOp } from 'utils/index';
import Form from '@wui/layout/form';
import Button from '@wui/input/button';
import Textbox from '@wui/input/textbox';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { validateEmail } from '@willing-shared/utils/validation';
import {
  emailExistsError,
  invalidEmailError,
  noPasswordError,
  wrongPasswordError,
} from 'utils/constants/errors';
import { useGlobalContext, useResetState } from 'hooks';

const defaultErrorState = {
  currentPassword: null,
  newEmail: null,
  genericError: null,
};

const ChangeEmail = ({ onSuccess }) => {
  const [currentPassword, setCurrentPassword, resetCurrentPassword] = useResetState('');
  const [newEmail, setNewEmail, resetNewEmail] = useResetState('');
  const [processing, setProcessing] = useState(false);
  const [errors, setErrors, resetErrors] = useResetState(defaultErrorState);

  const { clientData, clientDataHolder, updateClientData } = useGlobalContext();

  const setError = errorUpdate => {
    setErrors(Object.assign({}, defaultErrorState, errorUpdate));
    setProcessing(false);
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (processing) {
      return;
    }

    if (!currentPassword) {
      setError({ currentPassword: noPasswordError });
      return;
    }
    if (!validateEmail(newEmail)) {
      setError({ newEmail: invalidEmailError });
      return;
    }

    let response;
    setProcessing(true);
    try {
      response = await API.changeEmail(currentPassword, newEmail);
    } catch (error) {
      const genericError = error.getErrorMessage({
        wrong_password: wrongPasswordError,
        email_exists: emailExistsError,
      });
      setError({ genericError });

      // The user has failed too many times and the backend has logged them out
      if (error.errorCode === 'not_authenticated') {
        clientDataHolder.onLogoutSuccessful();
      }
      return;
    }

    clientDataHolder.analyticsEvents.updateEmail(
      clientDataHolder.clientData.email,
      response.data.email,
      clientDataHolder.serverData.googleAnalyticsId,
    );

    updateClientData(() => {
      clientData.setRawValue('email', response.data.email);
    });
    resetCurrentPassword();
    resetNewEmail();
    setProcessing(false);
    resetErrors();
    onSuccess();
  };

  return (
    <Form error={errors.genericError} onSubmit={onSubmit}>
      <DimensionLimiter h={400}>
        <Textbox
          label="Current Password"
          type="password"
          name="currentPassword"
          error={errors.currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          value={currentPassword}
        />
        <Textbox
          label="New Email"
          name="newEmail"
          error={errors.newEmail}
          onChange={e => setNewEmail(e.target.value)}
          value={newEmail}
        />
      </DimensionLimiter>
      <div>
        <Button variant="contained" color="primary" type="submit" processing={processing}>
          Change Email
        </Button>
      </div>
    </Form>
  );
};

ChangeEmail.propTypes = {
  onSuccess: PropTypes.func,
};

ChangeEmail.defaultProps = {
  onSuccess: noOp,
};

export default observer(ChangeEmail);
