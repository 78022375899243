import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@wui/layout/grid';
import Link from '@wui/basics/link';
import Button from '@wui/input/button';

import useStyles from './useStyles';

const ActionButton = ({ text, onClick, isLink, dataPath }) => {
  const classes = useStyles();

  return (
    <Grid item xs={7} md={5} container direction="row" justify="flex-end">
      {isLink ? (
        <Link
          data-path={dataPath}
          className={classes.paddedLink}
          onClick={onClick}
          variant="body1"
          component="button"
        >
          {text}
        </Link>
      ) : (
        <Button data-path={dataPath} variant="outlined" noMinWidth onClick={onClick}>
          {text}
        </Button>
      )}
    </Grid>
  );
};

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isLink: PropTypes.bool,
  dataPath: PropTypes.string,
};

ActionButton.defaultProps = {
  isLink: false,
  dataPath: null,
};

export default ActionButton;
