import { AssetType, MaritalStatus } from 'models/client-data/enums';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import SectionGuardians from 'ui/sections/guardians/SectionGuardians';
import SectionAssetBeneficiaries from 'ui/sections/asset-beneficiaries/SectionAssetBeneficiaries';
import SectionExecutors from 'ui/sections/executors/SectionExecutors';
import SectionHealthcare from 'ui/sections/healthcare/SectionHealthcare';
import { pad } from 'utils';
import Cookies from 'js-cookie';
import analytics from '@willing-shared/analytics';
import sentry from '@willing-shared/utils/sentry';
import { googleTagIdentifiers } from '@willing-shared/utils/env';

const noopFn = function (...args) {
  // eslint-disable-next-line
  console.log('noopFn', args);
};

export class AnalyticsEvents {
  constructor() {
    analytics.init();

    this.sentRegistrationEvent = false;
    this.sentUserProperties = false;
    this.pendingUserProperties = [];
  }

  // google
  get gtag() {
    return window.gtag || noopFn;
  }

  get dataLayer() {
    return window.dataLayer || [];
  }

  sendPageChange(pathname) {
    analytics.pageview(pathname);
  }

  setSessionExperiment(experiment) {
    if (!window.SessionExperiments) {
      return;
    }
    const { id, variant } = window.SessionExperiments[experiment];
    this.setExperiment(id, variant);
  }

  setExperiment(id, variant) {
    // We don't override the default send_page_view option because the page
    //   view ensures that the user's participation in the experiment is
    //   recorded, even if they drop off immediately.
    analytics.gtagConfig({
      experiments: [{ id, variant }],
    });
  }

  sendUserRegistered() {
    if (this.sentRegistrationEvent) {
      return;
    }

    // Make sure that this event can't be sent twice. This could happen
    //   if the user registers on the app (not from the family center)
    //   because the `clientDataJustCreated` server data property also
    //   can trigger this event.
    this.sentRegistrationEvent = true;

    this.gtag('event', 'Complete', { event_category: 'Registration' });
    this.gtag('event', 'conversion', {
      send_to: `${googleTagIdentifiers.adwords}/0St5CKymvWgQxo2B4wM`,
    });
    this.dataLayer.push({ event: 'willing.user.registration' });
  }

  sendIdentification(email, googleAnalyticsId) {
    if (window.__insp) {
      window.__insp.push(['tagSession', { email }]);
    }

    sentry.setUser({ email });
    analytics.identify({ googleAnalyticsId });

    if (googleAnalyticsId) {
      Cookies.set('user_id', googleAnalyticsId, {
        domain: 'willing.com',
      });
    }
  }

  updateEmail(oldEmail, newEmail, googleAnalyticsId) {
    this.sendIdentification(newEmail, googleAnalyticsId);
  }

  getUserData(clientData, serverData, experimentContext) {
    const dateRegistered = new Date(serverData.profileCreatedAt),
      dateRegisteredStr =
        dateRegistered.getFullYear() +
        '/' +
        pad(dateRegistered.getMonth() + 1, 2) +
        '/' +
        pad(dateRegistered.getDate(), 2);

    const clientExperiments = experimentContext.enabled;
    const serverExperiments = {
      explodingDiscount: serverData.experiments.explodingDiscountVariant,
    };
    const experiments = clientExperiments
      .concat(Object.keys(serverExperiments).filter(k => serverExperiments[k]))
      .join(',');

    const variants = Object.entries(serverExperiments)
      .filter(([, v]) => v)
      .map(([k, v]) => `${k}-${v}`)
      .join(',');

    const data = {
      WillingUserId: serverData.userId,
      Email: clientData.email.toLowerCase(),
      Name: clientData.name,
      FirstName: clientData.firstName,
      DOB:
        clientData.dob.year + '/' + pad(clientData.dob.month, 2) + '/' + pad(clientData.dob.day, 2),
      DateRegistered: dateRegisteredStr,
      HasMinorChildren: clientData.children.some(c => c.isMinor),
      ChildrenCount: clientData.children.length,
      NumberOfRealEstates: clientData.realEstates.length,
      NumberOfSpecificGifts: clientData.assets.filter(
        a =>
          ![AssetType.REAL_ESTATE, AssetType.RESIDUAL, AssetType.RESIDUAL_JOINT].includes(a.type),
      ).length,
      MaritalStatus: clientData.maritalStatus,
      ExcludedAChild: clientData.children.some(c => c.disinherited),
      PlanForBoth:
        clientData.maritalStatus === MaritalStatus.MARRIED && clientData.isPlanningForSpouse,
      DataExpired: serverData.dataExpired,
      State: clientData.address.state,

      CovidEmployer: clientData.covidEmployer,

      CompletedAboutYou: clientData.isSectionValid(SectionAboutYou),
      CompletedGuardians: clientData.isSectionValid(SectionGuardians),
      CompletedAssetBeneficiaries: clientData.isSectionValid(SectionAssetBeneficiaries),
      CompletedExecutors: clientData.isSectionValid(SectionExecutors),
      CompletedHealthcare: clientData.isSectionValid(SectionHealthcare),

      HasSubscription: serverData.hasSubscription,
      UserSubscriptionPlan: serverData.subscriptionPlan,
      DocumentBundle: serverData.documentBundle,
      HasStripePayment: serverData.hasStripePayment,
      HasPaypalPayment: serverData.hasPaypalPayment,
      HasCouponCode: serverData.hasCouponCode,
      HasGrouponCode: serverData.hasGrouponCode,
      CouponCode: serverData.couponCode,
      GrouponCode: serverData.grouponCode,
      EnabledExperiments: experiments,
      ExperimentVariants: variants,

      LockedRealEstateDocuments: serverData.lockedRealEstateDocuments,
      purchaseDateLast: serverData.purchaseDateLast,
    };

    // If the ClientData instance was just created in the database, it is
    //   indicitive of the user visiting the app for the first time. If the
    //   user registers via family center, then the ClientData instance is
    //   not created and this will be set to true the first time they visit
    //   the app. When this happens, we want to behave as if they just
    //   registered for the app.
    if (serverData.clientDataJustCreated) {
      this.sendUserRegistered();
    }

    return data;
  }

  sendUserProperties(clientData, serverData) {
    // This method is sometimes called with an empty serverData. When this
    //   happens we bail early to avoid sending bad values.
    if (!serverData.userId) {
      return;
    }

    // This data and event are sent on the data layer so we can
    //   use Google Optimize to apply experiments based on when
    //   the user registered.
    this.dataLayer.push({
      'willing.user.registered_at': Date.parse(serverData.profileCreatedAt),
    });
    this.dataLayer.push({ event: 'willing.user.loaded' });
    if (serverData.profileCreatedAt) {
      this.pendingUserProperties.forEach(event => this.dataLayer.push(event));
      this.pendingUserProperties = [];
      this.sentUserProperties = true;
    }
  }

  sendDataLayerEvent(event) {
    if (this.sentUserProperties) {
      this.dataLayer.push(event);
    } else {
      this.pendingUserProperties.push(event);
    }
  }

  aboutYouComplete() {
    this.gtag('event', 'Complete', { event_category: 'About You' });
  }

  guardiansComplete() {
    this.gtag('event', 'Complete', { event_category: 'Guardians' });
  }

  assetsComplete() {
    this.gtag('event', 'Complete', { event_category: 'Assets' });
  }

  executorsComplete() {
    this.gtag('event', 'Complete', { event_category: 'Representative' });
  }

  healthcareComplete() {
    this.gtag('event', 'Complete', { event_category: 'Healthcare' });
  }

  planSuccessFlow() {
    this.gtag('event', 'Go-to', { event_category: 'plan-success' });
  }

  goneToDocuments() {
    this.gtag('event', 'Go-to', { event_category: 'Documents' });
  }

  viewedDocuments() {
    this.gtag('event', 'View', { event_category: 'Documents' });
  }

  viewedPayment() {
    this.gtag('event', 'View', { event_category: 'Payment' });
  }

  completedPayment(checkoutResponse) {
    const { id, documentBundle, upgradeFromDocumentBundle } = checkoutResponse;
    const value = checkoutResponse.discountedPrice / 100;

    let name = documentBundle;
    if (upgradeFromDocumentBundle) {
      name = `${name} - upgrade from ${upgradeFromDocumentBundle}`;
    }

    this.gtag('event', 'purchase', {
      value,
      transaction_id: id,
      items: [
        {
          name,
          id: name,
          quantity: 1,
          price: value.toFixed(2),
        },
      ],
    });

    this.gtag('event', 'Complete', {
      event_category: 'Payment',
      value: value,
    });
    this.gtag('event', 'conversion', {
      send_to: `${googleTagIdentifiers.adwords}/gzRCCJDrpWgQxo2B4wM`,
      transaction_id: id,
      value: value,
      currency: 'USD',
    });
    this.dataLayer.push({
      event: 'willing.user.purchase',
      conversionValue: value,
      transactionId: id,
    });
  }

  redeemedCoupon() {
    this.gtag('event', 'Redeemed', { event_category: 'Coupon' });
  }

  redeemedCovid() {
    this.gtag('event', 'Redeemed', { event_category: 'Covid' });
  }

  subscriptionCreated(checkoutResponse) {
    const { id } = checkoutResponse;
    const value = checkoutResponse.discountedPrice / 100;

    const subscriptionData = {
      event_category: 'Created',
      transaction_id: id,
    };

    if (value) {
      subscriptionData.value = value;
    }

    this.gtag('event', 'Subscription', subscriptionData);
  }

  subscriptionCanceled({ cancelReason: reason }) {
    this.gtag('event', 'Subscription', {
      event_category: 'Canceled',
      event_label: reason,
    });
  }

  async dispatchEvent(event, arg) {
    // This class contains a corresponding method for each field, e.g., this.aboutYouComplete()

    this[event](arg);
  }
}
