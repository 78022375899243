import { getLogoutUrl } from '@u/tokens';

export const AUTH_WRONG_USERNAME_OR_PASSWORD = 'wrong_username_or_password';
export const AUTH_INVALID_ACCESS_TOKEN = 'invalid_access_token';
export const AUTH_RATE_LIMIT_EXCEEDED = 'rate_limit_exceeded';
export const AUTH_NOT_AUTHENTICATED = 'not_authenticated';
export const AUTH_ALREADY_EXISTS = 'already_exists';
export const AUTH_AXES_LOCKOUT = 'axes_lockout';
export const AUTH_COVID_LOGIN = 'covid_login';

export function getAxesErrorMessage(response) {
  return response.coolOffHours
    ? `Too many failed attempts, try again in ${response.coolOffHours} hours or contact support.`
    : 'Too many failed attempts. Please contact support.';
}

export default {
  methods(http) {
    return {
      get: () => http.get.success('/api/v1/user/'),
      logout: async () => {
        window.location = getLogoutUrl();
        return null;
      },
      login: (email, password) =>
        http.post.success('/api/v1/auth/login', null, { formData: { email, password } }),
      register: (email, password) =>
        http.post.success('/api/v1/auth/register', null, { formData: { email, password } }),
      oauth: (provider, email, token) =>
        http.post.success('/api/v1/oauth/authenticate', null, {
          formData: { provider, email, token },
        }),
    };
  },
  errors: {
    [AUTH_WRONG_USERNAME_OR_PASSWORD]: 'Invalid email address or password.',
    [AUTH_INVALID_ACCESS_TOKEN]:
      'There was an error connecting with the external provider. Please try again.',
    [AUTH_RATE_LIMIT_EXCEEDED]:
      "You've tried to login too many times. Please try again in an hour.",
    [AUTH_NOT_AUTHENTICATED]: 'You must be logged in to perform this action.',
    [AUTH_ALREADY_EXISTS]:
      'A user with this email already exists, please login with your email and password.',
    [AUTH_AXES_LOCKOUT]: getAxesErrorMessage,
  },
};
