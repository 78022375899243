import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';

const useStyles = makeStyles({
  primary: {},

  subtitle: {
    '$primary &': {
      fontSize: 16,
    },
  },
  title: {
    fontSize: 18,
    lineHeight: '23px',

    '$primary &': {
      fontSize: 24,
      lineHeight: '29px',
    },
  },
});

const OptionWithImage = ({
  reverse,
  title,
  subtitle,
  body,
  isPrimary,
  callback,
  buttonText,
  Image,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const wrap = isSmall ? 'wrap' : 'nowrap';

  return (
    <Grid
      container
      direction={reverse ? 'row-reverse' : 'row'}
      wrap={wrap}
      spacing={6}
      alignItems="center"
      justify="center"
      className={isPrimary ? classes.primary : ''}
    >
      <Grid item>
        <img style={{ maxWidth: 320 }} src={Image} alt="" />
      </Grid>
      <Grid item>
        <Typography variant="caption" className={classes.subtitle} bold>
          {subtitle}
        </Typography>
        <Spacer v={isPrimary ? 16 : 12} />
        <Typography className={classes.title} bold>
          {title}
        </Typography>
        <Spacer v={isPrimary ? 20 : 16} />
        <Typography variant="body1">{body}</Typography>
        <Spacer v={24} />
        <Button
          onClick={callback}
          variant={isPrimary ? 'contained' : 'outlined'}
          color={isPrimary ? 'primary' : 'default'}
          noMinWidth
        >
          {buttonText}
        </Button>
      </Grid>
    </Grid>
  );
};

OptionWithImage.propTypes = {
  reverse: PropTypes.bool,
  isPrimary: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  Image: PropTypes.node.isRequired,
};

OptionWithImage.defaultProps = {
  reverse: false,
  isPrimary: false,
};

export default observer(OptionWithImage);
