import React from 'react';
import PropTypes from 'prop-types';
import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';
import SignatureCanvas from 'react-signature-canvas';
import { titleCase } from '@willing-shared/utils/text';
import { CanvasTrimmer } from 'utils/canvas';

import './SignaturePopup.scss';

class Steps {
  static SIGNATURE = 'signature';
  static INITIALS = 'initials';

  static getNumber(step) {
    return [this.SIGNATURE, this.INITIALS].indexOf(step) + 1;
  }
}

export default class SignaturePopup extends React.Component {
  static propTypes = {
    setImages: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
  };

  static defaultProps = {
    signature: '',
    initials: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      padRef: null,
      step: Steps.SIGNATURE,
      data: {},
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.step !== this.state.step) {
      this.clear();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.clear);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.clear);
  }

  setStep = nextStep => {
    const { step: currentStep, padRef, data } = this.state;
    const newData = Object.assign({}, data, {
      [currentStep]: padRef.toData(),
    });
    this.setState(
      {
        step: nextStep,
        data: newData,
      },
      () => padRef.fromData(data[nextStep] || []),
    );
  };

  goToNext = () => {
    const { step, padRef } = this.state;
    const { close, setImages } = this.props;

    if (this.canvasIsEmpty) {
      return;
    }

    setImages({
      [step]: CanvasTrimmer.trimSignature(padRef.getCanvas()).toDataURL(),
    });

    if (step === Steps.SIGNATURE) {
      this.setStep(Steps.INITIALS);
    } else {
      close();
    }
  };

  clear = () => {
    this.state.padRef.clear();
    this.state.padRef._resizeCanvas();

    // Reevaluate button states
    this.forceUpdate();
  };

  get canvasIsEmpty() {
    const { padRef } = this.state;

    if (!padRef) {
      return true;
    }

    return padRef.isEmpty();
  }

  get renderButtons() {
    return (
      <div className="popup__buttons">
        <div className="popup__secondary-buttons">
          {this.state.step === Steps.INITIALS && (
            <PureButton
              className="button--tertiary button-inline"
              onClick={() => this.setStep(Steps.SIGNATURE)}
            >
              <div className="back-icon" />
              Back
            </PureButton>
          )}
          <PureButton
            className="button--tertiary button--inline"
            onClick={this.clear}
            disabled={this.canvasIsEmpty}
          >
            Clear
          </PureButton>
        </div>

        <PureButton
          className="button--primary button--inline"
          onClick={this.goToNext}
          disabled={this.canvasIsEmpty}
        >
          Save and Continue
        </PureButton>
      </div>
    );
  }

  // Done here to avoid exceeding callback stack
  //   See: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs
  setRef = padRef => this.setState({ padRef });

  render() {
    const { close } = this.props;
    const { step } = this.state;

    // this.forceUpdate() on ending a stroke to force the buttons to reevaluate whether or not to be disabled
    return (
      <div className="popup-fuzz">
        <Popup onCloseClick={close} className="signing-popup" disableBodyScrolling>
          <div className="popup__headers">
            <div className="headers__step">Step {Steps.getNumber(step)} of 2:</div>

            <div className="headers__hero">Draw your {step}</div>
          </div>

          <div className={`popup__drawbox drawbox--${step}`}>
            <SignatureCanvas
              penColor="#00009F"
              velocityFilterWeight={0.7}
              minWidth={0.9}
              maxWidth={4.5}
              canvasProps={{ className: 'drawbox__canvas' }}
              ref={this.setRef}
              onEnd={() => this.forceUpdate()}
              clearOnRezize={false}
            />

            <div className="drawbox__line">{titleCase(step)}</div>
          </div>

          {this.renderButtons}
        </Popup>
      </div>
    );
  }
}
