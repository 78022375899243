import React from 'react';

import { useGlobalContext } from 'hooks';
import DepByDefault from './DepByDefault';
import Bundle from './Bundle';
import { observer } from 'mobx-react';

const MlpBundle = () => {
  const { serverData } = useGlobalContext();

  return serverData.depByDefault ? <DepByDefault /> : <Bundle />;
};

export default observer(MlpBundle);
