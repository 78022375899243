import axios from 'axios';
import Cookies from 'js-cookie';

import tokenFetcher, { getLogoutUrl } from '@willing-shared/utils/tokens';
import { redirectWithoutPrompt } from 'urls';

export const NETWORK_ERROR = 'network_error';

export function createErrorFromData(data) {
  // If there is no data, set a generic
  //   errorCode that can be handled by the caller
  //   if desired.
  data = data || { errorCode: 'missing_response_data' };

  const errorCode = data.errorCode || data.error;
  const error = new Error(errorCode);

  Object.assign(error, data);

  error.getErrorMessage = mapping => {
    if (mapping && mapping.hasOwnProperty(errorCode)) {
      return mapping[errorCode];
    } else {
      return `Unexpected backend error: ${errorCode}`;
    }
  };

  return error;
}

export class API {
  static initAxios() {
    this.serverTimeOffset = 0;

    this.api = axios.create({
      baseURL: '/api/v1/',
      responseType: 'json',
      headers: {
        'X-REQUESTED-WITH': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    });
    this.api.interceptors.request.use(async function (config) {
      config.headers['X-CSRFToken'] = Cookies.get('csrftoken');
      if (config.data) {
        // Convert objects to url search params, since that's what we're posting to the backend.
        // Strings are assumed to be JSON.
        if (typeof config.data === 'object') {
          const params = new URLSearchParams();
          for (const [key, value] of Object.entries(config.data)) {
            params.append(key, value);
          }
          config.data = params;
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
      }

      // Track when this request was sent using the local time.
      config.requestSentAt = new Date().getTime();

      if (!config.skipAuthentication) {
        const accessToken = await tokenFetcher.accessToken;
        // If it is available, set the access token to authorize the user
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }

      return config;
    });
    this.api.interceptors.response.use(
      response => {
        const requestSentAtServer = response.headers['x-request-sent-at'];

        if (requestSentAtServer) {
          // Track the difference in time between the server
          //   and the client so we can get a somewhat accurate
          //   representation of what the server time is.
          this.serverTimeOffset = Date.parse(requestSentAtServer) - response.config.requestSentAt;
        }

        return response.data;
      },
      error => {
        // Catch errors from the backend that set success = false and reject the Promise with the error.
        if (error.response) {
          return Promise.reject(createErrorFromData(error.response.data));
        } else if (error.message === 'Network Error') {
          return Promise.reject(createErrorFromData({ errorCode: NETWORK_ERROR }));
        } else {
          // If there is no response (e.g. a timeout occurred) or
          //   there was some other issue that isn't handled, throw
          //   an error so it can be caught by Sentry.
          const url = error.config !== null ? error.config.url : 'url';
          let errorWithGetter = new Error(
            `Unable to send request to ${url} ` + `or other unknown error: ${error.stack}`,
          );

          // Some error handlers expect `getErrorMessage()` to return
          //   the text of the error, so add it to the error that is
          //   being thrown and return a generic message.
          errorWithGetter.getErrorMessage = () => 'An unexpected error occurred.';

          throw errorWithGetter;
        }
      },
    );
  }

  // Return the perceived time on the server. Clients machines
  //   can have very messed up times.
  static getTime() {
    return new Date().getTime() + this.serverTimeOffset;
  }

  static async getClientData() {
    return await this.api.get('client-data/json');
  }

  static async putClientData(clientData) {
    return await this.api.put('client-data/json', JSON.stringify(clientData));
  }

  static async register(email, password, couponCode, additionalInfo) {
    const data = {
      email,
      password,
      couponCode,
    };

    if (additionalInfo) {
      Object.assign(data, additionalInfo);
    }

    return await this.api.post('auth/register', data, {});
  }

  static async login(email, password) {
    return await this.api.post('auth/login', { email, password });
  }

  static async changeEmail(password, newEmail) {
    return await this.api.post('auth/change-email', {
      password,
      email: newEmail,
    });
  }

  static async changePassword(email, password, newPassword) {
    return await this.api.post('auth/change-password', {
      email,
      password,
      new_password: newPassword,
    });
  }

  static async resetPassword(email) {
    return await this.api.post('auth/forgotten-password', { email });
  }

  static async resetPasswordComplete(userId, token, newPassword) {
    return await this.api.post('auth/forgotten-password/complete', {
      userId,
      token,
      new_password: newPassword,
    });
  }

  static async logout() {
    redirectWithoutPrompt(getLogoutUrl());
    return null;
  }

  static startAdminSession() {
    return this.api.post('auth/start-admin-session');
  }

  static async getPriceInfo(couponCode = '', documentBundle, subscriptionPlan, includedRealEstate) {
    /* this function has no side effects, it just returns information */
    includedRealEstate = JSON.stringify(includedRealEstate || []);
    return await this.api.post('payment/get-price-info', {
      coupon: couponCode,
      documentBundle,
      subscriptionPlan,
      includedRealEstate: includedRealEstate || [],
    });
  }

  static async applyCoupon(couponCode = '', documentBundle, subscriptionPlan, includedRealEstate) {
    /* in addition to returning information, this function can have side effects */
    includedRealEstate = JSON.stringify(includedRealEstate || []);
    return await this.api.post('payment/apply-coupon', {
      coupon: couponCode,
      documentBundle,
      subscriptionPlan,
      includedRealEstate: includedRealEstate || [],
    });
  }

  static async checkout(source, documentBundle, couponCode, subscriptionPlan, includedRealEstate) {
    includedRealEstate = JSON.stringify(includedRealEstate || []);
    return await this.api.post('payment/charge', {
      source,
      documentBundle,
      coupon: couponCode,
      subscriptionPlan: subscriptionPlan,
      includedRealEstate,
    });
  }

  static gtagSubscription(subscriptionPlan, data) {
    if (window.gtag) {
      window.gtag('event', 'subscription', {
        event_category: 'ecommerce',
        event_label: subscriptionPlan,
        value: data.discountedPrice / 100,
      });
    }
    return data;
  }

  static async updateStripeSubscription(subscriptionPlan, source) {
    return await this.api
      .post('payment/update-stripe-subscription', {
        subscriptionPlan: subscriptionPlan,
        source: source || '',
      })
      .then(data => this.gtagSubscription(subscriptionPlan, data));
  }

  static async updatePaypalSubscription(subscriptionPlan, paymentToken) {
    // activate a paypal billing agreement using the given payment token
    return await this.api
      .post('payment/update-paypal-subscription', {
        subscriptionPlan,
        paymentToken,
      })
      .then(data => this.gtagSubscription(subscriptionPlan, data));
  }

  static async getDocumentsInfo(documentBundle) {
    let config = {};
    if (documentBundle) {
      config = { params: { documentBundle: documentBundle } };
    }
    return await this.api.get('docs/documents-info', config);
  }

  static async getDocuments() {
    return await this.api.get('docs/render');
  }

  static async getDocumentsPdf(params) {
    const config = { params };
    return this.api.get('docs/render-pdf', config);
  }

  static async emailDocuments() {
    return await this.api.post('docs/email-documents');
  }

  static async getNotarizationEvidenceUrls() {
    return await this.api.get('notarization/evidence-urls');
  }

  static async getNotarizationCombinedDocuments() {
    return await this.api.get('notarization/combined-documents/');
  }

  static async getNotarizationDocumentsInfo() {
    return await this.api.get('notarization/documents-info');
  }

  static async getNotarizationCompletedSessionInfo() {
    return await this.api.get('notarization/completed-session-info');
  }

  static async postOauthLogin(email, token, provider, couponCode, additionalInfo) {
    const data = {
      email,
      token,
      provider,
    };

    if (couponCode) {
      data.couponCode = couponCode;
    }

    if (additionalInfo) {
      Object.assign(data, additionalInfo);
    }

    return await this.api.post('oauth/authenticate', data);
  }

  static async getSupportInfo() {
    return await this.api.get('get-support-info');
  }

  static async resetExpiredAccount() {
    return await this.api.post('client-data/reset-expired-account');
  }

  static async getNotarizationSession() {
    return await this.api.get('notarization/session');
  }

  static async getNotarizationToken() {
    return await this.api.get('notarization/token');
  }

  static async getNotarizationImageUrls() {
    return await this.api.get('notarization/session/image-urls');
  }

  static async postNotarizationTechCheckComplete() {
    return await this.api.post('notarization/session/tech-check-complete');
  }

  static async postNotarizationVerifyId(forSpouse, frontData, backData, originalBackData) {
    return await this.api.post(
      'notarization/session/verify-id',
      JSON.stringify({
        forSpouse,
        frontData,
        backData,
        originalBackData: originalBackData || '',
      }),
    );
  }

  static async postNotarizationIdentityVerification(ssn, firstName, lastName, forSpouse) {
    return await this.api.post(
      'notarization/session/kba/identity',
      JSON.stringify({
        ssn,
        firstName,
        lastName,
        forSpouse,
      }),
    );
  }

  static async getNotarizationKBAQuestions(forSpouse) {
    return await this.api.get('notarization/session/kba/questions', {
      params: { forSpouse },
    });
  }

  static async postNotarizationKBAAnswers(answers, forSpouse) {
    return await this.api.post(
      'notarization/session/kba/answers',
      JSON.stringify({
        answers,
        forSpouse,
      }),
    );
  }

  static async createCustomSignature(signature, initials, forSpouse) {
    return await this.api.post(
      `notarization/${forSpouse ? 'spouse' : 'client'}/custom-signature`,
      JSON.stringify({
        signature,
        initials,
      }),
    );
  }

  static async getCustomSignature(forSpouse) {
    return await this.api.get(`notarization/${forSpouse ? 'spouse' : 'client'}/custom-signature`);
  }

  static async postNotarizationSignatureCreated(forSpouse) {
    return await this.api.post(
      'notarization/session/signature-created',
      JSON.stringify({
        forSpouse,
      }),
    );
  }

  static async getNotarizationSessionSuccessful() {
    return await this.api.get('notarization/last-session-successful');
  }

  static async getNotarizationAdminSessions() {
    return await this.api.get('notarization/admin/sessions');
  }

  static async getNotarizationAdminSession(sessionId) {
    return await this.api.get(`notarization/admin/sessions/${sessionId}/`);
  }

  static async getNotarizationAdminToken(sessionId, layoutClass) {
    return await this.api.get(`notarization/admin/sessions/${sessionId}/token`, {
      params: {
        layoutClass: layoutClass,
      },
    });
  }

  static async getNotarizationAdminImageUrls(sessionId) {
    return await this.api.get(`notarization/admin/sessions/${sessionId}/image-urls`);
  }

  static async getNotarizationAdminIdImageUrls(sessionId) {
    return await this.api.get(`notarization/admin/sessions/${sessionId}/id-image-urls`);
  }

  static async postNotarizationJoinNotary(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/notary-join`);
  }

  static async postNotarizationJoinWitness(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/witness-join`);
  }

  static async postNotarizationStartSession(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/start`);
  }

  static async postNotarizationStartArchiving(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/start-archiving`);
  }

  static async postNotarizationStopArchiving(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/stop-archiving`);
  }

  static async putNotarizationSetSigningStatus(sessionId, newStatus) {
    return await this.api.put(
      `notarization/admin/sessions/${sessionId}/set-signing-status`,
      JSON.stringify({ newStatus }),
    );
  }

  static async postNotarizationSetIdApproval(sessionId, forSpouse, isApproved) {
    return await this.api.post(
      `notarization/admin/sessions/${sessionId}/set-id-approval`,
      JSON.stringify({ forSpouse, isApproved }),
    );
  }

  static async postNotarizationGenerateRenderedDocuments(sessionId) {
    return await this.api.post(
      `notarization/admin/sessions/${sessionId}/generate-rendered-documents`,
    );
  }

  static async postNotarizationCompleteSession(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/complete`);
  }

  static async postNotarizationFailSession(sessionId) {
    return await this.api.post(`notarization/admin/sessions/${sessionId}/fail`);
  }

  static async getNotarySeal() {
    return await this.api.get('notarization/admin/seal/');
  }

  static async getNotaryScript(sessionId) {
    return this.api.get(`notarization/admin/sessions/${sessionId}/script`);
  }

  static async postAdminAdvanceStep(ceremonyId, transitionType) {
    const data = { transition_type: transitionType };
    return this.api.post(`notarization/admin/sessions/${ceremonyId}/advance-step`, data);
  }

  static async postAdvanceStep() {
    const data = { transition_type: 'primary' };
    return this.api.post('notarization/advance-step', data);
  }

  static async postAdminReverseStep(ceremonyId) {
    const data = {};
    return this.api.post(`notarization/admin/sessions/${ceremonyId}/reverse-step`, data);
  }

  static async testBandwidth() {
    try {
      await this.api.get('notarization/bw/download', { timeout: 8000 });
    } catch (e) {
      return false;
    }

    const numBytesToSend = 625000;
    const data = '0'.repeat(numBytesToSend);

    try {
      await this.api.post('notarization/bw/upload', data, {
        timeout: 8000,
        headers: { 'Content-Type': 'text/plain' },
      });
    } catch (e) {
      return false;
    }
    return true;
  }

  static async getSessionInfoWithPin(documentId, pin) {
    return await this.api.post(
      `notarization/${documentId}/session-information`,
      { pin },
      { skipAuthentication: true },
    );
  }

  static async trackCheckoutView() {
    return this.api.get('client-data/checkout-page-viewed');
  }

  static async listTestatorDocuments() {
    return await this.api.get('notarization/list-testator-documents/');
  }

  static async requestAttorneyReview(payload) {
    return await this.api.post('client-data/request-attorney-review', payload);
  }

  static async sendUserEvent(event) {
    return await this.api.post('home/user-event', { event_name: event });
  }

  static async hasMlpDep() {
    return await this.api.get('auth/has-mlp-dep');
  }

  static async getAuraStatus() {
    return await this.api.get('auth/aura-status');
  }

  static async getAuraRedirect() {
    return await this.api.get('auth/aura-redirect');
  }

  static async getRecordAuraPrompt() {
    return await this.api.get('auth/aura-prompt-displayed');
  }
}

API.initAxios();

window.API = API;
