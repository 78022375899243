import React from 'react';
import { STATES } from 'utils';
import Input from 'ui/components/controls/Input';
import { BoundControl } from 'ui/components/BoundControl';
import AutoValidationDisplay from 'ui/components/wizard/AutoValidationDisplay';
import { Select } from 'ui/components/controls/Select';

import './AddressInput.scss';

export default class AddressInput extends BoundControl {
  render() {
    const obj = this.getRawValue();
    const includeCounty = Object.keys(obj.constructor.allFields).includes('county');
    const paths = ['street', 'city', 'state', 'zip'];
    if (includeCounty) {
      paths.push('county');
    }

    return (
      <div className="address-input">
        <Input
          obj={obj}
          path="street"
          placeholder="123 Main St."
          className="text-input--inline text-input--4"
          showValidationErrors={false}
        />
        <Input
          obj={obj}
          path="apt"
          className="text-input--4 text-input--inline"
          placeholder="Apt. 789"
          showValidationErrors={false}
        />
        <br />
        <Input
          obj={obj}
          path="city"
          className="text-input--4 text-input--inline"
          placeholder="City"
          showValidationErrors={false}
        />
        <Select obj={obj} path="state" showValidationErrors={false}>
          <option value="" />
          {Object.keys(STATES).map((state, i) => (
            <option value={state} key={i}>
              {state}
            </option>
          ))}
        </Select>
        <Input
          obj={obj}
          path="zip"
          className="text-input--3 text-input--inline"
          placeholder="Zip"
          showValidationErrors={false}
        />
        <br />
        {includeCounty && (
          <Input
            obj={obj}
            path="county"
            className="text-input--4 text-input--inline"
            placeholder="County"
            showValidationErrors={false}
          />
        )}

        {paths.map((path, key) => (
          <AutoValidationDisplay obj={obj} path={path} key={key} />
        ))}
      </div>
    );
  }
}
