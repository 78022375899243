import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import { useGlobalContext } from 'hooks';
import GooglePlacesAutocomplete from 'material-ui/components/GooglePlacesAutocomplete';
import BoundTextField from 'material-ui/components/BoundTextField';
import SideBySide from 'material-ui/components/layout/SideBySide';
import StateSelector from 'material-ui/components/StateSelector';

const useStyles = makeStyles({
  rightPaddedInput: {
    width: '50%',
    marginRight: 16,
  },
});

const AddressInput = ({
  obj,
  title,
  sideText,
  hideTitle,
  skipStandardValidation,
  onStateChange,
}) => {
  const classes = useStyles();
  const { updateClientData } = useGlobalContext();
  const includeCounty = useMemo(
    () => Object.keys(obj.address.constructor.allFields).includes('county'),
    [obj],
  );

  const onSelect = selection => {
    updateClientData(() => {
      const addressValues = {
        // Clear apartment when we set the address
        //   from a suggestion because the suggestion
        //   will never have one.
        apt: '',
        city: selection.city,
        zip: selection.zipCode,
        street: selection.address,
        state: selection.stateShort,
      };

      if (includeCounty) {
        addressValues.county = selection.county || '';
      }
      obj.address.setRawValues(addressValues);

      obj.address.validate();
    });
  };

  return (
    <React.Fragment>
      {!hideTitle && <SideBySide left={title || obj.name} right={sideText} />}

      <GooglePlacesAutocomplete
        obj={obj}
        path="address.street"
        label="Street Address"
        onSelect={onSelect}
        skipStandardValidation={skipStandardValidation}
      />

      <BoundTextField
        label="Suite / Apt"
        obj={obj}
        path="address.apt"
        skipStandardValidation={skipStandardValidation}
      />

      <BoundTextField
        label="City"
        obj={obj}
        path="address.city"
        skipStandardValidation={skipStandardValidation}
      />

      <SideBySide>
        <div className={classes.rightPaddedInput}>
          <StateSelector
            addressObject={obj.address}
            abbreviated={true}
            onChange={onStateChange}
            skipStandardValidation={skipStandardValidation}
          />
        </div>

        <BoundTextField
          label="Zip Code"
          obj={obj}
          path="address.zip"
          skipStandardValidation={skipStandardValidation}
        />
      </SideBySide>

      {includeCounty && <BoundTextField label="County" obj={obj} path="address.county" />}
    </React.Fragment>
  );
};

AddressInput.propTypes = {
  obj: PropTypes.object.isRequired,
  title: PropTypes.string,
  sideText: PropTypes.string,
  hideTitle: PropTypes.bool,
  skipStandardValidation: PropTypes.bool,
  onStateChange: PropTypes.func,
};

AddressInput.defaultProps = {
  hideTitle: false,
  skipStandardValidation: false,
  onStateChange: () => {},
};

export default observer(AddressInput);
