import ReactDOM from 'react-dom';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './Portal.scss';

export class Portal extends PureComponent {
  static propTypes = {
    container: PropTypes.object.isRequired,
    className: PropTypes.string,
    styles: PropTypes.object,
  };

  static defaultProps = {
    styles: {},
    position: '',
    className: '',
  };

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.el.classList.add('portal__wrapper');
    this.el.onclick = () => null;

    if (props.className) {
      const classes = props.className.split(' ');
      classes.forEach(classItem => this.el.classList.add(classItem));
    }

    Object.assign(this.el.style, props.styles);

    this.state = {
      container: props.container,
    };
  }

  componentDidMount() {
    const { container } = this.state;
    container.appendChild(this.el);
  }

  componentWillUnmount() {
    const { container } = this.state;

    if (this.el && this.el.parentNode === container) {
      container.removeChild(this.el);
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
