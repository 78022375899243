import React from 'react';
import PropTypes from 'prop-types';

import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';
import { ExcessChanges } from 'utils/enums';

export default class WizardPopUpChanges extends ClientDataReferer {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    popupCode: PropTypes.string,
  };

  generateText = popupCode => {
    switch (popupCode) {
      case ExcessChanges.ZIP_CODE:
        return 'Zip code';
      case ExcessChanges.NAME:
        return 'Name';
      default:
        return null;
    }
  };

  onClose = () => {
    this.clientDataHolder.setClientDataPristine();
    this.props.onCloseClick();
  };

  render() {
    const { popupCode } = this.props;

    const text = this.generateText(popupCode);
    if (!text) {
      return null;
    }
    return (
      <div className="popup-fuzz document-pop">
        <Popup onCloseClick={this.onClose} className="doc__action--stop" showCloseIcon={false}>
          <h4 className="center">{text} change not allowed!</h4>
          <p className="center small">
            Looks like your {text.toLowerCase()} has been changed too many times. Please contact us
            so we can help you resolve it.
          </p>
          <div className="document-pop__actions">
            <PureButton onClick={this.onClose} className="button--primary center actions__button">
              Okay
            </PureButton>
          </div>
        </Popup>
      </div>
    );
  }
}
