import React from 'react';
import { TunnelPlaceholder } from 'react-tunnels';

export default class WuiProgressPlaceholder extends React.Component {
  render() {
    // A wui-progress tunnel is rendered in Layout using appLocation
    //   and a backup is rendered in App for pages that do not use
    //   Layout. If there are multiple tunnels, we use the one from
    //   Layout, which is the second one.
    return (
      <TunnelPlaceholder id="wui-progress">
        {({ items, children }) => children || (items ? items[1].children : null)}
      </TunnelPlaceholder>
    );
  }
}
