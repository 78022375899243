import React from 'react';

import { LifeSupport, PainTreatment } from 'models/client-data/enums';
import { ProgressSections } from 'material-ui/components/progress';
import { HealthcareAgent } from 'models/client-data/agents';
import { Tips, Tip } from 'material-ui/components/tips';
import { PeopleBuilder } from 'models/utils';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';

import PersonPage from 'material-ui/components/wizard-elements/PersonPage';
import BoundRadioGroup from 'material-ui/components/BoundRadioGroup';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup';

export default class MaterialHealthcare extends MuiWizardPage {
  static progressPage = ProgressSections.YOUR_WISHES.HEALTHCARE;

  questions = [
    {
      label:
        'Do you want to be put on life support if you are in a medical condition from which there is no' +
        ' reasonable probability of recovery?',
      options: [
        [LifeSupport.YES, 'Yes'],
        [LifeSupport.NO, 'No'],
        [LifeSupport.AGENT, 'Let my agent decide'],
      ],
      path: 'healthcareWishes.lifeSupport',
    },
    {
      label:
        'Do you want to receive treatment for pain at all times, even if it hastens your death?',
      options: [
        [PainTreatment.YES, 'Yes'],
        [PainTreatment.NO, 'No'],
        [PainTreatment.AGENT, 'Let my agent decide'],
      ],
      path: 'healthcareWishes.painTreatment',
    },
  ];

  get label() {
    return 'Who do you want to make decisions about your medical care if you are too sick to decide for yourself?';
  }

  renderPage() {
    return (
      <React.Fragment>
        <Tips>
          <Tip title="Healthcare Agents">
            <p>
              A healthcare agent is also referred to as a "healthcare proxy" or "medical power of
              attorney."
            </p>
            <p>
              Choose someone you trust who will be available to make medical decisions on your
              behalf if you're unable to do so for yourself.
            </p>
          </Tip>

          <Tip title="Healthcare Decisions">
            <p>
              If you're ever in a medical situation where the doctors cannot simply ask you what you
              want, they'll look to these wishes.
            </p>
          </Tip>
        </Tips>

        <PersonPage
          title="Healthcare Choices"
          agentName="Healthcare Agent"
          agentClass={HealthcareAgent}
          peopleBuilderMethod={PeopleBuilder.getHealthcareAgents}
          primaryPath="primaryHealthcareAgent"
          alternatePath="alternateHealthcareAgent"
          caption={this.label}
        />

        <SpacedFieldGroup>
          {this.questions.map((props, index) => (
            <BoundRadioGroup {...props} key={index} />
          ))}
        </SpacedFieldGroup>
      </React.Fragment>
    );
  }
}
