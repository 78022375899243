import React from 'react';
import { DocumentType, DocumentBundle } from 'utils/enums';
import { documentBundleIncludes, statesThatProhibitAHDs, TOD_STATES } from 'utils';

import Property from '@a/images/checkout-v6/property.svg';
import Guardian from '@a/images/checkout-v6/guardian.png';
import MedicalEmergency from '@a/images/checkout-v6/medical-emergency.svg';
import UnlimitedChanges from '@a/images/checkout-v6/unlimited-changes.svg';
import PlanYouSpouse from '@a/images/checkout-v6/plan-you-spouse.svg';
import HomeCourtFees from '@a/images/checkout-v6/home-court-fees.svg';
import DocDelivery from '@a/images/checkout-v6/doc-delivery.svg';
import CustomerSupport from '@a/images/checkout-v6/customer-support.svg';
import MoneybackGuarantee from '@a/images/checkout-v6/moneyback-guarantee.png';

const genItem = (title, icon, subitems, included = true) => ({
  title,
  subitems,
  included,
  icon: <img alt="" src={icon} />,
});

const SINGLE_BENEFITS = cd => {
  const benefits = [
    genItem('Leave property to loved ones', Property, [
      'Decide who inherits your worldly possessions. Prevent disputes, confusion, and state law control.',
    ]),
  ];

  if (cd.children.some(c => c.isMinor)) {
    benefits.push(
      genItem('Name guardians for children', Guardian, [
        "Choose who will raise your children if you're unable to. Don't let a court choose for you.",
      ]),
    );
  }

  benefits.push(
    ...[
      genItem('Plan for a medical emergency', MedicalEmergency, [
        'Give clear authority for financial and health care matters. Avoid uncertainty and disagreements.',
      ]),
      genItem(
        `Unlimited changes for ${DocumentBundle.changePeriod(DocumentBundle.SINGLE)}`,
        UnlimitedChanges,
        ['Make changes and re-print as many times as you need, for no additional charge.'],
      ),
    ],
  );

  return benefits;
};
const ULTIMATE_SINGLE_BENEFITS = cd =>
  SINGLE_BENEFITS(cd)
    .slice(0, -1)
    .concat([
      genItem(
        `Unlimited changes for ${DocumentBundle.changePeriod(DocumentBundle.ULTIMATE)}`,
        UnlimitedChanges,
        ['Make changes and re-print as many times as you need, for no additional charge.'],
      ),
    ]);
const MARRIED_BENEFITS = (cd, included) => [
  genItem(
    'Plan for you and your spouse',
    PlanYouSpouse,
    [
      `Get documents for both ${cd.firstName} and ${cd.spouse.firstName}. ` +
        'Cover both sets of wishes in one coherent way.',
    ],
    included,
  ),
];
const HOMEOWNERS_BENEFITS = included => [
  genItem(
    'Pass on your home without court fees',
    HomeCourtFees,
    [
      'Transfer real estate directly – no court intervention. Save thousands of dollars and months of hassle.',
    ],
    included,
  ),
];
const ULTIMATE_BENEFITS = included => [
  genItem(
    'Physical documents overnight delivery',
    DocDelivery,
    ["Get your documents fast. We'll collect your shipping address in next steps."],
    included,
  ),
  genItem(
    'Premium customer support',
    CustomerSupport,
    [
      'Enjoy priority attention from our most experienced agents, including on nights and weekends.',
    ],
    included,
  ),
];

export function getBenefitsForDocumentBundle(documentBundle, clientData) {
  // build benefits section
  const benefits = documentBundleIncludes(documentBundle, DocumentBundle.ULTIMATE)
    ? Array.from(ULTIMATE_SINGLE_BENEFITS(clientData))
    : Array.from(SINGLE_BENEFITS(clientData));

  if (documentBundleIncludes(documentBundle, DocumentBundle.COUPLES) && clientData.isMarried) {
    benefits.push(...MARRIED_BENEFITS(clientData));
  } else if (clientData.isMarried) {
    benefits.push(...MARRIED_BENEFITS(clientData, false));
  }

  if (documentBundleIncludes(documentBundle, DocumentBundle.HOMEOWNERS)) {
    benefits.push(...HOMEOWNERS_BENEFITS());
  } else {
    benefits.push(...HOMEOWNERS_BENEFITS(false));
  }

  if (documentBundleIncludes(documentBundle, DocumentBundle.ULTIMATE)) {
    benefits.push(...ULTIMATE_BENEFITS());
  } else {
    benefits.push(...ULTIMATE_BENEFITS(false));
  }

  // Standard across everything, but always the last item
  benefits.push(
    genItem('Moneyback Guarantee', MoneybackGuarantee, [
      "Get a full refund if you're unsatisfied or change your mind. We're confident you'll be happy.",
    ]),
  );

  return benefits;
}

export function getIncludedBenefitsForDocumentBundle(documentBundle, clientData) {
  return getBenefitsForDocumentBundle(documentBundle, clientData).filter(
    benefit => benefit.included,
  );
}

export function getBundleDescription(bundle, clientData) {
  const ownerString = clientData.isPlanningForSpouse
    ? 'for you and your spouse'
    : 'for your complete set of documents';

  switch (bundle) {
    case DocumentBundle.ULTIMATE:
      return `For people who want maximum coverage and protection.
                Make unlimited changes for ${DocumentBundle.changePeriod(
                  DocumentBundle.ULTIMATE,
                )}.`;
    case DocumentBundle.HOMEOWNERS:
      return 'Our signature plan for people who own a home. Keeps your home out of probate court.';
    case DocumentBundle.COUPLES:
      return "The perfect option for couples who don't own a home.";
    case DocumentBundle.SINGLE:
      return clientData.isMarried
        ? "The right choice when you're planning only for yourself."
        : "A set of core documents for those who don't own a home. Does not include probate avoidance.";
    default:
      return `One flat price ${ownerString}.`;
  }
}

function getRealEstateDocuments(clientData, docTypes) {
  let realEstateDocTypes = [];
  if (docTypes) {
    if (docTypes.includes(DocumentType.DEED)) {
      realEstateDocTypes.push(DocumentType.DEED);
    }
    if (docTypes.includes(DocumentType.TRUST)) {
      realEstateDocTypes.push(DocumentType.TRUST);
    }
  }
  if (!realEstateDocTypes.length) {
    const { primaryResidence } = clientData;
    if (primaryResidence) {
      if (TOD_STATES.includes(primaryResidence.address.state)) {
        realEstateDocTypes = [DocumentType.DEED];
      } else {
        realEstateDocTypes = [DocumentType.TRUST];
      }
    } else {
      realEstateDocTypes = [DocumentType.DEED, DocumentType.TRUST];
    }
  }
  return realEstateDocTypes;
}

export function getUnrestrictedDocuments(clientData, docTypes) {
  const basicDocTypes = [
    DocumentType.WILL,
    DocumentType.ADVANCE_HEALTHCARE_DIRECTIVE,
    DocumentType.POWER_OF_ATTORNEY,
  ];
  if (
    statesThatProhibitAHDs.includes(clientData.address.state) &&
    (!clientData.spouse.address || statesThatProhibitAHDs.includes(clientData.spouse.address.state))
  ) {
    // remove living will if both client and spouse live in a state that prohibits them
    basicDocTypes.splice(1, 1);
  }

  return [...basicDocTypes, ...getRealEstateDocuments(clientData, docTypes)];
}

export const getDocumentName = (bundle, clientData) => docType => {
  // If the user is planning for spouse and the document belongs to a bundle
  //   that includes the married bundle, we indicate that they will receive
  //   two of each basic document
  const basicDocTypes = [
    DocumentType.WILL,
    DocumentType.ADVANCE_HEALTHCARE_DIRECTIVE,
    DocumentType.POWER_OF_ATTORNEY,
  ];
  const getsTwo =
    basicDocTypes.includes(docType) &&
    documentBundleIncludes(bundle, DocumentBundle.COUPLES) &&
    clientData.isPlanningForSpouse;
  return DocumentType.displayName(docType) + (getsTwo ? ' x2' : '');
};
