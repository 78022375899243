import WizardSection from 'ui/components/wizard/WizardSection';
import Info from 'ui/sections/file/transfer/pages/Info';
import { Urls } from 'urls';

export default class SectionTransfer extends WizardSection {
  static url = '/transfer/';
  static title = 'Transfer';
  static description = 'Transfer your property';
  static finalUrl = Urls.file;
  static fallbackUrl = Urls.file;
  static showFooter = false;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(Info);
  }
}
