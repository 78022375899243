import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    '& + $root': {
      marginLeft: 15,
    },

    '&:focus': {
      fallbacks: theme.focus.native,
      outlineOffset: 4,
    },
  },
  disabled: {},
  contained: {
    fontSize: 22,
    minHeight: 48,
    fontWeight: 600,
    letterSpacing: 2,
    lineHeight: '30px',
    padding: [[0, 32]],
    textTransform: 'none',

    '&:hover$disabled, &$disabled': {
      color: '#fff',
      backgroundColor: theme.palette.custom.disabledBackground,
    },
  },
  text: {
    padding: 0,
    minWidth: 0,
    fontSize: 14,
    fontWeight: 600,
    lineHeight: '20px',

    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  },
  outlined: {
    fontSize: 12,
    minHeight: 48,
    letterSpacing: 1,
    lineHeight: '16px',
    padding: [[0, 16]],
    textTransform: 'uppercase',
    borderRadius: 0,
    fontWeight: 600,
  },
});

export default withStyles(styles)(Button);
