import React from 'react';
import PropTypes from 'prop-types';

import { API } from 'API';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

import './DocumentImageViewer.scss';

export default class DocumentImageViewer extends ClientDataReferer {
  static propTypes = {
    forAdmin: PropTypes.bool,
    session: PropTypes.object,
  };

  static defaultProps = {
    forAdmin: false,
  };

  hasFetchedImages = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      imageUrls: null,
      error: false,
    };
  }

  fetchImageLinks() {
    const { forAdmin, session } = this.props;
    const getUrls = forAdmin
      ? () => API.getNotarizationAdminImageUrls(session.id)
      : () => API.getNotarizationImageUrls();

    getUrls()
      .then(res => this.setState({ imageUrls: res.data }))
      .catch(() => this.setState({ error: true }));
  }

  componentDidUpdate() {
    if (this.hasFetchedImages) {
      return;
    }

    if (this.props.session && this.props.session.documentsGenerated) {
      this.fetchImageLinks();
      this.hasFetchedImages = true;
    }
  }

  get statuses() {
    const statuses = ['no_signatures', 'client_statement', 'client_requested', 'client_signed'];

    if (this.props.session.includesSpouse) {
      statuses.push(...['spouse_statement', 'spouse_requested', 'spouse_signed']);
    }

    statuses.push(
      ...[
        'witness1_requested',
        'witness1_signed',
        'witness2_requested',
        'witness2_signed',
        'notarized',
      ],
    );

    return statuses;
  }

  get renderedStatuses() {
    const statuses = ['no_signatures', 'client_signed'];
    if (this.props.session.includesSpouse) {
      statuses.push('spouse_signed');
    }

    statuses.push(...['witness1_signed', 'witness2_signed', 'notarized']);

    return statuses;
  }

  getRenderedStatus(status) {
    const statuses = this.statuses;
    const renderedStatuses = Array.from(this.renderedStatuses).reverse();

    return renderedStatuses.find(s => statuses.indexOf(s) <= statuses.indexOf(status));
  }

  get nextSigningStatus() {
    const { signingStatus } = this.props.session;
    const statuses = this.statuses;

    const nextStatus =
      statuses.find(s => statuses.indexOf(s) > statuses.indexOf(signingStatus)) ||
      statuses[statuses.length - 1];

    return this.getRenderedStatus(nextStatus);
  }

  get currentSigningStatus() {
    const { signingStatus } = this.props.session;
    return this.getRenderedStatus(signingStatus);
  }

  get urlBase() {
    const { forAdmin, session } = this.props;

    if (forAdmin) {
      return `/api/v1/notarization/admin/sessions/${session.id}`;
    }

    return '/api/v1/notarization/session';
  }

  makeImagesArray = (session, signingStatus) => {
    const { imageUrls } = this.state;

    if (imageUrls === null) {
      return null;
    }
    const urls = imageUrls[signingStatus];
    return urls.map(url => <img alt="Legal document" key={url} src={url} />);
  };

  render() {
    const { session } = this.props;
    const { imageUrls, error } = this.state;

    if (!session) {
      return null;
    }

    const currentSet = this.makeImagesArray(session, this.currentSigningStatus),
      nextSet = this.makeImagesArray(session, this.nextSigningStatus);

    if (error) {
      return (
        <div className="document-image-viewer">
          <h1>Error generating documents, please refresh the page.</h1>
        </div>
      );
    }

    if (!imageUrls) {
      return (
        <div className="document-image-viewer">
          <h1>Documents are generating, please wait...</h1>
        </div>
      );
    }

    return (
      <div className="document-image-viewer">
        {currentSet}

        <div className="hidden">{nextSet}</div>
      </div>
    );
  }
}
