import React from 'react';
import PropTypes from 'prop-types';
import { BoundControl } from 'ui/components/BoundControl';
import PureRadio from 'ui/components/pure-controls/PureRadio';

export default class Radio extends BoundControl {
  static propTypes = Object.assign({}, BoundControl.propTypes, {
    path: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    helpText: PropTypes.string,
  });

  render() {
    return (
      <PureRadio
        onCheck={() => this.setRawValue(this.props.value)}
        checked={this.getRawValue() === this.props.value}
        dataPath={this.path}
        dataValue={this.props.value}
        helpText={this.props.helpText}
      >
        {this.props.children}
      </PureRadio>
    );
  }
}
