import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import PurePersonChooser from 'material-ui/components/pure/PurePersonChooser';
import { PeopleBuilder } from 'models/utils';
import { AgentType } from 'models/client-data/enums';
import { propTypes, defaultProps } from 'prop-type-sets/BoundField';
import { useGlobalContext, useValidation } from 'hooks';

const AgentPersonChooser = ({
  agentClass,
  agentType,
  allowNull,
  extractInitialPeople,
  label,
  additionalValidations: externAdditionalValidations,
  ...props
}) => {
  const { clientData, testator, updateClientData } = useGlobalContext();
  const { path } = props;
  const additionalValidations = [
    () => {
      if (!testator.getValue(path) && !allowNull) {
        return [agentClass.nameGuidance || 'Please select a person.'];
      }

      return [];
    },
    ...externAdditionalValidations,
  ];

  const {
    domNode,
    getValidationErrors,
    showValidationErrors,
    setShowValidationErrors,
  } = useValidation({
    ...props,
    additionalValidations,
  });

  const initialPeople = useMemo(() => extractInitialPeople(clientData), [
    extractInitialPeople,
    clientData,
  ]);

  const onSelect = p => {
    let agent = null;

    if (p) {
      agent = new agentClass({
        ...p,
        type: agentType,
        owner: testator.ownerString,
      });

      setShowValidationErrors(false);
    }

    updateClientData(() => {
      testator.setValue(path, agent);
    });
  };

  const onBlur = () => setShowValidationErrors(true);

  // The crawl can be rather expensive, and doing it on every keystroke causes lag
  const allNames = useMemo(() => PeopleBuilder.getAll(clientData).map(p => p.name), [clientData]);

  return (
    <PurePersonChooser
      onBlur={onBlur}
      initialPeople={initialPeople}
      selectedPerson={testator.getValue(path)}
      onSelect={onSelect}
      label={label}
      allNames={allNames}
      containerRef={domNode}
      error={(showValidationErrors && getValidationErrors()[0]) || ''}
      optional={allowNull}
    />
  );
};

AgentPersonChooser.propTypes = {
  ...propTypes,

  // The Agent object type to convert the selected person to
  agentClass: PropTypes.func.isRequired,

  // Primary or alternate agent type
  agentType: PropTypes.oneOf(AgentType.values).isRequired,

  // Whether or not this can be null - usually yes for alternate agents, but not always
  allowNull: PropTypes.bool,

  // The function to gather the group of initially selectable people
  extractInitialPeople: PropTypes.func.isRequired,
};

AgentPersonChooser.defaultProps = {
  ...defaultProps,
  allowNull: false,

  // All of our agent accessors are not actually in the field list for the testator -
  //   they're just aliases for scanning the array for the objects we want.
  //
  // Our normal validation structure will throw an error when trying to access a
  //   non-existent path, and allowNull is prop controlled instead of type-system controlled.
  skipStandardValidation: true,
};

export default observer(AgentPersonChooser);
