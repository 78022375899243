import React from 'react';
import { Link } from 'react-router-dom';
import ValidationDisplay from 'ui/components/ValidationDisplay';
import WizardPage from 'ui/components/wizard/WizardPage';
import PureButton from 'ui/components/pure-controls/PureButton';

import './ButtonFlow.scss';

export default class IntroPage extends WizardPage {
  ctaText = 'Next';
  static showNextButton = true;
  static dataClass = 'vvcenter center';

  // We only want to show intro pages for spouses.
  static get noUserActions() {
    return !this.forSpouse;
  }

  // We only show subcopy for intro pages if
  //   the experiment is disabled.
  get showSubCopy() {
    return !this.context.experiments.actionPagesOnly;
  }

  renderPageContent() {
    return (
      <div data-wizardpage={this.props.fullUrl} className={this.constructor.dataClass}>
        {this.props.showCloseButton && <Link to={this.props.closeUrl} className="wizard-close" />}
        {this.renderPage()}

        {this.constructor.showNextButton && (
          <div className="button-flow button-flow--compressed center">
            <PureButton
              onClick={this.goToNext.bind(this)}
              className="button--primary button--inline button--no-right-margin"
              dataPath="next"
            >
              {this.ctaText}
            </PureButton>
          </div>
        )}

        {this.state.postError && <ValidationDisplay errors={[this.state.errorMessage]} />}
      </div>
    );
  }
}
