import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Grid from '@wui/layout/grid';
import Link from '@wui/basics/link';
import Account from '@/pages/account';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';
import CustomIcon from '@wui/basics/customIcon';
import Typography from '@wui/basics/typography';
import { withStyles } from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';

import { ReactComponent as AccountIcon } from '@a/images/account.svg';

const styles = theme => ({
  account: {
    position: 'relative',
    boxShadow: theme.customShadows.standard,
    zIndex: theme.zIndex.above,
  },
  accountButton: {
    padding: [24, '!important'],
    width: '100%',
    boxSizing: 'border-box',
  },
  logout: {
    padding: [[16, 32]],
    background: theme.palette.grey.lightBackground,
    borderRadius: [[0, 0, theme.dimensions.borderRadiusLarge, theme.dimensions.borderRadiusLarge]],
  },
});

class AccountNav extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    className: PropTypes.string,
    tooltip: PropTypes.bool,
    close: PropTypes.func,
  };

  static defaultProps = {
    className: '',
    tooltip: false,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  goToAccount = () => {
    this.props.close();
    this.context.clientDataHolder.props.history.push(Account.url);
  };

  goToMainMenu = () => {
    window.location = `${window.WillingConfig.membersAppLink}/services`;
  };

  onKeyDown = e => {
    e.stopPropagation();
    if (e.key === 'Escape') {
      this.props.close();
    }
  };

  render() {
    const { classes, className, tooltip } = this.props;
    const { clientData, logout, serverData } = this.context.clientDataHolder;
    const showReturnButton =
      clientData.covidEmployer !== 'upwise' &&
      !serverData.isFreeLook &&
      !digitalEstatePlanningService;

    return (
      <Panel
        round
        tooltip={tooltip}
        paddingless
        className={className}
        onClick={e => e.stopPropagation()}
        onKeyDown={this.onKeyDown}
      >
        {showReturnButton && (
          <div className={classes.account}>
            {serverData.fromPortunus ? (
              <Link
                component="button"
                underline="none"
                onClick={this.goToMainMenu}
                className={classNames(classes.accountButton, 'go-to-account')}
              >
                <Typography>Return to Main Menu</Typography>
              </Link>
            ) : (
              <Link
                component="button"
                underline="none"
                onClick={this.goToAccount}
                className={classNames(classes.accountButton, 'go-to-account')}
                data-path="goToAccount"
              >
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography align="left" variant="h6">
                      My Account
                    </Typography>
                    <Typography align="left">{clientData.name}</Typography>
                  </Grid>
                  <CustomIcon src={AccountIcon} width={36} />
                </Grid>
              </Link>
            )}
          </div>
        )}
        <div className={classes.logout}>
          <Grid container direction="row" justify="flex-end">
            <Link component="button" underline="none" onClick={logout}>
              <Typography>Sign out</Typography>
              <Spacer h={12} />
              <CustomIcon src={LogoutIcon} color={theme => theme.palette.text.primary} />
            </Link>
          </Grid>
        </div>
      </Panel>
    );
  }
}

export default withStyles(styles)(AccountNav);
