import React from 'react';
import PropTypes from 'prop-types';

import Link from '@wui/basics/link';
import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import VerticalDivider from '@wui/layout/verticalDivider';
import { getPhoneNumber, getEmail } from 'utils';

export default class Help extends React.Component {
  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
  };
  render() {
    const {
      clientDataHolder,
      clientDataHolder: { clientData, serverData },
    } = this.context;

    return (
      <React.Fragment>
        <Typography variant="h6">Need help?</Typography>
        <Typography>Our customer support team is glad to assist you.</Typography>
        <Spacer v={16} />
        <Grid container direction="row" alignItems="center">
          {clientDataHolder.supportInfo.showSupportPhoneNumber && serverData.fromPortunus && (
            <>
              <Typography>
                <Link href={getPhoneNumber(clientData, serverData, 'link')}>
                  {getPhoneNumber(clientData, serverData)}
                </Link>
              </Typography>
              <VerticalDivider spacing={16} />
            </>
          )}
          <Typography>
            <Link href={`mailto:${getEmail()}`}>{getEmail()}</Link>
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
}
