import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Modal from '@wui/basics/modal';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import DimensionLimiter from '@wui/layout/dimensionLimiter';

const ManualSigningModal = ({ open, onClose }) => (
  <Modal title="Please sign offline" open={open} onClose={onClose}>
    <DimensionLimiter h={360}>
      <Typography variant="body1">
        Customers in skilled nursing facilities must sign and notarize their documents manually, in
        the presence of an advocate or ombudsman.
      </Typography>
      <Spacer v={24} />
      <Typography variant="body1">
        Click "Other Options" to print or download your documents instead.
      </Typography>
      <Spacer v={16} />
      <Grid container direction="row" justify="flex-end">
        <Button variant="contained" color="primary" noMinWidth onClick={onClose}>
          Dismiss
        </Button>
      </Grid>
    </DimensionLimiter>
  </Modal>
);

ManualSigningModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManualSigningModal;
