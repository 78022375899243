import React from 'react';

import App from '@/App';
import Loading from '@willing-shared/components/loading';

const LoadingScreen = ({ children, ...props }) => (
  <App wrapContent showHeader={false} {...props}>
    <Loading>{children}</Loading>
  </App>
);

export default LoadingScreen;
