import React from 'react';
import PropTypes from 'prop-types';
import IntroPage from 'ui/components/wizard/IntroPage';
import PureButton from 'ui/components/pure-controls/PureButton';

import './FailurePage.scss';

export default class FailurePage extends IntroPage {
  ctaText = this.props.buttonText;
  static pageName = 'FailurePage';
  static showNextButton = false;
  static dataClass = 'vvcenter center no-bottom-padding no-top-padding';
  static enterNavigation = false;

  static propTypes = {
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    hideButton: PropTypes.bool,
    onRetry: PropTypes.func.isRequired,
    pointerHeaderText: PropTypes.string,
    pointers: PropTypes.array,
    hideIcon: PropTypes.bool,
  };

  static defaultProps = {
    hideButton: false,
    hideIcon: false,
    pointers: [],
  };

  renderPage() {
    const {
      headerText,
      bodyText,
      pointerHeaderText,
      pointers,
      children,
      hideButton,
      hideIcon,
    } = this.props;

    return (
      <div className="fail-info">
        {!hideIcon && <div className="icon" />}

        <h1>{headerText}</h1>

        <p className="fail-body">{bodyText}</p>

        {pointerHeaderText && <p className="pointer-header">{pointerHeaderText}</p>}

        {pointers && (
          <ul className="pointers">
            {pointers.map((p, i) => (
              <li key={i}>{p}</li>
            ))}
          </ul>
        )}

        {!hideButton && (
          <PureButton
            onClick={this.props.onRetry}
            className="button--primary button--inline"
            dataPath="back"
          >
            {this.ctaText}
          </PureButton>
        )}

        {children}
      </div>
    );
  }
}
