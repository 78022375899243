import AboutYou from './pages/AboutYou';
import WizardSection from 'ui/components/wizard/WizardSection';
import RealEstate from 'ui/sections/about-you/pages/RealEstate';
import AboutYourFamily from 'ui/sections/about-you/pages/AboutYourFamily';
import PageVisit from 'ui/components/wizard/PageVisit';
import PlanNotice from 'ui/sections/about-you/pages/PlanNotice';
import WelcomeBack from 'ui/sections/about-you/pages/WelcomeBack';
import MlpBundle from 'ui/sections/about-you/pages/MlpBundle';
import Urls from 'urls';

export default class SectionAboutYou extends WizardSection {
  static url = Urls.sectionAboutYou;
  static afterRegisterUrl = new PageVisit(SectionAboutYou, AboutYourFamily).url;
  static title = 'Personal Details';
  static description = 'Enter basic info about family and real estate.';
  static showCloseButton = false;
  static isPlanSection = true;

  get finalUrl() {
    // dynamically add final page here
    return null;
  }

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    const v4LaunchDate = new Date(2018, 4, 11);
    const previousLoginDate = serverData.previousLogin
      ? new Date(serverData.previousLogin)
      : new Date();
    if (
      previousLoginDate < v4LaunchDate &&
      !clientData.isSectionValid(SectionAboutYou) &&
      !clientData.isStatusValid('sawWelcomeBack')
    ) {
      visit(WelcomeBack);
    }
    if (serverData.fromPortunus && !clientData.isStatusValid('sawMlpBundle')) {
      visit(MlpBundle);
    }
    visit(AboutYou);
    visit(AboutYourFamily);
    visit(RealEstate);

    if (clientData.realEstates.length && !clientData.covidEmployer && !serverData.fromPortunus) {
      visit(PlanNotice);
    }
  }
}
