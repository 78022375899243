export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function snakeCase(string) {
  return string.split(/(?=[A-Z])/).join('_').toLowerCase();
}

export function titleCase(string) {
  return string.split(' ').map(w => capitalize(w.toLowerCase())).join(' ');
}

export function capitalizeFirstAndLast(string) {
  const words = string.split(' ');
  words[0] = capitalize(words[0]);
  words[words.length - 1] = capitalize(words[words.length - 1]);
  return words.join(' ');
}

export function formatPhoneNumber(phone) {
  const cleaned = (phone || '').replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  return match
    ? `(${match[1]}) ${match[2]}-${match[3]}`
    : null;
}
