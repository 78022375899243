import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { API } from 'API';
import { Urls } from 'urls';
import CheckoutV6 from 'material-ui/pages/checkout-v6/CheckoutV6';
import { useGlobalContext, useDocumentTitle } from 'hooks';

const CheckoutPicker = ({ title, location: { search } }) => {
  useDocumentTitle(title);
  const { serverData, experiments, analyticsEvents, clientDataHolder } = useGlobalContext();
  const { firstCheckoutPageViewedAt } = serverData;

  const [loaded, setLoaded] = useState(false);
  const [trackedCheckoutView, setTrackedCheckoutView] = useState(
    Boolean(firstCheckoutPageViewedAt),
  );

  const updateServerData = newServerData => {
    clientDataHolder.setServerData(newServerData);
    clientDataHolder.sendUserProperties();
  };

  const trackCheckoutView = () => {
    if (trackedCheckoutView) {
      return;
    }

    API.trackCheckoutView()
      .then(updateServerData)
      // If this fails, proceed anyways.
      .catch(() => {})
      .finally(() => setTrackedCheckoutView(true));
  };

  useEffect(() => {
    analyticsEvents.dataLayer.push({ event: 'willing.page.loaded.checkout-picker' });
    setTimeout(() => setLoaded(true), experiments.delay);
    trackCheckoutView();

    // We only want this on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded || !trackedCheckoutView) {
    return null;
  }

  const params = new URLSearchParams(search);
  return <Redirect to={`${CheckoutV6.url}?${params.toString()}`} />;
};

CheckoutPicker.url = Urls.checkoutPicker;

CheckoutPicker.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default observer(CheckoutPicker);
