import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import OT from '@opentok/client';

import GenericOpenTokError from './GenericOpenTokError';

const OpenTokPublisher = ({
  targetElementId,
  options,
  initHandler,
  completionHandler: externCompletionHandler,
  destructionHandler,
}) => {
  const [error, setError] = useState(false);
  const publisherRef = useRef(null);

  const initPublisher = useCallback(() => {
    const completionHandler = hasError => {
      externCompletionHandler(hasError);

      if (hasError) {
        setError(true);
      }
    };

    publisherRef.current = OT.initPublisher(targetElementId, options, completionHandler);
    initHandler(publisherRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetElementId, options, externCompletionHandler]);

  const destroyPublisher = useCallback(() => {
    const { current: publisher } = publisherRef;

    if (publisher) {
      publisher.destroy();
      publisherRef.current = null;
      destructionHandler();
    }
  }, [publisherRef, destructionHandler]);

  useEffect(() => {
    initPublisher();

    return destroyPublisher;
  }, [initPublisher, destroyPublisher]);

  return <GenericOpenTokError open={error} onClose={() => setError(false)} />;
};

OpenTokPublisher.propTypes = {
  targetElementId: PropTypes.string,
  options: PropTypes.object,
  initHandler: PropTypes.func,
  completionHandler: PropTypes.func,
  destructionHandler: PropTypes.func,
};

OpenTokPublisher.defaultProps = {
  targetElementId: 'publisher',
  options: {},
  completionHandler: () => null,
  destructionHandler: () => null,
  initHandler: () => null,
};

export default observer(OpenTokPublisher);
