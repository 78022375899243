import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Urls from 'urls';
import { API } from 'API';
import { handleChange } from 'willing-shared/src/utils/input';
import AuthLayout from 'material-ui/components/authentication/AuthLayout';
import Help from 'material-ui/components/Help';
import withLegalPlansRedirect from '@willing-shared/hocs/withLegalPlansRedirect';

import Form from '@wui/layout/form';
import Button from '@wui/input/button';
import Textbox from '@wui/input/textbox';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';
import TabDivider from '@wui/layout/tabDivider';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

class ResetPassword extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      sent: false,
      validationError: null,
      inputErrors: {},
    };

    this.handleChange = handleChange.bind(this);
  }

  validateForm() {
    const errors = {};

    if (!this.state.email) {
      errors.email = 'Please enter your email.';
    }

    this.setState({
      inputErrors: errors,
    });

    return Object.keys(errors).length === 0;
  }

  handleSubmit = async e => {
    e.preventDefault();
    if (!this.validateForm()) {
      return;
    }
    try {
      await API.resetPassword(this.state.email);
      this.setState({ sent: true });
    } catch (response) {
      const errorMessage = response.getErrorMessage({
        send_failed: 'There was an error resetting your password. Please try again.',
      });
      this.setState({
        validationError: errorMessage,
      });
    }
  };

  renderSuccess() {
    return (
      <React.Fragment>
        <Typography variant="body1">Check your email for a link to reset your password.</Typography>
        <TabDivider halfMargin />
        <Help />
      </React.Fragment>
    );
  }

  renderResetPasswordForm() {
    const { inputErrors, validationError } = this.state;

    return (
      <Form onSubmit={this.handleSubmit} noMargin error={validationError}>
        <Textbox
          name="email"
          type="email"
          label="Email"
          autoComplete="off"
          value={this.state.email}
          onChange={this.handleChange}
          error={inputErrors.email}
        />
        <Spacer v={8} />
        <Button variant="contained" color="primary" type="submit" noMinWidth size="large">
          Request Reset
        </Button>
      </Form>
    );
  }

  render() {
    const { history } = this.props;
    return (
      <AuthLayout>
        <Button variant="text" size="small" onClick={() => history.push(Urls.login)}>
          <ArrowBackIcon style={{ width: 18, height: 18 }} />
          Back to login
        </Button>
        <Spacer v={24} />
        <Typography variant="h4">Reset Password</Typography>
        {this.state.sent ? this.renderSuccess() : this.renderResetPasswordForm()}
      </AuthLayout>
    );
  }
}

export default withLegalPlansRedirect(withRouter(ResetPassword));
