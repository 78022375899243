import React from 'react';
import IntroPage from 'ui/components/wizard/IntroPage';
import MaterialJointWarning from 'material-ui/sections/assets/ResidualJointWarning';

export default class BadPrimariesWarning extends IntroPage {
  static baseUrl = 'bad-primaries/';

  static customDisplay() {
    return ({ props }) => <MaterialJointWarning {...props} forSpouse={this.forSpouse} />;
  }
}
