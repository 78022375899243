import React from 'react';
import { observer } from 'mobx-react';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';

import TechCheckHeader from '../TechCheckHeader';
import Fields from './Fields';

class TechCheckVideoSource extends NotarizationWizardPageMixin(MuiWizardPage) {
  static baseUrl = 'tech-check/video/';
  static notarizationSession = NotarizationStatus.TECH_CHECK;

  renderPage() {
    return (
      <>
        <TechCheckHeader selectedTab={0} />
        <Fields goToNext={this.goToNext.bind(this)} />
      </>
    );
  }
}

TechCheckVideoSource.layoutProps = {
  ...TechCheckVideoSource.layoutProps,
  wide: 2,
};

export default observer(TechCheckVideoSource);
