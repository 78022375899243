import React from 'react';
import PropTypes from 'prop-types';

import { BoundControl } from 'ui/components/BoundControl';
import PureInput from 'ui/components/pure-controls/PureInput';
import { joinClass } from 'utils';

export default class Input extends BoundControl {
  static propTypes = {
    maxLength: PropTypes.number,
  };

  render() {
    const className = joinClass(
      this.props.className,
      this.hasValidationErrors() ? 'text-input--error' : '',
    );

    return (
      <span>
        <PureInput
          type={this.props.type}
          value={this.getRawValue()}
          className={className}
          onChange={value => this.setRawValue(value)}
          onBlur={() => this.setState({ showValidationErrors: true })}
          placeholder={this.props.placeholder || null}
          dataPath={this.props.path}
          maxLength={this.props.maxLength}
        />

        {this.renderValidation()}
      </span>
    );
  }
}
