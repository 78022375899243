import PropTypes from 'prop-types';
import { BoundControl } from 'ui/components/BoundControl';

export class StaticValue extends BoundControl {
  static propTypes = Object.assign(
    {
      value: PropTypes.any,
    },
    BoundControl.propTypes,
  );

  componentDidMount() {
    super.componentDidMount();
    this.setRawValue(this.props.value);
  }

  render() {
    return null;
  }
}
