import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FailurePage from '../Failure';

const Failed = ({ retry }) => (
  <FailurePage
    message="No suitable video feed"
    details="Seems like we could not identify a suitable video feed. Please check your video inputs and try again."
    buttonText="Retry Video Test"
    retry={retry}
  />
);

Failed.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default observer(Failed);
