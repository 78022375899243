import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Redirect, useHistory } from 'react-router-dom';

import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import TabDivider from '@wui/layout/tabDivider';
import Modal from '@wui/basics/modal';

import SectionSign from 'ui/sections/sign/SectionSign';
import WuiApp from '@/WuiApp';
import Layout from '@c/layout/Layout';
import { useGlobalContext, useDocumentTitle } from 'hooks';
import { AppLocation } from 'utils/enums';
import { canNotarizeOnline } from 'utils';
import Urls from 'urls';

import AttorneyReview from '@a/images/attorney-review.png';
import Notarize from '@a/images/notarize.png';
import FinishYourPlan from '@willing-shared/assets/images/finish-your-plan.png';
import Map from '@a/images/map.png';
import OptionWithImage from './OptionWithImage';

import { API } from 'API';

const analogBody =
  'Your documents are ready to be signed. Print or download your documents to have them signed and legally notarized by witnesses and a notary.';
const digitalBody =
  'Your documents are ready to be signed. Sign and notarize your documents online with one of our licensed notaries, or download and notarize your documents offline.';

const DepByDefaultSummary = ({ title }) => {
  useDocumentTitle(title);
  const history = useHistory();
  const { clientData, serverData } = useGlobalContext();
  const [showStartOverModal, setShowStartOverModal] = useState(false);

  useEffect(() => {
    API.sendUserEvent('DEP_SUMMARY_SEEN');
  }, []);

  if (!serverData.depByDefault) {
    return <Redirect to={SectionSign.url} />;
  }

  if (!clientData.isPlanValid()) {
    return <Redirect to={Urls.planDashboard} />;
  }

  const finishNow = () => {
    history.push(SectionSign.url);
  };

  const scheduleCall = () => {
    history.push(Urls.attorneyReview);
  };

  const keepPlan = async () => {
    await API.sendUserEvent('DEP_KEEP_PLAN_CLICKED');
    setShowStartOverModal(false);
  };

  const startOver = async () => {
    await API.sendUserEvent('DEP_START_OVER_CLICKED');

    const params = new URLSearchParams();
    params.append('selected', 'WI');
    params.append('selected', 'LW');
    params.append('selected', 'PA');
    window.location = `${window.WillingConfig.membersAppLink}/directory?${params.toString()}`;
  };

  const showAttorneyReview = !clientData.minorChildBeneficiaries.length;
  const analog = !canNotarizeOnline(clientData, serverData);
  const backButtonProps = {
    // Linking directly here to skip the animation
    onClick: serverData.attorneyReviewRequested ? () => history.push('/documents/review/') : null,
  };

  return (
    <WuiApp>
      <Modal
        open={showStartOverModal}
        onClose={() => setShowStartOverModal(false)}
        title="Start Over"
      >
        <Typography variant="body1">
          Are you sure you want to start over and create a new estate plan with an attorney? By
          clicking “Start Over", you will be taken to a page that shows attorneys in your area. New
          documents may take 4-6 weeks to complete.
        </Typography>
        <Spacer v={32} />
        <Grid container direction="row" spacing={2}>
          <Grid item>
            <Button onClick={startOver} variant="outlined" noMinWidth>
              Start Over
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={keepPlan} variant="contained" color="primary" noMinWidth>
              Keep My Current Plan
            </Button>
          </Grid>
        </Grid>
      </Modal>
      <Layout
        wide={2}
        showBackButton
        appLocation={AppLocation.DOCUMENTS}
        backButtonProps={backButtonProps}
      >
        <Spacer v={24} />

        <OptionWithImage
          title={analog ? 'Print or download your documents' : 'Notarize your documents now'}
          subtitle="Take the final step"
          body={analog ? analogBody : digitalBody}
          buttonText="Finish Now"
          callback={finishNow}
          Image={analog ? FinishYourPlan : Notarize}
          reverse
          isPrimary
        />

        <Spacer v={32} />
        <TabDivider expanded noMargin />
        <Spacer v={32} />

        {showAttorneyReview && (
          <>
            <OptionWithImage
              title="Have an attorney review your documents."
              subtitle="Not ready to sign?"
              body="Schedule a phone call with one of our network attorneys to review your documents and
                answer legal questions."
              buttonText="Schedule a Call"
              callback={scheduleCall}
              Image={AttorneyReview}
            />

            <Spacer v={32} />
            <TabDivider expanded noMargin />
            <Spacer v={32} />
          </>
        )}

        <OptionWithImage
          title="Start over and create new documents with an attorney "
          subtitle="Not what you're looking for?"
          body="Meet with an attorney to create a new set of estate planning documents that match your needs."
          buttonText="Start Over"
          callback={() => setShowStartOverModal(true)}
          Image={Map}
          reverse
        />
      </Layout>
    </WuiApp>
  );
};

DepByDefaultSummary.propTypes = {
  title: PropTypes.string,
};
export default observer(DepByDefaultSummary);
