import WizardSection from 'ui/components/wizard/WizardSection';
import MaterialHealthcare from 'material-ui/sections/healthcare/MaterialHealthcare';
import SpouseHealthcare from 'material-ui/sections/healthcare/SpouseHealthcare';
import { AppLocation } from 'utils/enums';
import Urls from 'urls';
import HealthcareAgentAddressInput from 'material-ui/sections/healthcare/HealthcareAgentAddressInput';
import SpouseHealthcareAgentAddressInput from 'material-ui/sections/healthcare/SpouseHealthcareAgentAddressInput';

export default class SectionHealthcare extends WizardSection {
  static url = Urls.sectionHealthcare;
  static title = 'Healthcare';
  static description = 'Select your end-of-life healthcare wishes.';
  static mobileDescription = 'Medical choices & your agent';
  static appLocation = AppLocation.HEALTHCARE;
  static isPlanSection = true;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(MaterialHealthcare);
    if (clientData.addressRequiredHealthcareAgents.length > 0) {
      visit(HealthcareAgentAddressInput);
    }

    if (clientData.isPlanningForSpouse) {
      visit(SpouseHealthcare);
      if (clientData.spouse.addressRequiredHealthcareAgents.length > 0) {
        visit(SpouseHealthcareAgentAddressInput);
      }
    }
  }
}
