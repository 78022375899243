import BequestInterface from 'ui/sections/bequest-interface/BequestInterface';
import SpouseBequestInterface from 'ui/sections/bequest-interface/SpouseBequestInterface';
import WizardSection from 'ui/components/wizard/WizardSection';
import BadPrimariesWarning from 'ui/sections/asset-beneficiaries/pages/BadPrimariesWarning';
import SpouseBadPrimariesWarning from 'ui/sections/asset-beneficiaries/pages/SpouseBadPrimariesWarning';
import BeneficiaryAddressInput from 'material-ui/sections/assets/BeneficiaryAddressInput';
import SpouseBeneficiaryAddressInput from 'material-ui/sections/assets/SpouseBeneficiaryAddressInput';
import BeneficiaryRelationshipInput from 'material-ui/sections/assets/BeneficiaryRelationshipInput';
import SpouseBeneficiaryRelationshipInput from 'material-ui/sections/assets/SpouseBeneficiaryRelationshipInput';
import ProbateChoice from 'material-ui/sections/assets/ProbateChoice';
import SpouseProbateChoice from 'material-ui/sections/assets/SpouseProbateChoice';
import { Relationship } from 'models/client-data/enums';
import { AppLocation } from 'utils/enums';
import Urls from 'urls';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';

export default class SectionAssetBeneficiaries extends WizardSection {
  static url = Urls.sectionAssetBeneficiaries;
  static title = 'Assets';
  static description = 'Choose who will receive your assets.';
  static mobileDescription = 'Assets & beneficiaries';
  static appLocation = AppLocation.ASSET_BENEFICIARIES;
  static isPlanSection = true;

  static pagesMappedToRelationship = {
    [BequestInterface]: {
      [Relationship.SELF]: BequestInterface,
      [Relationship.SPOUSE]: SpouseBequestInterface,
    },
    [BeneficiaryAddressInput]: {
      [Relationship.SELF]: BeneficiaryAddressInput,
      [Relationship.SPOUSE]: SpouseBeneficiaryAddressInput,
    },
  };

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    this.getBIPageFlow(clientData, serverData, visit);

    if (clientData.isMarried && clientData.isPlanningForSpouse) {
      this.getBISpousePageFlow(clientData, serverData, visit);
    }
  }

  static getBIPageFlow(clientData, serverData, visit) {
    // get bequests
    visit(BequestInterface);

    // if real estate is jointly owned and not specifically left, it needs a special handler for beneficiaries
    if (clientData.needsResidualJointAsset) {
      visit(BadPrimariesWarning);
    }

    // check if the relationship of any beneficiary needs to be collected
    if (clientData.relationshipRequiredBeneficiaries.length > 0) {
      visit(BeneficiaryRelationshipInput);
    }

    // check if the address of any beneficiary needs to be collected
    if (clientData.addressRequiredBeneficiaries.length > 0) {
      visit(BeneficiaryAddressInput);
    }

    if (
      clientData.hasOwnRealEstate &&
      !clientData.covidEmployer &&
      !digitalEstatePlanningService &&
      !serverData.probateDocumentsRestricted
    ) {
      // ask the client if they want to avoid probate
      visit(ProbateChoice);
    }
  }

  static getBISpousePageFlow(clientData, serverData, visit) {
    // as above, but with spouse classes
    visit(SpouseBequestInterface);

    if (clientData.spouse.needsResidualJointAsset) {
      visit(SpouseBadPrimariesWarning);
    }

    if (clientData.spouse.relationshipRequiredBeneficiaries.length > 0) {
      visit(SpouseBeneficiaryRelationshipInput);
    }

    if (clientData.spouse.addressRequiredBeneficiaries.length > 0) {
      visit(SpouseBeneficiaryAddressInput);
    }

    if (
      clientData.spouse.hasOwnRealEstate &&
      !clientData.covidEmployer &&
      !digitalEstatePlanningService &&
      !serverData.probateDocumentsRestricted
    ) {
      visit(SpouseProbateChoice);
    }
  }
}

/*


joint =>
    (primary beneficiaries of asset),
    (residual primary == spouse ? residual alternates)
    (primary beneficiaries of joint residual)

individually owned =>
    (primary, alternates of asset)
    (primary, alternates of residual)

 */
