import React from 'react';

import './CheckoutFooter.scss';

export default class CheckoutFooter extends React.PureComponent {
  render() {
    return (
      <div className="checkout-footer__section">
        <div className="checkout-section__item">
          <span className="logo norton" />
        </div>
        <div className="checkout-section__item">
          <span className="logo mcafee" />
        </div>
        <div className="checkout-section__item">
          <span className="logo accredited-business" />
        </div>
      </div>
    );
  }
}
