import React from 'react';
import PropTypes from 'prop-types';
import GaugeChart from 'react-gauge-chart';

import theme from '@wui/theme';
import SectionSign from 'ui/sections/sign/SectionSign';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { getFileSections, getPlanSections } from 'ui/sections/sections';
import { RECOMMENDED_NOTIFICATIONS } from 'material-ui/pages/notify/Notify';

// percentage complete is the weighted combination of the plan sections, file sections, and notification count
const planSectionsWeight = 0.6;
export const fileSectionsWeight = 0.2;
export const notificationsWeight = 0.2;

class PlanHealthMeter extends React.Component {
  static propTypes = {
    // Required by the library and generating a random one is ill-advised
    id: PropTypes.string.isRequired,
    v: PropTypes.number,
    h: PropTypes.number,
    needleColor: PropTypes.string,
    needleBaseColor: PropTypes.string,
  };

  static defaultProps = {
    v: 131,
    h: 160,
    needleColor: theme.palette.text.primary,
    needleBaseColor: theme.palette.grey.panelBorder,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
    experiments: PropTypes.shape({}),
  };

  constructor(...args) {
    super(...args);

    this.state = {
      textColor: '#ddd',
    };
  }

  componentDidMount() {
    // For some reason GaugeChart's initial render is often very funky.
    //   We aren't using the textColor prop, this is a hack to force
    //   it to render again.
    this.setState({ textColor: '#fff' });
  }

  get planSections() {
    const {
      experiments,
      clientDataHolder: { clientData },
    } = this.context;
    return getPlanSections(clientData, experiments);
  }

  get fileSections() {
    const { clientData, serverData } = this.context.clientDataHolder;
    return [SectionSign].concat(getFileSections(clientData, serverData));
  }

  get percentComplete() {
    const { clientData } = this.context.clientDataHolder;

    const { fileSections: fs, planSections: ps } = this;
    const psPercentage = ps.filter(s => clientData.isSectionValid(s)).length / ps.length;
    const fsPercentage = fs.filter(s => clientData.isSectionValid(s)).length / fs.length;
    const notificationPercentage =
      Math.min(RECOMMENDED_NOTIFICATIONS, clientData.notifiedPeople.length) /
      RECOMMENDED_NOTIFICATIONS;

    const psStrength = psPercentage * planSectionsWeight;
    const fsStrength = fsPercentage * fileSectionsWeight;
    const notificationsStrength = notificationPercentage * notificationsWeight;

    return psStrength + fsStrength + notificationsStrength;
  }

  render() {
    const { id, v, h, needleColor, needleBaseColor } = this.props;
    const { textColor } = this.state;

    return (
      <DimensionLimiter v={v} h={h} centerH>
        <div>
          <GaugeChart
            id={id}
            marginInPercent={0.05}
            percent={this.percentComplete}
            nrOfLevels={18}
            arcWidth={0.19}
            cornerRadius={2}
            colors={theme.gradients.planHealthMeter}
            needleColor={needleColor}
            needleBaseColor={needleBaseColor}
            hideText
            textColor={textColor}
          />
        </div>
      </DimensionLimiter>
    );
  }
}

export default PlanHealthMeter;
