import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Modal from '@wui/basics/modal';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';
import Button from '@wui/input/button';

import ProbateGraphic from 'material-ui/assets/images/with-probate.svg';

const styles = theme => ({
  graphic: {
    maxWidth: '100%',
    maxHeight: '96px',
    objectFit: 'contain',
    margin: [[0, 'auto']],
    display: 'block',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.notPhone]: {
      flexDirection: 'row',
    },
  },
});

class ProbatePopup extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    proceedWithSelected: PropTypes.func.isRequired,
    proceedWithHomeowners: PropTypes.func.isRequired,
    selectedFriendlyName: PropTypes.string.isRequired,
    primaryResidence: PropTypes.shape({}).isRequired,
  };

  render() {
    const {
      classes,
      open,
      close,
      proceedWithSelected,
      proceedWithHomeowners,
      selectedFriendlyName,
      primaryResidence,
    } = this.props;

    return (
      <Modal open={open} onClose={close} title="Your properties are at risk">
        <img alt="" src={ProbateGraphic} className={classes.graphic} />

        <Spacer v={32} />

        <Typography variant="intro">
          Only the Homeowners bundle keeps your property at {primaryResidence.address.street} from
          being locked up in probate court.
        </Typography>

        <Spacer v={16} />

        <Typography variant="intro">
          You've selected the {selectedFriendlyName} bundle. The {selectedFriendlyName} bundle
          includes a Will, which controls who gets your property, but it does not avoid probate
          court
        </Typography>

        <Spacer v={48} />

        <div className={classes.buttonContainer}>
          <Button variant="outlined" onClick={proceedWithSelected}>
            Choose {selectedFriendlyName}
          </Button>

          <Spacer h={24} xsDown />
          <Spacer v={8} mdUp />

          <Button variant="contained" color="primary" onClick={proceedWithHomeowners}>
            Switch to Homeowners
          </Button>
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(ProbatePopup);
