import urlSlug from 'url-slug';
import { WBoolean, WInteger, WObject, WString, WPastDate } from 'models/types';
import { ClientDataObject } from 'models/type-system';
import {
  WCounty,
  WEmailOrEmpty,
  WFullName,
  WNonEmptyString,
  WUSZip,
  WUUID,
} from 'models/hoc-types';

import { Gender, LifeSupport, PainTreatment } from 'models/client-data/enums';
import { State, Relationship } from './enums';
import { getStateFullName } from 'utils';

export function isMinor(dob) {
  // https://codereview.stackexchange.com/a/118274
  return (
    new Date(parseInt(dob.year, 10) + 18, parseInt(dob.month, 10) - 1, parseInt(dob.day, 10)) >
    new Date()
  );
}

export class WDob extends WPastDate {
  get dob() {
    return {
      year: parseInt(this.year, 10),
      month: parseInt(this.month, 10),
      day: parseInt(this.day, 10),
    };
  }
}

export class WAdultDob extends WDob {
  // a date of birth at least 18 years in the past
  validate(addParentError) {
    addParentError = addParentError || (() => {});
    super.validate(addParentError);
    if (isMinor(this.dob)) {
      addParentError('Must be at least 18 years of age.');
    }
  }
}

export class Address extends ClientDataObject {
  static fields = {
    street: new WNonEmptyString(),
    apt: new WString(),
    city: new WNonEmptyString(),
    state: new State(),
    zip: new WNonEmptyString(),
  };

  get stateName() {
    return getStateFullName(this.state);
  }

  get addressComplete() {
    return this.street && this.city && this.state && this.zip;
  }
}

const PersonMixinFields = {
  pk: new WInteger(),
  personUUID: new WUUID(),
  relationship: new Relationship(),
  clientRelationship: new Relationship(),
  spouseRelationship: new Relationship(),
  name: new WFullName(),
  gender: new Gender(),
  email: new WEmailOrEmpty(),
  dob: new WObject(WDob),
};

export const PersonMixin = superclass =>
  class extends superclass {
    static fields = PersonMixinFields;
    static keyFieldName = 'personUUID';

    get firstName() {
      return this.name ? this.name.split(' ')[0] : '';
    }

    makePosessive(name) {
      if (!this.name || !name) {
        return '';
      }

      return `${name}'s`;
    }

    get names() {
      return this.makePosessive(this.name);
    }

    get firstNames() {
      return this.makePosessive(this.firstName);
    }

    get lastName() {
      // Returns all tokens in the name after the first. That way we don't
      // end up returning a suffix alone.
      if (!this.name) {
        return '';
      }
      return this.name.split(' ').slice(1).join(' ');
    }

    get isMinor() {
      if (!this.dob || [this.dob.year, this.dob.month, this.dob.day].some(v => v === null)) {
        return null;
      }

      return isMinor(this.dob);
    }

    // Use date very far in the future to indicate that the person
    //   is a minor.
    setAsMinor() {
      this.setRawValue('dob', new WDob({ year: 2500, month: 1, day: 1 }));
    }

    // Use date very far in the past to indicate that the person
    //   is an adult.
    setAsAdult() {
      this.setRawValue('dob', new WAdultDob({ year: 1500, month: 1, day: 1 }));
    }

    // In the past, we asked for the DOB of the person, but this was
    //   not really needed. So instead, we just ask if they are a
    //   minor or not and set the date accordingly.
    set isMinor(value) {
      if (value) {
        this.setAsMinor();
      } else {
        this.setAsAdult();
      }
    }

    get urlSlug() {
      return urlSlug(this.name);
    }

    // Return the fields that are part of all people, but none
    //   of the fields that are specific to any subclasses.
    basePerson(keepAddress = false) {
      const base = {};

      // Don't use the actual object (`this`) in case the
      //   fields are objects and have other references.
      const source = this.deepCopy();

      Object.keys(PersonMixinFields).forEach(k => (base[k] = source[k]));

      if (keepAddress) {
        base.address = this.address ? this.address.deepCopy() : new Address();
      }

      return base;
    }

    get initials() {
      return this.name
        ? this.name
            .split(' ')
            .map(i => i[0])
            .join('')
        : '';
    }

    get hasAddress() {
      return this.address && this.address.addressComplete;
    }
  };

export class Person extends PersonMixin(ClientDataObject) {
  static fields = {
    address: new WObject(Address),
  };
}

export class USAddress extends Address {
  static fields = {
    zip: new WUSZip(),
  };
}

export class AssetAddress extends USAddress {
  static fields = {
    county: new WCounty(),
  };
}

export class HealthcareWishes extends ClientDataObject {
  static fields = {
    lifeSupport: new LifeSupport(),
    painTreatment: new PainTreatment(),
    accessRecords: new WBoolean(true),
    assistedLiving: new WBoolean(true),
    guardian: new WBoolean(true),
    research: new WBoolean(true),
    organDonation: new WBoolean(true),
    forcedTreatment: new WBoolean(true),
    informationDisclosure: new WBoolean(true),
  };
}

export class PowerOfAttorney extends ClientDataObject {
  static fields = {
    allPowersGranted: new WBoolean(),
    realEstate: new WBoolean(true),
    banking: new WBoolean(true),
    insurance: new WBoolean(true),
    personalExpenses: new WBoolean(true),
    legalActions: new WBoolean(true),
    businessOperations: new WBoolean(true),
    trusts: new WBoolean(true),
    governmentBenefit: new WBoolean(true),
    retirementPlan: new WBoolean(true),
    taxes: new WBoolean(true),
  };
}

// TODO: Remove debugging exports
window.Address = Address;
