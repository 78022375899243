import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

const useScreenSize = () => {
  const theme = useTheme();

  const isLaptopOrSmallerDisplay = useMediaQuery(theme.breakpoints.down('md'));
  const isTabletOrSmallerDisplay = useMediaQuery(theme.breakpoints.down('sm'));
  const isPhoneDisplay = useMediaQuery(theme.breakpoints.down('xs'));

  return {
    isLaptopOrSmallerDisplay,
    isTabletOrSmallerDisplay,
    isPhoneDisplay,
  };
};

export default useScreenSize;
