import React from 'react';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage.jsx';
import Typography from '@wui/basics/typography';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup.jsx';

export default class Info extends MuiWizardPage {
  static baseUrl = '';
  static layoutProps = { hideRight: true };
  static wide = true;

  get showTestatorSwitcher() {
    return false;
  }

  get whatCopy() {
    const ps = this.clientData.isPlanningForSpouse;
    const fn = this.clientData.spouse.firstName;
    const andFn = ps ? `and ${fn}` : '';

    return [
      `Bank accounts, life insurance policies, retirement accounts, and most other types of financial accounts
        allow you ${andFn} to specify who should receive the funds in each account after you ${andFn} pass away.
        These are known as "beneficiaries" and they take priority over whoever you ${andFn} put in your
        ${ps ? 'wills' : 'will'}.`,
      `If you ${andFn} don't update the beneficiaries on your accounts, the funds may go to the wrong people or
            the right people may need to hire an attorney to get probate court approval to receive the funds.
            This is a big inconvenience, can take months, and cost thousands of dollars.`,
      'Luckily, updating the beneficiaries on your accounts usually just takes a few minutes.',
    ];
  }

  get howCopy() {
    const ps = this.clientData.isPlanningForSpouse;
    const fn = this.clientData.spouse.firstName;
    const andFn = ps ? `and ${fn}` : '';

    return `Contact each financial institution where you ${andFn} have an account. Let them know you'd like to
        update the beneficiaries on your accounts.`;
  }

  renderPage() {
    return (
      <SpacedFieldGroup>
        <Typography variant="h3">Updating the beneficiaries on your financial accounts.</Typography>

        <Typography variant="h6">What is this?</Typography>
        {this.whatCopy.map((c, i) => (
          <Typography key={i}>{c}</Typography>
        ))}

        <Typography variant="h6">How do I do this?</Typography>
        <Typography>{this.howCopy}</Typography>
      </SpacedFieldGroup>
    );
  }
}
