import React from 'react';
import PropTypes from 'prop-types';

import './WizardRow.scss';

export default class WizardRow extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    subtext: PropTypes.string,
    className: PropTypes.string,
  };

  renderLabel() {
    if (!this.props.label) {
      return null;
    }
    return (
      <label>
        {this.props.label}
        {this.props.subtext && <p className="wizard-row__subtext">{this.props.subtext}</p>}
      </label>
    );
  }

  renderSubtext() {
    if (!this.props.subtext) {
      return null;
    }
    return <p className="wizard-row__subtext">{this.props.subtext}</p>;
  }

  render() {
    return (
      <div className={`wizard-row ${this.props.className}`}>
        {this.renderLabel()}
        {this.props.children}
      </div>
    );
  }
}
