import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import { API } from 'API';
import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import AudioPlayer from 'material-ui/components/AudioPlayer';
import ButtonFooter from '../ButtonFooter';
import AudioTestFile from '!a/audio-test.mp3';
import Failed from './Failed';

const useStyles = makeStyles({
  root: {
    maxWidth: 571,
  },
});

const Fields = ({ goToNext }) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (isSuccess) {
      API.postNotarizationTechCheckComplete().then(goToNext);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const retry = () => {
    setIsSuccess(null);
  };

  if (isSuccess === false) {
    return <Failed retry={retry} />;
  }

  return (
    <Grid container direction="row" wrap="nowrap" justify="center">
      <div className={classes.root}>
        <Grid container direction="column" alignItems="center">
          <Typography variant="h4">PRESS PLAY TO START AUDIO</Typography>
          <AudioPlayer src={AudioTestFile} />
          <Spacer v={24} />

          <ButtonFooter
            prompt="Do you hear the audio clip being played?"
            acceptAction={() => setIsSuccess(true)}
            rejectAction={() => setIsSuccess(false)}
          />
        </Grid>
      </div>
    </Grid>
  );
};

Fields.propTypes = {
  goToNext: PropTypes.func.isRequired,
};

export default observer(Fields);
