import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Panel, { borderWidth } from '@wui/layout/panel';
import PanelTitle from '@wui/layout/panelTitle';
import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';
import TabDivider from '@wui/layout/tabDivider';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import CustomIcon from '@wui/basics/customIcon';
import { getBenefitsForDocumentBundle, getDocumentName } from './benefits';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { documentBundleIncludes } from 'utils';
import { DocumentType, DocumentBundle } from 'utils/enums';

import { ReactComponent as IncludedIcon } from '@a/images/checkout-v6/check.svg';
import { ReactComponent as NotIncludedIcon } from '@a/images/checkout-v6/red-x.svg';

const styles = theme => ({
  noNotice: {},
  special: {},

  root: {
    '&:not(first-child)': {
      marginLeft: -borderWidth,
    },

    '& + &': {
      marginTop: 0,
    },

    '&$noNotice': {
      marginTop: 14,
    },

    '&$special': {
      zIndex: theme.zIndex.above,
      boxShadow: theme.shadow.standard,
    },
  },
});

export const bundleShape = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  special: PropTypes.bool.isRequired,
  unrestrictedDocuments: PropTypes.arrayOf(PropTypes.string),
  noticeText: PropTypes.string,
};

class BundleDetails extends ClientDataReferer {
  static propTypes = {
    ...bundleShape,
    selectBundle: PropTypes.func.isRequired,
    classes: PropTypes.shape({}),
  };

  static defaultProps = {
    noticeText: null,
  };

  get divider() {
    return (
      <React.Fragment>
        <Spacer v={8} />
        <TabDivider noMargin />
        <Spacer v={8} />
      </React.Fragment>
    );
  }

  renderItem = (item, isIncluded) => (
    <React.Fragment key={item}>
      <Grid container direction="row" justify="space-between" wrap="nowrap" spacing={1}>
        <Grid item>
          <Typography>{item}</Typography>
        </Grid>
        <Grid item>
          <Spacer v={6} />
          <CustomIcon width={16} height={16} src={isIncluded ? IncludedIcon : NotIncludedIcon} />
        </Grid>
      </Grid>
      {this.divider}
    </React.Fragment>
  );

  renderDocument = docType => {
    const isIncluded =
      ![DocumentType.DEED, DocumentType.TRUST].includes(docType) ||
      documentBundleIncludes(this.props.id, DocumentBundle.HOMEOWNERS);
    return this.renderItem(getDocumentName(this.props.id, this.clientData)(docType), isIncluded);
  };

  get title() {
    const { name, price } = this.props;
    return (
      <Grid container direction="row" justify="space-between" wrap="nowrap">
        <Grid item>
          <Typography variant="h6">{name}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1">{price}</Typography>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      id,
      special,
      primary,
      noticeText,
      unrestrictedDocuments,
      selectBundle,
      classes,
    } = this.props;

    const buttonProps = { variant: 'outlined' };
    const specialButtonProps = {
      variant: 'contained',
      color: 'primary',
    };
    const button = (
      <Button noMinWidth {...(special ? specialButtonProps : buttonProps)} onClick={selectBundle}>
        Choose
      </Button>
    );

    const rootClass = classNames(
      classes.root,
      noticeText || classes.noNotice,
      special && classes.special,
    );

    return (
      <Panel lessPadding className={rootClass}>
        <PanelTitle isNotice noticeProps={{ special, primary }}>
          {noticeText}
        </PanelTitle>
        {this.title}
        <Spacer v={48} />
        {button}
        <Spacer v={32} />
        <Typography variant="h6">Benefits</Typography>
        {this.divider}
        {getBenefitsForDocumentBundle(id, this.clientData).map(b =>
          this.renderItem(b.title, b.included),
        )}
        <Spacer v={32} />
        <Typography variant="h6">Documents</Typography>
        {this.divider}
        {unrestrictedDocuments.map(this.renderDocument)}
        <Spacer v={32} />
        {this.title}
        <Spacer v={24} />
        {button}
      </Panel>
    );
  }
}

export default withStyles(styles)(BundleDetails);
