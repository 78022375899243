import React from 'react';
import isMobile from 'react-device-detect';
import IntroPage from 'ui/components/wizard/IntroPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';

import PureButton from 'ui/components/pure-controls/PureButton';
import OpenTokPublisher from 'ui/execution/OpenTokPublisher';

import './WaitingRoom.scss';

export default class WaitingRoom extends NotarizationWizardPageMixin(IntroPage) {
  static baseUrl = 'waiting-room';
  static pageName = 'Waiting for a notary';
  static showPrevNextButtons = false;
  static showNextButton = false;
  static updateSessionDelay = 1000;
  static notarizationSection = NotarizationStatus.WAITING_TO_START;

  constructor(props, context) {
    super(props, context);

    this.state = {
      position: null,
      total: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.options = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
    };
    if (isMobile) {
      this.options.facingMode = 'user';
    } else if (this.clientData.acceptedVideoSource) {
      this.options.videoSource = this.clientData.acceptedVideoSource;
    }
  }

  displayTimeEstimate(seconds) {
    if (seconds === null) {
      return null;
    }
    const minutes = Math.ceil(seconds / 60);
    const s = minutes === 1 ? '' : 's';
    return `${minutes} minute${s}`;
  }

  handleSession = session => {
    if (session.status === NotarizationStatus.ONGOING) {
      this.goToNext();
    }
    this.setState({
      position: session.queuePosition,
      total: session.queueTotal,
      timeEstimate: this.displayTimeEstimate(session.queueTimeEstimate),
    });
  };

  renderPage() {
    return (
      <div className="center info-container waiting-room">
        {this.options && <OpenTokPublisher options={this.options} />}
        <div id="publisher" />
        <h1>Finding a Notary...</h1>
        <p className="small-margin">
          We're finding the next available notary to help you sign your documents.
        </p>
        <p>A notary will be with you soon.</p>

        <PureButton onClick={() => null} className="button--primary">
          Awaiting Notary...
        </PureButton>
      </div>
    );
  }
}
