import React from 'react';
import { isMobile } from 'react-device-detect';

import { noOp } from 'utils';
import IntroPage from 'ui/components/wizard/IntroPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';

const canFullScreen = !isMobile && Boolean(document.documentElement.requestFullscreen);

export default class IdIntro extends NotarizationWizardPageMixin(IntroPage) {
  static baseUrl = 'id-verification/';
  static pageName = 'ID Verification Intro';
  static notarizationSection = NotarizationStatus.ID_VERIFICATION;
  ctaText = canFullScreen ? 'Continue in full screen' : 'Next';
  static cardClass = 'card--max-height no-bottom-padding';
  static dataClass = 'vvcenter center no-bottom-padding no-top-padding';
  static wizardPageClass = 'wizard-page--full-height';

  get titleCopy() {
    return `Confirming ${this.testator.firstNames} Identity`;
  }

  get subCopy() {
    let copy = `In this section, we'll scan your ID and ask you some security questions.
            Please have a valid photo ID ready.`;
    if (!isMobile && !canFullScreen) {
      copy += ' We recommend switching your browser to full screen mode for best results.';
    }
    return copy;
  }

  goToNext() {
    if (canFullScreen) {
      document.documentElement.requestFullscreen().catch(noOp);
    }
    super.goToNext();
  }

  renderPage() {
    return (
      <div className="centered info-container">
        <h1>{this.titleCopy}</h1>
        <p>{this.subCopy}</p>
      </div>
    );
  }
}
