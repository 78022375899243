import { onReactionError } from 'mobx';
import * as Sentry from '@sentry/browser';
import { Dedupe, ExtraErrorData } from '@sentry/integrations';

import env from './env';

let dsnSet = false;

function isEnabled(ignoreDsn = false) {
  return (ignoreDsn || dsnSet) && env.isProductionBuild();
}

function whenEnabled(wrapped, fallback, ignoreDsn = false) {
  return function checkWhenEnabled(...args) {
    if (isEnabled(ignoreDsn)) {
      wrapped(...args);
    } else if (fallback) {
      fallback(...args);
    }
  };
}

const init = whenEnabled(
  dsn => {
    if (!dsn) {
      return;
    }

    dsnSet = true;

    Sentry.init({
      dsn,

      // The environment matches the configuration of that in the app
      //   and the backend, so we use the existing environment variables
      //   even though we aren't using Django.
      release: process.env.DJANGO_GIT_COMMIT_HASH,
      environment: env.willingEnvironment(),

      ignoreErrors: [
        // See: https://github.com/getsentry/sentry/issues/5267
        /Blocked a frame with origin/,
        // We don't do this, so it is from a browser extension.
        /can't redefine non-configurable property "userAgent"/,
        // PayPal's SDK is awful...
        /Window closed/,
        /cleaned up/,
        // We don't care if a credit card was declined.
        /credit_card_declined/,
      ],

      blacklistUrls: [
        // See: WILLING-REACT-PQ
        's0.2mdn.net',
        // Mozilla extensions don't matter to us.
        /moz-extension/,
        // Google login sometimes throws this error.
        /apps-static/,
      ],

      integrations: [new Dedupe(), new ExtraErrorData()],
    });

    // Catch any internal MobX errors using Sentry.
    onReactionError(Sentry.captureException);
  },
  () => {
    console.warn('sentry not enabled'); // eslint-disable-line no-console
  },
  true,
);

const message = whenEnabled(
  text => {
    Sentry.captureMessage(text);
  },
  text => {
    console.warn('sentry.message:', text); // eslint-disable-line no-console
  },
);

const captureException = whenEnabled(
  (...args) => {
    Sentry.captureException(...args);
  },
  (...args) => {
    console.error('sentry.captureException:', ...args); // eslint-disable-line no-console
  },
);

const setUser = whenEnabled(
  user => {
    Sentry.configureScope(scope => scope.setUser(user));
  },
  user => {
    console.warn('sentry.setUser:', user); // eslint-disable-line no-console
  },
);

const attempt = fn => {
  try {
    fn();
  } catch (err) {
    captureException(err);
  }
};

export default {
  init,
  message,
  setUser,
  isEnabled,
  captureException,
  attempt,
};
