import React from 'react';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage.jsx';
import Typography from '@wui/basics/typography';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup.jsx';

import { MaritalStatus } from 'models/client-data/enums';
import { getCompanyName } from 'utils';

export default class Info extends MuiWizardPage {
  static baseUrl = '';
  static layoutProps = { hideRight: true };
  static wide = true;

  get showTestatorSwitcher() {
    return false;
  }

  get numDeeds() {
    return Object.values(this.serverData.assets).length;
  }

  get deedString() {
    const numHtd = Object.values(this.serverData.assets).filter(asset => asset.qcdCreationMethod)
      .length;
    const numTod = this.numDeeds - numHtd;

    let todString = '';
    if (numTod === 1) {
      todString = 'Transfer on Death Deed';
    } else if (numTod > 1) {
      todString = 'Transfer on Death Deeds';
    }

    let htdString = '';
    if (numHtd === 1) {
      htdString = 'Home Transfer Deed';
    } else if (numHtd > 1) {
      htdString = 'Home Transfer Deeds';
    }

    const andString = numTod && numHtd ? ' and ' : '';

    return htdString + andString + todString;
  }

  get whatCopy() {
    const nd = this.numDeeds;
    const im = this.clientData.maritalStatus === MaritalStatus.MARRIED;

    return `Your ${this.deedString} must be filed in the official records of the county where
        ${nd > 1 ? 'each' : 'your'} property is located before you
        ${im ? `or ${this.clientData.spouse.firstName}` : ''} pass away to make ${
      nd > 1 ? 'them' : 'it'
    } valid.
        This process is called "recording".`;
  }

  get howCopy() {
    return `Your ${this.deedString} will be recorded in the official county records. This usually takes
        about 2-3 weeks.`;
  }

  get assuranceCopy() {
    const nd = this.numDeeds;

    return `We will email you a copy of the recorded ${this.deedString}, which ${
      nd > 1 ? 'include' : 'includes'
    }
        the exact date of recording in the county where ${
          nd > 1 ? 'each' : 'the'
        } property is located.`;
  }

  renderPage() {
    const nd = this.numDeeds;

    return (
      <SpacedFieldGroup>
        <Typography variant="h3">
          Recording your notarized Transfer {nd > 1 ? 'Deeds' : 'Deed'}.
        </Typography>

        <Typography variant="h6">What is this?</Typography>
        <Typography>{this.whatCopy}</Typography>

        <Typography variant="h6">How do I do this?</Typography>
        <Typography>Mail ONLY your original notarized {this.deedString} to:</Typography>
        <Typography>
          {getCompanyName(this.serverData)} Deed Recording
          <br />
          8940 Main Street, Suite 2<br />
          Clarence, NY 14031
        </Typography>
        <Typography>{this.howCopy}</Typography>
        <Typography>{this.assuranceCopy}</Typography>
      </SpacedFieldGroup>
    );
  }
}
