import React from 'react';
import PropTypes from 'prop-types';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import PureButton from 'ui/components/pure-controls/PureButton';

import './ButtonFlow.scss';

export class PrevNextNavigation extends ClientDataReferer {
  static propTypes = {
    onGoToPrev: PropTypes.func.isRequired,
    onGoToNext: PropTypes.func.isRequired,
  };

  render() {
    return (
      <div className="button-flow">
        <PureButton
          onClick={this.props.onGoToPrev}
          className="button--tertiary button--inline"
          dataPath="prev"
        >
          Back
        </PureButton>
        <PureButton
          onClick={this.props.onGoToNext}
          className="button--primary button--inline"
          dataPath="next"
        >
          Save and Continue
        </PureButton>
      </div>
    );
  }
}
