import React from 'react';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import GlobalDataStore from 'stores';
import { GlobalContext, ForSpouseContext } from 'contexts';

class MobxTranslationLayer extends ClientDataReferer {
  render() {
    const { children } = this.props;
    const { clientData, serverData, analyticsEvents, clientDataHolder } = this;
    const { experiments, history } = this.context;

    const all = {
      clientData,
      serverData,
      analyticsEvents,
      experiments,
      clientDataHolder,
      history,
    };

    if (!this.store) {
      this.store = new GlobalDataStore(all);
    } else {
      for (const [key, value] of Object.entries(all)) {
        this.store[key] = value;
      }
    }

    const { store } = this;

    return (
      <GlobalContext.Provider value={store}>
        <ForSpouseContext.Provider>{children}</ForSpouseContext.Provider>
      </GlobalContext.Provider>
    );
  }
}

export default MobxTranslationLayer;
