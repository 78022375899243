import sentry from '@u/sentry';

export const UNKNOWN_ERROR = 'unknown_error';

export const FRIENDLY_ERROR_MESSAGES = {
  [UNKNOWN_ERROR]: 'An unknown error occurred. Please try again.',
};

export function registerErrorCodes(errors) {
  Object.assign(FRIENDLY_ERROR_MESSAGES, errors);
}

export function friendlyErrorMessage(errorCode) {
  return FRIENDLY_ERROR_MESSAGES[errorCode] || FRIENDLY_ERROR_MESSAGES[UNKNOWN_ERROR];
}

// Given error data, make sure an error code and a
//   user-friendly error are present.
export default function standardizeErrorResponse(data) {
  const errorCode = data.errorCode || data.error || UNKNOWN_ERROR;
  const error = friendlyErrorMessage(errorCode);
  const errorText = typeof error === 'function' ? error(data) : error;

  if (errorCode === UNKNOWN_ERROR) {
    sentry.message('error without error code occurred');
  } else if (errorText === FRIENDLY_ERROR_MESSAGES[UNKNOWN_ERROR]) {
    sentry.message(`unfriendly error code: ${errorCode}`);
  }

  return {
    ...data,
    errorCode,
    errorText,
  };
}
