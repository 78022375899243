import React from 'react';
import { observer } from 'mobx-react';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import OT from '@opentok/client';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';

import Failed from './Failed';

class TechCheckBrowserDevices extends NotarizationWizardPageMixin(MuiWizardPage) {
  static baseUrl = 'tech-check/browser-device/';
  static notarizationSection = NotarizationStatus.TECH_CHECK;

  constructor(props, context) {
    super(props, context);

    const isSuccess = OT.checkSystemRequirements() === 1;
    this.state = { isSuccess };
  }

  componentDidMount() {
    super.componentDidMount();
    const { isSuccess } = this.state;

    if (isSuccess) {
      this.goToNext();
    }
  }

  renderPage() {
    const { isSuccess } = this.state;

    // goToNext() is async, prevent flickering by returning nothing
    if (isSuccess) {
      return null;
    }

    return <Failed />;
  }
}

export default observer(TechCheckBrowserDevices);
