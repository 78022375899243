import UUID4 from 'uuid/v4';
import { WArray, WBoolean, WInteger, WObject, WString, WDate } from 'models/types';
import { ClientDataObject } from 'models/type-system';
import { Address, PersonMixin } from 'models/client-data/mixins';
import {
  AgentOwner,
  AssetType,
  Lineage,
  MaritalStatus,
  Relationship,
} from 'models/client-data/enums';
import { Asset } from 'models/client-data/assets';
import { Executor, Guardian, HealthcareAgent, NotifiedPerson } from 'models/client-data/agents';
import { Spouse } from 'models/client-data/spouse';
import { TestatorMixin } from 'models/client-data/testator';
import { ValidWizardItemsMixin } from 'models/client-data/ValidWizardItemsMixin';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import { documentBundleIncludes } from 'utils';
import { DocumentBundle } from 'utils/enums';
import { Profile } from 'models/client-data/profile';
import { NotarizationStaff } from 'models/client-data/notarizationStaff';

export class Child extends PersonMixin(ClientDataObject) {
  static fields = {
    disinherited: new WBoolean(),
    lineage: new Lineage(),
    propertyPercentage: new WInteger(),
    hasSpecialNeeds: new WBoolean(),
  };
}

/** Not used for serializing to/from the backend, but as a helper for things that need to pass around people params */
export class GenericPerson extends PersonMixin(ClientDataObject) {
  static fields = {};

  constructor(data, keepPrimaryKey = false) {
    // It is important that a personUUID in data overrides the default so
    //   that the personUUID is preserved when calling deepCopy or fromPOJO
    super(
      {
        personUUID: UUID4(),
        ...data,
      },
      keepPrimaryKey,
    );
  }
}

export class ClientData extends ValidWizardItemsMixin(TestatorMixin(ClientDataObject)) {
  static fields = {
    maritalStatus: new MaritalStatus(),
    address: new WObject(Address),
    isParent: new WBoolean(),
    isPlanningForSpouse: new WBoolean(),
    ownsRealEstate: new WBoolean(),
    clientRelationship: new WString(Relationship.SELF),
    spouseRelationship: new WString(Relationship.SPOUSE),
    notarizationDate: new WObject(WDate),

    profile: new WObject(Profile),
    notarizationStaff: new WObject(NotarizationStaff),
    spouse: new WObject(Spouse),
    children: new WArray(new WObject(Child)),
    executors: new WArray(new WObject(Executor)),
    guardians: new WArray(new WObject(Guardian)),
    healthcareAgents: new WArray(new WObject(HealthcareAgent)),
    notifiedPeople: new WArray(new WObject(NotifiedPerson)),

    assets: new WArray(new WObject(Asset)),

    childrenCanBeMinors: new WBoolean(),
    childrenHaveSameParents: new WBoolean(),

    acceptedVideoSource: new WString(),
    acceptedAudioSource: new WString(),

    covidEmployer: new WString(),
  };

  static delayedFields = ['childrenCanBeMinors', 'childrenHaveSameParents', 'ownsRealEstate'];

  constructor(data, keepPrimaryKey = false) {
    super(data, keepPrimaryKey);
    this.raw.spouse.spouse = this;
    if (this.residualAsset === null) {
      this.assets.push(
        new Asset({
          type: AssetType.RESIDUAL,
          owners: [],
        }),
      );
    }

    if (this.residualJointAsset === null) {
      this.assets.push(
        new Asset({
          type: AssetType.RESIDUAL_JOINT,
          owners: [],
        }),
      );
    }
  }

  get clientData() {
    // The purpose of this method, along with the clientData
    //   getter in Spouse, is to allow us to get the
    //   clientData from an unknown testator object.
    return this;
  }

  ensureChildPresent() {
    if (this.children.length === 0) {
      this.addChild();
    }
  }

  addChild() {
    this.children.push(
      new Child({
        clientRelationship: Relationship.CHILD,
        spouseRelationship: Relationship.CHILD,
      }),
    );
  }

  removeChild(i) {
    this.children.splice(i, 1);
  }

  get allChildrenHaveBothLineage() {
    return this.children.every(c => c.lineage === Lineage.BOTH);
  }

  removeAsset(i) {
    this.assets.splice(i, 1);
  }

  removeAssetByInstance(asset) {
    this.removeAsset(this.assets.findIndex(a => a.assetUUID === asset.assetUUID));
  }

  getAssetsOfType(type) {
    return this.assets.filter(item => item.type === type);
  }

  get ownerString() {
    return AgentOwner.CLIENT;
  }

  walkBeneficiaries(fn) {
    for (const asset of this.assets) {
      for (const primary of asset.primaryBeneficiaries) {
        fn(primary, asset);

        for (const alternate of primary.alternateBeneficiaries) {
          fn(alternate, asset);
        }
      }
    }
  }

  get isNotarizationComplete() {
    return this.notarizationDate.year && this.notarizationDate.month && this.notarizationDate.day;
  }

  get eitherTestatorAvoidsProbate() {
    return this.avoidProbate || (this.isPlanningForSpouse && this.spouse.avoidProbate);
  }

  get eitherTestatorIsParent() {
    // We compare to false because we only want to clear children in the
    // case where the user has explicitly set these values
    return this.isParent !== false || (this.isPlanningForSpouse && this.spouse.isParent !== false);
  }

  get bothTestatorsAreParents() {
    return this.isParent && this.spouse.isParent && this.isPlanningForSpouse;
  }

  get realEstates() {
    return this.assets.filter(a => a.type === AssetType.REAL_ESTATE);
  }

  get unlockedRealEstates() {
    return this.realEstates.filter(a => !a.deedLocked);
  }

  get outOfStateUnlockedRealEstates() {
    return this.unlockedRealEstates.filter(a => a.address.state !== this.address.state);
  }

  get jointlyOwnsAllRealEstate() {
    return this.realEstates.length === this.sharedRealEstates.length;
  }

  get sharedRealEstates() {
    return this.realEstates.filter(a => a.isJointlyOwned);
  }

  get missingEitherResidualBeneficiaries() {
    const forClient = this.missingAnyResidualBeneficiaries;

    if (this.isPlanningForSpouse) {
      return forClient || this.spouse.missingAnyResidualBeneficiaries;
    }

    return forClient;
  }

  get recommendedDocumentBundle() {
    if (this.realEstates.length) {
      return DocumentBundle.HOMEOWNERS;
    } else if (this.isPlanningForSpouse) {
      return DocumentBundle.COUPLES;
    } else if (this.isSectionValid(SectionAboutYou)) {
      return DocumentBundle.SINGLE;
    }

    return null;
  }

  spouseSigns(documentBundle) {
    const spouseSignsWill =
      this.isPlanningForSpouse && documentBundleIncludes(documentBundle, DocumentBundle.COUPLES);
    const spouseSignsClientDeed =
      this.maritalStatus === MaritalStatus.MARRIED &&
      this.realEstates.length &&
      this.avoidProbate &&
      documentBundleIncludes(documentBundle, DocumentBundle.HOMEOWNERS);
    return spouseSignsWill || spouseSignsClientDeed;
  }

  get eitherTestatorNeedsBeneficiaryAddresses() {
    return (
      this.needsBeneficiaryAddresses ||
      (this.isPlanningForSpouse && this.spouse.needsBeneficiaryAddresses)
    );
  }

  get collectiveName() {
    return this.maritalStatus === MaritalStatus.MARRIED
      ? `${this.name} and ${this.spouse.name}`
      : this.name;
  }

  get documentsFileName() {
    return `${this.collectiveName}'s Estate Planning Documents.pdf`;
  }

  get residualIncludesRealEstate() {
    return this.realEstates.some(re => re.primaryBeneficiaries.length === 0);
  }

  get minorChildBeneficiaries() {
    const minorChildUUIDs = this.children.filter(c => c.isMinor).map(c => c.personUUID);

    const result = [];
    const visitBeneficiary = (b, asset) => {
      const assetIncludesRealEstate =
        asset.type === AssetType.REAL_ESTATE ||
        (asset.type === AssetType.RESIDUAL && this.residualIncludesRealEstate);
      if (assetIncludesRealEstate && minorChildUUIDs.includes(b.personUUID)) {
        result.push(b);
      }
    };
    this.walkBeneficiaries(visitBeneficiary);
    return result;
  }
}

// TODO: Remove debugging exports
window.ClientData = ClientData;
window.ClientDataObject = ClientDataObject;
window.Spouse = Spouse;
window.PersonMixin = PersonMixin;
window.Executor = Executor;
