import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useGlobalContext } from 'hooks';
import DocumentBundle from './DocumentBundle';
import SubscriptionPlan from './SubscriptionPlan';
import { Urls } from 'urls';

const List = () => {
  const {
    clientDataHolder,
    clientDataHolder: { clientData, serverData },
  } = useGlobalContext();
  const logout = async e => {
    e.preventDefault();
    await clientDataHolder.logout();
  };

  if (clientDataHolder.isAuthenticated) {
    return (
      <ul className="dropdown__list">
        <li>
          <Link to={clientDataHolder.dashboardUrl}>Overview</Link>
        </li>
        {!clientData.covidEmployer && !serverData.fromPortunus && (
          <React.Fragment>
            <DocumentBundle />
            <SubscriptionPlan />
          </React.Fragment>
        )}
        <li>
          <Link to="#" onClick={logout}>
            Log out
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <ul className="dropdown__list">
      <li>
        <Link to={Urls.login}>Login</Link>
      </li>

      <li>
        <Link to={Urls.resetPassword}>Reset password</Link>
      </li>
    </ul>
  );
};

export default observer(List);
