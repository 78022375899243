import React from 'react';
import { Tunnel } from 'react-tunnels';
import { StickyContainer } from 'react-sticky';

import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';

class TestatorSwitcherTunnel extends React.Component {
  render() {
    const { children } = this.props;

    // The testator switcher is displayed as part
    //   of the header when on a phone so that it
    //   will scroll off the page and scroll back
    //   when needed.
    if (this.isPhoneDisplay) {
      return (
        <Tunnel id="testator-switcher">
          {/* There is a slight chance that the content from the
                        tunnel will have a Sticky component in it due to a
                        race condition with how the screen width is propagated
                        to the components. To prevent an error, we add a
                        StickyContainer, which ensures the Sticky is rendered
                        correctly. */}
          <StickyContainer>{children}</StickyContainer>
        </Tunnel>
      );
    }

    return children;
  }
}

export default withIsMobileDisplay(TestatorSwitcherTunnel);
