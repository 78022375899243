import React from 'react';
import SignatureSelection from 'ui/sections/notarize/pages/SignatureSelection';
import { API } from 'API';

import DrawSignatureInput from 'ui/sections/notarize/DrawSignatureInput';

export default class DrawSignature extends SignatureSelection {
  static enterNavigation = false;
  static hideZendesk = true;

  goToNext = async (signature, initials) => {
    if (!this.triggerValidation()) {
      return;
    }
    this.setState({ loading: true });
    try {
      await this.clientDataHolder.persistClientData();
      await API.createCustomSignature(signature, initials, this.constructor.forSpouse);
      await API.postNotarizationSignatureCreated(this.constructor.forSpouse);
    } catch (error) {
      this.setState({
        loading: false,
        postError: true,
        errorMessage: error.getErrorMessage(),
      });
      return;
    }
    super.goToNext();
  };

  get subText() {
    if (this.hasBothImages) {
      return 'Your electronic signature is now ready to be used to sign your documents.';
    }

    return "Let's create a digital signature for you to use when signing your documents.";
  }

  renderPage() {
    const { loading } = this.state;
    return (
      <div className="vvcenter center info-container info-container--wide">
        <h1>Your Electronic Signature</h1>
        <p>{this.subText}</p>
        <DrawSignatureInput
          testator={this.testator}
          loading={loading}
          forSpouse={this.constructor.forSpouse}
          submit={this.goToNext}
        />
      </div>
    );
  }
}
