import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Panel from '@wui/layout/panel';
import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';

const ButtonFooter = ({ prompt, acceptAction, rejectAction }) => (
  <Panel tooltip noMargin>
    <Grid container direction="column" alignItems="center" alignContent="center">
      <Typography variant="body1">{prompt}</Typography>

      <Spacer v={24} />

      <Grid container direction="row" wrap="nowrap" justify="center" spacing={1}>
        <Grid item>
          <Button variant="outlined" onClick={acceptAction} noMinWidth size="small">
            Yes
          </Button>
        </Grid>

        <Grid item>
          <Button variant="outlined" onClick={rejectAction} noMinWidth size="small">
            No
          </Button>
        </Grid>
      </Grid>
    </Grid>
  </Panel>
);

ButtonFooter.propTypes = {
  prompt: PropTypes.string,
  acceptAction: PropTypes.func.isRequired,
  rejectAction: PropTypes.func.isRequired,
};

export default observer(ButtonFooter);
