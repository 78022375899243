import React from 'react';
import { Redirect } from 'react-router-dom';
import { Urls } from 'urls';
import AnimatedLoader from 'ui/components/AnimatedLoader';
import WizardPage from 'ui/components/wizard/WizardPage';

import './DocumentLoader.scss';
import 'ui/Sign.scss';

const progressSteps = ['Processing Your Information', 'Generating Documents'];

export default class DocumentLoader extends WizardPage {
  static get url() {
    return 'loading/';
  }
  static cardWrapperClass = 'cardwrapper--dashboard';
  static cardClass = 'card--dashboard';

  goToReview = () => {
    if (this.mounted) {
      this.goToNext();
    }
  };

  render() {
    if (!this.clientData.isPlanValid()) {
      return <Redirect to={Urls.planDashboard} />;
    }

    return (
      <div className="sign-loader">
        <AnimatedLoader
          completeOnClick={!window.WillingConfig.production}
          progressSteps={progressSteps}
          onComplete={this.goToReview}
          successText="Your Documents Are Ready For Review"
        />
      </div>
    );
  }
}
