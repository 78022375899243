import React, { useState } from 'react';
import { observer } from 'mobx-react';

import {
  AboutYouPanel,
  GuardiansPanel,
  ExecutorsPanel,
  HealthcarePanel,
} from 'material-ui/components/Summary/Panels';

import { useGlobalContext } from 'hooks';

const Summary = () => {
  const { clientData } = useGlobalContext();
  const [expandedIndex, setExpandedIndex] = useState(null);

  let index = 0;

  const hasMinorChildren =
    clientData.hasMinorChildren ||
    (clientData.isPlanningForSpouse && clientData.spouse.hasMinorChildren);

  return (
    <React.Fragment>
      <AboutYouPanel setExpanded={setExpandedIndex} expandedIndex={expandedIndex} index={index++} />
      {hasMinorChildren && (
        <GuardiansPanel
          setExpanded={setExpandedIndex}
          expandedIndex={expandedIndex}
          index={index++}
        />
      )}
      <ExecutorsPanel
        setExpanded={setExpandedIndex}
        expandedIndex={expandedIndex}
        index={index++}
      />
      <HealthcarePanel
        setExpanded={setExpandedIndex}
        expandedIndex={expandedIndex}
        index={index++}
      />
    </React.Fragment>
  );
};

export default observer(Summary);
