import React from 'react';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';

/* eslint-disable consistent-return */
export function useDocumentTitle(title) {
  React.useEffect(() => {
    window.document.title = title;
    return () => {
      window.document.title = DEFAULT_DOCUMENT_TITLE;
    };
  }, [title]);
}

export default useDocumentTitle;
