import React from 'react';
import PropTypes from 'prop-types';
import IntroPage from 'ui/components/wizard/IntroPage';

import './SuccessPage.scss';

export default class SuccessPage extends IntroPage {
  ctaText = this.props.buttonText;
  static pageName = 'SuccessPage';
  static dataClass = 'vvcenter center no-bottom-padding no-top-padding';
  static enterNavigation = false;

  static propTypes = {
    headerText: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
  };

  renderPage() {
    const { headerText, bodyText } = this.props;

    return (
      <div className="success-info">
        <div className="icon"></div>

        <h1>{headerText}</h1>

        <p>{bodyText}</p>
      </div>
    );
  }
}
