import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

// When positioning absolutely, there might be elements
//   beneath that have shadows and would not be hidden,
//   so we slightly enlarge the container and add
//   padding so everything remains aligned.
const hideShadows = 4;

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },

  white: {
    background: '#fff',
  },

  absolute: {
    top: -hideShadows,
    left: -hideShadows,
    right: -hideShadows,
    bottom: -hideShadows,
    padding: hideShadows,
    position: 'absolute',
    zIndex: theme.zIndex.above,
  },

  content: {
    maxWidth: 1000,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    margin: [[64, 'auto']],
    flexDirection: 'column',

    '$absolute &': {
      margin: [[0, 'auto']],
    },
  },

  vertically: {
    justifyContent: 'center',
  },
});

export default withStyles(styles)(({
  white,
  classes,
  absolute,
  children,
  className,
  notVertically,
}) => {
  const rootClassName = classNames(
    className,
    classes.root,
    {
      [classes.white]: white,
      [classes.absolute]: absolute,
    },
  );

  const contentClassName = classNames(
    classes.content,
    {
      [classes.vertically]: !notVertically,
    },
  );

  return (
    <div className={rootClassName}>
      <div className={contentClassName}>
        {children}
      </div>
    </div>
  );
});
