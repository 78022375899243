import React from 'react';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    textDecoration: 'inherit',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

function maybePreventDefault(props) {
  const { href } = props;
  let { onClick } = props;

  if (href === '#' && !onClick) {
    onClick = e => e.preventDefault();
  }

  return { ...props, onClick };
}

export default withStyles(styles)(({
  classes,
  children,
  className,
  ...other
}) => (
  <Typography
    component="a"
    className={classNames(
      classes.root,
      className,
    )}
    {...maybePreventDefault(other)}
  >
    {children}
  </Typography>
));
