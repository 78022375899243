import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useToggledState } from 'hooks';
import Spacer from '@wui/layout/spacer';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import PauseIcon from 'icons/svg/icon-pause.svg';
import PlayIcon from 'icons/svg/icon-play.svg';

const AudioPlayer = ({ src }) => {
  const audio = useMemo(() => {
    const result = new Audio(src);
    result.volume = 0.6;
    result.loop = true;
    return result;
  }, [src]);

  const [playing, togglePlaying] = useToggledState(false);

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
    return () => audio.pause();
  }, [playing, audio]);

  return (
    <Grid container direction="column" justify="center" alignItems="center" onClick={togglePlaying}>
      <img alt="" src={playing ? PauseIcon : PlayIcon} />
      <Spacer v={8} />
      <Typography variant="caption">{playing ? 'PLAYING AUDIO' : 'AUDIO PAUSED'}</Typography>
    </Grid>
  );
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};

export default AudioPlayer;
