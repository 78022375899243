import React from 'react';
import PropTypes from 'prop-types';
import { ClientData } from 'models/client-data/main';
import { Callbacks, noOp, getCompanyName } from 'utils';
import { API, NETWORK_ERROR } from 'API';
import { getRedirectToUrl, redirectToJournal, Urls } from 'urls';
import { withRouter } from 'react-router-dom';
import {
  invalidateWizardItems,
  sendSectionValidationEvents,
  invalidatePostPlanSections,
} from 'models/client-data/invalidator';

import { debugLog } from 'debug';
import { getFileSections, getPlanSections, allPlanSectionsValid } from 'ui/sections/sections';
import { AnalyticsEvents } from 'analytics';
import WarningPopup from 'ui/components/account-expiration/WarningPopup';
import ExpiredPopup from 'ui/components/account-expiration/ExpiredPopup';
import NewVersionPopup from 'ui/components/NewVersionPopup';
import SectionAssetBeneficiaries from 'ui/sections/asset-beneficiaries/SectionAssetBeneficiaries';
import LoadingScreen from '@/components/LoadingScreen';
import SectionNotarize from 'ui/sections/notarize/SectionNotarize';
import MobxTranslationLayer from 'hocs/MobxTranslationLayer';
import PasswordResetRedirect from 'ui/components/authentication/PasswordResetRedirect';
import tokenFetcher from '@willing-shared/utils/tokens';

export const ClientDataHolder = withRouter(
  class ClientDataHolder extends React.Component {
    static propTypes = {
      history: PropTypes.object,
      location: PropTypes.object,
    };

    static contextTypes = {
      experiments: PropTypes.object,
    };

    static childContextTypes = {
      history: PropTypes.object,
      clientDataHolder: PropTypes.object,
      location: PropTypes.object,
      hasMlpDep: PropTypes.bool,
    };

    constructor(props) {
      super(props);

      this.state = {
        isLoading: true,
        isPersisting: false,
        isAuthenticated: null,
        clientDataCallbacks: [],
        validateAllCallbacks: [],
        showDataExpirationWarning: false,
        showDataExpirationNotice: false,
        showNewVersionNotice: false,
        googleAnalyticsId: null,
        firstTimeLoaded: false,
        maintenanceMode: false,
        hasMlpDep: null,
      };

      this._setData(new ClientData(), {}, false);

      this.clientDataCallbacks = new Callbacks(this, 'clientDataCallbacks');
      this.validateAllCallbacks = new Callbacks(this, 'validateAllCallbacks');

      // Kind of DI to avoid circular dependency
      this.invalidateWizardItems = invalidateWizardItems;
      this.getPlanSections = getPlanSections;
      this.getFileSections = getFileSections;

      // Non-functional until we GET clientData
      this.analyticsEvents = new AnalyticsEvents();
      window.analyticsEvents = this.analyticsEvents;
      this.supportInfo = {};
    }

    get isLoading() {
      return this.state.isLoading;
    }

    get isAuthenticated() {
      return this.state.isAuthenticated;
    }

    get googleAnalyticsId() {
      return this.state.googleAnalyticsId;
    }

    get roles() {
      return this.serverData.roles || {};
    }

    get hasNotaryPermission() {
      return 'notary' in this.roles;
    }

    get hasWitnessPermission() {
      return 'witness' in this.roles;
    }

    get dashboardUrl() {
      return this.serverData.documentBundle && this.allPlanSectionsValid()
        ? Urls.file
        : Urls.planDashboard;
    }

    get docsUrl() {
      return this.clientData.isSectionValid(SectionNotarize) ? Urls.storage : Urls.documents;
    }

    onLoginSuccessful = (googleAnalyticsId, isRegistration, skipLoad) => {
      // We set the skipLoad flag to true when there is already
      //   another request to load the client data. We avoid
      //   setting the authentication status to null here if that
      //   request has found that the user is authenticated.
      this.setState({
        isAuthenticated: (skipLoad && this.state.isAuthenticated) || null,
        googleAnalyticsId: googleAnalyticsId,
      });

      if (!skipLoad) {
        this._loadClientData(isRegistration);
      }
    };

    logout = async () => {
      if (await API.logout()) {
        this.onLogoutSuccessful();
      }
    };

    onLogoutSuccessful = () => {
      const covid = Boolean(this.clientData.covidEmployer);
      this.setState({ isAuthenticated: false });
      this._setData(new ClientData(), {});
      if (covid) {
        window.location = Urls.registerCovid;
      }
    };

    // convenience function for checking if the user is ready for checkout
    allPlanSectionsValid = () => allPlanSectionsValid(this.clientData);

    updateInvalidations(specificSection) {
      const allPlanSections = this.getPlanSections(this.clientData);
      const sectionsToCheck = specificSection ? [specificSection] : allPlanSections;

      // TODO: After beneficiary address post payment experiment ends, reevaluate if we need experiments here
      this.invalidateWizardItems(
        this.clientData,
        this.clientDataPristine,
        this.context.experiments,
      );

      // Mark each section we are checking as valid or not.
      sectionsToCheck.forEach(section => {
        const valid = section
          .getPageVisits(this.clientData, this.serverData, this.context.experiments)
          .every(this.clientData.isPageVisitValid.bind(this.clientData));

        if (valid) {
          this.clientData.setSectionValid(section);
        } else {
          this.clientData.setSectionInvalid(section);
        }
      });

      // If all plan sections are complete, mark plan as complete.
      if (allPlanSections.every(this.clientData.isSectionValid.bind(this.clientData))) {
        if (!this.clientData.isPlanValid()) {
          this.analyticsEvents.gtag('event', 'plan-completed', {
            event_category: 'aura-integration',
          });
        }
        this.clientData.setPlanValid();
      } else {
        this.clientData.setPlanInvalid();
        invalidatePostPlanSections(this.clientData);
      }

      // If all file sections are complete, mark file as complete.
      const fileValid = this.getFileSections(this.clientData, this.serverData).every(
        this.clientData.isSectionValid.bind(this.clientData),
      );

      if (fileValid) {
        this.clientData.setFileValid();
        this.clientData.setAppValid();
      } else {
        this.clientData.setFileInvalid();
        this.clientData.setAppInvalid();
      }
    }

    async updateInvalidationsAndSave() {
      const existingCount = this.clientData.validWizardItems.length;
      this.updateInvalidations();

      if (existingCount !== this.clientData.validWizardItems.length) {
        await this.persistClientData();
      }
    }

    _setClientData(clientData, emit = true) {
      this.clientData = clientData;
      window.clientData = this.clientData;
      this.clientDataPristine = this.clientData.deepCopy();
      window.clientDataPristine = this.clientDataPristine;
      if (emit) {
        this.clientData.validate();

        this.clientDataCallbacks.emit();
      }
    }

    setServerData(serverData) {
      this.serverData = serverData;
      window.serverData = serverData;
      this.serverDataPristine = Object.assign({}, this.serverData);
      window.serverDataPristine = this.serverDataPristine;

      if (
        serverData.commitHash &&
        window.WillingConfig.commitHash &&
        serverData.commitHash !== window.WillingConfig.commitHash
      ) {
        this.setState({ showNewVersionNotice: true });
      }

      // If the server data doesn't have any content,
      //   then we don't have what we need to set
      //   experiments, so bail out.
      if (!Object.keys(this.serverData).length) {
        return;
      }

      const {
        explodingDiscountExperimentId,
        experiments: { explodingDiscountVariant },
      } = this.serverData;

      if (explodingDiscountVariant !== null) {
        this.analyticsEvents.setExperiment(explodingDiscountExperimentId, explodingDiscountVariant);
      }
    }

    _setData(clientData, serverData, emit = true) {
      this.setServerData(serverData);
      this._setClientData(clientData, emit);
    }

    componentDidMount() {
      tokenFetcher.start(null, null, noOp);

      // Send a page view on load so that it is tracked.
      this.analyticsEvents.sendPageChange(this.props.location.pathname);

      // When the user navigates to a different page, set the data
      //   back the the pristine values. We use `history.block` here
      //   in order to run the change before navigating to the next
      //   page. This allows the next page to change the clientData
      //   during its initialization or mounting. If we just used
      //   `history.listen` instead, the clientData would be changed
      //   when the next route is mounted, but then changed back to
      //   the pristine values because the callback occurs after
      //   the navigation.
      this.historyUnblock = this.props.history.block(() => {
        this.setClientDataPristine();
        return true;
      });

      this._loadClientData();

      this.supportInfoInterval = setInterval(this.updateSupportInfo, 300000);
      this.updateSupportInfo();

      API.hasMlpDep()
        .then(result => this.setState({ hasMlpDep: result.data }))
        .catch(() => null);
    }

    componentWillUnmount() {
      this.historyUnblock();
      clearInterval(this.supportInfoInterval);

      // cancel any warnings on window close
      window.onbeforeunload = null;
    }

    componentDidUpdate(prevProps) {
      // Eslint catches the props which are part of react router
      // eslint-disable-next-line
      if (prevProps.location.pathname !== this.props.location.pathname) {
        window.scrollTo(0, 0);
        this.analyticsEvents.sendPageChange(this.props.location.pathname);
      }

      const currentExperiments = this.context.experiments.enabled.join(',');
      if (currentExperiments !== this.previousExperiments) {
        this.previousExperiments = currentExperiments;
        this.sendUserProperties();
      }
    }

    setClientDataPristine = () => {
      this._setData(this.clientDataPristine, this.serverDataPristine);
    };

    updateSupportInfo = async () => {
      const supportInfo = await API.getSupportInfo().catch(() => ({}));

      // If there is an error getting the support information,
      //   ignore it and use the previous setting value. Worst
      //   case, we show the phone number for too long and the
      //   user leaves a voicemail. If we don't show the number
      //   when it should be, the user has the option to send
      //   an email.
      if (supportInfo && supportInfo.data) {
        this.supportInfo = supportInfo.data;
        this.setState({
          maintenanceMode: supportInfo.data.maintenanceMode,
        });
        this.clientDataCallbacks.emit();
      }
    };

    async _loadClientData(disablePopups) {
      this.setState({
        isLoading: true,
      });

      let response;

      try {
        response = await API.getClientData();
      } catch (errorResponse) {
        switch (errorResponse.errorCode) {
          case 'missing_response_data':
          case 'not_authenticated':
          case NETWORK_ERROR:
            this.setState({
              isLoading: false,
              firstTimeLoaded: true,
              isAuthenticated: false,
            });
            break;
          default:
            // Only raise an exception if there is an error code. When there
            //   is not one, it means that the request likely failed because
            //   of the user navigating away from the page.
            if (errorResponse.errorCode) {
              throw `Unexpected errorCode: ${errorResponse.errorCode}`;
            }
        }
        this._setData(new ClientData(), {});
        return;
      }

      const redirectTo = getRedirectToUrl();

      this._setData(ClientData.fromPOJO(response.data), response.serverData);
      await this.updateInvalidationsAndSave();

      this.analyticsEvents.sendIdentification(
        this.clientData.email.toLowerCase(),
        response.serverData.googleAnalyticsId,
      );

      this.sendUserProperties(redirectTo);

      if (redirectTo) {
        window.location = redirectTo;
        return;
      }

      this.setState({
        isLoading: false,
        isAuthenticated: true,
        firstTimeLoaded: true,
      });

      if (!disablePopups) {
        const { showDataExpirationWarning, showDataExpirationNotice } = this.serverData;
        this.setState({
          showDataExpirationWarning,
          showDataExpirationNotice,
        });
      }
    }

    logMissingResidualAssets(message, residual, residualJoint) {
      if (residual || residualJoint) {
        debugLog([message, `R:${Boolean(residual)}`, `RJ:${Boolean(residualJoint)}`].join(' '));
      }
    }

    logMissingResidualBeneficiaries(message) {
      const missing =
        this.clientData.missingEitherResidualBeneficiaries &&
        this.clientData.isSectionValid(SectionAssetBeneficiaries);

      if (missing) {
        debugLog(
          [
            message,
            `R[C]:${this.clientData.missingResidualBeneficiaries}`,
            `RJ[C]:${this.clientData.missingResidualJointBeneficiaries}`,
            `R[S]:${this.clientData.spouse.missingResidualBeneficiaries}`,
            `RJ[S]:${this.clientData.spouse.missingResidualJointBeneficiaries}`,
          ].join(', '),
        );
      }

      return missing;
    }

    async persistClientData() {
      // isLoading is used in far too many contexts here for it to be a good key for retries
      //   so we use a method specific one
      const { isPersisting } = this.state;

      // If currently in the process of persisting, wait
      //   until the current operation completes before
      //   persisting again.
      if (isPersisting) {
        return new Promise(resolve => setTimeout(() => resolve(this.persistClientData()), 100));
      }

      this.setState({ isLoading: true, isPersisting: true });

      const preSaveResidualAsset = this.clientData.residualAsset;
      const preSaveResidualJointAsset = this.clientData.residualJointAsset;

      this.logMissingResidualAssets(
        'Residual missing before persist!',
        !preSaveResidualAsset,
        !preSaveResidualJointAsset,
      );

      const beneficiariesMissingBefore = this.logMissingResidualBeneficiaries(
        'Beneficiaries missing from residual before persist!',
      );

      const response = await API.putClientData({
        data: this.clientData.toPOJO(),
      }).finally(() => this.setState({ isLoading: false, isPersisting: false }));

      if (response.success) {
        // When the data is updated, it is also returned
        //   so that we can update the local copy. The
        //   updates should generally just be primary
        //   keys that were created by the database. These
        //   are used by the backend's update mechanism to
        //   update rows without creating new ones and
        //   then deleting the old ones.
        // The update occurs in-place so that any existing
        //   references to the objects being updated
        //   remain intact.
        this.clientData.updateInPlace(response.data);

        this.clientDataPristine = this.clientData.deepCopy();
        window.clientDataPristine = this.clientDataPristine;
        this.setServerData(response.serverData);
      }

      const hasMissingResidual = preSaveResidualAsset && !this.clientData.residualAsset;
      const hasMissingResidualJoint =
        preSaveResidualJointAsset && !this.clientData.residualJointAsset;

      this.logMissingResidualAssets(
        'Residual missing before persist!',
        hasMissingResidual,
        hasMissingResidualJoint,
      );

      if (!beneficiariesMissingBefore) {
        this.logMissingResidualBeneficiaries('Beneficiaries missing from residual after persist!');
      }

      sendSectionValidationEvents(this.clientData, this.clientDataPristine, this.analyticsEvents);
      this.sendUserProperties();

      return response;
    }

    sendUserProperties(redirectTo = null) {
      this.analyticsEvents.sendUserProperties(
        this.clientData,
        this.serverData,
        this.context.experiments,
        redirectTo,
      );
    }

    updateClientData(func) {
      const result = func();
      this.clientData.validate();
      this.clientDataCallbacks.emit();
      return result;
    }

    getChildContext() {
      return {
        clientDataHolder: this,
        history: this.props.history,
        location: this.props.location,
        hasMlpDep: this.state.hasMlpDep,
      };
    }

    get isOnCheckoutFlow() {
      const urlPatterns = ['pricing', 'subscription', 'checkout'];
      return urlPatterns.some(e => window.location.pathname.includes(e));
    }

    get permissions() {
      const permissions = [[Urls.planDashboard, 'Client']];

      if (this.hasNotaryPermission) {
        permissions.push(['/notary/', 'Notary']);
      }

      if (this.hasWitnessPermission) {
        permissions.push(['/witness/', 'Witness']);
      }

      return permissions;
    }

    render() {
      const { showDataExpirationWarning, firstTimeLoaded, maintenanceMode } = this.state;
      if (this.roles.notary && this.roles.notary.hasUnfinishedJournal) {
        redirectToJournal(true);
      }

      // TODO: organize this better later so the translation layer isn't repeated
      // TODO: just get rid of this legacy context system altogether
      if (!firstTimeLoaded) {
        return (
          <MobxTranslationLayer>
            <LoadingScreen />
          </MobxTranslationLayer>
        );
      }

      if (maintenanceMode) {
        const companyName = getCompanyName(this.serverData);
        return (
          <MobxTranslationLayer>
            <LoadingScreen>
              {companyName} is currently undergoing scheduled maintenance. Please check back later.
            </LoadingScreen>
          </MobxTranslationLayer>
        );
      }

      return (
        <React.Fragment>
          <div className="standard-styles">
            <WarningPopup
              open={showDataExpirationWarning}
              onCloseClick={() =>
                this.setState({
                  showDataExpirationWarning: false,
                })
              }
            />
            <ExpiredPopup
              open={this.serverData.showDataExpirationNotice && !this.isOnCheckoutFlow}
              onCloseClick={() =>
                this.setState({
                  showDataExpirationNotice: false,
                })
              }
              resetComplete={() => this._loadClientData(true)}
            />
            <NewVersionPopup open={this.state.showNewVersionNotice} />
          </div>
          <MobxTranslationLayer>
            <PasswordResetRedirect />
            {this.props.children}
          </MobxTranslationLayer>
        </React.Fragment>
      );
    }
  },
);
