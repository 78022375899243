import React from 'react';
import { observer } from 'mobx-react';
import { Switch } from 'react-router-dom';

import { getTopLevelRoutes } from 'ui/TopLevelRoutes';
import { useGlobalContext } from 'hooks';

const AppRoutes = () => {
  const { clientData, serverData, experiments, clientDataHolder } = useGlobalContext();
  const frontendRoutes = getTopLevelRoutes(clientData, serverData, experiments, clientDataHolder);

  return <Switch>{frontendRoutes}</Switch>;
};

export default observer(AppRoutes);
