import React from 'react';
import { Link } from 'react-router-dom';

import './NotFound.scss';

export default class NotFound extends React.PureComponent {
  render() {
    return (
      <div className="not-found">
        <div className="not-found__hero">404</div>
        <div className="not-found__subheading">Oops! This page could not be found.</div>
        <div className="not-found__body">
          Sorry, but the page you are looking for does not exist, or is temporarily unavailable.
        </div>
        <Link to="/">
          <button className="button button--primary">Go to Homepage</button>
        </Link>
      </div>
    );
  }
}
