import React from 'react';
import PropTypes from 'prop-types';
import { joinClass } from 'utils';

import './PureSelect.scss';

export class PureSelect extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    dataPath: PropTypes.string,
  };

  componentDidMount() {
    if (this.select.value !== this.props.value) {
      this.props.onChange(this.select.value);
    }
  }

  render() {
    return (
      <select
        className={joinClass('select-input', this.props.className)}
        onChange={e => this.props.onChange(e.target.value)}
        value={this.props.value || ''}
        onClick={this.props.onClick}
        ref={select => {
          this.select = select;
        }}
        data-path={this.props.dataPath}
      >
        {this.props.children}
      </select>
    );
  }
}
