import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    position: ['absolute', '!important'],
    width: ['1px', '!important'],
    height: ['1px', '!important'],
    padding: ['0px', '!important'],
    margin: ['-1px', '!important'],
    overflow: ['hidden', '!important'],
    clip: ['rect(0,0,0,0)', '!important'],
    whiteSpace: ['nowrap', '!important'],
    border: [[0], '!important'],
  },
};

const ScreenReaderNotification = ({ children, classes }) => (
  <span className={classes.root} role="alert">
    {children}
  </span>
);

ScreenReaderNotification.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(ScreenReaderNotification);
