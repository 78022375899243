import React from 'react';
import { API } from 'API';
import WizardPage from 'ui/components/wizard/WizardPage';
import { validateSSN, getPhoneNumber } from 'utils';
import { NotarizationStatus } from 'utils/enums';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';

import StateMismatchPopup from 'ui/sections/notarize/StateMismatchPopup';
import WizardRow from 'ui/components/wizard/WizardRow';
import AddressInput from 'ui/components/AddressInput';
import DateSelect from 'ui/components/wizard/DateSelect';
import PureButton from 'ui/components/pure-controls/PureButton';
import WizardLabel from 'ui/components/WizardLabel';
import CallbackValidationDisplay from 'ui/components/wizard/CallbackValidationDisplay';

import './IdentityVerification.scss';

export default class IdentityVerification extends NotarizationWizardPageMixin(WizardPage) {
  static baseUrl = 'kba/identity/';
  static showPrevNextButtons = false;
  static notarizationSection = NotarizationStatus.KBA;
  static enterNavigation = false;

  constructor(props, context) {
    super(props, context);
    this.state = {
      ssn: '',
      mode: 'normal',
      firstName: this.testator.firstName,
      lastName: this.testator.lastName,
      loading: false,
      addressError: false,
    };
  }

  get label() {
    return `${this.testator.firstName}, we need the following information to validate your
            identity and generate your security questions.`;
  }

  handleChange = field => e => {
    this.setState({ [field]: e.target.value });
  };

  goToNext = async () => {
    if (!this.triggerValidation() || this.state.loading) {
      return;
    }

    this.setState({ loading: true });
    try {
      const { verificationAddress } = this.testator;
      let address = this.clientData.address;

      if (verificationAddress.addressComplete && verificationAddress.state !== address.state) {
        this.setState({ addressError: true, loading: false });
        return;
      }

      await this.clientDataHolder.persistClientData();

      await API.postNotarizationIdentityVerification(
        this.state.ssn,
        this.state.firstName,
        this.state.lastName,
        this.testator.clientRelationship === 'spouse',
      );
      super.goToNext();
    } catch (err) {
      this.setState({ mode: 'error', ssn: '', loading: false });
    }
  };

  renderError() {
    return (
      <div className="vvcenter center info-container">
        <div className="intro-error" />
        <h1>Failed ID Validation.</h1>
        <p>
          We were unable to verify your identity. Please try again or give us a call at{' '}
          {getPhoneNumber(this.clientData, this.serverData)}.
        </p>
        <PureButton onClick={() => this.setState({ mode: 'normal' })} className="button--primary">
          Try again
        </PureButton>
      </div>
    );
  }

  renderPage() {
    const { mode, loading } = this.state;
    if (mode === 'error') {
      return this.renderError();
    }

    return (
      <div>
        <WizardLabel label={this.label} />
        <WizardRow label="First Name">
          <input
            className="text-input"
            type="text"
            name="firstName"
            onChange={this.handleChange('firstName')}
            value={this.state.firstName}
          />
        </WizardRow>
        <WizardRow label="Last Name">
          <input
            className="text-input"
            type="text"
            name="lastName"
            onChange={this.handleChange('lastName')}
            value={this.state.lastName}
          />
        </WizardRow>
        <WizardRow label="Last four of SSN">
          <p className="ssn-input">
            &bull; &bull; &bull;
            <span className="light"> -</span> &bull; &bull; <span className="light">-</span>{' '}
            <input
              type="text"
              name="ssn"
              className="text-input text-input--2 text-input--inline"
              value={this.state.ssn}
              placeholder=""
              onChange={this.handleChange('ssn')}
            />
          </p>
          <CallbackValidationDisplay validateCallback={() => validateSSN(this.state.ssn)} />
        </WizardRow>
        <WizardRow label="Date of Birth">
          <DateSelect path="dob" />
        </WizardRow>
        {this.state.addressError && (
          <StateMismatchPopup
            onCloseClick={() => this.setState({ addressError: false })}
            newState={this.testator.verificationAddress.state}
            oldState={this.clientData.address.state}
          />
        )}
        <WizardRow label={`${this.testator.firstNames} Residence`}>
          <AddressInput obj={this.testator} path="verificationAddress" />
        </WizardRow>
        <PureButton onClick={() => this.goToNext()} className="button--primary" disabled={loading}>
          Submit
        </PureButton>
      </div>
    );
  }
}
