import React from 'react';
import { observer } from 'mobx-react';
import { isMobile } from 'react-device-detect';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';
import { API } from 'API';

import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';

import Failed from './Failed';

class TechCheckInternet extends NotarizationWizardPageMixin(MuiWizardPage) {
  static baseUrl = 'tech-check/internet/';
  static notarizationSection = NotarizationStatus.TECH_CHECK;

  constructor(props, context) {
    super(props, context);

    this.state = {
      isSuccess: null,
    };
  }

  check = async () => {
    const isSuccess = await API.testBandwidth();

    if (isSuccess) {
      if (isMobile) {
        await API.postNotarizationTechCheckComplete();
      }
      this.goToNext();
      return;
    }

    this.setState({ isSuccess });
  };

  componentDidMount() {
    super.componentDidMount();

    this.check();
  }

  retry = () => {
    this.setState({ isSuccess: null });

    this.check();
  };

  renderPage() {
    const { isSuccess } = this.state;

    if (isSuccess === false) {
      return <Failed retry={this.retry} />;
    }

    return (
      <Grid container direction="column" justify="center" alignItems="center" alignContent="center">
        <Typography variant="h3">Testing your internet...</Typography>

        <Spacer v={32} />

        <Typography variant="subtitle1">
          We're testing your internet connection to make sure you have a great video session. Please
          wait.
        </Typography>
      </Grid>
    );
  }
}

export default observer(TechCheckInternet);
