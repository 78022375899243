import React from 'react';
import PropTypes from 'prop-types';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';

import '../DocumentPopUp.scss';
import { getPersonNameByUUID } from 'utils';

export default class ReplacePopUp extends ClientDataReferer {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    goToCheckList: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    personData: PropTypes.object.isRequired,
  };

  render() {
    const { onCloseClick, goToCheckList, onCancel, personData } = this.props;

    const personName = getPersonNameByUUID(this.clientData, personData.personUuid).fullName;

    return (
      <div className="popup-fuzz document-pop">
        <Popup onCloseClick={onCloseClick} className="doc__action--stop">
          <h4 className="center">Let's replace this person</h4>
          <p className="center small">
            Replacing {personName} could impact your wishes. It's best to do this in your checklist.
          </p>
          <div className="document-pop__actions">
            <PureButton onClick={onCancel} className="button--tertiary actions__button">
              Cancel
            </PureButton>
            <PureButton onClick={goToCheckList} className="button--primary actions__button">
              Go to checklist
            </PureButton>
          </div>
        </Popup>
      </div>
    );
  }
}
