import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import CheckoutPicker from 'ui/CheckoutPicker';
import { API } from 'API';

export default class ExpiredPopup extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    resetComplete: PropTypes.func,
    onCloseClick: PropTypes.func,
  };

  static defaultProps = {
    open: false,
  };

  constructor() {
    super();

    this.state = {
      confirmation: false,
    };
  }

  resetAccount = async () => {
    await API.resetExpiredAccount();
    this.props.resetComplete();
    this.props.onCloseClick();
  };

  render() {
    const { open, onCloseClick } = this.props;
    if (!open) {
      return null;
    }

    const noopfn = () => null;

    if (!this.state.confirmation) {
      return (
        <div className="popup-fuzz">
          <Popup onCloseClick={noopfn} showCloseIcon={false} className="center">
            <h4>Your account has expired.</h4>
            <p className="small">
              Welcome back. Your trial period has ended and your account has expired. You can pay
              now to finish and access your estate planning documents.
            </p>

            <p className="small">
              If you decide to continue without paying, your account will reset and you will have to
              re-enter your information and wishes.
            </p>

            <div className="button-flow button-flow--right">
              <PureButton
                onClick={() => this.setState({ confirmation: true })}
                className="button--tertiary"
              >
                Reset my account
              </PureButton>
              <Link to={CheckoutPicker.url} onClick={onCloseClick}>
                <PureButton onClick={noopfn} className="button--primary">
                  Pay and Continue
                </PureButton>
              </Link>
            </div>
          </Popup>
        </div>
      );
    }

    return (
      <div className="popup-fuzz">
        <Popup onCloseClick={noopfn} showCloseIcon={false} className="center">
          <h4>Your account will be reset</h4>
          <p className="small">
            Please confirm that you want your account reset. You will need to re-enter your
            information and wishes to proceed and access your documents.
          </p>

          <div className="button-flow button-flow--right">
            <Link to={CheckoutPicker.url} onClick={onCloseClick}>
              <PureButton onClick={noopfn} className="button--tertiary">
                Pay and Continue
              </PureButton>
            </Link>
            <PureButton onClick={this.resetAccount} className="button--primary">
              Confirm Reset
            </PureButton>
          </div>
        </Popup>
      </div>
    );
  }
}
