import React from 'react';
import PropTypes from 'prop-types';
import OT from '@opentok/client';

import GenericOpenTokError from 'ui/execution/GenericOpenTokError';

// This component handles shared set up/tear down and error handling for the
// OpenTok publisher, passing a new options object will destroy the old
// publisher and create a new one with the updated options.
export default class OpenTokPublisher extends React.Component {
  static propTypes = {
    targetElementId: PropTypes.string,
    options: PropTypes.object,
    initHandler: PropTypes.func,
    completionHandler: PropTypes.func,
    destructionHandler: PropTypes.func,
  };

  static defaultProps = {
    targetElementId: 'publisher',
    options: {},
    completionHandler: () => null,
    destructionHandler: () => null,
    initHandler: () => null,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      error: false,
    };
  }

  componentDidMount() {
    this.initPublisher();
  }

  componentWillUnmount() {
    this.destroyPublisher();
  }

  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.destroyPublisher();
      this.initPublisher();
    }
  }

  completionHandler = error => {
    this.props.completionHandler(error);
    if (error) {
      this.setState({ error: true });
    }
  };

  initPublisher() {
    const { targetElementId, options } = this.props;
    this.publisher = OT.initPublisher(targetElementId, options, this.completionHandler);
    this.props.initHandler(this.publisher);
  }

  destroyPublisher() {
    if (this.publisher) {
      this.publisher.destroy();
      this.publisher = null;
      this.props.destructionHandler();
    }
  }

  render() {
    if (this.state.error) {
      return <GenericOpenTokError closeFn={() => this.setState({ error: false })} />;
    }

    return null;
  }
}
