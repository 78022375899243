import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalContext } from 'hooks';

import Link from '@wui/basics/link';
import Spacer from '@wui/layout/spacer';
import { getPhoneNumber } from '!/utils';
import Typography from '@wui/basics/typography';

export default function Footer({ indentMobile }) {
  const { clientData, serverData } = useGlobalContext();
  return (
    <React.Fragment>
      <Spacer v={96} />
      <Typography variant="intro">
        {indentMobile && <Spacer inline mdUp h={36} />}
        Questions? Give us a call at{' '}
        <Link noWrap href={getPhoneNumber(clientData, serverData, 'link')}>
          {getPhoneNumber(clientData, serverData)}
        </Link>
      </Typography>
    </React.Fragment>
  );
}

Footer.propTypes = {
  indentMobile: PropTypes.bool,
};

Footer.defaultProps = {
  indentMobile: false,
};
