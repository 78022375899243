import React from 'react';
import { observer } from 'mobx-react';
import { Link as RouterLink } from 'react-router-dom';

import Link from '@wui/basics/link';
import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';

import Button from '@wui/input/button';

import ActionButton from './ActionButton';
import useStyles from './useStyles';

import CheckoutPicker from 'ui/CheckoutPicker';
import { documentBundleIncludes } from 'utils';
import { DocumentBundle } from 'utils/enums';

import { useGlobalContext } from 'hooks';

const DocumentButton = () => {
  const {
    clientData,
    serverData,
    history,
    serverData: { documentBundle },
    clientDataHolder: { docsUrl },
  } = useGlobalContext();
  const classes = useStyles();

  const goToCheckoutPicker = () => history.push(CheckoutPicker.url);

  if (!documentBundle) {
    return <ActionButton text="Learn More" onClick={goToCheckoutPicker} />;
  }

  const upgradeButton =
    documentBundleIncludes(documentBundle, DocumentBundle.HOMEOWNERS) ||
    clientData.covidEmployer ||
    serverData.fromPortunus ? null : (
      <Button variant="outlined" noMinWidth onClick={goToCheckoutPicker}>
        Upgrade
      </Button>
    );

  return (
    <Grid item xs={7} md={5} container direction="row" justify="flex-end">
      <Typography variant="body1">
        <Link to={docsUrl} className={classes.paddedLink} component={RouterLink}>
          View
        </Link>
      </Typography>
      {upgradeButton}
    </Grid>
  );
};

export default observer(DocumentButton);
