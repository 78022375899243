import React from 'react';
import PropTypes from 'prop-types';

import './PureRadio.scss';

export default class PureRadio extends React.PureComponent {
  static propTypes = {
    checked: PropTypes.bool,
    onClick: PropTypes.func,
    onCheck: PropTypes.func,
    dataPath: PropTypes.string,
    dataValue: PropTypes.any,
    disabled: PropTypes.bool,
    helpText: PropTypes.string,
    wrapperClassName: PropTypes.string,
  };

  static defaultProps = {
    wrapperClassName: '',
  };

  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
    if (this.props.onCheck && !this.props.checked) {
      this.props.onCheck();
    }
  };

  handleDisabled(func) {
    if (!this.props.disabled) {
      return func();
    }

    return null;
  }

  spaceListener = e => {
    if (e.charCode === 32) {
      if (e.target === document.body) {
        e.preventDefault();
      }

      return this.handleDisabled(this.handleClick);
    }
    return false;
  };

  addSpaceListener = () => document.addEventListener('keypress', this.spaceListener);

  removeSpaceListener = () => document.removeEventListener('keypress', this.spaceListener);

  render() {
    const disabled = this.props.disabled;
    return (
      <div className={`${this.props.wrapperClassName}`}>
        <div
          onClick={() => this.handleDisabled(this.handleClick)}
          className="radio"
          data-path={this.props.dataPath}
          data-value={this.props.dataValue}
          tabIndex="0"
          onFocus={this.addSpaceListener}
          onBlur={this.removeSpaceListener}
        >
          <span className={'radio__indicator' + (disabled ? ' radio-disabled' : '')}>
            {this.props.checked && <span className="radio__check" />}
          </span>
          <span className={'radio__label' + (disabled ? ' radio-disabled' : '')}>
            {this.props.children}
          </span>
        </div>
        {this.props.helpText && (
          <div className="tooltipflow">
            <div className="blocker"></div>
            <div className="tooltip">{this.props.helpText}</div>
          </div>
        )}
      </div>
    );
  }
}
