import React from 'react';

import WizardPage from 'ui/components/wizard/WizardPage';

import App from '@/App';
import Notify from './Notify';

export class Page extends WizardPage {
  static baseUrl = '';
  static enterNavigation = false;

  static customDisplay() {
    return () => (
      <App showFooter={false}>
        <Notify />
      </App>
    );
  }
}
