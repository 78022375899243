import React from 'react';

import WizardPage from 'ui/components/wizard/WizardPage';

import App from '@/App';
import DocumentList from './DocumentList';

export class DocumentListPage extends WizardPage {
  static baseUrl = '';
  static enterNavigation = false;

  static customDisplay() {
    return () => (
      <App showFooter={false}>
        <DocumentList />
      </App>
    );
  }

  goToNext() {
    const now = new Date();

    this.updateClientData(() => {
      this.clientData.notarizationDate.setRawValues({
        year: now.getFullYear(),
        month: now.getMonth() + 1,
        day: now.getDate(),
      });
    });

    super.goToNext();
  }
}
