import React from 'react';
import { API } from 'API';
import KBALocked from 'ui/KBALocked';
import { NotarizationStatus } from 'utils/enums';
import PageVisit from 'ui/components/wizard/PageVisit';
import { canNotarizeOnline } from 'utils';
import urls from 'urls';
import sentry from '@willing-shared/utils/sentry';

const NotarizationStatusOrder = [
  NotarizationStatus.TECH_CHECK,
  NotarizationStatus.ID_VERIFICATION,
  NotarizationStatus.KBA,
  NotarizationStatus.SIGNATURE_CREATION,
  NotarizationStatus.ID_VERIFICATION_SPOUSE,
  NotarizationStatus.KBA_SPOUSE,
  NotarizationStatus.SIGNATURE_CREATION_SPOUSE,
  NotarizationStatus.WAITING_TO_START,
  NotarizationStatus.ONGOING,
];

export const NotarizationWizardPageMixin = superclass =>
  class extends superclass {
    static updateSessionDelay = 30000;

    // Hide the next and back buttons
    static layoutProps = {
      buttonRenderer: () => null,
      showBackButton: false,
    };

    get showTestatorSwitcher() {
      return false;
    }

    handleSession() {
      // This function is called with the session data as an argument when it
      // is periodically fetched from the server. Override it to perform
      // page-specific checks or updates that rely on the session data.
    }

    updateSession = async () => {
      // These imports are inside the function instead of at the top of the
      // file to avoid circular dependencies.
      /* eslint-disable no-undef */
      const SectionNotarize = require('ui/sections/notarize/SectionNotarize').default;
      const TechCheckBrowserDevices = require('material-ui/sections/notarization/TechCheckBrowserDevices')
        .default;
      const IdVerification = require('ui/sections/notarize/pages/id-verification/IdVerification')
        .default;
      const SpouseIdVerification = require('ui/sections/notarize/pages/SpouseIdVerification')
        .default;
      const IdentityVerification = require('ui/sections/notarize/pages/IdentityVerification')
        .default;
      const SpouseIdentityVerification = require('ui/sections/notarize/pages/SpouseIdentityVerification')
        .default;
      const WaitingRoom = require('ui/sections/notarize/pages/WaitingRoom').default;
      const SignatureSelection = require('ui/sections/notarize/pages/SignatureSelection').default;
      const SpouseSignatureSelection = require('ui/sections/notarize/pages/SpouseSignatureSelection')
        .default;
      /* eslint-enable no-undef */

      const notarizationStatusToUrl = {
        [NotarizationStatus.TECH_CHECK]: new PageVisit(SectionNotarize, TechCheckBrowserDevices)
          .url,
        [NotarizationStatus.ID_VERIFICATION]: new PageVisit(SectionNotarize, IdVerification).url,
        [NotarizationStatus.ID_VERIFICATION_SPOUSE]: new PageVisit(
          SectionNotarize,
          SpouseIdVerification,
        ).url,
        [NotarizationStatus.KBA]: new PageVisit(SectionNotarize, IdentityVerification).url,
        [NotarizationStatus.KBA_SPOUSE]: new PageVisit(SectionNotarize, SpouseIdentityVerification)
          .url,
        [NotarizationStatus.SIGNATURE_CREATION]: new PageVisit(SectionNotarize, SignatureSelection)
          .url,
        [NotarizationStatus.SIGNATURE_CREATION_SPOUSE]: new PageVisit(
          SectionNotarize,
          SpouseSignatureSelection,
        ).url,
        [NotarizationStatus.WAITING_TO_START]: new PageVisit(SectionNotarize, WaitingRoom).url,
      };

      try {
        const response = await API.getNotarizationSession();
        const responseIdx = NotarizationStatusOrder.indexOf(response.data.status);
        const sectionIdx = NotarizationStatusOrder.indexOf(this.constructor.notarizationSection);
        if (responseIdx >= sectionIdx) {
          this.handleSession(response.data);
        } else {
          this.clientDataHolder.props.history.push(notarizationStatusToUrl[response.data.status]);
        }
      } catch (err) {
        if (err.errorCode === 'kba_locked') {
          this.clientDataHolder.props.history.push(KBALocked.url);
        } else if (err.errorCode === 'no_session') {
          this.clientDataHolder.props.history.push(urls.signSection);
        } else {
          sentry.captureException(err);
          this.setState({
            validationErrors: ['Something went wrong, please refresh and try again.'],
          });
        }
      }
    };

    componentDidMount() {
      super.componentDidMount();
      if (!canNotarizeOnline(this.clientData, this.serverData)) {
        window.location.replace(this.clientDataHolder.dashboardUrl);
        return;
      }
      this.updateSession(this.props.status);
      this.sessionInterval = setInterval(this.updateSession, this.constructor.updateSessionDelay);
    }

    componentWillUnmount() {
      super.componentWillUnmount();
      clearInterval(this.sessionInterval);
    }
  };
