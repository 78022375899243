import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Panel from '@wui/layout/panel';
import Button from '@wui/input/button';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';
import Grid from '@wui/layout/grid';
import { formatMoney } from 'utils';

const styles = theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.appBar,
  },
});

const StickyPurchaseCTA = ({ bundle, price, onClick, cta, classes }) => (
  <Panel active className={classes.root}>
    <Grid container direction="row" alignItems="center">
      <Button color="primary" variant="contained" onClick={onClick}>
        {cta}
      </Button>
      <Hidden smDown>
        <Spacer h={32} />
        <Typography variant="h6">{bundle}</Typography>
      </Hidden>
      <Spacer h={16} />
      <Typography variant="body1">{formatMoney(price / 100)}</Typography>
    </Grid>
  </Panel>
);

StickyPurchaseCTA.propTypes = {
  bundle: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  cta: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(StickyPurchaseCTA);
