import PropTypes from 'prop-types';

import sentry from '@willing-shared/utils/sentry';

import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { invalidatePostPlanSections } from 'models/client-data/invalidator';
import { API } from 'API';

// This is, in theory, all that is needed to create a component to process payments
//   from paypal and stripe. Extra functionality can be added on top, such as the
//    paymentRequest API or using react-stripe-elements to build a form
class PaymentHandler extends ClientDataReferer {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,

    // From injectStripe() - handles our stripe checkout actions
    //   Can be either taken from there or passed from a host page
    stripe: PropTypes.shape({}).isRequired,

    // The already applied coupon code.
    couponCode: PropTypes.string,

    // Current priceInfo, managed in the host page
    priceInfo: PropTypes.shape({}).isRequired,

    // Handle application of coupons in the host page
    applyCoupon: PropTypes.func.isRequired,

    // Current bundle selected
    bundle: PropTypes.string.isRequired,

    // Included additional real estate
    includedRealEstate: PropTypes.array,

    // Trigger the redirect to subscriptions when all is done here
    onSuccess: PropTypes.func.isRequired,
  };

  static defaultProps = {
    couponCode: '',
    includedRealEstate: [],
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      paymentError: false,
      isPaymentPending: false,
      couponCode: props.couponCode,
    };
  }

  setPaymentError = errorMessage => {
    const error = errorMessage ? errorMessage : true;
    this.setState({ paymentError: error, isPaymentPending: false });
  };

  // Expects a submit event from a form or button click
  //   Used when building a form using react-stripe-elements,
  //   the paymentRequest API uses its own internal call
  getStripeSource = async e => {
    if (e) {
      e.preventDefault();
    }

    this.setPaymentError('We are no longer accepting new orders at this time.');
  };

  // Returns bool to indicate success for paymentRequest completion
  handleStripeSubmit = async source => {
    if (!source) {
      this.setPaymentError();
      return false;
    }

    let response; // Declared here to avoid block scoping issues
    const { bundle, includedRealEstate, couponCode } = this.props;

    try {
      response = await API.checkout(
        source.id,
        bundle,
        couponCode,
        '', // Subscriptions are not handled in this version of the checkout form
        includedRealEstate,
      );
    } catch (ex) {
      this.setPaymentError();
      return false;
    }

    try {
      await this.bundlePaymentSuccess(response);
    } catch (ex) {
      sentry.captureException(ex);
    }

    this.setState({ isPaymentPending: false });
    return true;
  };

  // Handle sending the payment analytics out to all of our providers
  //   then persists ClientData so it and serverData will reflect the updates
  //   on following screens
  bundlePaymentSuccess = async (response = null) => {
    if (response) {
      this.sendPaymentAnalytics(response);
    }

    this.analyticsEvents.dataLayer.push({
      event: 'willing.trigger.subscription-version-selection',
    });

    this.updateClientData(() => {
      // Upgrading will make new documents become available,
      //   so the future sections having to do with signing
      //   and filing are no-longer completed.
      invalidatePostPlanSections(this.clientData);
    });

    await this.clientDataHolder.persistClientData();
  };

  sendPaymentAnalytics = response => {
    this.analyticsEvents.dispatchEvent('completedPayment', response);
    if (response.couponDiscount > 0) {
      this.analyticsEvents.dispatchEvent('redeemedCoupon');
    }
  };

  paypalCallback = async response => {
    const { onSuccess } = this.props;
    const knownErrors = {
      failed_transaction: 'Transaction failed, please contact support.',
      missing_response_data: 'There was an error, please contact support.',
      product_already_owned: 'You already own this product, please contact support.',
    };

    if (!response) {
      this.setPaymentError(knownErrors.missing_response_data);
      sentry.captureException(new Error('paypalCallback failed. No response!'));
      return;
    } else if (response.success) {
      await this.bundlePaymentSuccess(response);
      // proceed to next step
      onSuccess();
      return;
    }

    const errorMessage = response.getErrorMessage(knownErrors);
    if (Object.values(knownErrors).includes(errorMessage)) {
      this.setPaymentError(errorMessage);
    } else {
      sentry.captureException(
        new Error('paypalCallback failed. Unknown errorCode: ' + JSON.stringify(response)),
      );
      this.setPaymentError();
    }
  };
}

export default PaymentHandler;
