/**
 * The base object for ClientData, Spouse, Child and all the other objects contained. The entry point for the type
 * system. A static field called fields contains a mapping of the fields in the object and their types.
 * All the raw values (unconverted types) are held in this.raw[fieldName]. On validate, those are converted
 * and put in this[fieldName]. After that they are validated and validation errors stored in
 * this.validationErrors[fieldName].
 */
import { deepEquals } from 'utils';

export class ClientDataObject {
  static delayedFields = [];

  // When updating an array of objects in-place, each
  //   object must have a value that differentiates it
  //   from the other instances so that we can update
  //   the proper object because object order in the
  //   array is not guaranteed. When set, this variable
  //   should be the name of the field for the object
  //   from which the value can be obtained or a
  //   method that the object can be passed into to get
  //   the value.
  static keyFieldName = null;

  // By default, when creating a new instance of an object,
  //   the primary key is not kept from the data that is
  //   passed in because it is assumed that you want to
  //   create a new record in the database. Supplying a
  //   primary key would update an existing record.
  constructor(data, keepPrimaryKey = false) {
    if (typeof data === 'undefined') {
      data = {};
    }

    this.raw = {};
    this._initDefaults(data, keepPrimaryKey);
    this.validate();
  }

  /**
   * Return an object with all the fields of the current type and include the fields
   * from base classes recursively.
   */
  static get allFields() {
    if (!this.hasOwnProperty('_allFields')) {
      this._allFields = Object.assign({}, Object.getPrototypeOf(this).allFields, this.fields);
    }
    return this._allFields;
  }

  // Some objects are not valid for storage when persisting,
  //   but we still want to persist the rest of the data
  //   anyways. When this is set to true, objects in an
  //   array will ignore the object for persistence.
  get pojoArrayIgnores() {
    return false;
  }

  get isEmpty() {
    return this.raw.length === 0;
  }

  hasField(fieldName) {
    return fieldName in this.constructor.allFields;
  }

  _initDefaults(overrides, keepPrimaryKey) {
    for (const [fieldName, fieldType] of Object.entries(this.constructor.allFields)) {
      if (fieldName in overrides && overrides[fieldName] !== null) {
        if (!keepPrimaryKey && fieldName === 'pk') {
          continue;
        }

        this.raw[fieldName] = overrides[fieldName];
      } else if (this.constructor.delayedFields.includes(fieldName)) {
        // Don't initialize delayed fields.
      } else {
        this.raw[fieldName] = fieldType.makeDefault();
      }
    }
  }

  /** Trigger recursive validation for the object. Needed after changes to .raw */
  validate() {
    this.validationErrors = [];
    for (const [fieldName, fieldType] of Object.entries(this.constructor.allFields)) {
      const errors = [],
        rawValue = this.raw[fieldName],
        addError = error => errors.push(error),
        value = fieldType.convert(rawValue, addError);

      if (typeof rawValue === 'undefined' && this.constructor.delayedFields.includes(fieldName)) {
        // Don't set a delayed field to null if it isn't set yet.
      } else if (typeof value !== 'undefined') {
        this[fieldName] = value;
      } else {
        this[fieldName] = null;
      }
      fieldType.validate(value, addError);
      this.validationErrors[fieldName] = errors;
    }
  }

  /**
   * Allows nested access using a dotted location. For a path of a.b.c, it calls cb with a.b as the first
   * parameter and "c" as a string for the second one. Returns the return value of cb().
   */
  _accessByPath(path, cb, raw = true) {
    const pathParts = path.split('.'),
      lastPart = pathParts.pop();

    let obj = this; // eslint-disable-line consistent-this
    for (const part of pathParts) {
      let next;
      if (raw && 'raw' in obj) {
        next = obj.raw[part];
      } else {
        next = obj[part];
      }
      if (typeof next === 'undefined') {
        throw `Trying to access property ${part} on object ${obj.constructor.name}`;
      }
      obj = next;
    }

    return cb(obj, lastPart);
  }

  getValue(path) {
    return this._accessByPath(path, (o, p) => o[p]);
  }

  getRawValue(path) {
    return this._accessByPath(path, (o, p) => o.raw[p]);
  }

  getField(path) {
    return this._accessByPath(path, (o, p) => o.constructor.allFields[p]);
  }

  setValue(path, value) {
    this._accessByPath(path, (o, p) => {
      o[p] = value;
    });
  }

  setRawValue(path, value) {
    this._accessByPath(path, (o, p) => {
      if (!o.hasField(p)) {
        throw `Trying to assign property ${p} on object ${o} that is not in fields.`;
      }
      o.raw[p] = value;

      const handlerName = 'on' + p[0].toUpperCase() + p.substr(1) + 'Changed',
        changedHandler = o[handlerName];

      if (changedHandler) {
        changedHandler.call(o);
      }
    });
  }

  setRawValues(map) {
    Object.entries(map).forEach(([path, value]) => this.setRawValue(path, value));
  }

  // Given a `ClientDataObject`, return the value that should be used
  //   to differentiate between instances of the same type when
  //   updating data in place.
  static keyForUpdate(clientDataObject) {
    return typeof this.keyFieldName === 'string'
      ? clientDataObject[this.keyFieldName]
      : this.keyFieldName(clientDataObject);
  }

  get keyForUpdate() {
    return this.constructor.keyForUpdate(this);
  }

  // Update the values for the current object in-place,
  //   recursively updating child objects in the same
  //   manner. This is useful when there are existing
  //   references to objects but we need to update the
  //   data from an external source (like the backend).
  updateInPlace(pojo) {
    // Only the supplied fields are updated, so this
    //   behaves like an UPSERT. If a field is missing,
    //   the existing value will be retained.
    if (pojo === null) {
      return;
    }

    for (const [key, value] of Object.entries(pojo)) {
      const fieldType = this.constructor.allFields[key];

      if (fieldType) {
        this.raw[key] = fieldType.updateInPlace(this.raw[key], value);
      }
    }

    // Running validation copies the raw values to the
    //   top level values (which are used externally
    //   generally speaking).
    this.validate();
  }

  setDelayedValues(map) {
    for (const [path, initializer] of Object.entries(map)) {
      this._accessByPath(path, (o, p) => {
        if (typeof o[p] === 'undefined') {
          this.setRawValue(path, initializer(this));
        }
      });
    }
  }

  getValidationErrors(path) {
    return this._accessByPath(path, (o, p) => {
      if (!o.hasField(p)) {
        throw `Trying to getValidationErrors on object ${o} for ` + `field ${p} that doesn't exist`;
      }
      return o.validationErrors[p];
    });
  }

  // Convert a plain object to an instance of that object type.
  //   This will keep the primary key of the object, so you should
  //   only use this if doing so makes sense. If it does not, you
  //   should use `new ObjectClass(pojo)` instead.
  static fromPOJO(pojo) {
    const initial = {};

    if (pojo !== null) {
      for (const [fieldName, fieldType] of Object.entries(this.allFields)) {
        if (fieldName in pojo) {
          initial[fieldName] = fieldType.fromPOJO(pojo[fieldName]);
        }
      }
    }
    return new this(initial, true);
  }

  /**
   * Recursively converts the current type to a plain JavaScript object (not containing raw values, validation, etc.)
   */
  toPOJO() {
    const result = {};
    for (const [fieldName, fieldType] of Object.entries(this.constructor.allFields)) {
      result[fieldName] = fieldType.toPOJO(this[fieldName]);
    }
    return result;
  }

  walkObjects(cb) {
    cb(this);

    for (const [fieldName, fieldType] of Object.entries(this.constructor.allFields)) {
      fieldType.walkObjects(this.raw[fieldName], cb);
    }
  }

  walkPeople(paths, cb) {
    const walkCb = obj => {
      if ('personUUID' in obj.constructor.allFields && obj.name) {
        cb(obj);
      }
    };

    if (paths) {
      for (const path of paths) {
        this._accessByPath(path, (o, p) => {
          o.constructor.allFields[p].walkObjects(walkCb);
        });
      }
    } else {
      this.walkObjects(walkCb);
    }
  }

  deepCopy() {
    return this.constructor.fromPOJO(this.toPOJO());
  }

  deepEquals(clientDataObject, ignoreKeys) {
    const a = this.toPOJO(),
      b = clientDataObject.toPOJO();
    for (const key of ignoreKeys || []) {
      delete a[key];
      delete b[key];
    }
    return deepEquals(a, b);
  }
}

/**
 * The base for all the types in the type system. While a ClientDataObject class represents a type and an instance
 * contains information, an a WType or a child class is the type blueprint and an instance is the specific type.
 */
export class WType {
  constructor(makeDefault) {
    this.makeDefault = makeDefault || (() => null);
  }

  /** Called to convert a raw value (for example a string) to a "converted" value (for example a number). */
  convert(value) {
    return value;
  }

  /** Called to recursively validate (a converted) value. Calls addError to report validationErrors for the value. */
  // eslint-disable-next-line no-unused-vars
  validate(value, addError) {
    // No validation for base WType
  }

  /** Called to recursively convert pojo to a typed value */
  fromPOJO(pojo) {
    return pojo;
  }

  /** Called to recursively convert value to a POJO */
  toPOJO(value) {
    return value;
  }

  /** Called to recursively walk all ClientDataObjects inside value */
  // eslint-disable-next-line no-unused-vars
  walkObjects(value, cb) {}

  // For simple types, the new value is the supplied
  //   data.
  updateInPlace(existing, pojo) {
    // eslint-disable-line no-unused-vars
    return pojo;
  }
}
