import React from 'react';

import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { ProgressSections } from 'material-ui/components/progress/Progress';
import { Tips, Tip } from 'material-ui/components/tips';
import { Guardian } from 'models/client-data/agents';
import { PeopleBuilder } from 'models/utils';
import { andList } from 'utils';

import PersonPage from 'material-ui/components/wizard-elements/PersonPage';

export default class MaterialGuardians extends MuiWizardPage {
  static progressPage = ProgressSections.YOUR_WISHES.GUARDIANS;

  get showTestatorSwitcher() {
    return this.testator.spouse.hasMinorChildren;
  }

  renderPage() {
    const minorChildren = this.testator.ownMinorChildren.map(c => c.firstName);

    return (
      <React.Fragment>
        <Tips>
          <Tip title="Naming Guardians for Minors">
            <p>
              This is one of the most important parts of making a will. Choose the right person to
              look after {andList(minorChildren)} if something happens to you.
            </p>

            <p>
              Note: It is not necessary to name a child's other parent here if that other parent is
              also currently raising the child. A court will look to these wishes only in the
              absence of the parent(s) currently raising the child.
            </p>
          </Tip>
        </Tips>

        <PersonPage
          title="Guardians"
          agentName="Guardian"
          agentClass={Guardian}
          peopleBuilderMethod={PeopleBuilder.getGuardians}
          primaryPath="primaryGuardian"
          alternatePath="alternateGuardian"
          caption="Who would you like to name as guardian for your children?"
        />
      </React.Fragment>
    );
  }
}
