import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Headroom from 'react-headroom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import ReactResizeDetector from 'react-resize-detector';
import withIsMobileDisplay from 'hocs/withIsMobileDisplay';
import ReactRouterPropTypes from 'react-router-prop-types';

import MetLifeLogo from '@a/images/mlp-logo.svg';
import { withRouter } from 'react-router-dom';

const bottomBorderWidth = 2;
const mobileToolbarHeight = 56;

const styles = theme => ({
  // Used to track the position before
  //   any additional children are added
  //   to the header content.
  preChildren: {},

  appBar: {
    justifyContent: 'space-between',
    boxSizing: 'content-box',
    background: '#fff',

    [theme.breakpoints.up('md')]: {
      height: theme.layout.headerHeight,
    },
  },
  headerZ: {
    zIndex: theme.zIndex.header,
  },
  bottomBorder: {
    // The bottom border of the header is
    //   only added when no additional
    //   children are present. If there
    //   are non-standard header children,
    //   then the border must be supplied
    //   by them.
    '$preChildren + &': {
      borderBottom: `${bottomBorderWidth}px solid ${theme.palette.custom.border}`,
    },
  },
  toolBar: {
    height: mobileToolbarHeight,

    padding: [[0, theme.layout.side.default]],
    [theme.breakpoints.up('md')]: {
      minHeight: 'auto',
      height: theme.layout.headerHeight,
      padding: [[0, theme.layout.side.mdUp]],
    },
  },
  logo: {
    height: 24,
  },
  grow: {
    flexGrow: 1,
  },
});

class Header extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    navigation: PropTypes.node,
    className: PropTypes.string,
    width: PropTypes.string.isRequired,
    fromPortunus: PropTypes.bool,
    fromUpwise: PropTypes.bool,
    classes: PropTypes.shape({}).isRequired,
    history: ReactRouterPropTypes.history.isRequired,
  };

  static defaultProps = {
    children: null,
    className: null,
    navigation: null,
    fromPortunus: true,
    fromUpwise: false,
  };

  static contextTypes = {
    headerHeight: PropTypes.shape({}),
  };

  constructor(...args) {
    super(...args);

    const {
      headerHeight: { set: setHeaderHeight },
    } = this.context;
    setHeaderHeight(mobileToolbarHeight);
  }

  get spacerProps() {
    const {
      headerHeight: { value: headerHeight },
    } = this.context;

    if (this.isTabletOrSmallerDisplay) {
      return {
        style: { height: headerHeight },
      };
    }

    return {};
  }

  onClickHandler = e => {
    const { fromPortunus, fromUpwise, history } = this.props;
    e.preventDefault();
    if (fromUpwise) {
      window.location = '#close';
    } else if (fromPortunus) {
      window.location = `${window.WillingConfig.membersAppLink}/services` || '/';
    } else {
      history.push('/');
    }
  };

  render() {
    const { classes, children, navigation, className: externalClassName } = this.props;

    const {
      headerHeight: { set: setHeaderHeight },
    } = this.context;
    const [Wrapper, wrapperProps] = this.isPhoneDisplay
      ? [Headroom, { className: classes.headerZ }]
      : [React.Fragment, {}];

    const appBarPlaceholderClassName = classNames(classes.appBar, classes.headerZ);

    const appBarClassName = classNames(externalClassName, appBarPlaceholderClassName);

    return (
      <Wrapper {...wrapperProps}>
        <div {...this.spacerProps} className={appBarPlaceholderClassName} />

        <AppBar
          position="fixed"
          className={appBarClassName}
          style={{ minWidth: '100vw' }}
          elevation={0}
        >
          <Toolbar className={classes.toolBar}>
            <a href="/" onClick={this.onClickHandler}>
              <img src={MetLifeLogo} className={classes.logo} alt="MetLife logo home page" />
            </a>
            <div className={classes.grow} />
            {navigation}
          </Toolbar>

          {/* Dynamic resizing is used because the additional children below
              can change the height of the content displayed in the header
              and there is no way to pass the height back from arbitrary
              children to the parent. */}
          <ReactResizeDetector handleHeight onResize={(_, h) => setHeaderHeight(h)} />

          <span className={classes.preChildren} />

          {children}

          <div className={classes.bottomBorder} />
        </AppBar>
      </Wrapper>
    );
  }
}

export default withRouter(withStyles(styles)(withIsMobileDisplay(Header)));
