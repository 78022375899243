import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import TabDivider from '@wui/layout/tabDivider';
import Spacer from '@wui/layout/spacer';

import Layout from '@c/layout/Layout';
import { useGlobalContext } from 'hooks';
import { joinClass } from 'utils';
import DepStep from './DepStep';
import DocumentDetails from './DocumentDetails';
import StartPlanBox from './StartPlanBox';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';

import CircleArrow from '@a/images/circle-arrow.svg';
import Notarize from '@a/images/dep-notarize.png';
import Personalize from '@a/images/dep-personalize.png';
import Review from '@a/images/dep-review.png';

const useStyles = makeStyles(theme => ({
  review: {},
  override: {},
  title: {
    '&$override': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: 300,
        fontSize: 28,
        margin: '0 auto',
      },
    },
  },
  step: {
    width: '27%',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 360,
    },

    '&$review': {
      width: '33%',

      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
    },
  },
  arrow: {
    [theme.breakpoints.down('sm')]: {
      transform: 'rotate(90deg)',
      margin: [[8, 0]],
    },
  },
}));

const DepByDefault = () => {
  const {
    serverData: { probateDocumentsRestricted },
  } = useGlobalContext();
  const classes = useStyles();
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <Layout wide={2} hideRight buttonRenderer={() => null}>
      <Typography
        variant="h2"
        align="center"
        className={joinClass(classes.title, classes.override)}
      >
        How creating your estate plan works
      </Typography>
      <Hidden smDown>
        <Typography variant="body1" align="center">
          Protect the people you love in as little as 15 minutes
        </Typography>
      </Hidden>

      <Spacer v={40} />

      <Grid
        container
        direction={isSmall ? 'column' : 'row'}
        justify="space-between"
        wrap="nowrap"
        alignItems="center"
      >
        <DepStep
          title="Personalize your Plan"
          text={
            <span>
              Answer simple questions to create your personalized plan in as little as{' '}
              <strong>15 minutes</strong>.
            </span>
          }
          image={Personalize}
          className={classes.step}
        />

        <img src={CircleArrow} className={classes.arrow} />

        <DepStep
          title="Review with an Attorney"
          text={
            <span>
              Review your estate plan and get answers to legal questions with one of our trusted
              attorneys if eligible through <strong>Attorney Review</strong>.
            </span>
          }
          image={Review}
          className={joinClass(classes.step, classes.review)}
        />

        <img src={CircleArrow} className={classes.arrow} />

        <DepStep
          title="Notarize your Plan"
          text={
            <span>
              Print and sign your estate plan, or notarize online instantly with{' '}
              <strong>Video Notary</strong>.
            </span>
          }
          image={Notarize}
          className={classes.step}
        />
      </Grid>

      <Spacer v={32} />

      <StartPlanBox text="Create a personalized estate plan now." />

      <Spacer v={32} />

      <Typography variant="h3">What's Included</Typography>

      <Spacer v={8} />
      <TabDivider noMargin dashed />
      <Spacer v={24} />

      <Grid container spacing={4} direction="row">
        <DocumentDetails
          title="Last Will & Testament"
          text="Leave your assets to your loved ones and specify guardians for minor children."
        />
        <DocumentDetails
          title="Living Will (Advance Healthcare Directive)"
          text="State your end of life choices and choose someone to make healthcare decisions for you if you can't."
        />
        <DocumentDetails
          title="Financial Power of Attorney"
          text="Choose someone who can manage your finances in case of an emergency."
        />
        {probateDocumentsRestricted || digitalEstatePlanningService || (
          <DocumentDetails
            title="Probate Avoidance Documents"
            text="Save time and money by avoiding the probate process if you transfer your home directly to or for the benefit of the beneficiaries of your choosing with either a Transfer on Death Deed or Revocable Living Trust, depending on your state."
          />
        )}
      </Grid>

      <Spacer v={32} />

      <StartPlanBox text="Create estate planning documents now." />
    </Layout>
  );
};

export default observer(DepByDefault);
