import React from 'react';

import { capitalizeFirstAndLast } from '@willing-shared/utils/text';

import BoundTextField from './BoundTextField';

export default class BoundNameField extends React.Component {
  convertOnBlur = value => value.trim();
  parseForDisplay = value => capitalizeFirstAndLast(value);

  render() {
    return (
      <BoundTextField
        {...this.props}
        path="name"
        convertValueOnBlur={this.convertOnBlur}
        parseValueForDisplay={this.parseForDisplay}
      />
    );
  }
}
