import { getApplicableSections } from 'ui/sections/sections';

// Currently written as a mixin, since adding it to ClientDataReferer causes a circular dependency error
export const withCurrentPageClass = superClass =>
  class extends superClass {
    get currentPageClass() {
      // Get a list of all available sections.
      const sections = [].concat.apply(
        [],
        Object.values(getApplicableSections(this.clientData, this.serverData)),
      );

      // Create a map of page path to page component class.
      const pages = {};
      sections.forEach(section => {
        section
          .getPageVisits(this.clientData, this.serverData, this.context.experiments)
          .forEach(visit => {
            pages[visit.url] = visit.pageClass;
          });
      });

      // Return the page component class for the
      //   current URL.
      return pages[window.location.pathname];
    }
  };
