import React from 'react';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import PropTypes from 'prop-types';

const GenericOpenTokError = ({ closeFn }) => (
  <div className="popup-fuzz">
    <Popup onCloseClick={closeFn}>
      <div className="center">
        <h4>Something went wrong</h4>
        <p className="small">
          We were unable to perform the action. If the problem persists, please try again later.
        </p>
      </div>
      <PureButton className="button--primary button--pull-right" onClick={closeFn}>
        Try again
      </PureButton>
    </Popup>
  </div>
);

GenericOpenTokError.propTypes = {
  closeFn: PropTypes.func.isRequired,
};

export default GenericOpenTokError;
