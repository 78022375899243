import { ClientDataObject } from 'models/type-system';
import { AgentOwner, Lineage, MaritalStatus } from 'models/client-data/enums';
import { WBoolean, WString } from 'models/types';
import { TestatorMixin } from 'models/client-data/testator';
import { Relationship } from './enums';

export class Spouse extends TestatorMixin(ClientDataObject) {
  static fields = {
    isParent: new WBoolean(),
    clientRelationship: new WString(Relationship.SPOUSE),
    spouseRelationship: new WString(Relationship.SELF),
  };

  static delayedFields = ['isParent'];

  get children() {
    return this.spouse.children.filter(c => [Lineage.BOTH, Lineage.SPOUSE].includes(c.lineage));
  }

  get maritalStatus() {
    return MaritalStatus.MARRIED;
  }

  get assets() {
    return this.spouse.assets;
  }

  get realEstates() {
    return this.spouse.realEstates;
  }

  get ownerString() {
    return AgentOwner.SPOUSE;
  }

  get executors() {
    return this.spouse.executors;
  }

  get healthcareAgents() {
    return this.spouse.healthcareAgents;
  }

  get guardians() {
    return this.spouse.guardians;
  }

  get clientData() {
    return this.spouse;
  }
}
