import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import OpenTokPublisher from 'ui/execution/OpenTokPublisher';

import './BackCamera.scss';

const driversLicenseAspectRatio = 1 / 0.63;
const captureIndicatorSize = 0.95;

export default class BackCamera extends React.Component {
  static propTypes = {
    side: PropTypes.string.isRequired,
    handleCaptureResult: PropTypes.func.isRequired,
    videoSource: PropTypes.string,
    step: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.publisher = null;
    this.state = {
      currentId: '',
      camera: 'front',
      captureWidth: null,
      captureHeight: null,
    };
    this.publisherRef = React.createRef();

    this.options = {
      insertMode: 'append',
      facingMode: 'environment',
      width: '100%',
      height: '100%',
      scaleMode: 'fit',
      fitMode: 'contain',
      resolution: '1280x720',
      style: { buttonDisplayMode: 'off' },
    };

    if (isMobile) {
      // For mobile devices we prevent the default behavior of mirroring
      // the video because it makes it harder to capture the ID
      this.options.mirror = false;
    } else if (props.videoSource) {
      this.options.videoSource = props.videoSource;
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.updateCaptureWidth);
    window.addEventListener('keyup', this.handleKeypress);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateCaptureWidth);
    window.removeEventListener('keyup', this.handleKeypress);
  };

  publisherInitHandler = publisher => {
    this.publisher = publisher;
  };

  handleKeypress = () => {
    if (window.innerWidth >= 1000) {
      this.takeImage();
    }
  };

  takeImage = () => {
    const { side, loading, setLoading, handleCaptureResult } = this.props;

    if (!this.publisher || loading) {
      return;
    }

    setLoading();

    const cameraWidth = this.publisher.videoWidth();
    const cameraHeight = this.publisher.videoHeight();

    const imgData = this.publisher.getImgData();

    const image = new Image();
    image.src = `data:image/jpeg;base64, ${imgData}`;
    const canvas = document.getElementById('capture-id');
    const context = canvas.getContext('2d');

    image.addEventListener('load', () => {
      canvas.width = cameraWidth;
      canvas.height = cameraHeight;

      context.drawImage(image, 0, 0);

      const img = canvas.toDataURL('image/jpeg');
      const params = {
        imageData: img.split(',')[1],
        callback: handleCaptureResult,
        documentType: side === 'front' ? 'DL_Front' : 'DL_Back',
      };
      window.MitekMobileWeb.processDataURL(params);
    });
  };

  updateCaptureWidth = () => {
    // The webcam video is displayed inside the publisher element using fitMode:
    // 'contain', the capture indicator should have the aspect ratio of a driver's
    // license and should be whichever is smaller between 90% as tall as the video
    // and 90% as wide as the video. This is calculated using js on window resize
    // because we need to know the dimensions of the rendered video.
    if (!this.publisher) {
      return;
    }

    const cameraAspectRatio = this.publisher.videoWidth() / this.publisher.videoHeight();
    const pubWidth = this.publisherRef.current.offsetWidth;
    const pubHeight = this.publisherRef.current.offsetHeight;
    const pubAspectRatio = pubWidth / pubHeight;
    const captureAspectRatio = driversLicenseAspectRatio;
    const cameraWidth =
      cameraAspectRatio > pubAspectRatio ? pubWidth : pubHeight * cameraAspectRatio;
    let captureWidth;
    if (captureAspectRatio > cameraAspectRatio) {
      captureWidth = captureIndicatorSize * cameraWidth;
    } else {
      captureWidth = (captureIndicatorSize * cameraWidth * captureAspectRatio) / cameraAspectRatio;
    }
    this.setState({
      captureWidth: `${captureWidth}px`,
      captureHeight: `${captureWidth / captureAspectRatio}px`,
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.step !== this.props.step) {
      this.updateCaptureWidth();
    }
  }

  render() {
    const captureStyles = {
      width: this.state.captureWidth,
      height: this.state.captureHeight,
    };
    return (
      <div className="back-camera">
        <canvas id="capture-id" />
        <OpenTokPublisher
          options={this.options}
          initHandler={this.publisherInitHandler}
          completionHandler={this.updateCaptureWidth}
        />
        <div className="first-info">Press any key to take a picture.</div>
        <div className="publisher-wrapper">
          {this.props.loading && <div className="loading" />}
          <div id="publisher" ref={this.publisherRef} />
          <div className="id-place-wrapper">
            <canvas className="id-place" style={captureStyles} />
          </div>
        </div>
        <button className="camera__button" onClick={this.takeImage} />
      </div>
    );
  }
}
