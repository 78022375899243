import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';
import Grid from '@wui/layout/grid';
import Button from '@wui/input/button';
import Typography from '@wui/basics/typography';
import PanelTitle from '@wui/layout/panelTitle';
import { bundleShape } from 'material-ui/pages/checkout-v6/BundleDetails';

import CloseIcon from '@a/images/close.svg';

const styles = () => ({
  root: {
    margin: [[0, 16]],
  },
});

const TabSelect = ({ bundles, classes, hideSelect, selectTab }) => {
  const buttonProps = { variant: 'outlined' };
  const specialButtonProps = {
    variant: 'contained',
    color: 'primary',
  };
  return (
    <div className={classes.root}>
      <Grid container alignItems="flex-end">
        <Grid item>
          <Button variant="text" className={classes.hideButton} onClick={hideSelect}>
            Hide Options
            <Spacer h={8} />
            <img alt="" src={CloseIcon} />
          </Button>
        </Grid>
      </Grid>
      <Spacer v={24} />
      {bundles.map((b, idx) => (
        <React.Fragment key={b.id}>
          <Panel lessPadding>
            <PanelTitle
              isNotice
              noticeProps={{
                special: b.special,
                primary: b.primary,
              }}
            >
              {b.noticeText}
            </PanelTitle>
            <Grid container alignItems="center" justify="space-between" direction="row">
              <Grid item>
                <Typography variant="h6">{b.name}</Typography>
                <Typography variant="body1">{b.price}</Typography>
              </Grid>
              <Grid item>
                <Button
                  noMinWidth
                  {...(b.special ? specialButtonProps : buttonProps)}
                  onClick={() => selectTab(idx, true)}
                >
                  View
                </Button>
              </Grid>
            </Grid>
          </Panel>
          <Spacer v={24} />
        </React.Fragment>
      ))}
    </div>
  );
};

TabSelect.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.shape(bundleShape)),
  classes: PropTypes.shape({}),
  hideSelect: PropTypes.func.isRequired,
  selectTab: PropTypes.func.isRequired,
};

export default withStyles(styles)(TabSelect);
