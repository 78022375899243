import WizardSection from 'ui/components/wizard/WizardSection';
import Info from 'ui/sections/file/fund/pages/Info';
import { Urls } from 'urls';

export default class SectionFund extends WizardSection {
  static url = '/fund/';
  static title = 'Fund your trust';
  static description = 'Fund your trust with a Home Transfer Deed.';
  static finalUrl = Urls.file;
  static fallbackUrl = Urls.file;
  static showFooter = false;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(Info);
  }
}
