import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FailurePage from '../Failure';

const Failed = ({ retry }) => (
  <FailurePage
    message="No suitable audio input"
    details="We could not identify a suitable audio input. Please check your microphone and try again."
    buttonText="Retry Microphone Test"
    retry={retry}
  />
);

Failed.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default observer(Failed);
