export function handleChange(e) {
  const { target } = e;
  const value = target.type === 'checkbox' ? target.checked : target.value;

  this.setState({
    [target.name]: value,
  });
}

export default {
  handleChange,
};
