import React from 'react';

import { Tip } from '@c/tips';

export default () => (
  <Tip title="Marital Status">
    Enter your current marital status. If it may change soon, you can always come back and update it
    once the change is official.
  </Tip>
);
