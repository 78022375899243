import React from 'react';
import PropTypes from 'prop-types';
import PlanHealthMeter from 'material-ui/components/planHealth/PlanHealthMeter';
import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';

import theme from '@wui/theme';

export default class PlanHealth extends React.Component {
  static propTypes = {
    meterId: PropTypes.string.isRequired,
  };

  render() {
    const { meterId } = this.props;

    return (
      <Grid container direction="row" wrap="nowrap" alignItems="center">
        <Grid item xs={7}>
          <Typography variant="h6">Your Plan Strength</Typography>
          <Spacer v={4} />
        </Grid>
        <Grid item xs={5}>
          <PlanHealthMeter
            id={meterId}
            needleColor={theme.palette.grey.selectedTab}
            needleBaseColor={theme.palette.grey.selectedTab}
          />
        </Grid>
      </Grid>
    );
  }
}
