import React from 'react';
import PropTypes from 'prop-types';
import { useDataBindings, useValidation, useObjOrTestator } from 'hooks';
import { propTypes, defaultProps } from 'prop-type-sets/BoundField';
import PureTextField from '@wui/input/textbox';

const BoundTextField = ({
  obj: externObj,
  path,
  type,
  label,
  style,
  disabled,
  helperText,
  hideEmptyOption,
  dataPathOverride,
  inputPropsModifier,
  parseValueForDisplay,
  convertValueForStorage,
  convertValueOnBlur,
  skipStandardValidation,
  additionalValidations,
  onBlur: externOnBlur,
  onChange: externOnChange,
  component: Component,
  options: overrideOptions,
  autoFocus,
  raw,
}) => {
  const obj = useObjOrTestator(externObj);
  const {
    domNode,
    showValidationErrors,
    setShowValidationErrors,
    getValidationErrors,
  } = useValidation({
    obj,
    path,
    disabled,
    skipStandardValidation,
    additionalValidations,
  });
  const [value = '', setValue] = useDataBindings({ obj, path, raw, onChange: externOnChange });

  // If options are given from the parent, use them for a
  //   select box.
  let options = overrideOptions || {};

  // If the field type has `values` set, and there are no
  //   override options then it should be rendered as a
  //   select box based on the field type.
  if (!Object.keys(options).length) {
    const fieldType = obj.getField(path);
    const enumOptions = Array.from(fieldType.values || []);

    options = enumOptions.reduce((result, option) => {
      // Only include values that are not disabled, but include
      //   a disabled value if it is already selected somehow.
      const enabled =
        value === option || !fieldType.constructor.DISABLED_SELECTION_TYPES.includes(option);

      if (enabled) {
        result[option] = fieldType.constructor.DISPLAY_NAME_MAP[option] || option;
      }

      return result;
    }, {});
  }

  const onBlur = () => {
    setValue(convertValueOnBlur(value));
    setShowValidationErrors(Boolean(getValidationErrors()[0]));
    externOnBlur();
  };

  const onChange = v => {
    setValue(convertValueForStorage(v));
  };

  const error = showValidationErrors ? getValidationErrors()[0] : '';

  return (
    <Component
      name={path}
      type={type}
      style={style}
      error={error}
      label={label}
      options={options}
      disabled={disabled}
      onBlur={onBlur}
      helperText={helperText}
      onChange={onChange}
      hideEmptyOption={hideEmptyOption}
      dataPathOverride={dataPathOverride}
      value={parseValueForDisplay(value || '')}
      inputPropsModifier={inputPropsModifier}
      inputRef={domNode}
      autoFocus={autoFocus}
    />
  );
};

BoundTextField.propTypes = {
  ...propTypes,
  // Input type to use for the text field
  //   (if not a select field).
  type: PropTypes.string,

  // An object that provides options for the field
  //   instead of those dictated by the field type.
  //   Each key is the value submitted by the form
  //   and each value is what is displayed to the
  //   user.
  options: PropTypes.object,

  // If options are being displayed, whether or not
  //   to show an empty value in addition to the
  //   valid values.
  hideEmptyOption: PropTypes.bool,

  // A value to use for the input element's
  //   `data-path` property. If unset, the
  //   path for the component is used.
  dataPathOverride: PropTypes.string,

  // A function that accepts an object with
  //   properties for the input tag and
  //   returns an object that should be used
  //   for those properties.
  inputPropsModifier: PropTypes.func,

  // The type of component that should be
  //   rendered.
  component: PropTypes.elementType,

  // Parse the input value for display
  //   in the component.
  parseValueForDisplay: PropTypes.func,

  // Convert the value for storage in
  //   the data source.
  convertValueForStorage: PropTypes.func,

  // Callback which is invoked when a
  //   blur event fires on the input
  onBlur: PropTypes.func,

  // Passed through.
  style: PropTypes.object,

  // Passed through.
  autoFocus: PropTypes.bool,
};

BoundTextField.defaultProps = {
  ...defaultProps,
  raw: true,
  onChange: () => {},
  additionalValidations: [],
  style: {},
  type: 'text',
  onBlur: () => null,
  component: PureTextField,
  inputPropsModifier: props => props,
  convertValueOnBlur: value => value,
  parseValueForDisplay: value => value,
  convertValueForStorage: e => e.target.value,
  autoFocus: false,
};

export default BoundTextField;
