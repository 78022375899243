import { createMuiTheme } from '@material-ui/core/styles';

function titleColorWithOpacity(opacity) {
  return `rgba(51, 54, 71, ${opacity})`;
}

const inputBorderWidth = 2;
const bodyColor = '#525252';
const titleColor = titleColorWithOpacity(1); // #333647

export default createMuiTheme({
  typography: {
    fontFamily: ['AvertaPE'],

    subtitle1: { // Willing: Body Intro
      fontSize: 18,
      color: bodyColor,
      lineHeight: '28px',
    },
    body1: { // Willing: Body Medium
      fontSize: 16,
      color: bodyColor,
      lineHeight: '26px',
      textTransform: 'none',
    },
    body2: { // Willing: Body Regular
      fontSize: 14,
      color: bodyColor,
      lineHeight: '24px',
    },

    h2: {
      fontSize: 32,
      fontWeight: 600,
      color: titleColor,
      lineHeight: '42px',
    },
    h3: {
      fontSize: 28,
      fontWeight: 600,
      color: titleColor,
      lineHeight: '39px',
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      color: titleColor,
      lineHeight: '39px',
    },
    h5: {
      fontSize: 22,
      fontWeight: 600,
      color: titleColor,
      lineHeight: '39px',
    },
    h6: {
      fontSize: 18,
      fontWeight: 600,
      color: titleColor,
      lineHeight: '28px',
    },

    // This isn't a supported variant.
    //   Create a class that uses these
    //   rules when needed.
    h1: {
      fontSize: 48,
      fontWeight: 600,
      color: titleColor,
      lineHeight: '54px',
    },
  },
  palette: {
    error: { main: '#ff5959' },
    primary: { main: '#4c84f5' },
    background: { default: '#f4f5f7' },
    success: { main: '#22b67c' },
    text: {
      primary: bodyColor,
      secondary: titleColor,
    },
    custom: {
      border: '#e6e6e6',
      selection: '#3c59ba',
      darkBorder: '#c5cdd5',
      unselected: '#757575',
      disabledBackground: '#94b6f9',
    },
    highlighted: {
      main: '#f2f2f2',
      light: '#fafafa',
    },
    sideTitle: {
      main: '#898a8f',
    },
  },
  shadow: {
    standard: '0 0 13px 0 rgba(0, 0, 0, 0.06)',
  },
  layout: {
    inputHeight: 56,
    headerHeight: 80,
    inputBorderWidth,
    paperPadding: 32,
    leftSideMobileGutter: 24,

    side: {
      mdUp: 64,
      default: 24,
    },

    disabledNode: {
      opacity: 0.6,
      pointerEvents: 'none',
    },
    formField: {
      marginTop: 8,
      marginBottom: 10,
    },
    formFieldHelperText: {
      marginTop: 4,
      fontSize: 14,
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
    },
    sideBySide: {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      alignItems: 'center',
      marginBottom: 20,
    },
  },
  zIndex: {
    above: 900,
    header: 1150,
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: titleColorWithOpacity(0.25),
      },
    },
    MuiFormHelperText: {
      contained: {
        margin: [[8, 0, 0]],
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: inputBorderWidth,
        },
      },
    },
  },
});
