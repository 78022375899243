import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import { useGlobalContext } from 'hooks';
import { getCompanyName } from 'utils';

import './Footer.scss';

const Footer = ({ isOnRegister }) => {
  const {
    clientDataHolder: { isAuthenticated, serverData },
  } = useGlobalContext();
  const year = new Date(Date.now()).getFullYear();

  const className = classNames('footer--authenticationfooter', {
    'footer--fixed': !(isAuthenticated || isOnRegister),
  });
  const companyName = getCompanyName(serverData);

  return (
    <footer className={className}>
      <div className="footer__section">
        <div className="section__item">
          <span className="norton-logo" />
        </div>
        <div className="section__item">
          <span className="mcafee-logo" />
        </div>
      </div>

      <div className="footer__section">
        <div className="section__item">
          Copyright &copy; {year} {companyName}
        </div>
        <div className="section__item">
          <a href="https://willing.com/privacy-policy.html">Privacy Policy</a>
        </div>
        <div className="section__item">
          <a href="https://willing.com/terms.html">Terms of Service</a>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  isOnRegister: PropTypes.bool,
};

Footer.defaultProps = {
  isOnRegister: false,
};

export default observer(Footer);
