import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import AboutYou from 'ui/sections/about-you/pages/AboutYou';

export default class StateMismatchPopup extends React.Component {
  static propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    newState: PropTypes.string.isRequired,
    oldState: PropTypes.string.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      mode: 'initial',
    };
  }

  renderOldCorrect = () => (
    <Popup onCloseClick={this.props.onCloseClick} className="center">
      <h4>Please correct the address</h4>
      <p>
        Your documents have been created for {this.props.oldState}. Please correct the address here
        to reflect the same state.
      </p>
      <PureButton onClick={this.props.onCloseClick} className="button--primary button--pull-right">
        Ok
      </PureButton>
    </Popup>
  );

  renderNewCorrect = () => (
    <Popup onCloseClick={this.props.onCloseClick} className="center">
      <h4>Update your documents</h4>
      <p>
        Your documents were originally created for {this.props.oldState}. If {this.props.newState}{' '}
        is your state of legal residence, you will need to go back and update it so we can make sure
        your documents were created correctly.
      </p>
      <Link to={`${SectionAboutYou.url}${AboutYou.baseUrl}`}>
        <button className="button button--primary button--pull-right">Go back and update</button>
      </Link>
    </Popup>
  );

  renderInitial = () => (
    <Popup onCloseClick={this.props.onCloseClick} className="center">
      <h4>What is your state of legal residence?</h4>
      <p>
        You created your documents for {this.props.oldState}, but you have now entered{' '}
        {this.props.newState}. We need to know the correct state to ensure your documents are
        legally valid.
      </p>

      <div className="button-flow button-flow--popup">
        <PureButton
          onClick={() => this.setState({ mode: 'newCorrect' })}
          className="button--primary button--inline button--pull-right"
        >
          {this.props.newState} is correct
        </PureButton>
        <PureButton
          onClick={() => this.setState({ mode: 'oldCorrect' })}
          className="button--tertiary button--inline button--pull-right"
        >
          {this.props.oldState} is correct
        </PureButton>
      </div>
    </Popup>
  );

  chooseContents = () => {
    switch (this.state.mode) {
      case 'initial':
        return this.renderInitial();
      case 'oldCorrect':
        return this.renderOldCorrect();
      case 'newCorrect':
        return this.renderNewCorrect();
      default:
        return null;
    }
  };

  render() {
    return <div className="popup-fuzz">{this.chooseContents()}</div>;
  }
}
