import { ClientDataObject } from 'models/type-system';
import { WPhoneNumber } from 'models/hoc-types';
import { WBoolean } from 'models/types';

export class Profile extends ClientDataObject {
  static fields = {
    phoneNumber: new WPhoneNumber(),
    needsPasswordReset: new WBoolean(),
  };
}
