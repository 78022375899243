import React from 'react';
import PropTypes from 'prop-types';

import { noOp, getCompanyName } from 'utils';
import Check from 'ui/components/controls/Check';
import PureButton from 'ui/components/pure-controls/PureButton';
import CallbackValidationDisplay from 'ui/components/wizard/CallbackValidationDisplay';
import { API } from 'API';
import SignaturePopup from 'ui/sections/notarize/SignaturePopup';
import { isMobile } from 'react-device-detect';

import './DrawSignatureInput.scss';

const canFullScreen = Boolean(document.documentElement.requestFullscreen);
const canLockOrientation = Boolean(
  window.screen &&
    window.screen.orientation &&
    window.screen.orientation.lock &&
    window.screen.orientation.unlock,
);

export default class DrawSignatureInput extends React.Component {
  static propTypes = {
    testator: PropTypes.object.isRequired,
    submit: PropTypes.func,
    forSpouse: PropTypes.bool,
    loading: PropTypes.bool,
    autoSubmit: PropTypes.bool,
  };

  static defaultProps = {
    forSpouse: false,
    loading: false,
    autoSubmit: false,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showModal: false,
      images: {
        signature: null,
        initials: null,
      },
    };
  }

  componentDidMount() {
    this.getExistingSignatures();
  }

  componentWillUnmount() {
    if (this.lockInterval) {
      window.clearInterval(this.lockInterval);
    }
  }

  getExistingSignatures = async () => {
    const response = await API.getCustomSignature(this.props.forSpouse);
    const { signature, initials } = response.data;
    this.setState({ images: { signature, initials } });
  };

  setImages = newData => {
    this.setState({ images: { ...this.state.images, ...newData } }, () => {
      if (this.props.autoSubmit && this.hasBothImages) {
        this.props.submit(this.state.images.signature, this.state.images.initials);
      }
    });
  };

  tryLock = () => {
    if (!document.fullscreenElement) {
      return;
    }

    if (canLockOrientation) {
      window.screen.orientation.lock('landscape');
    }
    if (this.lockInterval) {
      window.clearInterval(this.lockInterval);
    }
  };

  showModal = () => {
    if (isMobile && canFullScreen) {
      document.documentElement.requestFullscreen().catch(noOp);
    }

    this.lockInterval = window.setInterval(this.tryLock, 100);
    this.setState({ showModal: true });
  };

  hideModal = () => {
    if (isMobile) {
      if (this.lockInterval) {
        window.clearInterval(this.lockInterval);
        this.lockInterval = null;
      }

      // This needs to be executed before fullscreen is disabled, otherwise it fails
      if (canLockOrientation) {
        window.screen.orientation.unlock();
      }

      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }

    this.setState({ showModal: false });
  };

  get hasBothImages() {
    return Boolean(this.state.images.signature && this.state.images.initials);
  }

  validateCallback() {
    if (!this.hasBothImages) {
      return ['Please draw your signature and initials'];
    }
    if (!this.props.testator.acceptedSignature) {
      return ['Please check the box indicating you agree.'];
    }
    return [];
  }

  renderButtons = () => {
    const { signature, initials } = this.state.images;

    if (this.hasBothImages) {
      return (
        <React.Fragment>
          {!this.props.autoSubmit && (
            <PureButton
              onClick={() => this.props.submit(signature, initials)}
              className="button--primary button--space button--nomargin"
              disabled={this.props.loading}
            >
              Continue
            </PureButton>
          )}

          <PureButton onClick={this.showModal} className="button--tertiary button--nomargin">
            Edit Signature
          </PureButton>
        </React.Fragment>
      );
    }

    return (
      <PureButton
        onClick={this.showModal}
        className="button--primary button--space button--nomargin"
        disabled={this.props.loading}
      >
        Create a Signature
      </PureButton>
    );
  };

  renderModal = () => {
    const { showModal } = this.state;

    if (!showModal) {
      return null;
    }

    return <SignaturePopup close={this.hideModal} setImages={this.setImages} />;
  };

  render() {
    const { signature, initials } = this.state.images;

    return (
      <React.Fragment>
        {this.renderModal()}

        <div className="signature-display-flow">
          <div className="flow__box flow__box--signature">
            {signature ? (
              <img alt="Signature" className="signature-img box__signature" src={signature} />
            ) : (
              <div className="signature-img box__signature" />
            )}
            <div className="box__line">Signature</div>
          </div>

          <div className="flow__box">
            {initials ? (
              <img alt="Initials" className="signature-img" src={initials} />
            ) : (
              <div className="signature-img" />
            )}
            <div className="box__line">Initials</div>
          </div>
        </div>

        {this.hasBothImages && (
          <Check obj={this.testator} path="acceptedSignature" centerCheck={true}>
            <div className="small-check-text">
              I agree and intend that the signature or initials above will be my electronic
              signature or initials on each document electronically signed by me this session, and
              understand they will be legally binding as pen-and-paper signatures and initials.
            </div>
            <div className="small-check-text">
              I designate {getCompanyName(this.context.clientDataHolder.serverData, 'owner')}, owner
              and operator of this application, to serve as custodian of my electronic will in
              accordance with Nevada law.
            </div>
          </Check>
        )}

        <CallbackValidationDisplay validateCallback={this.validateCallback.bind(this)} />

        {this.renderButtons()}
      </React.Fragment>
    );
  }
}
