import React from 'react';
import WizardPage from 'ui/components/wizard/WizardPage';

import { SignatureFont, Relationship } from 'models/client-data/enums';
import { API } from 'API';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { getCompanyName } from 'utils';

import Check from 'ui/components/controls/Check';
import PureButton from 'ui/components/pure-controls/PureButton';
import RadioGroup from 'ui/components/wizard/RadioGroup';
import Radio from 'ui/components/controls/Radio';
import CallbackValidationDisplay from 'ui/components/wizard/CallbackValidationDisplay';

import './SignatureSelection.scss';
import { NotarizationStatus } from 'utils/enums';

export default class SignatureSelection extends NotarizationWizardPageMixin(WizardPage) {
  static baseUrl = 'signature/';
  static showPrevNextButtons = false;
  static enterNavigation = false;
  static notarizationSection = NotarizationStatus.SIGNATURE_CREATION;

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectCustom: false,
      loading: false,
    };
  }

  goToNext = async () => {
    if (!this.triggerValidation()) {
      return;
    }
    this.setState({ loading: true });
    try {
      await this.clientDataHolder.persistClientData();
      await API.postNotarizationSignatureCreated(
        this.testator.clientRelationship === Relationship.SPOUSE,
      );
    } catch (error) {
      this.setState({
        loading: false,
        postError: true,
        errorMessage: error.getErrorMessage(),
      });
      return;
    }
    super.goToNext();
  };

  validateCallback = () =>
    !this.testator.acceptedSignature ? ['Please check the box indicating you agree.'] : [];

  renderCustom = () => (
    <div className="signature-selection">
      <h1>Select a signature style.</h1>
      <RadioGroup obj={this.testator} path="signatureFont">
        {SignatureFont.CHOICES.map(choice => (
          <Radio value={choice} key={choice}>
            <span className={choice}>{this.testator.name}</span>
          </Radio>
        ))}
      </RadioGroup>

      <div className="button-flow">
        <PureButton
          onClick={() => this.setState({ selectCustom: false })}
          className="button--tertiary"
        >
          Back
        </PureButton>

        <PureButton
          onClick={() => this.setState({ selectCustom: false })}
          className="button--primary"
        >
          Save and Continue
        </PureButton>
      </div>
    </div>
  );

  renderPage() {
    if (this.state.selectCustom) {
      return this.renderCustom();
    }

    return (
      <div className="vvcenter center info-container info-container--wide">
        <h1>Your Electronic Signature.</h1>
        <p>
          We've created an electronic signature for you. You can continue with this one or choose
          another.
        </p>

        <div className="signature-example-flow">
          <div className="signature-example">
            <span className="label">Signature</span>
            <span className="initial-marker">x</span>
            <span className={this.testator.signatureFont}>{this.testator.name}</span>
          </div>

          <div className="signature-example">
            <span className="label">Initials</span>
            <span className="initial-marker">x</span>
            <span className={this.testator.signatureFont}>{this.testator.initials}</span>
          </div>
        </div>

        <Check obj={this.testator} path="acceptedSignature" centerCheck={true}>
          <div className="small-check-text">
            I agree and intend that the signature or initials above will be my electronic signature
            or initials on each document electronically signed by me this session, and understand
            they will be legally binding as pen-and-paper signatures and initials.
          </div>
          <div className="small-check-text">
            I designate {getCompanyName(this.clientDataHolder.serverData)}, 'owner'), owner and
            operator of this application, to serve as custodian of my electronic will in accordance
            with Nevada law.
          </div>
        </Check>

        <CallbackValidationDisplay validateCallback={this.validateCallback} />

        <PureButton
          onClick={() => this.goToNext()}
          className="button--primary button--space"
          disabled={this.state.loading}
        >
          Continue
        </PureButton>

        <PureButton
          onClick={() => this.setState({ selectCustom: true })}
          className="button--tertiary"
        >
          Choose another
        </PureButton>
      </div>
    );
  }
}
