import React from 'react';

import WizardPage from 'ui/components/wizard/WizardPage';

import WuiApp from '@/WuiApp';
import PersonalDetails from '@s/about-you/PersonalDetails';

export default class AboutYou extends WizardPage {
  static baseUrl = '';

  static customDisplay() {
    return () => (
      <WuiApp wrapContent={false}>
        <PersonalDetails />
      </WuiApp>
    );
  }
}
