import React from 'react';
import { Redirect } from 'react-router-dom';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import WizardPage from 'ui/components/wizard/WizardPage';
import { API } from 'API';

import WizardRow from 'ui/components/wizard/WizardRow';
import Input from 'ui/components/controls/Input';
import AddressInput from 'ui/components/AddressInput';
import DateSelect from 'ui/components/wizard/DateSelect';
import DrawSignatureInput from 'ui/sections/notarize/DrawSignatureInput';
import CallbackValidationDisplay from 'ui/components/wizard/CallbackValidationDisplay';

import './NotaryDetails.scss';

export default class NotaryDetails extends WizardPage {
  static baseUrl = '';

  componentDidMount() {
    super.componentDidMount();
    this.updateSealImage();
  }

  updateSealImage = async () => {
    const { data } = await API.getNotarySeal();

    this.setState({ data });
  };

  get notary() {
    return this.clientDataHolder.roles.notary || {};
  }

  goToNext = () => {
    this.clientData.address.setRawValue('zip', this.clientData.address.zip);

    super.goToNext();
  };

  validateCallback = () => {
    if (this.clientDataHolder.hasNotaryPermission) {
      if (!this.notary.signatureUrl) {
        return ['Please ensure your signature has been uploaded.'];
      } else if (!this.notary.hasSigningDevice) {
        return ['Please ensure your signing device has been set.'];
      }
    }

    return [];
  };

  submitSignature = async (signature, initials) => {
    try {
      await API.createCustomSignature(signature, initials, this.constructor.forSpouse);
    } catch (error) {
      this.setState({
        postError: true,
        errorMessage: error.getErrorMessage(),
      });
    }
  };

  renderUploadedImage(attribute) {
    const url = this.notary[attribute];

    if (url) {
      return <img alt="Signature" src={url} className="seal" />;
    }

    return 'Not yet uploaded. This must be done manually by a developer.';
  }

  renderPage() {
    if (!this.clientDataHolder.hasNotaryPermission && !this.clientDataHolder.hasWitnessPermission) {
      return <Redirect to={SectionAboutYou.url} />;
    }

    return (
      <div className="notary-details">
        <h3>Personal Details</h3>

        <WizardRow label="Full Name">
          <Input obj={this.clientData} path="notarizationStaff.person.name" />
        </WizardRow>

        <WizardRow label="Address of residence">
          <AddressInput obj={this.clientData} path="notarizationStaff.person.address" />
        </WizardRow>

        {this.clientDataHolder.hasNotaryPermission && (
          <React.Fragment>
            <WizardRow label="Commission Number">
              <Input
                obj={this.clientData}
                path="notarizationStaff.commissionNumber"
                maxLength={127}
              />
            </WizardRow>

            <WizardRow label="Commission Expiration Date">
              <DateSelect
                obj={this.clientData}
                path="notarizationStaff.commissionExpirationDate"
                future={true}
              />
            </WizardRow>

            <WizardRow label="Seal Preview">
              <img
                alt="Notary Seal"
                src={this.state.data}
                onClick={this.updateSealImage}
                className="seal"
              />
            </WizardRow>

            <WizardRow label="Digital Signature">
              {this.renderUploadedImage('signatureUrl')}
            </WizardRow>
          </React.Fragment>
        )}

        {this.clientDataHolder.hasWitnessPermission && (
          <WizardRow label="Digital Signature">
            <DrawSignatureInput
              testator={this.testator}
              submit={this.submitSignature}
              autoSubmit={true}
            />
          </WizardRow>
        )}

        <CallbackValidationDisplay validateCallback={this.validateCallback} />
      </div>
    );
  }
}
