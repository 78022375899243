// Based on: https://bit.ly/2WtcaTb

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { StripeProvider } from 'react-stripe-elements';

export default class AsyncStripeProvider extends Component {
  static propTypes = {
    apiKey: PropTypes.string.isRequired,
  };

  state = { stripe: null };

  componentDidMount() {
    this._mounted = true;
    const { apiKey } = this.props;

    const stripeJs = document.createElement('script');
    stripeJs.src = 'https://js.stripe.com/v3/';
    stripeJs.async = true;
    stripeJs.onload = () => {
      if (this._mounted) {
        this.setState({ stripe: window.Stripe(apiKey) });
      }
    };

    if (document.body) {
      document.body.appendChild(stripeJs);
    }
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  render() {
    const { stripe } = this.state;

    return <StripeProvider stripe={stripe}>{this.props.children}</StripeProvider>;
  }
}
