import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { API } from 'API';
import Theme from '@willing-shared/WuiTheme';
import Countdown from '@wui/basics/countdown';
import Typography from '@wui/basics/typography';

const styles = theme => ({
  root: {
    padding: [[10, 0]],
    position: 'relative',
    background: theme.palette.blue.banner,
    boxShadow: theme.customShadows.standard,
    borderBottom: [[1, 'solid', theme.palette.grey.bannerBorder]],
  },
  textContainer: {
    marginLeft: 8,

    [theme.breakpoints.phone]: {
      marginLeft: 0,
      textAlign: 'center',
    },
  },
  closeButton: {
    right: 75,
    top: '50%',
    padding: 20,
    fontSize: 30,
    cursor: 'pointer',
    position: 'absolute',
    transform: 'translateY(-50%)',
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,

    [theme.breakpoints.phone]: {
      right: 0,
      top: -20,
      transform: 'none',
    },
  },
});

class ExplodingDiscountBanner extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    onFinish: PropTypes.func,
  };

  static defaultProps = {
    onFinish: () => null,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
  };

  constructor(...args) {
    super(...args);

    this.state = { visible: !this.expired };
  }

  get serverData() {
    return this.context.clientDataHolder.serverData;
  }

  get expiresAt() {
    return new Date(this.serverData.explodingDiscountExpiresAt);
  }

  get discountPercent() {
    return this.serverData.explodingDiscountPercentOff;
  }

  get expired() {
    // Use the server time to compare against for a
    //   more accurate representation of whether or
    //   not the discount is expired.
    return API.getTime() > this.expiresAt.getTime();
  }

  hide = () => {
    this.setState({ visible: false });
    this.props.onFinish();
  };

  render() {
    const { classes } = this.props;
    const { visible } = this.state;

    if (!visible) {
      return null;
    }

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        alignContent="center"
        className={classes.root}
      >
        <div onClick={this.hide} className={classes.closeButton}>
          &#x2715;
        </div>

        <Grid item>
          <Countdown onFinish={this.hide} end={Number(this.expiresAt)} />
        </Grid>
        <Grid item xs={12} sm="auto" className={classes.textContainer}>
          <Typography component="span" color="textPrimary">
            <strong>Save {this.discountPercent}%</strong>
            &nbsp;on any bundle -&nbsp;
            <strong>expires soon</strong>
          </Typography>
          <Typography component="span" variant="caption" color="textPrimary">
            You can still make changes after payment
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const StyledExplodingDiscountBanner = withStyles(styles)(ExplodingDiscountBanner);

export default props => (
  <Theme>
    <StyledExplodingDiscountBanner {...props} />
  </Theme>
);
