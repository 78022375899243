import React from 'react';
import PropTypes from 'prop-types';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import { refresh } from 'utils';

export default class NewVersionPopup extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
  };

  render() {
    if (!this.props.open) {
      return null;
    }

    return (
      <div className="popup-fuzz">
        <Popup onCloseClick={refresh} showCloseIcon={false} className="center">
          <h4>A new version of the app is available</h4>
          <p className="small">
            Please refresh your browser or press the button below to continue.
          </p>

          <div className="button-flow button-flow--right">
            <PureButton onClick={refresh} className="button--primary">
              Continue
            </PureButton>
          </div>
        </Popup>
      </div>
    );
  }
}
