import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { STATES } from 'utils';
import BoundTextField from '@c/BoundTextField';

const StateSelector = ({ addressObject, abbreviated, ...props }) => {
  const options = useMemo(
    () =>
      !abbreviated
        ? STATES
        : Object.keys(STATES).reduce((r, v) => {
            r[v] = v;
            return r;
          }, {}),
    [abbreviated],
  );

  return (
    <BoundTextField path="state" label="State" options={options} obj={addressObject} {...props} />
  );
};

StateSelector.propTypes = {
  // The address that will be modified.
  addressObject: PropTypes.object.isRequired,

  // Whether or not the display should
  //   show the state abbreviation.
  abbreviated: PropTypes.bool,
};

export default StateSelector;
