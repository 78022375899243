import React from 'react';
import PropTypes from 'prop-types';
import BundleDetails, { bundleShape } from 'material-ui/pages/checkout-v6/BundleDetails';
import Grid from '@wui/layout/grid';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import CompareIcon from '@a/images/checkout-v6/compare.svg';

const CompareBundles = ({ bundles, selectBundle, toggleCompareOptions }) => (
  <Grid container direction="row" wrap="nowrap">
    <Grid item>
      <Grid container direction="row" wrap="nowrap" alignItems="stretch">
        {Object.values(bundles).map((bundle, idx) => (
          <BundleDetails key={bundle.id} selectBundle={() => selectBundle(idx, true)} {...bundle} />
        ))}
      </Grid>
    </Grid>
    <Spacer h={12} />
    <Grid item shrink="0">
      <Spacer v={20} />
      <Button variant="outlined" noMinWidth size="small" onClick={toggleCompareOptions} transparent>
        <img alt="" src={CompareIcon} />
        <Spacer inline h={8} />
        Hide Options
      </Button>
    </Grid>
  </Grid>
);

CompareBundles.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.shape(bundleShape)),
  selectBundle: PropTypes.func.isRequired,
  toggleCompareOptions: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
};

export default CompareBundles;
