import React from 'react';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { withCurrentPageClass } from 'utils/withCurrentPageClass';

// This component is used to hide the Zendesk message button on specific
// pages where it interferes with the UI
export class ZendeskHolder extends withCurrentPageClass(ClientDataReferer) {
  get shouldHide() {
    let currentPageClass = this.currentPageClass;
    return currentPageClass && currentPageClass.hideZendesk;
  }

  // Zendesk attaches a zE object to the window that we can use to update
  // the appearance of the widget.
  get zE() {
    const noopFn = () => ({});
    const canUseWindow = window.zE && window.zE.show && window.zE.hide;

    return canUseWindow ? window.zE : { hide: noopFn, show: noopFn };
  }

  evaluate() {
    if (this.shouldHide) {
      this.zE.hide();
    } else {
      this.zE.show();
    }
  }

  componentDidMount() {
    super.componentDidMount();

    this.evaluate();
  }

  componentDidUpdate() {
    this.evaluate();
  }

  render() {
    return null;
  }
}
