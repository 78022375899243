import React from 'react';

import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { ProgressSections } from 'material-ui/components/progress';
import { Tips, Tip } from 'material-ui/components/tips';

import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup';
import BoundRadioGroup from 'material-ui/components/BoundRadioGroup';

export default class ProbateChoice extends MuiWizardPage {
  static progressPage = ProgressSections.YOUR_WISHES.ASSETS;
  static baseUrl = 'probate/';

  get label() {
    const itThem = this.testator.homeOrHomes === 'home' ? 'it' : 'them';
    return `When it comes time to transfer your ${this.testator.homeOrHomes}, would you like ${itThem} to go
        directly to your beneficiaries, skipping the probate court process?`;
  }

  renderPage() {
    const subHeading = this.testator.isMarried ? this.testator.name : '';
    return (
      <React.Fragment>
        <Tips>
          <Tip title="Probate Avoidance">
            <p>
              The probate court process is long, expensive, and a hassle for beneficiaries. Most
              people choose "Yes" here.
            </p>

            <p>In some rare cases, people prefer court supervision.</p>
          </Tip>
        </Tips>

        <SpacedFieldGroup masterHeading="Probate Avoidance" subHeading={subHeading}>
          <BoundRadioGroup path="avoidProbate" label={this.label} />
        </SpacedFieldGroup>
      </React.Fragment>
    );
  }
}
