import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import MuiLink from '@material-ui/core/Link';

import { andList } from 'utils';
import Link from '@wui/basics/link';
import Modal from '@wui/basics/modal';
import Panel from '@wui/layout/panel';
import Button from '@wui/input/button';
import DotRow from '@wui/layout/dotRow';
import Spacer from '@wui/layout/spacer';
import Dropdown from '@wui/input/dropdown';
import PanelTitle from '@wui/layout/panelTitle';
import TabDivider from '@wui/layout/tabDivider';
import Typography from '@wui/basics/typography';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import BequestStep from './BequestStep';
import { AssetType, ShareDistribution } from 'models/client-data/enums';
import { AlternateBeneficiary, PrimaryBeneficiary } from 'models/client-data/assets';

import AssetEditor from './AssetEditor';
import BeneficiaryChooser from './BeneficiaryChooser';

import { ReactComponent as TrashIcon } from '@a/images/trash.svg';

const STEP_ACTIVE = 1;
const STEP_PENDING = 0;
const STEP_COMPLETE = 2;

const DEFAULT_TITLE = 'Gift';

// FIXME: Do this using react.context eventually. Didn't
//   for now because the SVG loader was acting funny.
const ICONS = {
  [AssetType.ART]: require('-!svg-react-loader!@a/images/asset-art.svg'),
  [AssetType.OTHER]: require('-!svg-react-loader!@a/images/asset-other.svg'),
  [AssetType.CASH_GIFT]: require('-!svg-react-loader!@a/images/asset-cash.svg'),
  [AssetType.VEHICLE]: require('-!svg-react-loader!@a/images/asset-vehicle.svg'),
  [AssetType.JEWELRY]: require('-!svg-react-loader!@a/images/asset-jewelry.svg'),
  [AssetType.BUSINESS]: require('-!svg-react-loader!@a/images/asset-business.svg'),
  [AssetType.RESIDUAL]: require('-!svg-react-loader!@a/images/asset-residual.svg'),
  [AssetType.REAL_ESTATE]: require('-!svg-react-loader!@a/images/asset-real-estate.svg'),
  [AssetType.BANK_ACCOUNT]: require('-!svg-react-loader!@a/images/asset-bank-account.svg'),
  [AssetType.LIFE_INSURANCE]: require('-!svg-react-loader!@a/images/asset-bank-account.svg'),
  [AssetType.INVESTMENT_ACCOUNT]: require('-!svg-react-loader!@a/images/asset-bank-account.svg'),
};

class Bequest extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    step: PropTypes.number.isRequired,
    asset: PropTypes.object.isRequired,
    width: PropTypes.string.isRequired,
    updateStep: PropTypes.func.isRequired,
    visibleAssets: PropTypes.array.isRequired,
  };

  static defaultProps = {
    disabled: false,
  };

  static contextTypes = {
    section: PropTypes.object,
    testator: PropTypes.object,
  };

  constructor(...args) {
    super(...args);

    let subStep = null;
    const { step, asset } = this.props;
    const choosingBackups = step === BequestStep.CHOOSE_BACKUPS;

    // Figure out if we should start on a substep based
    //   on the current state of the asset.
    this.primaryBeneficiaries.forEach((b, i) => {
      // Only set the subStep when choosing backups,
      const setSubStep =
        choosingBackups &&
        // a previous substep hasn't been chosen,
        subStep === null &&
        // and the share distribution for the
        //   alternates is not valid.
        !ShareDistribution.valid(b.shareDistribution, b.alternateBeneficiaries);

      if (setSubStep) {
        subStep = i;
      }
    }, {});

    // When loading a bequest, we need to know if a primary beneficiary
    //   has skipped adding alternates so we can highlight the 'None'
    //   option in the beneficiary chooser. If freshlyAddedRealEstate
    //   is set, then this is a real estate asset with a pre-populated
    //   primary beneficiary, which we don't want to assume has been
    //   skipped.
    const skippedAlternates = asset.freshlyAddedRealEstate ? {} : this.skippedAlternatesFromAsset;

    this.state = {
      subStep,
      skippedAlternates,
      breakoutAlternates: [],
      forceEditButtons: false,
    };
  }

  get skippedAlternatesFromAsset() {
    return this.primaryBeneficiaries.reduce((result, b) => {
      result[b.name] = !b.alternateBeneficiaries.length;
      return result;
    }, {});
  }

  get residualTitle() {
    const { testator } = this.context;

    const testatorHasSpecificGifts = testator.ownAssets.some(
      a =>
        // Non real estate assets are always considered
        //   broken out.
        !a.isRealEstate ||
        // If the real estate asset doesn't have an
        //   address, it is a placeholder, so it
        //   is considered broken out.
        !a.hasAddress ||
        // If the asset has a beneficiary, then it
        //   was broken out.
        testator.getPrimaryBeneficiariesForAsset(a).length,
    );

    if (testatorHasSpecificGifts) {
      return 'the rest of my assets';
    }

    return 'all of my assets';
  }

  get isResidual() {
    const { asset } = this.props;
    return asset.type === AssetType.RESIDUAL;
  }

  title(descriptive = false) {
    const { asset } = this.props;

    if (this.isResidual) {
      return this.residualTitle;
    }

    const text = descriptive ? asset.fullAssetDescription : AssetType.DISPLAY_NAME_MAP[asset.type];

    return text || DEFAULT_TITLE;
  }

  get inlineTitle() {
    const { asset, step } = this.props;

    if (this.isResidual) {
      return this.residualTitle;
    } else if (step === BequestStep.REVIEW) {
      const description = asset.fullAssetDescription;
      return `${description.charAt(0).toLowerCase()}${description.slice(1)}`;
    }

    return 'this gift';
  }

  get icon() {
    const { asset } = this.props;

    return ICONS[asset.type] || ICONS[AssetType.OTHER];
  }

  get preventDelete() {
    const { asset } = this.props;
    const { testator } = this.context;
    const { residualAsset } = testator;

    // The residual can never be deleted.
    if (this.isResidual) {
      return true;
    }

    // Anything remaining other than real estate
    //   can always be deleted.
    return (
      asset.isRealEstate &&
      // If it does not have an address yet,
      //   then the user is in the process of
      //   selecting the asset, so it can
      //   still be removed.
      asset.hasAddress &&
      // If the residual asset doesn't have any
      //   beneficiaries selected yet, then a
      //   real estate asset can still be removed.
      testator.getPrimaryBeneficiariesForAsset(residualAsset).length &&
      // If real estate needs to be broken out
      //   because of the residual selection,
      //   then real estate can't be deleted.
      this.realEstateNeedsBreakout
    );
  }

  enableEditing = () => {
    this.setState({ forceEditButtons: true });
  };

  cancelEditing = event => {
    event.preventDefault();
    this.setState({ forceEditButtons: false });
  };

  deleteBequest = eventOrOption => {
    const { testator } = this.context;
    const { asset, step, updateStep } = this.props;

    if (eventOrOption.preventDefault) {
      eventOrOption.preventDefault();
    }

    // We never actually delete a real estate
    //   asset from the interface. That needs
    //   to be done from the real estate page.
    if (asset.isRealEstate) {
      testator.removeBequestsFromAsset(asset);

      // Otherwise, we remove it.
    } else {
      testator.assets.splice(testator.assets.indexOf(asset), 1);
    }

    updateStep(asset, step);
  };

  get dropdownMenu() {
    const options = [
      {
        label: 'Edit',
        onClick: this.enableEditing,
      },
    ];

    if (!this.preventDelete) {
      options.push({
        label: 'Delete',
        onClick: this.deleteBequest,
      });
    }

    return (
      <Dropdown
        label="Edit"
        options={options}
        linkProps={{ role: 'button', 'aria-role': 'button' }}
      />
    );
  }

  get right() {
    const { asset, step, visibleAssets, width } = this.props;
    const { forceEditButtons } = this.state;
    const parts = [];

    // The residual and smaller screens never show the
    //   counter.
    if (!this.isResidual && isWidthUp('sm', width)) {
      const index = visibleAssets.indexOf(asset) + 1;

      parts.push(
        <span>
          {/* Subtract one for the residual asset. */}
          {index} of {visibleAssets.length - 1}
        </span>,
      );
    }

    // If they've already started editing, but
    //   haven't chosen a step yet, then they
    //   can still cancel.
    if (forceEditButtons) {
      parts.push(
        <Link href="#" color="inherit" onClick={this.cancelEditing} role="button">
          Save
        </Link>,
      );

      // In review mode, we always show the
      //   dropdown because there could be
      //   multiple available options.
    } else if (step === BequestStep.REVIEW) {
      parts.push(this.dropdownMenu);

      // Otherwise, show a delete button if
      //   the asset can be deleted because
      //   they are already editing.
    } else if (!this.preventDelete) {
      parts.push(
        <Link href="#" color="inherit" onClick={this.deleteBequest} role="button">
          <TrashIcon />
          Delete
        </Link>,
      );
    }

    // Add a separator between each component.
    const separatedParts = parts.reduce((result, part, i) => {
      result.push(part);

      if (i !== parts.length - 1) {
        result.push(<Spacer h={40} content={<span>&bull;</span>} />);
      }

      return result;
    }, []);

    return (
      <Grid container spacing={0} justify="center" alignItems="center">
        {separatedParts.map((p, i) => (
          <Grid key={i} item>
            {p}
          </Grid>
        ))}
      </Grid>
    );
  }

  renderDefineAssetStep() {
    const { forceEditButtons } = this.state;
    const { asset, step, updateStep } = this.props;

    // This step isn't shown in review mode, but it
    //   is if we are showing the edit button so that
    //   the user can redefine the asset if need be.
    if (step === BequestStep.REVIEW && !forceEditButtons) {
      return null;
    }

    const stepTitle = this.renderStepTitle({
      step: BequestStep.DEFINE_ASSET,
      completeText: `${this.title(true)}.`,
    });

    if (stepTitle) {
      return stepTitle;
    }

    return (
      <AssetEditor
        asset={asset}
        updateStep={updateStep}
        realEstateBeneficiary={this.realEstateBeneficiary}
      />
    );
  }

  setStep = (step, subStep) => event => {
    const { asset, updateStep } = this.props;

    if (event) {
      event.preventDefault();
    }

    updateStep(asset, step);

    // Once a step is selected, then the edit
    //   buttons should be hidden so that they
    //   don't appear the next time we are in
    //   review mode.
    this.setState({ forceEditButtons: false, subStep });
  };

  stepValue(step, subStep) {
    // Return a numeric value for each step and
    //   substep so that it is easy to compare
    //   steps and get the current state.
    return step * 1000 + (subStep || 0);
  }

  stepState(step, subStep = null) {
    const { step: currentStep } = this.props;
    const { subStep: currentSubStep } = this.state;
    const stepValue = this.stepValue(step, subStep);
    const currentStepValue = this.stepValue(currentStep, currentSubStep);

    if (stepValue === currentStepValue) {
      return STEP_ACTIVE;
    } else if (stepValue < currentStepValue) {
      return STEP_COMPLETE;
    }

    return STEP_PENDING;
  }

  shareDistributionFromBeneficiaries(beneficiaries) {
    return beneficiaries.every(b => b.share === ShareDistribution.EQUAL_SHARES)
      ? ShareDistribution.EQUAL_SHARES
      : ShareDistribution.CUSTOM;
  }

  generateBeneficiaries = (beneficiaries, beneficiaryClass) => {
    const { testator } = this.context;

    return beneficiaries.map(b => {
      const beneficiary = b.person.basePerson(true);
      beneficiary.owner = testator.ownerString;

      if (b.share !== ShareDistribution.EQUAL_SHARES) {
        beneficiary.sharePercentage = b.share;
      }

      const instance = new beneficiaryClass(beneficiary);

      if (beneficiaryClass === PrimaryBeneficiary && b.alternates) {
        this.setAlternateBeneficiaries(instance, b.alternates, false, true);
      }

      return instance;
    });
  };

  setPrimaryBeneficiaries(asset, beneficiaries, proceed = true) {
    const { testator } = this.context;
    let applicableBeneficiaries = beneficiaries;
    const { asset: bequestAsset, step, updateStep } = this.props;
    const currentBeneficiaries = testator.getPrimaryBeneficiariesForAsset(asset);

    // If the asset is real estate and there are no people selected,
    //   make sure a placeholder is still in the list so that the
    //   asset doesn't disappear.
    if (asset.isRealEstate && !beneficiaries.filter(b => b.person).length) {
      applicableBeneficiaries = [
        {
          person: this.realEstateBeneficiary,
          share: ShareDistribution.EQUAL_SHARES,
        },
      ];
    }

    const newShareDistribution = this.shareDistributionFromBeneficiaries(applicableBeneficiaries);

    const nothingChanged =
      asset[testator.assetShareDistributionKey] === newShareDistribution &&
      currentBeneficiaries.length === applicableBeneficiaries.length &&
      applicableBeneficiaries.every(b =>
        currentBeneficiaries.find(
          c =>
            [c.sharePercentage, ShareDistribution.EQUAL_SHARES].includes(b.share) &&
            b.person.personUUID === c.personUUID,
        ),
      );

    // If nothing changed, then we don't want to replace the
    //   beneficiaries because doing so would clear the alternates
    //   that may have already been set for the next step.
    if (!nothingChanged) {
      testator.removeBequestsFromAsset(asset);
      asset.setRawValue(testator.assetShareDistributionKey, newShareDistribution);
      asset.primaryBeneficiaries.push(
        ...this.generateBeneficiaries(applicableBeneficiaries, PrimaryBeneficiary),
      );

      // If we are setting the beneficiaries for this bequest
      //   then we need to reset the state as well. This is
      //   not done for other assets that we set beneficiaries
      //   for because the state is handled internally to
      //   those bequests.
      if (asset === bequestAsset) {
        this.setState({ skippedAlternates: {}, subStep: 0 });
      }
    }

    // By default, go to the next step.
    let nextStep = BequestStep.CHOOSE_BACKUPS;

    // A falsey value means stay on the current
    //   step.
    if (!proceed) {
      nextStep = step;

      // Any other truthy value is a specific
      //   step to go to.
    } else if (proceed !== true) {
      nextStep = proceed;
    }

    updateStep(asset, nextStep);
  }

  breakoutRealEstateAssets(alternates) {
    const { testator } = this.context;

    // Remove the spouse if they were set as a primary because
    //   they can't be a backup for themselves in the breakout.
    const applicableAlternates = alternates.filter(
      a => a.person.personUUID !== testator.spouse.personUUID,
    );
    const oneAlternateRemaining = applicableAlternates.length === 1;

    // If the spouse was removed and there were specific shares
    //   set, then we need to have the user choose the shares
    //   for the breakout because it can't be implied. However,
    //   if there is only one alternate left, we don't need to
    //   do anything special because the only option is for
    //   remaining alternate to have a full share distribution.
    const changeAlternates =
      alternates.length !== applicableAlternates.length &&
      !alternates.every(a => a.share === ShareDistribution.EQUAL_SHARES) &&
      !oneAlternateRemaining;

    // If the alternates were modified, then don't put
    //   the bequest into review mode so that the user
    //   can make share selections.
    const stepForBreakout = changeAlternates ? BequestStep.CHOOSE_BACKUPS : BequestStep.REVIEW;

    // If there is only one alternate left, make sure they
    //   have an equal share distribution because nothing
    //   else makes sense.
    if (oneAlternateRemaining) {
      applicableAlternates[0].share = ShareDistribution.EQUAL_SHARES;

      // Otherwise, if we changed the alternates by removing
      //   the spouse, we can't infer what shares the person
      //   wanted because we removed the spouse, so set them
      //   to null, which indicates to the user that they
      //   must choose.
    } else if (changeAlternates) {
      applicableAlternates.forEach(a => (a.share = null));
    }

    const breakouts = testator.ownAndJointRealEstates
      // If the property is already broken out, then don't change
      //   the beneficiary since it must already be the spouse and
      //   the user might have selected alternates.
      .filter(r => !testator.getPrimaryBeneficiariesForAsset(r).length);

    // Set the alternates for the spouse to the primary that was selected
    //   for the residual. This way, the user can continue with the residual
    //   instead of needing to edit the new broken out asset.
    const breakoutSpouse = this.spouseBeneficiary;
    breakoutSpouse.alternates = applicableAlternates;

    breakouts.forEach(r => this.setPrimaryBeneficiaries(r, [breakoutSpouse], stepForBreakout));

    // Only show the modal if something was broken out,
    //   there is no need to do this if the user already
    //   had their assets bequeathed properly.
    if (breakouts.length) {
      this.setState({ breakoutAlternates: applicableAlternates });
    }
  }

  get spouseBeneficiary() {
    const { testator } = this.context;

    return {
      person: testator.spouse,
      share: ShareDistribution.EQUAL_SHARES,
    };
  }

  get realEstateNeedsBreakout() {
    const { testator } = this.context;
    const { isMarried, residualAsset, spouse } = testator;
    const primaryBeneficiaries = testator.getPrimaryBeneficiariesForAsset(residualAsset);

    return (
      isMarried &&
      (primaryBeneficiaries.length !== 1 ||
        primaryBeneficiaries[0].personUUID !== spouse.personUUID)
    );
  }

  replacePrimaryBeneficiaries = (beneficiaries, proceed = true) => {
    const { asset } = this.props;

    this.setPrimaryBeneficiaries(asset, beneficiaries, proceed);

    if (this.isResidual && this.realEstateNeedsBreakout && beneficiaries.length) {
      this.breakoutRealEstateAssets(beneficiaries);
    }
  };

  forcePrimaryToSpouse = () => {
    this.replacePrimaryBeneficiaries([this.spouseBeneficiary]);
  };

  setAlternateBeneficiaries = (
    primary,
    beneficiaries,
    proceed = true,
    skipStateUpdates = false,
  ) => {
    const { skippedAlternates } = this.state;
    const { asset, step, updateStep } = this.props;

    primary.alternateBeneficiaries.length = 0;
    skippedAlternates[primary.name] = !beneficiaries.length;
    primary.setRawValue(
      'shareDistribution',
      this.shareDistributionFromBeneficiaries(beneficiaries),
    );
    primary.alternateBeneficiaries.push(
      ...this.generateBeneficiaries(beneficiaries, AlternateBeneficiary),
    );

    if (skipStateUpdates) {
      return;
    }

    let nextStep = proceed ? BequestStep.REVIEW : step;

    const stateUpdates = { skippedAlternates };
    const nextSubStep = this.primaryBeneficiaries.indexOf(primary) + 1;

    if (proceed && nextSubStep < this.primaryBeneficiaries.length) {
      stateUpdates.subStep = nextSubStep;
      nextStep = BequestStep.CHOOSE_BACKUPS;
    }

    this.setState(stateUpdates);
    updateStep(asset, nextStep);
  };

  distributionString(shareDistribution, beneficiaries, emptyText = null) {
    if (!beneficiaries.length) {
      return emptyText || 'be distributed according to applicable law';
    } else if (shareDistribution === ShareDistribution.EQUAL_SHARES) {
      return andList(beneficiaries.map(b => b.name));
    }

    return andList(beneficiaries.map(b => `${b.name} (${b.sharePercentage}%)`));
  }

  get primaryDistributionString() {
    const { asset } = this.props;
    const { testator } = this.context;
    const shareDistribution = asset[testator.assetShareDistributionKey];

    return this.distributionString(shareDistribution, this.primaryBeneficiaries);
  }

  renderStepTitle({ step, subStep, subtitle, pendingText, completeText, forceStepComplete }) {
    const { forceEditButtons } = this.state;
    const { step: currentStep } = this.props;
    const stepState = forceStepComplete ? STEP_COMPLETE : this.stepState(step, subStep);

    // Active steps only show the content of the step,
    //   not the step itself.
    if (stepState === STEP_ACTIVE) {
      return null;
    }

    const text = stepState === STEP_PENDING ? pendingText : completeText;

    // If there is no title, then the content is
    //   shown too.
    if (!text) {
      return null;
    }

    const reviewMode = currentStep === BequestStep.REVIEW;
    const showEditButton =
      !forceStepComplete && (forceEditButtons || (!reviewMode && stepState === STEP_COMPLETE));

    const variant = reviewMode || !forceStepComplete ? 'intro' : 'h6';

    const color = 'initial';

    const editLinkProps = {
      href: '#',
      onClick: this.setStep(step, subStep),
      role: 'button',
    };

    let typographyProps = {};
    if (showEditButton) {
      typographyProps = {
        ...editLinkProps,
        underline: 'none',
        color: 'textSecondary',

        // MuiLink is used because using the WUI
        //   link would color the text blue, which
        //    we don't want in this case.
        component: MuiLink,
        role: 'button',
      };
    }

    return (
      <div>
        {/*
                    This extra `div` (above) helps center the dotRow
                    dot properly. Without it, the Grid (below) spacing
                    would cause the position to be incorrect.
                */}

        <Grid
          container
          spacing={1}
          wrap="nowrap"
          alignItems="center"
          alignContent="center"
          justify="space-between"
        >
          <Grid item>
            <Typography color={color} variant={variant} {...typographyProps}>
              {text}
            </Typography>

            {subtitle && !reviewMode && <Typography color={color}>{subtitle}</Typography>}
          </Grid>
          {showEditButton && (
            <Grid item>
              <Typography>
                <Link {...editLinkProps}>Edit</Link>
              </Typography>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }

  get primaryBeneficiaries() {
    const { asset } = this.props;
    const { testator } = this.context;

    return testator.getPrimaryBeneficiariesForAsset(asset, false);
  }

  get forceSpouse() {
    const { asset } = this.props;
    const { testator } = this.context;

    // Real estate assets must have their primary
    //   beneficiary as the spouse if the testator
    //   is married.
    return testator.isMarried && asset.isRealEstate;
  }

  get editPrimariesStepSubtitle() {
    const { testator } = this.context;

    if (!this.forceSpouse) {
      return null;
    }

    return `As your spouse, ${testator.spouse.firstName} is first in line to receive your real estate.`;
  }

  renderEditPrimariesStep() {
    const { asset } = this.props;
    const { testator } = this.context;

    const distribution = this.forceSpouse ? testator.spouse.name : this.primaryDistributionString;
    const titlePrefix = `I leave ${this.inlineTitle} to`;
    const completeText = `${titlePrefix} ${distribution}.`;

    // Generally, we want to use the current step state to
    //   render a title, but when forcing the spouse, want
    //   to be able to get the same content that is displayed
    //   when rendering a step that is pending even though
    //   the step is complete.
    const stepTitleGenerator = forceStepComplete =>
      this.renderStepTitle({
        completeText,
        forceStepComplete,
        step: BequestStep.EDIT_PRIMARIES,
        subtitle: this.editPrimariesStepSubtitle,
        pendingText: this.forceSpouse ? completeText : 'Name Beneficiary',
      });

    const stepTitle = stepTitleGenerator();

    if (stepTitle) {
      return stepTitle;
    }

    if (this.forceSpouse) {
      return (
        <React.Fragment>
          {stepTitleGenerator(true)}

          <Spacer v={16} />

          <Button
            color="primary"
            variant="contained"
            onClick={this.forcePrimaryToSpouse}
            className="bequest-continue-button"
          >
            Continue
          </Button>
        </React.Fragment>
      );
    }

    return (
      <BeneficiaryChooser
        excludedNames={[this.context.testator.name]}
        asset={asset}
        title={`${titlePrefix}:`}
        saveBequest={this.saveBequest}
        beneficiaries={this.primaryBeneficiaries}
        onSelection={this.replacePrimaryBeneficiaries}
        shareDistribution={asset[testator.assetShareDistributionKey]}
      />
    );
  }

  chooseBackupsStep(subStep, primary) {
    const nonResidual = !this.isResidual;
    const { primaryBeneficiaries } = this;
    const { asset, step: currentStep } = this.props;
    const { forceEditButtons, skippedAlternates } = this.state;
    const theirShare = primaryBeneficiaries.length > 1 ? `${primary.names} share of` : '';
    const thisGift = this.isResidual ? this.residualTitle : 'this gift';
    const titlePrefix = `If ${primary.name} passes away before me, I leave ${theirShare} ${thisGift} to`;
    const distributionString = this.distributionString(
      primary.shareDistribution,
      primary.alternateBeneficiaries,
      nonResidual && 'be distributed with the rest of my assets',
    );

    const title = `Backup for ${primary.firstName} (optional)`;
    const completeText = `${titlePrefix} ${distributionString}.`;
    const pendingText = primary.alternateBeneficiaries.length ? completeText : title;

    const stepTitle = this.renderStepTitle({
      subStep,
      pendingText,
      completeText,
      step: BequestStep.CHOOSE_BACKUPS,
    });

    if (stepTitle) {
      const showContentForMissingAlternates =
        // If there are altrenates, the step is shown.
        primary.alternateBeneficiaries.length ||
        // In review mode, we may hide the step if
        //   the other conditions do not apply.
        currentStep !== BequestStep.REVIEW ||
        // No matter what, we show the step if
        //   edit buttons are forced.
        forceEditButtons ||
        // A residual asset that does not have
        //   any alternates should not show the
        //   step.
        nonResidual;

      return showContentForMissingAlternates ? stepTitle : null;
    }

    return (
      <BeneficiaryChooser
        asset={asset}
        title={title}
        subtitle={`${titlePrefix}:`}
        saveBequest={this.saveBequest}
        excludedNames={[primary.name, this.context.testator.name]}
        shareDistribution={primary.shareDistribution}
        onSelection={this.alternatesSelected(primary)}
        beneficiaries={primary.alternateBeneficiaries}
        skipped={skippedAlternates[primary.name] || false}
        isLastAlternate={subStep === primaryBeneficiaries.length - 1}
        otherBeneficiaries={primaryBeneficiaries.filter(b => b !== primary)}
      />
    );
  }

  alternatesSelected = primary => (beneficiaries, proceed) => {
    this.setAlternateBeneficiaries(primary, beneficiaries, proceed);
  };

  renderChooseBackupsStepPlaceholder() {
    return this.renderStepTitle({
      pendingText: 'Backups (optional)',
      step: BequestStep.CHOOSE_BACKUPS,
    });
  }

  get realEstateBeneficiary() {
    const { testator } = this.context;
    const { ownerString: owner } = testator;

    // Since the spouse must be the beneficiary
    //   for a real estate asset, don't add an
    //   empty placeholder. This prevents a
    //   partial save of a beneficiary without
    //   a name.
    const base = testator.isMarried ? testator.spouse.basePerson(true) : {};

    return new PrimaryBeneficiary({ ...base, owner });
  }

  get steps() {
    const steps = [];

    // There is nothing to define for the
    //   residual asset.
    if (!this.isResidual) {
      steps.push({
        step: BequestStep.DEFINE_ASSET,
        content: this.renderDefineAssetStep(),
      });
    }

    // Every asset must have a primary beneficiary.
    steps.push({
      step: BequestStep.EDIT_PRIMARIES,
      content: this.renderEditPrimariesStep(),
    });

    // If steps prior to choosing backups are active, then we don't know
    //   how many alternate steps we need because the primaries are not
    //   selected yet, so we show a placeholder.
    if (this.stepState(BequestStep.CHOOSE_BACKUPS) === STEP_PENDING) {
      steps.push({
        step: BequestStep.CHOOSE_BACKUPS,
        content: this.renderChooseBackupsStepPlaceholder(),
      });

      // Otherwise, each primary beneficiary gets a step
      //   to choose the alternates.
    } else {
      this.primaryBeneficiaries.forEach((beneficiary, subStep) =>
        steps.push({
          subStep,
          step: BequestStep.CHOOSE_BACKUPS,
          content: this.chooseBackupsStep(subStep, beneficiary),
        }),
      );
    }

    // Only return steps that actually have content.
    return steps.filter(stepDescriptor => stepDescriptor.content);
  }

  stepDescriptorKey(stepDescriptor) {
    return `${stepDescriptor.step}-${stepDescriptor.subStep}`;
  }

  rowClickable(active, step) {
    const { asset } = this.props;
    const { testator } = this.context;

    // Get the step that we can currently
    //   navigate until based on the current
    //   state of the asset. The review step
    //   is not included because it doesn't
    //   have a visible row for iteself.
    const maxStep = Math.min(BequestStep.CHOOSE_BACKUPS, BequestStep.fromAsset(asset, testator));

    // If the step is active, then there is
    //   no need for it to be clickable.
    //   Otherwise, it is clickable if the
    //   step is not after the most available
    //   step.
    return !active && step <= maxStep;
  }

  rowClicked = (active, step, subStep) => {
    if (!this.rowClickable(active, step)) {
      return null;
    }

    return this.setStep(step, subStep);
  };

  renderEditStep = stepDescriptor => {
    const active = this.stepState(stepDescriptor.step, stepDescriptor.subStep) === STEP_ACTIVE;

    return (
      <DotRow
        active={active}
        key={this.stepDescriptorKey(stepDescriptor)}
        onClick={this.rowClicked(active, stepDescriptor.step, stepDescriptor.subStep)}
      >
        {stepDescriptor.content}
      </DotRow>
    );
  };

  renderReviewStep = (stepDescriptor, index, steps) => {
    const addDivider = index !== steps.length - 1;

    return (
      <React.Fragment key={this.stepDescriptorKey(stepDescriptor)}>
        {stepDescriptor.content}

        {addDivider && <TabDivider dashed halfMargin />}
      </React.Fragment>
    );
  };

  renderSteps() {
    const { step } = this.props;
    const renderer = step !== BequestStep.REVIEW ? this.renderEditStep : this.renderReviewStep;

    return this.steps.map(renderer);
  }

  closeBreakoutModal = () => {
    this.setState({ breakoutAlternates: [] });
  };

  renderBreakoutModal() {
    const { testator } = this.context;
    const { breakoutAlternates } = this.state;

    const beneficiaryWord = breakoutAlternates.length > 1 ? 'beneficiaries' : 'beneficiary';
    const newBackups = andList(breakoutAlternates.map(a => a.person.name));

    return (
      <Modal title="Your Real Estate" open={breakoutAlternates.length > 0}>
        <Typography align="center" variant="superhero">
          As your spouse, {testator.spouse.firstName} is first in line to receive your real estate.
          You've selected to leave all of your property (including real estate) to someone other
          than {testator.spouse.firstName}.
          <br />
          <br />
          In this case, we automatically create a specific gift leaving the real estate to{' '}
          {testator.spouse.firstName} first. {testator.spouse.firstNames} backup {beneficiaryWord}{' '}
          will be {newBackups}.
          <Spacer v={32} />
          <Button color="primary" variant="contained" onClick={this.closeBreakoutModal}>
            Continue
          </Button>
        </Typography>
      </Modal>
    );
  }

  saveBequest = () => {
    const { asset, updateStep } = this.props;

    this.setState({ skippedAlternates: this.skippedAlternatesFromAsset });

    updateStep(asset, BequestStep.REVIEW);
  };

  get isLastAlternate() {
    const { step } = this.props;
    const { subStep } = this.state;

    // stepValue is used to normalize the subStep.
    return (
      this.stepValue(step, subStep) ===
      this.stepValue(BequestStep.CHOOSE_BACKUPS, this.primaryBeneficiaries.length - 1)
    );
  }

  renderSaveCallToAction() {
    const { asset, step } = this.props;
    const { testator } = this.context;

    // While being reviewed, there is no need for the button.
    const hide =
      step === BequestStep.REVIEW ||
      // If the bequest can not be in the review state (meaning a
      //   step is not complete), hide the button also.
      BequestStep.fromAsset(asset, testator, true) !== BequestStep.REVIEW ||
      // If the asset hasn't been persisted yet, then don't show
      //   it in the primary selection step because it could be
      //   confusing to have two CTAs.
      (!asset.pk && step === BequestStep.EDIT_PRIMARIES) ||
      // If we are choosing backups for the last primary
      //   beneficiary, then the CTA is rendered by the
      //   beneficiary chooser because and additional action
      //   is added.
      this.isLastAlternate;

    if (hide) {
      return null;
    }

    return (
      <React.Fragment>
        <TabDivider expanded halfMargin />

        <Typography align="right" component="div">
          <Button
            color="primary"
            variant="contained"
            onClick={this.saveBequest}
            className="bequest-save-button"
          >
            Save
          </Button>
        </Typography>
      </React.Fragment>
    );
  }

  render() {
    const { disabled } = this.props;
    const {
      section: { nextPending: persisting },
    } = this.context;

    return (
      <Panel
        noOverflow
        lessPadding
        disabled={disabled || persisting}
        className="bequest"
        aria-live="polite"
      >
        <PanelTitle icon={this.icon} right={this.right}>
          {this.title(false)}
        </PanelTitle>

        <div className="bequest-steps">{this.renderSteps()}</div>

        {this.renderSaveCallToAction()}

        {this.renderBreakoutModal()}
      </Panel>
    );
  }
}

export default withWidth()(Bequest);
