import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import sentry from '@willing-shared/utils/sentry';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import VideoSession from 'ui/execution/VideoSession';
import { API } from 'API';
import Card from 'ui/components/Card';
import RecordingIndicator from 'ui/execution/RecordingIndicator';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import DocumentImageViewer from 'ui/components/document-image-viewer/DocumentImageViewer';
import CallRoomMixin from 'ui/sections/notarize/pages/CallRoomMixin';

import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
export default class WitnessCallRoom extends CallRoomMixin(ClientDataReferer) {
  fetchedSession = false;

  static propTypes = {
    title: PropTypes.string,
  };
  constructor(props, context) {
    super(props, context);

    this.state = {
      sessionReady: false,
      session: null,
      cancelCall: false,
      showWitness1Confirmation: true,
      showWitness2Confirmation: true,
    };
  }

  get signatureRequested() {
    const { session } = this.state;
    return session && session.signingStatus === `${this.props.match.params.position}_requested`;
  }

  get signatureApplied() {
    const { session } = this.state;

    return session && `${this.props.match.params.position}_signed` === session.signingStatus;
  }
  get requestedWitness() {
    if (!this.state.session) {
      return 0;
    }

    const { signingStatus } = this.state.session;

    if (signingStatus === 'witness1_requested' || signingStatus === 'witness1_signed') {
      return 1;
    } else if (signingStatus === 'witness2_requested' || signingStatus === 'witness2_signed') {
      return 2;
    }

    return 0;
  }

  get popupStateKey() {
    if (!this.requestedWitness) {
      return null;
    }
    return this.requestedWitness === 1 ? 'showWitness1Confirmation' : 'showWitness2Confirmation';
  }

  get shouldShowConfirmationPopup() {
    if (!this.popupStateKey) {
      return false;
    }

    return this.signatureApplied && this.state[this.popupStateKey];
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }
  signDocuments = () => {
    API.postAdminAdvanceStep(this.sessionId, 'primary');
  };

  updateSession = async () => {
    const response = await API.getNotarizationAdminSession(this.sessionId);

    this.setState({
      session: response.data,
    });

    if (!this.fetchedSession) {
      this.fetchedSession = true;
      try {
        const position = this.props.match.params.position;
        const tokenResponse = await API.getNotarizationAdminToken(this.sessionId, position);
        this.setState({
          token: tokenResponse.data.token,
          sessionReady: true,
        });
      } catch (e) {
        sentry.captureException(e);
        this.fetchedSession = false;
      }
    }
  };

  get clientAndSpouseName() {
    const { session } = this.state;

    if (session.includesSpouse) {
      return `${session.clientName} and ${session.spouseName}`;
    }

    return session.clientName;
  }

  render() {
    const { sessionReady, session, cancelCall } = this.state;

    if (
      session &&
      !session.opentokIsArchiving &&
      (session.status === 'completed' || session.status === 'failed')
    ) {
      return <Redirect to="/witness/" />;
    }

    const { position } = this.props.match.params;
    const witnessNumber = position[position.length - 1];

    return (
      <div className="cardwrapper cardwrapper_full_height">
        {this.signatureRequested && (
          <div className="popup-fuzz">
            <Popup showCloseIcon={false} className="popup--top-padding popup--signature">
              <h4>Please say the following out loud:</h4>
              <p>
                I, {this.clientData.notarizationStaff.person.name}, just saw{' '}
                {this.clientAndSpouseName} sign these documents, and I'm now signing as a witness.
              </p>
              <PureButton onClick={this.signDocuments} className="button--primary">
                Sign all documents
              </PureButton>
            </Popup>
          </div>
        )}

        {cancelCall && this.renderCancelCallPopup()}

        {this.shouldShowConfirmationPopup && (
          <div className="popup-fuzz">
            <Popup showCloseIcon={false} className="popup--top-padding popup--signature">
              <h4>Signature successfully applied!</h4>
              <p>Your signature has been successfully applied to all of the documents.</p>
              <PureButton
                onClick={() =>
                  this.setState({
                    [this.popupStateKey]: false,
                  })
                }
                className="button--primary"
              >
                Close
              </PureButton>
            </Popup>
          </div>
        )}
        <Card className="card--fixed-height">
          <div className="document-view">
            <div className="top top--admin">
              <div className="left">
                {session && <DocumentImageViewer session={session} forAdmin={true} />}
              </div>
              <div className="right">
                {session && session.status === 'ongoing' && session.opentokIsArchiving && (
                  <RecordingIndicator />
                )}
                {sessionReady && (
                  <VideoSession
                    sessionId={this.state.session.opentokSessionId}
                    token={this.state.token}
                    name={`Witness ${witnessNumber}`}
                  />
                )}
              </div>
            </div>

            <div className="bottom">
              <PureButton onClick={this.cancelCall} className="button--red">
                Cancel Call
              </PureButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
