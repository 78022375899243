import { WEnum } from 'models/types';
import { ShareDistribution } from 'models/client-data/enums';

export default class BequestStep extends WEnum {
  static DEFINE_ASSET = 1;
  static EDIT_PRIMARIES = 2;
  static CHOOSE_BACKUPS = 3;
  static REVIEW = 4;

  constructor() {
    super('', [
      BequestStep.DEFINE_ASSET,
      BequestStep.EDIT_PRIMARIES,
      BequestStep.CHOOSE_BACKUPS,
      BequestStep.REVIEW,
    ]);
  }

  static fromAsset(asset, testator, ignorePlaceholders = false) {
    // If the asset is missing any fields that are needed,
    //   then the user must define it.
    if (!asset.hasAllRequiredFields) {
      return BequestStep.DEFINE_ASSET;
    }

    const primaryBeneficiaries = testator.getPrimaryBeneficiariesForAsset(
      asset,
      ignorePlaceholders,
    );

    // If there are no primary beneficiaries, then
    //   the user must select them.
    if (!primaryBeneficiaries.length) {
      return BequestStep.EDIT_PRIMARIES;

      // If there are primaries, but they are in an invalid
      //   state, then the user must edit them.
    } else if (
      !ShareDistribution.valid(asset[testator.assetShareDistributionKey], primaryBeneficiaries)
    ) {
      return BequestStep.EDIT_PRIMARIES;
    }

    // There might not be any alternates for each primary, but if there
    //   are, they need to have all required information.
    const alternatesAreIncomplete = primaryBeneficiaries.some(
      b => !ShareDistribution.valid(b.shareDistribution, b.alternateBeneficiaries),
    );

    // If there are any incomplete alternates,
    //   then the user should edit them. Otherwise,
    //   the asset has been bequeathed fully and
    //   it can just be reviewed.
    return alternatesAreIncomplete ? BequestStep.CHOOSE_BACKUPS : BequestStep.REVIEW;
  }
}
