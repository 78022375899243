import React from 'react';
import PropTypes from 'prop-types';

import { rootClassList } from '@u/dom';

const prefix = 'experiment-';

// Experiments can be enabled by using the globally
//   defined `window.enableExperiment` function. It
//   ensures that the experiment can be loaded when
//   the page first loads or asynchronously.

export class ExperimentProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = {
    experiments: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: this.googleOptimizeIsLoaded(),
      enabled: this.rootClassListExperiments,
    };

    if (!this.state.loaded) {
      this.pollGoogleOptimize();
    }
  }

  getChildContext() {
    return {
      experiments: {
        loaded: this.state.loaded,
        enabled: this.state.enabled,
        isEnabled: this.isEnabled.bind(this),
        actionPagesOnly: this.isEnabled('action-pages-only'),
        bennyAddressPostCheckout: this.isEnabled('beneficiary-address-post-checkout'),
        aboutYouAnimation: this.isEnabled('about-you-animation'),
        delayedMaritalStatus: this.isEnabled('delayed-marital-status'),
        lifeInsuranceStatus: this.isEnabled('life-insurance-status'),
        incomeRange: this.isEnabled('income-range'),
        redirectToReview: this.isEnabled('redirect-to-review'),

        // This delay is used in some places when triggering
        //   an experiment.
        delay: 500,
      },
    };
  }

  // Google Optimize uses a class name on the root
  //   HTML element to indicate whether or not the
  //   script has finished loading. This script
  //   must be placed as early as possible in the
  //   content of the page, so it is part of the
  //   HTML served by the backend, and not part of
  //   the frontend app code.
  googleOptimizeIsLoaded() {
    return !rootClassList().contains('async-hide');
  }

  pollGoogleOptimize() {
    this.pollTimeout = setTimeout(() => {
      if (!this.googleOptimizeIsLoaded()) {
        this.pollGoogleOptimize();
        return;
      }

      this.setLoaded();
      clearTimeout(this.pollTimeout);
    }, 100);
  }

  isEnabled(experiment) {
    return this.state.enabled.indexOf(experiment) >= 0;
  }

  get enabledText() {
    return this.state.enabled.join(',');
  }

  // Load experiments from the root HTML element since they
  //   might be enabled by legacy experiments.
  get rootClassListExperiments() {
    return this.standardizeList(Array.from(rootClassList()), true);
  }

  setLoaded() {
    this.setState({ loaded: true, enabled: this.rootClassListExperiments });
  }

  setExperiments(event) {
    this.setState({
      enabled: this.standardizeList(event.target.value.split(',').map(v => v.trim())),
    });
  }

  standardizeList(list, prefixedOnly = false) {
    function distinct(value, index, self) {
      return self.indexOf(value) === index;
    }

    return list
      .filter(e => !prefixedOnly || e.startsWith(prefix))
      .map(e => e.replace(prefix, ''))
      .filter(distinct);
  }

  monitorForExperimentUpdates(inputRef) {
    if (inputRef) {
      inputRef.addEventListener('input', this.setExperiments.bind(this));
    }
  }

  render() {
    // Wait until experiments are loaded to render the page.
    if (!this.state.loaded) {
      return null;
    }

    return (
      <React.Fragment>
        <input
          id="experiment-list"
          style={{ display: 'none' }}
          defaultValue={this.state.enabledText}
          ref={this.monitorForExperimentUpdates.bind(this)}
        />
        {this.props.children}
      </React.Fragment>
    );
  }
}
