import React, { useState, useEffect } from 'react';
import { useGlobalContext } from 'hooks';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import OpenTokPublisher from 'material-ui/components/OpenTokPublisher';
import { getDevices } from '../utils';

import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import ButtonFooter from '../ButtonFooter';
import Failed from './Failed';

const useStyles = makeStyles(theme => ({
  content: {
    height: 'calc(100 % - 220px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  audio: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bar: {
    margin: '0px 2px 0px 8px',
    borderRadius: '10px',
    display: 'inline-block',
    width: 10,
  },
  filled: {
    background: theme.gradients.special,
  },
  unfilled: {
    backgroundColor: theme.palette.background.default,
  },
}));

const Fields = ({ goToNext }) => {
  const { clientData, updateClientData } = useGlobalContext();
  const [audioDevices, setAudioDevices] = useState(null);
  const [audioDeviceIndex, setAudioDeviceIndex] = useState(null);
  const [audioLevel, setAudioLevel] = useState(0);
  const [isSuccess, setIsSuccess] = useState(null);
  const [publisherOptions, setPublisherOptions] = useState(null);

  const classes = useStyles();

  const incrementDeviceIndex = () => {
    setAudioDeviceIndex(v => (v === null ? 0 : v + 1));
  };

  const publisherInitHandler = publisher => {
    publisher.on('audioLevelUpdated', e => {
      setAudioLevel((e.audioLevel * 10).toFixed(0));
    });
  };

  useEffect(() => {
    const initTechCheck = async () => {
      const devices = await getDevices();
      const foundAudioDevices = devices.filter(d => d.kind === 'audioInput');

      return foundAudioDevices;
    };

    initTechCheck().then(newDevices => {
      setAudioDevices(newDevices);
      incrementDeviceIndex();
    });
  }, []);

  useEffect(() => {
    if (!audioDevices || !audioDevices.length || audioDeviceIndex === null) {
      return;
    }

    const audioDevice = audioDevices[audioDeviceIndex];

    setPublisherOptions({
      insertMode: 'append',
      height: '0',
      width: '0',
      audioSource: audioDevice.deviceId,
      videoSource: false,
      style: { audioLevelDisplayMode: 'on' },
    });
  }, [audioDevices, audioDeviceIndex]);

  useEffect(() => {
    if (isSuccess) {
      goToNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  // Used to determine the height of the bars for the volume indicator that
  // is shown to the user. level is a representation of the volume level
  // and is an int between 0 and 9 inclusive. The bars increase in height
  // towards the middle volume levels.
  const calcHeight = level => {
    const distance = Math.abs(4.5 - level);
    return 100 - distance * 10;
  };

  const acceptSource = () => {
    const source = audioDevices[audioDeviceIndex].deviceId;

    updateClientData(() => {
      clientData.setRawValue('acceptedAudioSource', source);
    });

    setIsSuccess(true);
  };

  const rejectSource = () => {
    if (audioDeviceIndex < audioDevices.length - 1) {
      incrementDeviceIndex();
    } else {
      setIsSuccess(false);
    }
  };

  const retry = () => {
    setIsSuccess(null);
    setAudioDeviceIndex(0);
  };

  if (isSuccess === false) {
    return <Failed retry={retry} />;
  }

  return (
    <Grid container direction="row" wrap="nowrap" justify="center">
      <Grid container direction="column" alignItems="center">
        <Typography variant="h4">Repeat, "Testing, testing, 1, 2, 3..."</Typography>

        {publisherOptions && (
          <OpenTokPublisher options={publisherOptions} initHandler={publisherInitHandler} />
        )}

        <Spacer v={24} />

        <div className={classes.content}>
          <div className={classes.audio}>
            {Object.keys([...new Array(10)]).map(i => {
              const style = {
                height: calcHeight(i),
              };
              return (
                <span
                  key={i}
                  style={style}
                  className={`${classes.bar} ${
                    audioLevel >= i ? classes.filled : classes.unfilled
                  }`}
                />
              );
            })}
          </div>
        </div>

        <Spacer v={8} />
        <Typography variant="body1">LISTENING FOR AUDIO</Typography>
        <Spacer v={24} />

        <ButtonFooter
          prompt="When you speak, do you see the bars above light up?"
          acceptAction={acceptSource}
          rejectAction={rejectSource}
        />
      </Grid>
    </Grid>
  );
};

Fields.propTypes = {
  goToNext: PropTypes.func.isRequired,
};

export default observer(Fields);
