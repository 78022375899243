import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import withWidth from '@material-ui/core/withWidth';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { AppLocation } from 'utils/enums';
import Fab from '@wui/input/fab';
import WuiTheme from '@willing-shared/WuiTheme';
import Panel from '@wui/layout/panel';
import Layout from '@c/layout/Layout';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Textbox from '@wui/input/textbox';
import Dropdown from '@wui/input/dropdown';
import BoundTextField from '@c/BoundTextField';
import RadioGroup from '@wui/input/radioGroup';
import Typography from '@wui/basics/typography';
import CustomIcon from '@wui/basics/customIcon';
import CloseButton from '@wui/basics/closeButton';
import GenericError from '@wui/basics/genericError';
import PurePersonChooser from '@c/pure/PurePersonChooser';
import { NotifiedPerson } from 'models/client-data/agents';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import renderWuiSaveButton from '@c/layout/renderWuiSaveButton';
import Table, { SPACING as TABLE_SPACING } from '@wui/layout/table';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';
import {
  NotifiedPersonPermissions,
  NotifiedPersonNotificationStatus,
  NotifiedPersonStatus,
} from 'models/client-data/enums';

import Share from '@a/images/share-no-bottom-bar.png';
import { ReactComponent as Pending } from '@a/images/pending.svg';
import { ReactComponent as Warning } from '@a/images/warning.svg';
import { ReactComponent as GreenTick } from '@a/images/green-tick.svg';

import EmailPreview from './EmailPreview';

const STATUS_ICON_WIDTH = 16;
export const RECOMMENDED_NOTIFICATIONS = 5;
const PHONE_ICON_INDENT = STATUS_ICON_WIDTH + TABLE_SPACING;

// We need to use this to prevent the entire
//   cell from being hidden, which happens when
//   the content is falsey.
const EMPTY_COLUMN = <span />;

const PERMISSIONS_OPTIONS = [
  [NotifiedPersonPermissions.ACCESS, 'Full Access', 'Gives full access to view your plan'],
  [
    NotifiedPersonPermissions.NOTIFY,
    'Notify Only',
    "Notifies that you've made a plan but does not give access to it",
  ],
];
const SORT_ORDER = [
  NotifiedPersonNotificationStatus.DECLINED,
  NotifiedPersonNotificationStatus.PENDING,
  NotifiedPersonNotificationStatus.SENT,
  NotifiedPersonNotificationStatus.CONFIRMED,
  NotifiedPersonNotificationStatus.NOT_SENT,
];

class Notify extends React.Component {
  static propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  static contextTypes = {
    testator: PropTypes.object,
    clientDataHolder: PropTypes.object,
    experiments: PropTypes.object,
  };

  constructor(...args) {
    super(...args);

    this.beneficiaries = this.testator.ownBeneficiaries;

    // When planning for spouse, don't give the option
    //   to notify them by default since they already
    //   know.
    if (this.clientData.isPlanningForSpouse) {
      this.beneficiaries = this.beneficiaries.filter(
        b => b.personUUID !== this.testator.spouse.personUUID,
      );
    }

    this.roles = Object.entries({
      Executor: this.testator.primaryExecutor,
      Guardian: this.testator.primaryGuardian,
      'Backup Executor': this.testator.alternateExecutor,
      'Backup Guardian': this.testator.alternateGuardian,
      'Healthcare Representative': this.testator.primaryHealthcareAgent,
      'Backup Healthcare Representative': this.testator.alternateHealthcareAgent,
    }).reduce((result, [role, person]) => {
      if (person) {
        result[person.personUUID] = result[person.personUUID] || {};
        result[person.personUUID][role] = person;
      }

      return result;
    }, {});

    const peopleWithRoles = Object.values(this.roles).reduce((result, roles) => {
      result.push(Object.values(roles)[0]);
      return result;
    }, []);

    // Get or create a new NotifiedPerson for each
    //   of the people we want to display. New
    //   records are only stored in memory and will
    //   not be persisted unless the user desires.
    const notifiedPeople = [
      ...peopleWithRoles,
      // Don't duplicate the person if they are
      //   already added by having a role.
      ...this.beneficiaries.filter(p => !Object.keys(this.roles).includes(p.personUUID)),
    ].map(this.getOrBuildNotifiedPerson);

    // Any person from the list above that doesn't have
    //   a primary key has not been notified yet, so
    //   store their records for display.
    const unnotifiedPeople = notifiedPeople.filter(p => !p.pk);

    this.state = {
      error: false,
      unnotifiedPeople,
      previewPerson: null,
      additionalPerson: null,
      selectedPersonUUID: null,
      additionalPersonFocused: false,
      additionalPersonAttempted: false,
    };
  }

  get testator() {
    return this.context.testator;
  }

  get clientDataHolder() {
    return this.context.clientDataHolder;
  }

  get clientData() {
    return this.clientDataHolder.clientData;
  }

  get clientDataPristine() {
    return this.clientDataHolder.clientDataPristine;
  }

  get isProcessing() {
    return this.clientDataHolder.isLoading;
  }

  // Return all NotifiedPerson records (persisted and pending)
  //   in the order that they should be displayed in.
  get notifiedPeople() {
    const { additionalPerson, unnotifiedPeople } = this.state;

    return [...this.testator.ownNotifiedPeople, ...unnotifiedPeople, additionalPerson]
      .filter(this.filterNotifiedPeople)
      .sort(this.sortNotifiedPeople);
  }

  filterNotifiedPeople(person, index, list) {
    // This can happen if the additional person
    //   is not set.
    if (!person) {
      return false;
    }

    // When adding a new notified person, there is
    //   a short amount of time when the person is
    //   duplicated in the list. To prevent this
    //   from rendering improperly, we always show
    //   only the last instance of the person. This
    //   works because the already persisted
    //   notified people are always the first in the
    //   list.
    return list.lastIndexOf(person) === index;
  }

  statusSortOrder(person) {
    return SORT_ORDER.indexOf(person.notificationStatus);
  }

  // Sort the notified people, using the following rules:
  //   - The additional person is always last.
  //   - Sort by persisted (has a primary key)
  //     vs. in-memory.
  //   - Then, sort by if they have a role or not.
  //   - Then, sort by if they are a beneficiary
  //     or not.
  //   - Then, sort by status.
  //   - Then, sort by name.
  sortNotifiedPeople = (a, b) => {
    const { additionalPerson } = this.state;

    if (additionalPerson && a.personUUID === additionalPerson.personUUID) {
      return 1;
    }

    // Persisted vs. in-memory.
    if (!a.pk && b.pk) {
      return 1;
    }

    if (a.pk && !b.pk) {
      return -1;
    }

    // Role.
    const aRoleCount = this.rolesForPerson(a).length;
    const bRoleCount = this.rolesForPerson(b).length;

    if (!aRoleCount && bRoleCount) {
      return 1;
    }

    if (aRoleCount && !bRoleCount) {
      return -1;
    }

    // Beneficiary.
    const aBeneficiary = this.beneficiaries.find(p => p.personUUID === a.personUUID);
    const bBeneficiary = this.beneficiaries.find(p => p.personUUID === b.personUUID);

    if (!aBeneficiary && bBeneficiary) {
      return 1;
    }

    if (aBeneficiary && !bBeneficiary) {
      return -1;
    }

    // Status - effective person is used so
    //   that changing an already persisted
    //   person does not alter the order of
    //   the list until the person is saved.
    const aOrder = this.statusSortOrder(this.effectivePerson(a));
    const bOrder = this.statusSortOrder(this.effectivePerson(b));

    if (aOrder < bOrder) {
      return -1;
    }

    if (aOrder > bOrder) {
      return 1;
    }

    // Name.
    if (a.name < b.name) {
      return -1;
    }

    if (a.name > b.name) {
      return 1;
    }

    return 0;
  };

  // If a person has a role, then default them
  //   to ACCESS permission (it can be changed
  //   by the user). Otherwise, they can only
  //   have the NOTIFY permission.
  defaultPermissionsForPerson(person) {
    return Object.keys(this.roles).includes(person.personUUID)
      ? NotifiedPersonPermissions.ACCESS
      : NotifiedPersonPermissions.NOTIFY;
  }

  getOrBuildNotifiedPerson = person =>
    this.testator.ownNotifiedPeople.find(p => p.personUUID === person.personUUID) ||
    this.buildNotifiedPerson(person);

  buildNotifiedPerson(person = {}) {
    return new NotifiedPerson({
      ...person,
      owner: this.testator.ownerString,
      permissions: this.defaultPermissionsForPerson(person),
      nameOverride: person.nameOverride || person.name || '',
    });
  }

  isSelected(person) {
    const { selectedPersonUUID } = this.state;
    return selectedPersonUUID === person.personUUID;
  }

  isAdditionalPerson(person) {
    const { additionalPerson } = this.state;
    return additionalPerson && additionalPerson.personUUID === person.personUUID;
  }

  // If a person is persisted, return the persisted data
  //   for that person. Otherwise, return the person that
  //   was passed as an argument. This is useful to prevent
  //   status and ordering changes when a person is changed.
  effectivePerson(person) {
    if (!person.pk) {
      return person;
    }

    return this.clientDataPristine.notifiedPeople.find(
      p => p.personUUID === person.personUUID && p.owner === person.owner,
    );
  }

  rolesForPerson(person, includeBeneficiary = false) {
    const roles = Object.keys(this.roles[person.personUUID] || {});

    if (includeBeneficiary && this.beneficiaries.find(b => b.personUUID === person.personUUID)) {
      roles.push('Beneficiary');
    }

    return roles.sort();
  }

  renderHeader() {
    return (
      <Grid container spacing={1} wrap="wrap-reverse" alignItems="center">
        <Grid item sm={7} xs={12}>
          <Spacer v={56} xsDown mdUp />

          <Typography variant="h2">Alert your executors and agents</Typography>
          <Typography variant="body1">
            Agents need to know that you have a plan, what it is, and how to carry it out. Executors
            need access at the very minimum. If they don't have access, they will not be able to
            carry out your plan.
          </Typography>
        </Grid>
        <Grid item sm={5} xs={12}>
          <DimensionLimiter h="100%">
            <img alt="" src={Share} />
          </DimensionLimiter>
        </Grid>
      </Grid>
    );
  }

  renderStatusIcon(person) {
    let iconSrc = Pending;
    let color = null; // Use the default color of the icon.

    switch (person.notificationStatus) {
      case NotifiedPersonNotificationStatus.CONFIRMED:
      case NotifiedPersonNotificationStatus.SENT:
        iconSrc = GreenTick;
        break;
      case NotifiedPersonNotificationStatus.PENDING:
        color = theme => theme.palette.blue.textboxFocus;
        break;
      case NotifiedPersonNotificationStatus.DECLINED:
        iconSrc = Warning;
        break;
      default:
        if (this.isSelected(person)) {
          color = theme => theme.palette.blue.textboxFocus;
        }
        break;
    }

    return <CustomIcon color={color} src={iconSrc} block={this.isPhoneDisplay ? 'inline' : true} />;
  }

  renderNameAndRole(person) {
    const effectivePerson = this.effectivePerson(person);
    const [nameDisplay, rolesDisplay] = this.isAdditionalPerson(person)
      ? ['Add a person', 'Tell us their details']
      : [person.name, this.rolesForPerson(person, true).join(', ')];

    return (
      <Typography
        component="div"
        variant="caption"
        indentLinesAfterFirst={this.isPhoneDisplay ? PHONE_ICON_INDENT : null}
      >
        <Typography variant="h6">
          {this.isPhoneDisplay && (
            <React.Fragment>
              {this.renderStatusIcon(effectivePerson)}
              <Spacer inline h={8} />
            </React.Fragment>
          )}
          {nameDisplay}
        </Typography>

        {rolesDisplay}
      </Typography>
    );
  }

  renderStatus(person) {
    if (this.isAdditionalPerson(person)) {
      return EMPTY_COLUMN;
    }

    const effectivePerson = this.effectivePerson(person);

    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item>{this.renderStatusIcon(effectivePerson)}</Grid>
        <Grid item>
          <Typography>{NotifiedPersonStatus.displayName(effectivePerson)}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderAccess(person) {
    if (this.isAdditionalPerson(person)) {
      return EMPTY_COLUMN;
    }

    const access = person.pk
      ? NotifiedPersonPermissions.displayName(this.effectivePerson(person).permissions)
      : NotifiedPersonPermissions.UNSET_DISPLAY_NAME;

    return <Typography>{access}</Typography>;
  }

  selectPerson = (person = null, setAdditional = false) => () => {
    const { selectedPersonUUID } = this.state;

    // If there is a person selected, then selecting another
    //   needs to reset it to the persisted state to prevent
    //   saving data that was not intended to be saved.
    if (selectedPersonUUID) {
      const currentlySelected = this.notifiedPeople.find(p => p.personUUID === selectedPersonUUID);

      // If the person is already persisted, then getting
      //   the data from the effective person is sufficient.
      //   Otherwise, the person is in-memory and the only
      //   things that could be changed by the user are the
      //   email and permissions, so set those to their
      //   default values.
      const resetUpdates = currentlySelected.pk
        ? this.effectivePerson(currentlySelected).toPOJO()
        : {
            email: null,
            permissions: this.defaultPermissionsForPerson(currentlySelected),
          };

      currentlySelected.updateInPlace(resetUpdates);
    }

    const updates = {
      error: false,
      additionalPersonFocused: false,
      additionalPersonAttempted: false,
      selectedPersonUUID: person ? person.personUUID : null,
    };

    // Set the additional person to null unless we are
    //   adding an additional person.
    updates.additionalPerson = person && setAdditional ? this.buildNotifiedPerson(person) : null;

    this.setState(updates);
  };

  changePermission = person => value => {
    // Both of these must be changed in order
    //   to display and persist correctly.
    person.setRawValue('permissions', value);
    person.permissions = value;

    // This is usually taken care of for a
    //   bound control automatically, but
    //   the radio group is not bound here,
    //   so manually force a render.
    this.forceUpdate();
  };

  renderActions(person) {
    const selected = this.isSelected(person);
    const { selectedPersonUUID } = this.state;

    if (selected) {
      // For phones, hide the cell completely
      //   to prevent wrapping it to the next
      //   line and adding a space between the
      //   row content and the edit form.
      return this.isPhoneDisplay ? null : EMPTY_COLUMN;
    }

    if (person.pk) {
      const button = (
        <Button noMinWidth variant="outlined" disabled={this.isProcessing}>
          <span>Options&nbsp;</span>

          <CustomIcon width={16} height={16} src={KeyboardArrowDownIcon} />
        </Button>
      );

      return (
        <Dropdown
          label={button}
          disabled={this.isProcessing}
          options={[
            {
              label: 'Edit Details',
              onClick: this.selectPerson(person),
            },
            {
              label: 'Resend Invite',
              onClick: this.saveChanges(person, true),
            },
          ]}
        />
      );
    }

    const buttonProps = selectedPersonUUID
      ? { variant: 'outlined' }
      : { variant: 'contained', color: 'primary' };

    return (
      <Button
        noMinWidth
        {...buttonProps}
        disabled={this.isProcessing}
        onClick={this.selectPerson(person)}
      >
        Notify
      </Button>
    );
  }

  renderPermissions(person) {
    // If they don't have a role, then they can't
    //   have any permissions, so don't give them
    //   the options.
    if (!this.rolesForPerson(person).length) {
      return null;
    }

    return (
      <React.Fragment>
        <Spacer v={20} />

        <Typography variant="caption">
          What kind of access do you want to give to {person.name || 'this person'}?
        </Typography>

        <Spacer v={4} />

        <Panel dashed lessPadding>
          <RadioGroup
            dots
            value={person.permissions}
            disabled={this.isProcessing}
            options={PERMISSIONS_OPTIONS}
            onChange={this.changePermission(person)}
          />
        </Panel>
      </React.Fragment>
    );
  }

  selectPersonName = person => {
    // When deselecting a person using the people picker,
    //   we don't want to replace the placeholder record
    //   that is being editted because this would result
    //   in some strange rendering. Instead, we just
    //   clear the name for the existing record.
    if (!person) {
      const { additionalPerson } = this.state;

      // We don't need to use setState here because
      //   additionalPerson is not a POJO and this
      //   works without it.
      additionalPerson.name = '';

      return;
    }

    this.selectPerson(person, true)();
  };

  renderPersonChooser(person) {
    // The only person that a name can be chosen
    //   for is the additional person.
    if (!this.isAdditionalPerson(person)) {
      return null;
    }

    const exclude = [
      this.testator.name,
      ...this.notifiedPeople.map(p => p.name),
      this.clientData.isPlanningForSpouse ? this.testator.spouse.name : null,
    ].filter(Boolean);

    const eligible = [];
    this.clientData.walkPeople(null, p => eligible.push(p));

    const { additionalPerson, additionalPersonAttempted, additionalPersonFocused } = this.state;
    const showError =
      !additionalPersonFocused && additionalPersonAttempted && !additionalPerson.name;
    const error = showError ? 'Please select a person.' : null;

    return (
      <PurePersonChooser
        error={error}
        label="Full Name"
        allNames={exclude}
        selectedPerson={person}
        disabled={this.isProcessing}
        TextFieldComponent={Textbox}
        onSelect={this.selectPersonName}
        initialPeople={eligible.filter(p => !exclude.includes(p.name))}
        onBlur={() => this.setState({ additionalPersonFocused: false })}
        onFocus={() =>
          this.setState({
            additionalPersonFocused: true,
            additionalPersonAttempted: true,
          })
        }
      />
    );
  }

  saveChanges = (person, forceSend = false) => () => {
    let updates = {};
    const { enqueueSnackbar } = this.props;

    // If the person is not already persisted,
    //   then we need to update the state and
    //   the clientData to include the person.
    if (!person.pk) {
      const { unnotifiedPeople } = this.state;

      // Remove the person being saved from any
      //   other place that might add them to
      //   the list of notified people that is
      //   being rendered.
      updates.additionalPerson = null;
      updates.unnotifiedPeople = unnotifiedPeople.filter(p => p.personUUID !== person.personUUID);

      // Add the person to the end of the list
      //   that will be persisted with the
      //   clientData.
      this.clientData.notifiedPeople.push(person);
    }

    if (forceSend) {
      person.forceNotificationSend = true;
    }

    const success = () => {
      // Deselect the person that was
      //   just saved since there is
      //   nothing else to do.
      updates.selectedPersonUUID = null;

      enqueueSnackbar('Notification sent!', { variant: 'success' });
    };

    const fail = () => {
      // Don't make any of the previously set
      //   updates because they only apply when
      //   a successful persist occurs for a new
      //   notification.
      // Instead, show an error.
      updates = { error: true };

      // When resending, setting an error above
      //   doesn't get displayed, so show it
      //   using a snackbar.
      if (forceSend) {
        enqueueSnackbar('Failed to resend the notification.', {
          variant: 'error',
        });
      }

      // Remove the last notified person since
      //   it was added for us to be able to
      //   persist, but it hasn't been yet.
      if (!person.pk) {
        this.clientData.notifiedPeople.pop();
      }
    };

    const always = () => {
      // Ensure that we have cleared the
      //   force option so we don't
      //   accidentaly send a notification
      //   again.
      person.forceNotificationSend = false;

      this.setState(updates);
    };

    this.clientDataHolder.persistClientData().then(success).catch(fail).finally(always);
  };

  changePreview = previewPerson => () => {
    this.setState({ previewPerson });
  };

  renderEdit(person) {
    if (!this.isSelected(person)) {
      return null;
    }

    const { error } = this.state;
    const nameMissing = Boolean(person.validationErrors.name.length);
    const saveDisabled = Boolean(person.validationErrors.email.length) || nameMissing;

    const saveText = person.pk ? 'Save Changes' : `Add ${person.firstName}`;

    const content = (
      <React.Fragment>
        {!this.isPhoneDisplay && <Spacer v={20} />}

        <Panel lessPadding special="force">
          {!this.isProcessing && !this.isPhoneDisplay && (
            <CloseButton position="top" onClick={this.selectPerson()} />
          )}

          <DimensionLimiter h={400}>
            {this.renderPersonChooser(person)}

            <BoundTextField
              obj={person}
              path="email"
              type="email"
              component={Textbox}
              disabled={this.isProcessing}
              label={`${this.isPhoneDisplay ? '' : person.firstNames} Email Address`.trim()}
            />
          </DimensionLimiter>

          {this.renderPermissions(person)}

          <Spacer v={20} />

          {error && (
            <React.Fragment>
              <GenericError />
              <Spacer v={20} />
            </React.Fragment>
          )}

          <Grid container spacing={1}>
            <Grid item xs={12} sm="auto">
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={saveDisabled}
                processing={this.isProcessing}
                onClick={this.saveChanges(person)}
              >
                {saveText}
              </Button>
            </Grid>
            <Grid item xs={12} sm="auto">
              <Button
                fullWidth
                variant="outlined"
                onClick={this.changePreview(person)}
                disabled={this.isProcessing || nameMissing}
              >
                Preview Invite
              </Button>
            </Grid>
          </Grid>
        </Panel>
      </React.Fragment>
    );

    return { xs: 12, content };
  }

  // On a phone, the actions need to be indented so
  //   they appear under the text and not under the
  //   status icon.
  renderActionsSpacer(content) {
    if (!content) {
      return null;
    } else if (!this.isPhoneDisplay) {
      return content;
    }

    return (
      <React.Fragment>
        <Spacer inline h={PHONE_ICON_INDENT} />
        {content}
      </React.Fragment>
    );
  }

  generateRow = person => {
    const content = [
      this.renderNameAndRole(person),
      this.renderStatus(person),
      this.renderAccess(person),
      this.renderActionsSpacer(this.renderActions(person)),
      this.renderEdit(person),
    ].filter(Boolean);

    return {
      content,
      alignItems: 'center',
    };
  };

  renderTable() {
    const people = this.notifiedPeople;

    if (!people.length) {
      return null;
    }

    const hidden = this.isPhoneDisplay;
    const firstColumnTitle = hidden ? 'Next Steps' : 'Person';

    const columns = [
      { title: firstColumnTitle, sm: 4, xs: 12 },
      { title: 'Invitation Status', sm: 3, hidden },
      { title: 'Access Type', sm: 2, hidden },
      {
        title: '',
        sm: 3,
        xs: 'auto',
        align: this.isPhoneDisplay ? 'left' : 'right',
      },
    ];

    const rows = people.map(this.generateRow);

    return <Table rows={rows} columns={columns} />;
  }

  inviteAnother = () => {
    this.selectPerson(this.buildNotifiedPerson(), true)();
  };

  renderInviteAnother() {
    const { additionalPerson } = this.state;

    if (additionalPerson) {
      return null;
    }

    const suffix = this.notifiedPeople.length ? 'else' : '';

    return (
      <Panel dashed lessPadding>
        <Fab
          icon={AddIcon}
          disabled={this.isProcessing}
          onClick={this.inviteAnother}
          label={`Invite someone ${suffix}`}
          className="add-notification"
        />
      </Panel>
    );
  }

  renderPreview() {
    const { previewPerson } = this.state;

    if (!previewPerson) {
      return null;
    }

    return (
      <EmailPreview
        person={previewPerson}
        testator={this.testator}
        onClose={this.changePreview(null)}
        roles={this.rolesForPerson(previewPerson)}
      />
    );
  }

  render() {
    return (
      <Layout
        wide={2}
        padded={false}
        testatorSwitcher
        buttonRenderer={renderWuiSaveButton}
        appLocation={AppLocation.FAMILY_CENTER}
      >
        <WuiTheme>
          {this.renderPreview()}
          <Spacer v={40} />
          {this.renderHeader()}
          <Spacer v={48} />
          {this.renderTable()}
          {this.renderInviteAnother()}
        </WuiTheme>
      </Layout>
    );
  }
}

export default withWidth()(withSnackbar(withIsMobileDisplay(Notify)));
