import { WBoolean, WObject, WString } from 'models/types';
import {
  HealthcareWishes,
  PersonMixin,
  WAdultDob,
  PowerOfAttorney,
  USAddress,
} from 'models/client-data/mixins';
import { AgentsAccessorsMixin } from 'models/client-data/agents';
import {
  Gender,
  Lineage,
  MaritalStatus,
  Relationship,
  SignatureFont,
  IncomeRange,
} from 'models/client-data/enums';
import { AssetType } from './enums';
import { Asset, AssetOwner } from 'models/client-data/assets';
import { QUICK_OPTION_GROUPS } from 'material-ui/sections/assets/BequestInterface/helpers';
import BequestStep from 'material-ui/sections/assets/BequestInterface/BequestStep';
import { requiresHealthcareAgentAddressStates } from 'utils';

export const TestatorMixin = superclass =>
  class extends AgentsAccessorsMixin(PersonMixin(superclass)) {
    static fields = {
      healthcareWishes: new WObject(HealthcareWishes),
      avoidProbate: new WBoolean(),
      dob: new WObject(WAdultDob),
      powerOfAttorney: new WObject(PowerOfAttorney),
      annualIncome: new IncomeRange(),
      editingAssetUUID: new WString(),
      hasLifeInsurance: new WBoolean(),
      verificationAddress: new WObject(USAddress),
      signatureFont: new SignatureFont(),
      acceptedSignature: new WBoolean(),
    };

    get isMarried() {
      return this.maritalStatus === MaritalStatus.MARRIED;
    }

    get assetShareDistributionKey() {
      return this.clientRelationship === Relationship.SELF
        ? 'clientShareDistribution'
        : 'spouseShareDistribution';
    }

    addAsset(type) {
      const owners = [];

      owners.push(new AssetOwner(this));

      if (type === AssetType.REAL_ESTATE && this.isMarried) {
        owners.push(new AssetOwner(this.spouse));
      }

      const newAsset = new Asset({ type: type, owners: owners });
      this.assets.push(newAsset);
      return newAsset;
    }

    getAssetOfType(type) {
      for (let asset of this.assets) {
        if (asset.type === type) {
          return asset;
        }
      }
      return null;
    }

    genderedSelector(male, female) {
      return this.gender === Gender.MALE ? male : female;
    }

    get hisHer() {
      return this.genderedSelector('his', 'her');
    }

    get heShe() {
      return this.genderedSelector('he', 'she');
    }

    get himHerself() {
      return this.genderedSelector('himself', 'herself');
    }

    get residualAsset() {
      return this.getAssetOfType(AssetType.RESIDUAL);
    }

    get residualJointAsset() {
      return this.getAssetOfType(AssetType.RESIDUAL_JOINT);
    }

    get needsResidualJointAsset() {
      // need residual joint whenever spouse is not first in line to receive real-estate

      if (this.maritalStatus !== MaritalStatus.MARRIED) {
        return false;
      }

      const residualPrimaries = this.getPrimaryBeneficiariesForAsset(this.residualAsset);
      const spouseIsSoleResidual =
        residualPrimaries.length === 1 &&
        residualPrimaries[0].personUUID === this.spouse.personUUID;

      // only happens if there is unbequeathed real estate and the spouse is not the sole residual beneficiary
      return (
        !spouseIsSoleResidual &&
        this.ownAndJointRealEstates.some(
          r =>
            // If the asset does not have an address, that means
            //   is is just a placeholder while being bequeathed
            //   in the bequest interface.
            r.hasAddress && !this.getPrimaryBeneficiariesForAsset(r, false).length,
        )
      );
    }

    get ownNotifiedPeople() {
      const source = this.clientRelationship === Relationship.SPOUSE ? this.spouse : this;

      return source.notifiedPeople.filter(p => p.owner === this.ownerString);
    }

    get ownChildren() {
      const singleLineage =
        this.clientRelationship === Relationship.SPOUSE ? Lineage.SPOUSE : Lineage.CLIENT;

      return this.children.filter(c => [Lineage.BOTH, singleLineage].includes(c.lineage));
    }

    get ownMinorChildren() {
      return this.ownChildren.filter(c => c.isMinor);
    }

    get hasMinorChildren() {
      return this.ownMinorChildren.length >= 1;
    }

    get editingAsset() {
      return this.getAssetByUUID(this.editingAssetUUID);
    }

    get primaryResidence() {
      return (
        this.assets.find(a => a.isPrimaryResidence && a.hasAddress) ||
        this.assets.find(a => a.type === AssetType.REAL_ESTATE && a.hasAddress)
      );
    }

    get hasOwnRealEstate() {
      return this.ownAndJointRealEstates.length >= 1;
    }

    getAssetByUUID(uuid) {
      for (const asset of this.assets) {
        if (asset.assetUUID === uuid) {
          return asset;
        }
      }

      return null;
    }

    // Some beneficiaries might not have names if they are
    //   just placeholders (like for real estate assets).
    //   If that is the case, we allow for filtering those
    //   out easily.
    getPrimaryBeneficiariesForAsset(asset, includePlaceholders = true) {
      return asset.primaryBeneficiaries.filter(
        b => b.owner === this.ownerString && (includePlaceholders || b.name),
      );
    }

    addNewBeneficiariesForAsset(asset, beneficiaries, filter) {
      for (let primary of asset.primaryBeneficiaries) {
        if (beneficiaries.every(b => b.personUUID !== primary.personUUID)) {
          if (!filter || filter(primary)) {
            beneficiaries.push(primary);
          }
        }

        if (primary.owner === this.ownerString) {
          for (let alternate of primary.alternateBeneficiaries) {
            if (beneficiaries.every(b => b.personUUID !== alternate.personUUID)) {
              if (!filter || filter(alternate)) {
                beneficiaries.push(alternate);
              }
            }
          }
        }
      }
    }

    getBeneficiariesForAssets(assets, filter) {
      const beneficiaries = [];
      let hasUnassignedRealEstate = false;

      for (const asset of assets) {
        this.addNewBeneficiariesForAsset(asset, beneficiaries, filter);

        if (this.getPrimaryBeneficiariesForAsset(asset).length === 0) {
          hasUnassignedRealEstate = true;
        }
      }

      if (hasUnassignedRealEstate) {
        if (this.needsResidualJointAsset) {
          this.addNewBeneficiariesForAsset(this.residualJointAsset, beneficiaries, filter);
        } else {
          this.addNewBeneficiariesForAsset(this.residualAsset, beneficiaries, filter);
        }
      }

      return beneficiaries;
    }

    get ownBeneficiaries() {
      return this.getBeneficiariesForAssets(
        this.ownAssetsWithResiduals,
        b => !QUICK_OPTION_GROUPS.includes(b.name),
      );
    }

    get relationshipRequiredBeneficiaries() {
      const reservedRelationships = [Relationship.SELF, Relationship.SPOUSE];
      const filter = p =>
        !reservedRelationships.includes(p.clientRelationship) &&
        (p.owner === this.ownerString || !p.owner);

      const assets = this.assets.filter(a => a.needsBeneficiaryRelationship);

      let beneficiaries = this.getBeneficiariesForAssets(assets, filter);

      if (this.clientRelationship === Relationship.SPOUSE) {
        const clientBennyUUIDs = this.spouse.relationshipRequiredBeneficiaries.map(
          b => b.personUUID,
        );
        beneficiaries = beneficiaries.filter(
          b =>
            b.clientRelationship !== Relationship.CHILD || !clientBennyUUIDs.includes(b.personUUID),
        );
      }

      return beneficiaries.filter(b => !QUICK_OPTION_GROUPS.includes(b.name));
    }

    get addressRequiredBeneficiaries() {
      const reservedRelationships = [Relationship.SELF, Relationship.SPOUSE];
      const filter = p =>
        !reservedRelationships.includes(p.clientRelationship) &&
        !p.isMinor &&
        (p.owner === this.ownerString || !p.owner);

      const assets = this.assets.filter(a => a.needsBeneficiaryAddress);

      const beneficiaries = this.getBeneficiariesForAssets(assets, filter);

      if (this.clientRelationship === Relationship.SPOUSE) {
        const clientBennyUUIDs = this.spouse.addressRequiredBeneficiaries.map(b => b.personUUID);
        return beneficiaries.filter(b => !clientBennyUUIDs.includes(b.personUUID));
      }
      return beneficiaries;
    }

    // Specifically the case for which we need to check if we have them
    get needsBeneficiaryAddresses() {
      return this.addressRequiredBeneficiaries.some(p => !p.hasAddress);
    }

    get ownHealthcareAgents() {
      return this.healthcareAgents.filter(p => p.owner === this.ownerString);
    }

    get addressRequiredHealthcareAgents() {
      if (!requiresHealthcareAgentAddressStates.includes(this.clientData.address.state)) {
        return [];
      }

      const excluded = new Set();
      const exclude = (people, force = false) =>
        people.forEach(p => {
          if (force || p.hasAddress) {
            excluded.add(p.personUUID);
          }
        });

      exclude(this.addressRequiredBeneficiaries);
      exclude(this.spouse.addressRequiredBeneficiaries);
      if (this.clientRelationship === Relationship.SPOUSE) {
        exclude(this.spouse.ownHealthcareAgents);
      }
      if (this.primaryResidence) {
        exclude([this, this.spouse], true);
      }

      return this.ownHealthcareAgents.filter(p => !excluded.has(p.personUUID));
    }

    get ownRealEstates() {
      return this.realEstates.filter(
        a => a.isOwnedByRelationship(this.clientRelationship) && !a.isJointlyOwned,
      );
    }

    get ownAndJointRealEstates() {
      return this.assets.filter(
        a => a.type === AssetType.REAL_ESTATE && a.isOwnedByRelationship(this.clientRelationship),
      );
    }

    get ownAssets() {
      return this.assets.filter(a => a.isOwnedByRelationship(this.clientRelationship));
    }

    get ownAssetsWithResiduals() {
      return [...this.ownAssets, this.residualAsset, this.residualJointAsset];
    }

    get homeOrHomes() {
      return this.assets.filter(a => a.type === AssetType.REAL_ESTATE).length > 1
        ? 'homes'
        : 'home';
    }

    removeBequestsFromAsset(asset) {
      // remove the primary beneficiaires designated by this testator from the given asset
      return asset.removePrimaryBeneficiariesByOwner(this.ownerString);
    }

    get hasUnbequeathedRealEstate() {
      // check if the testator has unallocated real estate assets
      return this.assets
        .filter(a => a.type === AssetType.REAL_ESTATE)
        .some(a => !a.primaryBeneficiaries.some(b => b.owner === this.ownerString));
    }

    get isPrimary() {
      return this.clientRelationship === Relationship.SELF;
    }

    get missingResidualBeneficiaries() {
      return (
        !this.residualAsset || !this.getPrimaryBeneficiariesForAsset(this.residualAsset).length
      );
    }

    get missingResidualJointBeneficiaries() {
      if (!this.needsResidualJointAsset) {
        return false;
      }

      return (
        !this.residualJointAsset ||
        !this.getPrimaryBeneficiariesForAsset(this.residualJointAsset).length
      );
    }

    get missingAnyResidualBeneficiaries() {
      return this.missingResidualBeneficiaries || this.missingResidualJointBeneficiaries;
    }

    get assetsVisibleForBequests() {
      // All assets are considered visible, with the exception of
      //   real estate. Real estate assets are visible when they
      //   have a primary beneficiary.
      const assets = this.ownAssets.filter(
        a => !a.isRealEstate || this.getPrimaryBeneficiariesForAsset(a).length,
      );

      // The residual asset is always visible.
      return [...assets, this.residualAsset];
    }

    get hasIncompleteBequests() {
      return this.assetsVisibleForBequests.some(a => a.bequestStep(this) !== BequestStep.REVIEW);
    }
  };
