import React from 'react';
import PropTypes from 'prop-types';

import { Asset } from '!m/client-data/assets';
import { AssetType } from '!m/client-data/enums';
import { ProgressSections } from '@c/progress';

import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import RealEstatePage from 'ui/sections/about-you/pages/RealEstate';
import { AppLocation } from 'utils/enums';

import Layout from '@c/layout/Layout';
import Property from './Property';
import AddButton from '../AddButton';
import FieldGroup from '../FieldGroup';
import RealEstateTips from '../RealEstateTips';
import BoundRadioGroup from '@c/BoundRadioGroup';

function initializeOwnsRealEstate(clientData) {
  const hasCompletedPage = clientData.isPageValid(SectionAboutYou, RealEstatePage);
  return clientData.realEstates.length ? true : hasCompletedPage ? false : null;
}

export default class RealEstate extends React.Component {
  static contextTypes = {
    page: PropTypes.object,
    testator: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    // Initialize any delayed fields that are used on
    //   this page based on the current state.
    this.context.clientDataHolder.updateClientData(() => {
      this.t.setDelayedValues({
        ownsRealEstate: initializeOwnsRealEstate,
      });
    });
  }

  // We use the testator a lot, so
  //   this helper is useful.
  get t() {
    return this.context.testator;
  }

  addProperty() {
    const asset = new Asset({ type: AssetType.REAL_ESTATE });

    // If a primary residence is already selected, then
    //   the new property can't be the primary.
    if (this.t.primaryResidence) {
      asset.setRawValue('isPrimaryResidence', false);
    }

    this.context.clientDataHolder.updateClientData(() => this.t.assets.push(asset));
  }

  addOrRemoveProperties() {
    // Remove all real estate assets if the user says
    //   they do not have any.
    if (!this.t.ownsRealEstate) {
      this.context.clientDataHolder.updateClientData(() => {
        this.t.realEstates.forEach(this.t.removeAssetByInstance.bind(this.t));
      });

      // Otherwise, ensure one is present if they say they do have one.
    } else if (!this.t.realEstates.length) {
      this.addProperty();
    }
  }

  renderAddPropertyButton() {
    const realEstates = this.t.realEstates;

    // The button is disabled if there are no properties
    //   in the list (the first is added based on the
    //   testators having real estate or not automatically).
    //   It is also disabled if the last property has not
    //   been completely filled in (based on all available
    //   fields because the last field, primary residence
    //   can be automatically filled in).
    const disabled =
      realEstates.length === 0 || !realEstates[realEstates.length - 1].isCompleteForRealEstate;

    return (
      <AddButton
        key="add"
        disabled={disabled}
        text="Add another property"
        onClick={this.addProperty.bind(this)}
        dataPath="addProperty"
      />
    );
  }

  renderProperties() {
    if (this.t.ownsRealEstate === false) {
      return null;
    }

    const properties = this.t.realEstates.map((property, i) => (
      <Property key={i} asset={property} disabled={!this.t.ownsRealEstate} />
    ));

    // If there are no properties to display, show a
    //   placeholder.
    if (!properties.length) {
      properties.push(<Property disabled={true} key="placeholder" />);
    }

    return properties.concat(this.renderAddPropertyButton());
  }

  render() {
    let spouseText = '';
    if (this.t.isPlanningForSpouse) {
      spouseText = `or ${this.t.spouse.firstName || 'your spouse'}`;
    }

    const label = `Do you ${spouseText} own any real estate? Include properties with a mortgage.`;

    return (
      <Layout
        progressPage={ProgressSections.ABOUT_YOU.REAL_ESTATE}
        appLocation={AppLocation.REAL_ESTATE}
        showBackButton
      >
        <RealEstateTips />
        <FieldGroup title="Your Real Estate">
          <BoundRadioGroup
            label={label}
            path="ownsRealEstate"
            onChange={this.addOrRemoveProperties.bind(this)}
          />
        </FieldGroup>
        {this.renderProperties()}
      </Layout>
    );
  }
}
