import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';

import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';

import { ReactComponent as ChromeIcon } from 'icons/svg/icon-chrome.svg';
import { ReactComponent as DownloadIcon } from 'icons/svg/icon-download.svg';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey.panelBorder}`,
    width: 283,
    paddingRight: 16,
    cursor: 'pointer',
  },
  iconContainer: {
    borderRadius: [[4, 0, 0, 4]],
    padding: 16,
    backgroundColor: theme.palette.blue.default,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
  },
  downloadIcon: {
    position: 'relative',
    top: 2,
  },
}));

const GoogleChromeChip = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="center"
      alignContent="center"
      className={classes.root}
      onClick={() => window.open('https://www.google.com/chrome/')}
    >
      <div className={classes.iconContainer}>
        <ChromeIcon />
      </div>

      <Typography variant="h6">Google Chrome</Typography>

      <DownloadIcon className={classes.downloadIcon} />
    </Grid>
  );
};

export default observer(GoogleChromeChip);
