import React from 'react';

import { Urls } from 'urls';
import { Page as Notify } from 'material-ui/pages/notify/Page';
import WizardSection from 'ui/components/wizard/WizardSection';

class SpouseNotify extends Notify {
  static forSpouse = true;
}

export default class SectionNotify extends WizardSection {
  static url = '/notify/';
  static title = 'Alert your executors and agents';
  static description =
    'Notify your agents and loved ones about your plan, so they' +
    ' know what to do when the time comes.';
  static finalUrl = Urls.file;
  static fallbackUrl = Urls.file;
  static showFooter = false;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(Notify);
    if (clientData.isPlanningForSpouse) {
      visit(SpouseNotify);
    }
  }
}
