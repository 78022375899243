import React from 'react';
import PropTypes from 'prop-types';
import { isIOS } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@material-ui/core/Icon';

import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Grid from '@wui/layout/grid';

import CheckoutForm from './CheckoutForm';
import PaymentHandler from './PaymentHandler';
import { getCompanyName } from 'utils';

const styles = theme => ({
  header: {
    padding: 16,
    borderBottom: `1px solid ${theme.palette.grey.panelBorder}`,
  },
  innerContent: {
    padding: [[32, 16]],
  },
});

class MobileCheckoutDrawer extends PaymentHandler {
  static propTypes = {
    ...PaymentHandler.propTypes,
    classes: PropTypes.shape({}).isRequired,

    // Whether or not to show or hide the drawer
    open: PropTypes.bool,
    close: PropTypes.func,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  static defaultProps = {
    ...PaymentHandler.defaultProps,
    open: false,
    close: () => null,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      showCheckoutForm: false,
    };
  }

  get canUseZendesk() {
    return window.zE && window.zE.show && window.zE.hide;
  }

  hideZendesk = () => {
    if (this.canUseZendesk) {
      window.zE.hide();
    }
  };

  showZendesk = () => {
    if (this.canUseZendesk) {
      window.zE.show();
    }
  };

  componentDidUpdate(prevProps) {
    const { priceInfo, bundle, open } = this.props;
    const { paymentRequest } = this.state;

    if (open) {
      this.hideZendesk();
    } else {
      this.showZendesk();
    }

    if (prevProps.priceInfo !== priceInfo || prevProps.bundle !== bundle) {
      if (!paymentRequest) {
        this.createPaymentRequest();
        return;
      }

      const companyName = getCompanyName(this.context.clientDataHolder.serverData);
      paymentRequest.update({
        total: {
          label: `${companyName} ${bundle} estate planning bundle`,
          amount: priceInfo.discountedPrice,
        },
      });

      this.forceUpdate();
    }
  }

  componentDidMount() {
    super.componentDidMount();

    setTimeout(this.createPaymentRequest, 600);
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    this.showZendesk();
  }

  createPaymentRequest = () => {
    const { stripe, priceInfo, bundle, onSuccess } = this.props;

    if (!priceInfo.discountedPrice) {
      return;
    }

    const companyName = getCompanyName(this.context.clientDataHolder.serverData);
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `${companyName} ${bundle} estate planning bundle`,
        amount: priceInfo.discountedPrice,
      },
    });

    paymentRequest.on('token', ({ complete, token }) => {
      this.handleStripeSubmit(token).then(success => {
        complete(success ? 'success' : 'fail');
        onSuccess();
      });
    });

    paymentRequest.canMakePayment().then(result => {
      this.setState({ canUsePaymentRequest: Boolean(result) });
    });

    this.setState({ paymentRequest });
  };

  renderHeader = () => {
    const { classes, close } = this.props;
    const { showCheckoutForm } = this.state;

    if (!showCheckoutForm) {
      return (
        <Grid container direction="row" justify="space-between" className={classes.header}>
          <Grid item xs={11}>
            <Typography style={{ fontWeight: 'bold' }}>Make a payment</Typography>
          </Grid>

          <Grid item xs={1}>
            <Icon onClick={close}>close</Icon>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        alignContent="center"
        className={classes.header}
        spacing={0}
      >
        <Grid
          container
          item
          direction="row"
          wrap="nowrap"
          xs={11}
          onClick={() => this.setState({ showCheckoutForm: false })}
          spacing={1}
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={2}>
            <Icon>keyboard_backspace</Icon>
          </Grid>

          <Grid item xs={10}>
            <Typography style={{ fontWeight: 'bold' }}>Pay with a card</Typography>
          </Grid>
        </Grid>

        <Grid item xs={1}>
          <Icon onClick={close}>close</Icon>
        </Grid>
      </Grid>
    );
  };

  renderContent = checkoutFormProps => {
    const { classes } = this.props;
    const { canUsePaymentRequest, paymentRequest, showCheckoutForm } = this.state;

    if (showCheckoutForm) {
      return <CheckoutForm {...checkoutFormProps} />;
    }

    return (
      <div className={classes.innerContent}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => this.setState({ showCheckoutForm: true })}
              data-path="pay"
              fullWidth
            >
              Pay with a card
            </Button>
          </Grid>

          {canUsePaymentRequest && (
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={paymentRequest.show} fullWidth>
                Pay with {isIOS ? 'Apple Pay' : 'Google Pay'}
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    );
  };

  render() {
    const { open, close, ...checkoutFormProps } = this.props;
    const { paymentRequest } = this.state;

    if (!paymentRequest) {
      return null;
    }

    return (
      <Drawer open={open} onClose={close} anchor="bottom">
        {this.renderHeader()}

        {this.renderContent(checkoutFormProps)}
      </Drawer>
    );
  }
}

export default withStyles(styles)(MobileCheckoutDrawer);
