import React from 'react';
import { Redirect } from 'react-router-dom';
import { API } from 'API';
import IntroPage from 'ui/components/wizard/IntroPage';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import { documentBundleIncludes } from 'utils';
import { DocumentBundle } from 'utils/enums';

import SuccessPage from 'ui/sections/notarize/pages/info/SuccessPage';

export default class FinalStatus extends IntroPage {
  static baseUrl = 'final-status/';
  static showNextButton = false;
  ctaText = 'Review my signed documents';

  constructor(props, context) {
    super(props, context);

    this.state = {
      sessionSuccessful: null,
    };
  }

  getStatus = async () => {
    const { data } = await API.getNotarizationSessionSuccessful();
    this.setState({ sessionSuccessful: data.sessionSuccessful });
  };

  componentDidMount() {
    super.componentDidMount();

    this.getStatus();
  }

  get andSpouse() {
    const includesSpouse =
      this.clientData.isPlanningForSpouse &&
      documentBundleIncludes(this.serverData.documentBundle, DocumentBundle.COUPLES);
    if (includesSpouse) {
      return `${this.clientData.firstName} and ${this.clientData.spouse.firstName}`;
    }

    return this.clientData.firstName;
  }

  get subCopy() {
    return `Congratulations, ${this.andSpouse}! Your documents have been successfully signed and notarized.`;
  }

  renderPage() {
    const { sessionSuccessful } = this.state;

    if (sessionSuccessful === null) {
      return null;
    }

    return sessionSuccessful ? (
      <SuccessPage
        headerText="Notarization Complete"
        bodyText={this.subCopy}
        buttonText="Review my signed documents"
        goToNext={this.props.goToNext}
      />
    ) : (
      <Redirect to={SectionDocuments.reviewUrl} />
    );
  }
}
