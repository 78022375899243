import React from 'react';
import PropTypes from 'prop-types';
import { joinClass } from 'utils';

import './PureCheck.scss';

export default class PureCheck extends React.PureComponent {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onCheck: PropTypes.func,
    onUncheck: PropTypes.func,
    dataPath: PropTypes.string,
    dataValue: PropTypes.string,
    disabled: PropTypes.bool,
    centerCheck: PropTypes.bool,
  };

  handleClick = () => {
    if (this.props.disabled) {
      return;
    }
    if (this.props.onChange) {
      this.props.onChange();
    }
    if (this.props.checked && this.props.onUncheck) {
      this.props.onUncheck();
    }
    if (!this.props.checked && this.props.onCheck) {
      this.props.onCheck();
    }
  };

  spaceListener = e => {
    if (e.charCode === 32) {
      if (!['INPUT', 'TEXTAREA'].includes(e.target.nodeName)) {
        e.preventDefault();
        return this.handleClick();
      }
    }
    return false;
  };

  addSpaceListener = () => document.addEventListener('keypress', this.spaceListener);

  removeSpaceListener = () => document.removeEventListener('keypress', this.spaceListener);

  render() {
    const checked = this.props.checked,
      disabled = this.props.disabled;

    let indicatorClass = 'check__indicator',
      checkmarkClass = 'check__checkmark';

    if (checked) {
      indicatorClass += ' check__indicator--checked';
      checkmarkClass += ' check__checkmark--checked';
    }

    if (disabled) {
      indicatorClass += ' disabled';
      checkmarkClass += ' disabled';
    }

    return (
      <div
        className={joinClass('check', this.props.centerCheck && 'check--centered')}
        onClick={this.handleClick}
        tabIndex="0"
        data-path={this.props.dataPath}
        data-value={this.props.dataValue}
        onFocus={this.addSpaceListener}
        onBlur={this.removeSpaceListener}
      >
        <div className={indicatorClass}>
          <div className={checkmarkClass} />
        </div>

        <label className="check__label">{this.props.children}</label>
      </div>
    );
  }
}
