import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.custom.border}`,
  },
  disabled: {
    backgroundColor: theme.palette.common.white,
  },
  focused: {
    fallbacks: theme.focus.native,
  },
}));

const SummaryPanel = ({ setExpanded, expandedIndex, index, title, children }) => {
  const classes = useStyles();
  const onChange = (_, expanded) => setExpanded(expanded ? index : null);

  return (
    <ExpansionPanel expanded={expandedIndex === index} onChange={onChange} classes={classes}>
      <ExpansionPanelSummary expandIcon={<Icon>arrow_drop_down</Icon>} classes={classes}>
        <Typography>{title}</Typography>
      </ExpansionPanelSummary>

      <ExpansionPanelDetails>{children}</ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

SummaryPanel.propTypes = {
  setExpanded: PropTypes.func,
  expandedIndex: PropTypes.any,
  index: PropTypes.number,
  title: PropTypes.string,
};

export default observer(SummaryPanel);
