import React from 'react';
import EducationPage from 'ui/components/wizard/EducationPage';

import WuiApp from '@/WuiApp';
import MaterialMlpBundle from '@s/about-you/MlpBundle';

export default class MlpBundle extends EducationPage {
  static baseUrl = '';
  ctaText = 'Create documents';

  goToNext = async () => {
    this.clientData.setStatusValid('sawMlpBundle');
    this.clientDataHolder.persistClientData();
  };

  static customDisplay() {
    return () => (
      <WuiApp wrapContent={false}>
        <MaterialMlpBundle />
      </WuiApp>
    );
  }
}
