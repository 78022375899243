import WizardSection from 'ui/components/wizard/WizardSection';
import MaterialExecutors from 'material-ui/sections/executors/MaterialExecutors';
import SpouseExecutors from 'material-ui/sections/executors/SpouseExecutors';
import { AppLocation } from 'utils/enums';
import Urls from 'urls';

export default class SectionExecutors extends WizardSection {
  static url = Urls.sectionExecutors;
  static title = 'Executors';
  static description = 'Name someone to manage your finances.';
  static mobileDescription = 'Managing your finances';
  static appLocation = AppLocation.EXECUTORS;
  static isPlanSection = true;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(MaterialExecutors);

    if (clientData.isPlanningForSpouse) {
      visit(SpouseExecutors);
    }
  }
}
