import PageVisit from 'ui/components/wizard/PageVisit';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import RealEstate from 'ui/sections/about-you/pages/RealEstate';
import SectionGuardians from 'ui/sections/guardians/SectionGuardians';
import SectionExecutors from 'ui/sections/executors/SectionExecutors';
import SectionHealthcare from 'ui/sections/healthcare/SectionHealthcare';
import SectionAssetBeneficiaries from 'ui/sections/asset-beneficiaries/SectionAssetBeneficiaries';
import BequestInterface from 'ui/sections/bequest-interface/BequestInterface';
import BeneficiaryAddressInput from 'material-ui/sections/assets/BeneficiaryAddressInput';
import { deepEquals } from 'utils';
import { AgentOwner, AssetType } from './enums';
import { applyConsistencyChecks } from 'models/client-data/consistencyChecks';
import SectionSign from 'ui/sections/sign/SectionSign';
import { getFileSections } from 'ui/sections/sections';
import PlanNotice from 'ui/sections/about-you/pages/PlanNotice';
import SectionNotarize from 'ui/sections/notarize/SectionNotarize';
import AboutYourFamily from 'ui/sections/about-you/pages/AboutYourFamily';
import { WDate } from 'models/types';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import DocumentLoader from 'ui/sections/documents/pages/DocumentLoader';
import MaterialExecutors from 'material-ui/sections/executors/MaterialExecutors';
import SpouseExecutors from 'material-ui/sections/executors/SpouseExecutors';
import MaterialGuardians from 'material-ui/sections/guardians/MaterialGuardians';
import SpouseGuardians from 'material-ui/sections/guardians/SpouseGuardians';
import MaterialHealthcare from 'material-ui/sections/healthcare/MaterialHealthcare';
import SpouseHealthcare from 'material-ui/sections/healthcare/SpouseHealthcare';

function invalidatePages(clientData, section, pages) {
  for (const page of pages) {
    clientData.setPageVisitInvalid(new PageVisit(section, page));
  }
  clientData.setSectionInvalid(section);
}

function diffAssets(newAssets, oldAssets) {
  const oldMap = {},
    newMap = {},
    added = [],
    removed = [],
    changedAttributes = [],
    changedBeneficiaries = [];

  for (const asset of oldAssets) {
    oldMap[asset.assetUUID] = asset;
  }
  for (const asset of newAssets) {
    newMap[asset.assetUUID] = asset;
  }

  for (const oldAsset of oldAssets) {
    if (!newMap.hasOwnProperty(oldAsset.assetUUID)) {
      removed.push(oldAsset);
    }
  }
  for (const newAsset of newAssets) {
    const oldAsset = oldMap[newAsset.assetUUID];
    if (oldAsset) {
      const sameAll = oldAsset.deepEquals(newAsset);
      if (sameAll) {
        continue;
      }

      const sameProps = oldAsset.deepEquals(newAsset, [
        'owners',
        'primaryBeneficiaries',
        'clientShareDistribution',
        'spouseShareDistribution',
        'isEditingClient',
        'isEditingSpouse',
      ]);
      if (sameProps) {
        changedBeneficiaries.push(oldAsset, newAsset);
      } else {
        changedAttributes.push(oldAsset, newAsset);
      }
    } else {
      added.push(newAsset);
    }
  }

  return { added, removed, changedAttributes, changedBeneficiaries };
}

export function invalidatePostPlanSections(clientData) {
  clientData.notarizationDate = new WDate();
  clientData.setRawValue('notarizationDate', new WDate());
  clientData.setSectionPagesInvalid(SectionSign);
  clientData.setSectionPagesInvalid(SectionDocuments);

  for (const section of getFileSections(null, null)) {
    clientData.setSectionPagesInvalid(section);
  }

  clientData.setFileInvalid();

  clientData.setSectionPagesInvalid(SectionNotarize);
}

function invalidateTestatorAssetsPages(clientData, testator, experiments) {
  const checks = {
    [BequestInterface]: t => t.missingAnyResidualBeneficiaries || t.hasIncompleteBequests,
  };

  if (experiments.loaded && !experiments.bennyAddressPostCheckout) {
    checks[BeneficiaryAddressInput] = t => t.addressRequiredBeneficiaries.some(b => !b.hasAddress);
  }

  Object.entries(checks).forEach(([page, check]) => {
    const effectivePage =
      SectionAssetBeneficiaries.pagesMappedToRelationship[page][testator.clientRelationship];

    if (clientData.isPageValid(SectionAssetBeneficiaries, effectivePage) && check(testator)) {
      invalidatePages(clientData, SectionAssetBeneficiaries, [effectivePage]);
    }
  });
}

function invalidateAgentPages(clientData, agentKey, section, mainPage, spousePage) {
  if (!clientData[agentKey]) {
    clientData.setPageVisitInvalid(new PageVisit(section, mainPage));
  }

  if (clientData.isPlanningForSpouse && !clientData.spouse[agentKey]) {
    clientData.setPageVisitInvalid(new PageVisit(section, spousePage));
  }
}

export function invalidateWizardItems(current, pristine, experiments) {
  const afterInitial = [AboutYourFamily, RealEstate, PlanNotice];

  applyConsistencyChecks(current, pristine);

  // If the user changes their plan we show them the document generation animation again.
  if (!current.deepEquals(pristine, ['validWizardItems'])) {
    current.setPageVisitInvalid(new PageVisit(SectionDocuments, DocumentLoader));
  }

  const addedNotarizationDate = !pristine.isNotarizationComplete && current.isNotarizationComplete;

  if (!current.isSectionValid(SectionSign) && !addedNotarizationDate) {
    const obj = current.notarizationDate;
    obj.setRawValue('year', null);
    obj.setRawValue('month', null);
    obj.setRawValue('day', null);
  }

  const updatedMaritalStatus = current.isMarried !== pristine.isMarried;
  const updatedPlanningForSpouse = current.isPlanningForSpouse !== pristine.isPlanningForSpouse;

  if (updatedMaritalStatus || updatedPlanningForSpouse) {
    for (let asset of current.assets) {
      asset.removePrimaryBeneficiariesByOwner(AgentOwner.CLIENT);
      asset.removePrimaryBeneficiariesByOwner(AgentOwner.SPOUSE);
    }

    const pages = updatedMaritalStatus ? afterInitial : afterInitial.slice(1);

    invalidatePages(current, SectionAboutYou, pages);

    current.setSectionPagesInvalid(SectionAssetBeneficiaries);
    current.setSectionPagesInvalid(SectionGuardians);
    current.setSectionPagesInvalid(SectionExecutors);
    current.setSectionPagesInvalid(SectionHealthcare);
  }

  if (current.address.state !== pristine.address.state) {
    invalidatePages(current, SectionAboutYou, afterInitial);
    current.setSectionPagesInvalid(SectionAssetBeneficiaries);
    current.setSectionPagesInvalid(SectionGuardians);
    current.setSectionPagesInvalid(SectionExecutors);
    current.setSectionPagesInvalid(SectionHealthcare);
    current.setSectionPagesInvalid(SectionDocuments);
    current.setSectionPagesInvalid(SectionSign);
    current.setSectionPagesInvalid(SectionNotarize);
    current.setAppInvalid();
    current.setFileInvalid();
  }

  const assetsDiff = diffAssets(current.assets, pristine.assets),
    changedRealEstate = assetsDiff.added
      .concat(assetsDiff.removed, assetsDiff.changedAttributes)
      .some(a => a.type === AssetType.REAL_ESTATE);

  if (changedRealEstate) {
    current.setSectionPagesInvalid(SectionAssetBeneficiaries);
  }

  if (current.realEstates.some(r => !r.ownershipType)) {
    invalidatePages(current, SectionAboutYou, [RealEstate]);
    current.setSectionPagesInvalid(SectionAssetBeneficiaries);
  }

  invalidateTestatorAssetsPages(current, current, experiments);
  invalidateTestatorAssetsPages(current, current.spouse, experiments);

  if (
    typeof pristine.ownsRealEstate !== 'undefined' &&
    pristine.ownsRealEstate !== current.ownsRealEstate
  ) {
    current.setSectionPagesInvalid(SectionAssetBeneficiaries);
  }

  invalidateAgentPages(
    current,
    'primaryExecutor',
    SectionExecutors,
    MaterialExecutors,
    SpouseExecutors,
  );
  invalidateAgentPages(
    current,
    'primaryGuardian',
    SectionGuardians,
    MaterialGuardians,
    SpouseGuardians,
  );
  invalidateAgentPages(
    current,
    'primaryHealthcareAgent',
    SectionHealthcare,
    MaterialHealthcare,
    SpouseHealthcare,
  );

  if (!deepEquals(current.children, pristine.children)) {
    current.setSectionPagesInvalid(SectionAssetBeneficiaries);
    current.setSectionPagesInvalid(SectionGuardians);
    current.setSectionPagesInvalid(SectionExecutors);
    current.setSectionPagesInvalid(SectionHealthcare);
  }
}

export function sendSectionValidationEvents(clientData, clientDataPristine, analyticsEvents) {
  const methods = [
    [SectionAboutYou, 'aboutYouComplete'],
    [SectionGuardians, 'guardiansComplete'],
    [SectionAssetBeneficiaries, 'assetsComplete'],
    [SectionExecutors, 'executorsComplete'],
    [SectionHealthcare, 'healthcareComplete'],
  ];

  for (const [section, method] of methods) {
    if (clientData.isSectionValid(section) && !clientDataPristine.isSectionValid(section)) {
      analyticsEvents.dispatchEvent(method);
    }
  }
}
