import React from 'react';
import IntroPage from 'ui/components/wizard/IntroPage';

import './EducationPage.scss';

export default class EducationPage extends IntroPage {
  static dataClass = 'center education';

  static get noUserActions() {
    return false;
  }
}
