import React from 'react';
import PropTypes from 'prop-types';

import sentry from '@willing-shared/utils/sentry';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { API } from 'API';
import VideoSession from 'ui/execution/VideoSession';
import Card from 'ui/components/Card';
import PureButton from 'ui/components/pure-controls/PureButton';
import RecordingIndicator from 'ui/execution/RecordingIndicator';
import DocumentImageViewer from 'ui/components/document-image-viewer/DocumentImageViewer';
import CallRoomMixin from 'ui/sections/notarize/pages/CallRoomMixin';
import { NotarizationStatus } from 'utils/enums';
import { IdViewer } from 'ui/execution/notary/IdViewer';
import { redirectToJournal } from 'urls';
import { NavExtension } from 'ui/NavExtension';

import WuiTheme from '@willing-shared/WuiTheme';
import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Button from '@wui/input/button';
import Typography from '@wui/basics/typography';
import Icon from '@material-ui/core/Icon';

import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
const transitionTypePrimary = 'primary';
const transitionTypeSecondary = 'secondary';

class ActionButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    className: 'button--primary',
  };

  render() {
    const { onClick, className, disabled } = this.props;

    return (
      <PureButton onClick={onClick} className={className} disabled={disabled}>
        {this.props.children}
      </PureButton>
    );
  }
}

export default class NotaryCallRoom extends CallRoomMixin(ClientDataReferer) {
  scriptLoaded = false;
  fetchedSession = false;
  static propTypes = {
    title: PropTypes.string,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      sessionReady: false,
      session: null,
      cancelCall: false,
      token: null,
      viewIdFor: null,
      serverLoading: false,
      sessionLoading: false,
      script: null,
      visualStep: 1,
      scriptError: false,
    };
  }

  updateSession = async () => {
    const { serverLoading, sessionLoading } = this.state;

    const response = await API.getNotarizationAdminSession(this.sessionId);
    const newState = { session: response.data };
    if (sessionLoading && !serverLoading) {
      newState.sessionLoading = false;
    }

    if (newState.session.documentsGenerated && !this.scriptLoaded) {
      this.getScript();
    }

    this.setState(newState);

    if (!this.fetchedSession) {
      this.fetchedSession = true;
      try {
        const tokenResponse = await API.getNotarizationAdminToken(this.sessionId, 'notary');
        this.setState({
          token: tokenResponse.data.token,
          sessionReady: true,
        });
      } catch (e) {
        sentry.captureException(e);
        this.fetchedSession = false;
      }
    }
  };

  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }
  get isLoading() {
    return this.state.sessionLoading || this.state.serverLoading;
  }

  actionPerformer = action => () => {
    this.setState({ serverLoading: true, sessionLoading: true });
    action().finally(() => this.setState({ serverLoading: false }));
  };

  doubleActionPerformer = (action1, action2) => () => {
    this.setState({ serverLoading: true, sessionLoading: true });
    action1().finally(() => action2().finally(() => this.setState({ serverLoading: false })));
  };

  renderActionButton(action, contents, className) {
    return (
      <ActionButton
        disabled={this.isLoading}
        onClick={this.actionPerformer(action)}
        className={className}
      >
        {contents}
      </ActionButton>
    );
  }

  renderButton(action, variant, contents) {
    return (
      <Button
        disabled={this.isLoading}
        variant={variant}
        color="primary"
        onClick={this.actionPerformer(action)}
      >
        {contents}
      </Button>
    );
  }

  startSession = () => API.postNotarizationStartSession(this.sessionId);

  startArchiving = () => API.postNotarizationStartArchiving(this.sessionId);

  stopArchiving = () => API.postNotarizationStopArchiving(this.sessionId);

  advanceStepPrimary = async () => {
    await API.postAdminAdvanceStep(this.sessionId, transitionTypePrimary);
  };

  advanceStepSecondary = () => API.postAdminAdvanceStep(this.sessionId, transitionTypeSecondary);

  reverseStep = async () => {
    await API.postAdminReverseStep(this.sessionId);
  };

  setIdApproval = (forSpouse, isApproved) => async () => {
    await this.actionPerformer(() =>
      API.postNotarizationSetIdApproval(this.sessionId, forSpouse, isApproved),
    )();
    this.setState({ viewIdFor: null });
    if (isApproved) {
      await API.postAdminAdvanceStep(this.sessionId, 'primary');
    }
  };

  completeSession = () => API.postNotarizationCompleteSession(this.sessionId);

  generateRenderedDocuments = () => API.postNotarizationGenerateRenderedDocuments(this.sessionId);

  getScript = () => {
    this.scriptLoaded = true;
    API.getNotaryScript(this.sessionId)
      .then(response => this.setState({ script: response.data }))
      .catch(() => this.setState({ scriptError: true }));
  };

  renderStepOptions(actionType) {
    const { cancelCall } = this.state;

    let onClickAction1 = this.actionPerformer(this.advanceStepPrimary);
    let onClickAction2 = this.actionPerformer(this.advanceStepSecondary);
    let buttonText1 = null;
    let buttonText2 = null;
    let disabled = this.isLoading;

    switch (actionType) {
      case 'cancel':
        onClickAction1 = this.actionPerformer(cancelCall);
        buttonText1 = 'End Call';
        break;
      case 'id':
        onClickAction1 = () => this.setState({ viewIdFor: 'client' });
        buttonText1 = 'Review Client ID';
        disabled = this.isLoading || this.state.viewIdFor;
        break;
      case 'spouse_id':
        onClickAction1 = () => this.setState({ viewIdFor: 'spouse' });
        buttonText1 = 'Review Spouse ID';
        disabled = this.isLoading || this.state.viewIdFor;
        break;
      case 'notarize':
        buttonText1 = 'Notarize';
        break;
      case 'stop_recording':
        onClickAction1 = this.doubleActionPerformer(this.stopArchiving, this.advanceStepPrimary);
        buttonText1 = 'Stop Recording';
        break;
      case 'finish':
        onClickAction1 = this.actionPerformer(this.completeSession);
        buttonText1 = 'Finish';
        break;
      case 'yes_no':
        buttonText1 = 'Yes';
        buttonText2 = 'No';
        break;
      case 'no_yes':
        buttonText1 = 'No';
        buttonText2 = 'Yes';
        break;
      case 'state':
        buttonText1 = 'In State';
        buttonText2 = 'Out of State';
        break;
      case 'start_recording':
        onClickAction1 = this.doubleActionPerformer(this.startArchiving, this.advanceStepPrimary);
        onClickAction2 = this.actionPerformer(cancelCall);
        buttonText1 = 'Start Recording';
        buttonText2 = 'End Call';
        break;
      case 'no_cancel':
        onClickAction2 = this.actionPerformer(cancelCall);
        buttonText1 = 'No';
        buttonText2 = 'End Call';
        break;
      case 'next_cancel':
        onClickAction2 = this.actionPerformer(cancelCall);
        buttonText1 = 'Next';
        buttonText2 = 'End Call';
        break;
      case 'client_sign_statement':
      case 'spouse_sign_statement':
        buttonText1 = 'Display Testator Statement';
        break;
      case 'client_sign_button':
      case 'spouse_sign_button':
        buttonText1 = 'Display Signing Button';
        break;
      case 'client_sign':
      case 'spouse_sign':
      case 'witness1_sign':
      case 'witness2_sign':
        buttonText1 = null;
        break;
      default:
        buttonText1 = 'Next';
        buttonText2 = null;
    }
    return (
      <React.Fragment>
        {buttonText1 && (
          <React.Fragment>
            <Button
              disabled={disabled}
              variant="contained"
              color="primary"
              onClick={onClickAction1}
            >
              {buttonText1}
            </Button>
          </React.Fragment>
        )}
        {buttonText2 && (
          <React.Fragment>
            <Spacer h={16} inline />
            <Button disabled={disabled} variant="outlined" color="primary" onClick={onClickAction2}>
              {buttonText2}
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { session, sessionReady, cancelCall, viewIdFor, script, scriptError } = this.state;
    const viewingForSpouse = viewIdFor === 'spouse';

    if (
      session &&
      !session.opentokIsArchiving &&
      (session.status === 'completed' || session.status === 'failed')
    ) {
      redirectToJournal();
    }

    return (
      <div className="cardwrapper cardwrapper_full_height">
        {cancelCall && this.renderCancelCallPopup()}
        <Card className="card--fixed-height">
          <div className="document-view">
            <div className="top top--admin">
              <div className="left">
                {session &&
                  (viewIdFor ? (
                    <IdViewer
                      session={session}
                      testatorString={viewIdFor}
                      approveId={this.setIdApproval(viewingForSpouse, true)}
                      rejectId={this.setIdApproval(viewingForSpouse, false)}
                      loading={this.isLoading}
                    />
                  ) : (
                    <DocumentImageViewer session={session} forAdmin={true} />
                  ))}
              </div>

              <div className="right">
                {session && session.status === 'ongoing' && session.opentokIsArchiving && (
                  <RecordingIndicator />
                )}
                {sessionReady && (
                  <React.Fragment>
                    <VideoSession
                      sessionId={session.opentokSessionId}
                      token={this.state.token}
                      name="Notary"
                    />
                    {session.renderedDocuments.map(doc => (
                      <div key={doc.signingStatus}>
                        {doc.signingStatus}: {doc.numPages === null ? 'pending' : 'generated'}
                      </div>
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>

            <div className="bottom">
              <WuiTheme>
                {scriptError && (
                  <div>
                    An Error occurred loading the notary script, please refresh to try again.
                  </div>
                )}
                {script && (
                  <React.Fragment>
                    <style>.notary-instructions {'{ font-style: italic; color: #3451ab; }'}</style>
                    <div
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      onClick={this.reverseStep}
                    >
                      <Icon>keyboard_backspace</Icon>
                      <Spacer h={4} />
                      <Typography variant="body1" style={{ fontSize: 14 }}>
                        Back
                      </Typography>
                    </div>
                    <Spacer v={4} />
                    {script[session.currentCeremonyStepId].visual_id && (
                      <Typography variant="overline">
                        {`Step ${script[session.currentCeremonyStepId].visual_id}`}
                      </Typography>
                    )}
                    <Typography variant="body2">
                      <div
                        style={{ fontFamily: 'inherit', fontSize: 16 }}
                        dangerouslySetInnerHTML={{
                          __html: `${script[session.currentCeremonyStepId].text}`,
                        }}
                      />
                    </Typography>
                    <Spacer v={16} />
                    {this.renderStepOptions(script[session.currentCeremonyStepId].action)}
                    <Spacer v={24} />
                  </React.Fragment>
                )}
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                  alignContent="center"
                  spacing={1}
                >
                  {session && (
                    <NavExtension>
                      {this.renderButton(this.cancelCall, 'outlined', `Cancel Call`)}
                      <Spacer h={16} inline />
                      {session.status === NotarizationStatus.WAITING_TO_START &&
                        this.renderButton(this.startSession, 'outlined', 'Start Session')}
                      {session.status === NotarizationStatus.ONGOING &&
                        !session.opentokIsArchiving &&
                        this.renderButton(this.startArchiving, 'outlined', 'Start Recording')}

                      {session.status === NotarizationStatus.ONGOING &&
                        session.opentokIsArchiving &&
                        this.renderButton(this.stopArchiving, 'outlined', 'Stop Recording')}
                      <Spacer h={16} inline />
                    </NavExtension>
                  )}
                </Grid>
              </WuiTheme>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
