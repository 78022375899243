import React from 'react';
import PropTypes from 'prop-types';
import { Tunnel } from 'react-tunnels';

export default class Tips extends React.Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return <Tunnel id="tips">{this.props.children}</Tunnel>;
  }
}
