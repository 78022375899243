import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';

import PureButton from 'ui/components/pure-controls/PureButton';
import { getPhoneNumber } from 'utils';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import { useGlobalContext, useDocumentTitle } from 'hooks';
const KBALocked = ({ title }) => {
  useDocumentTitle(title);
  const { history, clientData, serverData } = useGlobalContext();
  const redirect = () => history.push(SectionDocuments.reviewUrl);

  return (
    <div className="card-wrapper">
      <div className="card">
        <div className="wizard-page">
          <div className="vvcenter center info-container">
            <div className="intro-error" />
            <h1>Failed ID Validation</h1>
            <p>
              We were unable to verify your identity, please give us a call at{' '}
              {getPhoneNumber(clientData, serverData)}
            </p>
            <PureButton onClick={redirect} className="button--primary">
              I understand
            </PureButton>
          </div>
        </div>
      </div>
    </div>
  );
};

KBALocked.url = '/kba-locked/';

KBALocked.propTypes = {
  title: PropTypes.string,
};
export default observer(KBALocked);
