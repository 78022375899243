import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

import Layout from '@c/layout/Layout';
import WuiTheme from '@willing-shared/WuiTheme';
import WuiApp from 'material-ui/WuiApp';
import { AppLocation } from 'utils/enums';
import Theme from '@willing-shared/components/Theme';

class DashboardLayout extends React.Component {
  static contextTypes = {
    experiments: PropTypes.object,
  };

  render() {
    return (
      <MuiThemeProvider theme={Theme}>
        <Layout wide={2} appLocation={AppLocation.HOME} {...this.props}>
          <WuiTheme>{this.props.children}</WuiTheme>
        </Layout>
      </MuiThemeProvider>
    );
  }
}

const Wrapper = props => (
  <WuiApp>
    <DashboardLayout {...props} />
  </WuiApp>
);

export default Wrapper;
