import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import Link from '@c/Link';
import McAfeeLogo from '@a/images/mcafee.png';
import NortonLogo from '@a/images/norton.png';

const styles = theme => ({
  root: {
    display: 'flex',
    padding: [[24, 0]],
    borderTop: [[1, 'solid', theme.palette.custom.border]],

    '& img': {
      marginRight: 24,
      objectFit: 'contain',
    },

    '& a': {
      marginRight: 40,
      color: theme.palette.text.primary,
    },
  },

  grow: {
    flex: 1,
  },
});

export default withStyles(styles)(({ classes }) => (
  <div className={classes.root}>
    <img src={NortonLogo} alt="Norton secure seal" />
    <img src={McAfeeLogo} alt="McAfee secure seal" />

    <div className={classes.grow} />

    <Link href="/terms" variant="body2">
      Terms and Conditions
    </Link>

    <Link variant="body2" href="/privacy-policy">
      Privacy Policy
    </Link>

    <Typography variant="body2">&copy; {new Date().getFullYear()} Willing</Typography>
  </div>
));
