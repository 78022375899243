import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  backButton: {
    position: 'absolute',
    height: '100%',
    padding: [[0, 22]],
    borderRight: `2px solid ${theme.palette.custom.border}`,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  noBorder: {
    border: 'none !important',
    height: 'auto',
    marginTop: 14,
  },
  backLabel: {
    marginLeft: 8,

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
});

class BackButton extends React.Component {
  static contextTypes = {
    history: PropTypes.object,
  };

  static propTypes = {
    classes: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    showRightLine: PropTypes.bool,
  };

  static defaultProps = {
    onClick: null,
    showRightLine: true,
  };

  onKeyDown(event) {
    if (event.keyCode === 13) {
      event.stopPropagation();
      this.back(event);
    }
  }

  back(event) {
    event.preventDefault();
    const { onClick } = this.props;

    if (onClick) {
      onClick();
      return;
    }

    const { history } = this.context;

    history.go(-1);
  }

  render() {
    const { classes, showRightLine } = this.props;

    const rootClassName = classNames(classes.backButton, {
      [classes.noBorder]: !showRightLine,
    });

    return (
      <Hidden smDown>
        <div
          className={rootClassName}
          onClick={this.back.bind(this)}
          onKeyDown={this.onKeyDown.bind(this)}
          role="button"
          tabIndex="0"
        >
          <Icon>keyboard_backspace</Icon>
          <Typography variant="body1" className={classes.backLabel}>
            Back
          </Typography>
        </div>
      </Hidden>
    );
  }
}

export default withStyles(styles)(BackButton);
