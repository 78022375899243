import React from 'react';
import PropTypes from 'prop-types';
import BeneficiaryAddressInput from 'material-ui/sections/assets/BeneficiaryAddressInput';
import { Urls } from 'urls';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';

export default class IndependentBeneficiaryAddresses extends ClientDataReferer {
  static url = Urls.independentBeneficiaryAddresses;

  static childContextTypes = {
    section: PropTypes.object,
  };

  goToNext = async () => {
    await this.clientDataHolder.persistClientData();
    this.props.history.push(SectionDocuments.reviewUrl);
  };

  getChildContext() {
    return {
      section: {},
    };
  }

  render() {
    return (
      <div>
        <BeneficiaryAddressInput
          goToNext={this.goToNext}
          goToPrev={() => null}
          fullUrl={this.constructor.url}
        />
      </div>
    );
  }
}
