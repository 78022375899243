import WizardSection from 'ui/components/wizard/WizardSection';
import Info from 'ui/sections/file/update/pages/Info';
import { Urls } from 'urls';

export default class SectionUpdate extends WizardSection {
  static url = '/update/';
  static title = 'Designate Beneficiaries';
  static description =
    'Name beneficiaries on financial accounts to keep the accounts out of the probate process.';
  static finalUrl = Urls.file;
  static fallbackUrl = Urls.file;
  static showFooter = false;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(Info);
  }
}
