import React from 'react';
import PropTypes from 'prop-types';
import theme, { StaticWuiThemeProvider } from '@bequestinc/wui/theme';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';

const Theme = ({ children }) => (
  <StaticWuiThemeProvider>
    <MuiThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />

        {children}
      </React.Fragment>
    </MuiThemeProvider>
  </StaticWuiThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Theme;
