import React, { useEffect, useState } from 'react';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Table from '@wui/layout/table';
import CustomIcon from '@wui/basics/customIcon';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';

import { API } from 'API';
import { useGlobalContext } from 'hooks';
import { getSectionText } from 'utils';
import { DocumentType, DocumentStatus } from 'utils/enums';

import { ReactComponent as GreenTickIcon } from '@a/images/green-tick.svg';
import { ReactComponent as Pending } from '@a/images/pending.svg';

const DOCUMENT_STATUS = {
  [DocumentType.DEED]: {
    [DocumentStatus.PENDING]: {
      icon: Pending,
      title: 'In Progress',
      text:
        'Your Transfer on Death Deed must be filed in the official records of the county where your property is ' +
        'located before you pass away to make it valid. This process is called "recording". We will take the final ' +
        'steps to finalize and record your Transfer on Death Deed in your respective county. This can take 4-6 weeks ' +
        'depending on your county. Once recorded, we will provide you with copies of the recorded document. Make ' +
        'sure to safely store a copy of the Transfer on Death Deed for your own records.',
    },
    [DocumentStatus.COMPLETE]: {
      icon: GreenTickIcon,
      title: 'Completed',
      text: 'We have emailed a recorded version of this document to you.',
    },
  },
  [DocumentType.TRUST]: {
    [DocumentStatus.PENDING]: {
      icon: Pending,
      title: 'In Progress',
      text:
        'Now that you have signed your trust, a Home Transfer Deed must be prepared, signed, and recorded to ' +
        'transfer your real estate asset into the trust. This process is known as "funding your trust." We are ' +
        'currently preparing a Home Transfer Deed for your real estate asset and will send it to you via email with ' +
        'additional signing and recording instructions. This process can take 2-4 weeks.',
    },
    [DocumentStatus.COMPLETE]: {
      icon: GreenTickIcon,
      title: 'Completed',
      text:
        'The Home Transfer Deed for your real estate asset has been sent to you via email with additional signing ' +
        'and recording instructions.',
    },
  },
};

const defaultStatus = {
  icon: GreenTickIcon,
  title: 'Completed',
};

const makeRow = doc => {
  const status = DOCUMENT_STATUS[doc.type] ? DOCUMENT_STATUS[doc.type][doc.status] : defaultStatus;

  const content = [
    <Typography key="1">{doc.name}</Typography>,

    <Grid key="2" container spacing={1} alignItems="left">
      <Grid item>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
            <CustomIcon src={status.icon} />
          </Grid>
          <Grid item>
            <Typography variant="body1">{status.title}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>,
  ];

  if (status.text) {
    content.push({
      content: (
        <Grid item key="3" xs={12}>
          <Spacer v={8} />
          <Panel lessPadding style={{ background: '#F4F5F7' }}>
            <Typography>{status.text}</Typography>
          </Panel>
        </Grid>
      ),
      xs: 12,
    });
  }

  return {
    alignItems: 'center',
    content,
  };
};

const makeColumns = title => [
  { title, xs: 7 },
  { title: 'Status', xs: 5 },
];

const DigexDocumentList = () => {
  const [documents, setDocuments] = useState(null);
  const [error, setError] = useState(false);
  const { clientData } = useGlobalContext();

  useEffect(() => {
    API.listTestatorDocuments()
      .then(response => setDocuments(response.data))
      .catch(() => setError(true));
  }, []);

  if (error) {
    return (
      <Typography color="error">
        An error has occurred, please refresh the page to try again.
      </Typography>
    );
  }

  if (!documents) {
    return null;
  }

  const bySection = documents.reduce((acc, doc) => {
    const row = makeRow(doc);
    acc[doc.section] = acc[doc.section] || [];
    acc[doc.section].push(row);
    return acc;
  }, {});

  return (
    <>
      {Object.entries(bySection).map(([section, rows]) => (
        <Table
          key={section}
          rows={rows}
          columns={makeColumns(getSectionText(section, clientData))}
        />
      ))}
    </>
  );
};

export default DigexDocumentList;
