import React from 'react';
import PropTypes from 'prop-types';
import { joinClass } from 'utils';

import './PureInput.scss';

export default class PureInput extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['text', 'number']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    dataPath: PropTypes.string,
    maxLength: PropTypes.number,
  };

  static defaultProps = {
    type: 'text',
  };

  handleChange = e => {
    let value = e.target.value;

    if (this.props.type === 'number') {
      value = value.replace(/\D/, '');
    }

    return this.props.onChange(value);
  };

  render() {
    const classString = joinClass('text-input', this.props.className);

    return (
      <input
        type="text"
        value={this.props.value || ''}
        className={classString}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
        placeholder={this.props.placeholder || null}
        data-path={this.props.dataPath}
        maxLength={this.props.maxLength}
      />
    );
  }
}
