import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import AnchorTag from '@c/anchorTag';

const linkHoverStyles = theme => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.secondary,
});

const styles = theme => ({
  headerLink: {
    height: 26,
    float: 'left',
    marginLeft: 32,
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    ...theme.typography.body2,
    color: theme.palette.text.primary,

    '&:hover': linkHoverStyles(theme),

    // This is used to prevent the hover
    //   state from moving the rest of
    //   the navigation elements.
    '&:after': {
      ...linkHoverStyles(theme),

      height: 0,
      display: 'block',
      overflow: 'hidden',
      visibility: 'hidden',
      content: 'attr(title)',
    },
  },
});

class HeaderLink extends React.PureComponent {
  static propTypes = {
    href: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    classes: PropTypes.shape({}).isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  };

  static defaultProps = {
    to: null,
    href: null,
    className: null,
  };

  render() {
    const {
      to,
      href,
      classes,
      children,
      className,
      ...rest
    } = this.props;

    rest.className = classNames(className, classes.headerLink);

    const [Tag, tagProps] = to
      ? [Link, { to }]
      : [AnchorTag, { href }];

    return (
      <Tag {...rest} {...tagProps}>
        {children}
      </Tag>
    );
  }
}

export default withStyles(styles)(HeaderLink);
