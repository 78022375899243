import React from 'react';
import isMobile from 'react-device-detect';
import PropTypes from 'prop-types';
import OT from '@opentok/client';
import OpenTokPublisher from 'ui/execution/OpenTokPublisher';
import { OPENTOK_API_KEY } from 'utils/constants';

import './VideoSession.scss';
import GenericOpenTokError from 'ui/execution/GenericOpenTokError';

export default class VideoSession extends React.Component {
  static propTypes = {
    sessionId: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    videoSource: PropTypes.string,
    audioSource: PropTypes.string,
  };

  sessionInitialized = false;

  constructor(props, context) {
    super(props, context);

    this.publisher = null;
    this.session = null;
    this.state = {
      activeSessions: {},
      error: false,
      options: null,
    };
  }

  componentDidMount() {
    const options = {
      insertMode: 'append',
      width: '220px',
      height: '165px',
      name: this.props.name,
      style: {
        archiveStatusDisplayMode: 'off',
      },
    };

    if (isMobile) {
      options.facingMode = 'user';
    } else if (this.props.videoSource) {
      options.videoSource = this.props.videoSource;
    }

    if (this.props.audioSource) {
      options.audioSource = this.props.audioSource;
    }

    this.setState({ options });
  }

  componentWillUnmount() {
    this.cleanUp();
  }

  cleanUp() {
    if (this.session) {
      clearTimeout(this.maintainTimeout);
      this.session.off('streamCreated', this.onStreamCreated);
      this.session.disconnect();
      this.session = null;
    }
  }

  publisherInitHandler = publisher => {
    this.cleanUp();
    this.publisher = publisher;
    this.initSession();
  };

  initSession() {
    if (!this.session) {
      this.sessionInitialized = false;
      this.session = OT.initSession(OPENTOK_API_KEY, this.props.sessionId);
      this.session.on('streamCreated', this.onStreamCreated);

      this.session.connect(this.props.token, error => {
        // If the connection is successful, publish to the session
        if (error) {
          this.handleError(error);
        } else {
          this.session.publish(this.publisher, this.handleError);
          this.maintainPublisher();
        }
      });
    }
  }

  maintainPublisher() {
    this.maintainTimeout = setTimeout(() => {
      const stream = this.publisher && this.publisher.stream;
      const session = this.publisher && this.publisher.session;

      if (!stream || !stream.id || !session || !session.id) {
        if (this.sessionInitialized) {
          this.session.unpublish(this.publisher);

          // Sending a new options object recreates the publisher
          this.setState({ options: Object.assign({}, this.state.options) });

          return;
        }
      } else {
        this.sessionInitialized = true;
      }
      this.maintainPublisher();
    }, 200);
  }

  handleError = error => {
    if (error) {
      this.setState({ error: true });
    }
  };

  onStreamCreated = event => {
    const connectionData = JSON.parse(event.stream.connection.data);
    const elementId = connectionData.type;

    document.getElementById(elementId).innerHTML = '';

    this.session.subscribe(
      event.stream,
      elementId,
      {
        insertMode: 'append',
        width: '220px',
        height: '165px',
        name: event.stream.name,
        style: {
          nameDisplayMode: 'on',
          archiveStatusDisplayMode: 'off',
        },
      },
      this.handleError,
    );
    this.setState({
      activeSessions: {
        ...this.state.activeSessions,
        [elementId]: true,
      },
    });
  };

  render() {
    const { activeSessions, error, options } = this.state;

    return (
      <div className="video-session-flow">
        {error && <GenericOpenTokError closeFn={() => this.setState({ error: false })} />}
        {options && <OpenTokPublisher options={options} initHandler={this.publisherInitHandler} />}
        <div className="video-feed" id="publisher" />
        <div className={`video-feed ${!activeSessions.testator && 'inactive'}`} id="testator" />
        <div className={`video-feed ${!activeSessions.notary && 'inactive'}`} id="notary" />
        <div className={`video-feed ${!activeSessions.witness1 && 'inactive'}`} id="witness1" />
        <div className={`video-feed ${!activeSessions.witness2 && 'inactive'}`} id="witness2" />
      </div>
    );
  }
}
