// The blocks of code for which ESLint is disabled are from the
//   platforms themselves, so linting the code doesn't make sense.

import { googleTagIdentifiers } from '@/utils/env';

function loadScript(src) {
  const s = document.createElement('script');
  s.async = true;
  s.src = src;
  document.getElementsByTagName('head')[0].appendChild(s);
}

function initializeGtag() {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    // `arguments` must be used here instead of a rest
    //   parameter because it provides callee, which
    //   gtag needs to function.
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  };
  window.gtag('js', new Date());
}

function initializeOptimize() {
  const options = { [googleTagIdentifiers.getOptimize()]: true };

  /* eslint-disable */
  (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
  h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
  (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
  })(window,document.documentElement,'async-hide','dataLayer',1500, options);
  /* eslint-enable */
}

function initializeGoogleTagManager() {
  /* eslint-disable */
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-KLG4P3J');
  /* eslint-enable */
}

function initializeGoogle() {
  initializeOptimize();
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${googleTagIdentifiers.adwords}`);
  loadScript(`https://www.googletagmanager.com/gtag/js?id=${googleTagIdentifiers.analytics}`);
  initializeGtag();
  initializeGoogleTagManager();
}

export default [
  initializeGoogle,
];
