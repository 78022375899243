import React from 'react';
import PropTypes from 'prop-types';

import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { ProgressSections } from 'material-ui/components/progress';
import { andList } from 'utils';

import Typography from '@wui/basics/typography';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup';

export default class ResidualJointWarning extends MuiWizardPage {
  static progressPage = ProgressSections.YOUR_WISHES.ASSETS;

  static propTypes = {
    ...MuiWizardPage.propTypes,

    // Since the assets section currently has a weird flow, we'll be visiting this twice now
    forSpouse: PropTypes.bool,
  };

  static defaultProps = {
    ...MuiWizardPage.defaultProps,
    forSpouse: false,
  };

  componentDidMount() {
    super.componentDidMount();

    this.disableTabs();
    this.setForSpouse(this.props.forSpouse, true);
  }

  renderPage() {
    const asset = this.testator.residualJointAsset;
    const beneficiaries = this.testator.getPrimaryBeneficiariesForAsset(asset);

    const t = this.testator;
    const s = this.testator.spouse;

    return (
      <React.Fragment>
        <SpacedFieldGroup masterHeading="Real Estate for Married Couples">
          <Typography variant="subtitle1">
            As {t.firstNames} spouse, {s.firstName} is first in line to receive the home from{' '}
            {t.firstName}. {s.firstName} and {t.firstName} decide together who gets the home after
            both {s.firstName} and {t.firstName} pass away.
          </Typography>

          <Typography variant="subtitle1">
            If your wishes are different from each other, we'll combine them into a joint plan.
          </Typography>
        </SpacedFieldGroup>

        <SpacedFieldGroup
          subHeading={t.name}
          caption={`Here's who you've named to receive the home after both you and ${s.firstName} pass away:`}
        >
          <Typography variant="subtitle1">{andList(beneficiaries.map(p => p.name))}</Typography>
        </SpacedFieldGroup>
      </React.Fragment>
    );
  }
}
