import React from 'react';
import { BoundControl } from 'ui/components/BoundControl';
import PureCheck from 'ui/components/pure-controls/PureCheck';
import PropTypes from 'prop-types';

export default class Check extends BoundControl {
  static propTypes = {
    centerCheck: PropTypes.bool,
  };

  componentDidMount() {
    const rawValue = this.getRawValue(),
      checked = Boolean(rawValue);
    if (checked !== rawValue) {
      this.setRawValue(checked);
    }
  }

  handleClick() {
    this.setRawValue(!this.getRawValue());
  }

  render() {
    const checked = Boolean(this.getRawValue());

    return (
      <PureCheck
        onChange={() => this.handleClick()}
        checked={checked}
        dataPath={this.props.path}
        centerCheck={this.props.centerCheck}
      >
        {this.props.children}
      </PureCheck>
    );
  }
}
