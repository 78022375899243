import React from 'react';

import sentry from '@willing-shared/utils/sentry';
import WizardPage from 'ui/components/wizard/WizardPage';
import { API } from 'API';
import { NotarizationStatus } from 'utils/enums';
import CallRoomMixin from 'ui/sections/notarize/pages/CallRoomMixin';
import VideoSession from 'ui/execution/VideoSession';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import RecordingIndicator from 'ui/execution/RecordingIndicator';
import DocumentImageViewer from 'ui/components/document-image-viewer/DocumentImageViewer';

export default class CallRoom extends CallRoomMixin(WizardPage) {
  static baseUrl = 'call/';
  static pageName = 'On a call with your notary';
  static showPrevNextButtons = false;
  static cardwrapperClass = 'cardwrapper_full_height';
  static cardClass = 'card--fixed-height card--call-room';
  static wizardPageClass = 'wizard-page--full-height';
  static hideNav = true;

  fetchedSession = false;

  constructor(props, context) {
    super(props, context);

    this.state = {
      sessionReady: false,
      session: null,
      token: null,
      showClientConfirmation: true,
      showSpouseConfirmation: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    API.getAuraStatus();
  }

  updateSession = async () => {
    let response = null;

    try {
      response = await API.getNotarizationSession();
    } catch (e) {
      sentry.captureException(e);
      return;
    }

    if (response.data && response.data.status !== NotarizationStatus.ONGOING) {
      if (response.data.status === NotarizationStatus.COMPLETED) {
        this.analyticsEvents.gtag('event', 'online-notarization-completed', {
          event_category: 'aura-integration',
        });
      }
      this.goToNext();
      return;
    }

    this.setState({
      session: response.data,
    });

    if (!this.fetchedSession) {
      this.fetchedSession = true;
      try {
        const tokenResponse = await API.getNotarizationToken();
        this.setState({
          token: tokenResponse.data.token,
          sessionReady: true,
        });
      } catch (e) {
        sentry.captureException(e);
        this.fetchedSession = false;
      }
    }
  };

  signDocuments = () => {
    API.postAdvanceStep();
  };

  get signatureRequested() {
    const { session } = this.state;

    return (
      session &&
      ['client_statement', 'client_requested', 'spouse_statement', 'spouse_requested'].includes(
        session.signingStatus,
      )
    );
  }

  get statementOnly() {
    const { session } = this.state;

    return session && ['client_statement', 'spouse_statement'].includes(session.signingStatus);
  }

  get signatureApplied() {
    const { session } = this.state;

    return session && ['client_signed', 'spouse_signed'].includes(session.signingStatus);
  }

  get requestedTestator() {
    if (!this.state.session) {
      return null;
    }

    const { signingStatus } = this.state.session;

    if (['client_statement', 'client_requested', 'client_signed'].includes(signingStatus)) {
      return this.clientData;
    } else if (['spouse_statement', 'spouse_requested', 'spouse_signed'].includes(signingStatus)) {
      return this.clientData.spouse;
    }

    return null;
  }

  get popupStateKey() {
    if (!this.requestedTestator) {
      return null;
    }
    return this.requestedTestator.isPrimary ? 'showClientConfirmation' : 'showSpouseConfirmation';
  }

  get shouldShowConfirmationPopup() {
    return this.popupStateKey && this.signatureApplied && this.state[this.popupStateKey];
  }

  // Intentionally overwriting render() here, as we don't want or need any of the stuff that comes with it,
  // and it messes up our overflow
  render() {
    const { session } = this.state;

    return (
      <React.Fragment>
        {this.signatureRequested && (
          <div className="popup-fuzz">
            <Popup showCloseIcon={false} className="popup--top-padding popup--signature">
              <h4>Please say the following out loud:</h4>
              <p>
                I, {this.requestedTestator.name}, understand that I am about to electronically sign
                important legal documents. I am signing voluntarily and of my own free will.
              </p>
              {!this.statementOnly && (
                <PureButton onClick={this.signDocuments} className="button--primary">
                  Sign all my documents
                </PureButton>
              )}
            </Popup>
          </div>
        )}

        {this.shouldShowConfirmationPopup && (
          <div className="popup-fuzz">
            <Popup showCloseIcon={false} className="popup--top-padding popup--signature">
              <h4>Signature successfully applied!</h4>
              <p>Your signature has been successfully applied to all of your documents.</p>
              <PureButton
                onClick={() =>
                  this.setState({
                    [this.popupStateKey]: false,
                  })
                }
                className="button--primary"
              >
                Close
              </PureButton>
            </Popup>
          </div>
        )}

        <div className="document-view">
          <div className="top">
            <div className="left">
              <DocumentImageViewer session={session} />
            </div>

            <div className="right">
              {session && session.status === 'ongoing' && session.opentokIsArchiving && (
                <RecordingIndicator />
              )}
              {this.state.sessionReady && (
                <VideoSession
                  sessionId={this.state.session.opentokSessionId}
                  token={this.state.token}
                  name={`Principal${this.state.session.includesSpouse ? 's' : ''}`}
                  videoSource={this.clientData.acceptedVideoSource}
                  audioSource={this.clientData.acceptedAudioSource}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
