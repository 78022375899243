import PropTypes from 'prop-types';
import React from 'react';
import { observer } from 'mobx-react';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';

const DocumentDetails = ({ title, text }) => (
  <Grid item xs={12} md={4}>
    <Typography variant="h6">{title}</Typography>
    <Typography variant="caption" style={{ lineHeight: '28px' }}>
      {text}
    </Typography>
  </Grid>
);

DocumentDetails.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default observer(DocumentDetails);
