import React from 'react';
import PropTypes from 'prop-types';

import PureButton from 'ui/components/pure-controls/PureButton';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

import { getPersonNameByUUID, testName } from 'utils';
import { validatePersonNameUniqueness } from 'models/utils';

import './UpdateModal.scss';

export class UpdateModal extends ClientDataReferer {
  static propTypes = {
    dataset: PropTypes.object.isRequired,
    review: PropTypes.object.isRequired,
    handleUpdatePopUp: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    const { fullName, firstName } = this.getPersonName();
    let personData = {};
    this.clientData.walkPeople(null, person => {
      if (person.personUUID === props.dataset.personUuid) {
        personData = person;
      }
    });

    this.state = {
      popup: false,
      name: fullName,
      oldName: firstName,
      oldFullName: fullName,
      error: '',
      person: personData,
    };
  }

  onChangeName = event => {
    let value = event.target.value;

    this.setState({ name: value, error: '' });

    return true;
  };

  onPressUpdate = event => {
    if (event.charCode === 13) {
      this.onUpdateName();
    }
  };

  onUpdateName = () => {
    const { dataset } = this.props;
    const { name, oldFullName, oldName } = this.state;

    const error = this.validateCallback();

    if (error && error.length > 0) {
      this.setState({ error });
      return false;
    }

    const data = {
      fullName: name,
      firstName: name.split(' ')[0],
      oldName: oldName,
      oldFullName: oldFullName,
      personUUID: dataset.personUuid,
    };

    this.props.handleUpdatePopUp('update', data);
    return true;
  };

  validateCallback = () => {
    const { dataset } = this.props;
    const { name } = this.state;

    if (!testName(name)) {
      return ['Please enter a properly capitalized full name.'];
    }

    const error = validatePersonNameUniqueness(this.clientData, {
      personUUID: dataset.personUuid,
      name: name,
    })();

    if (error.length === 1) {
      return error[0];
    }

    return false;
  };

  getPersonName = () => getPersonNameByUUID(this.clientData, this.props.dataset.personUuid);

  render() {
    const { fullName } = this.getPersonName();
    const { name, error } = this.state;

    return (
      <div className="update__wrapper">
        <div className="update__item">
          <label>Update Spelling</label>
          <input
            type="text"
            value={name}
            className="text-input text-input--fullsize go-to-next__cancel"
            onChange={this.onChangeName}
            onKeyPress={this.onPressUpdate}
          />
          {error && <div className="update-error update-error--red">{error}</div>}
        </div>
        <PureButton
          className="button--primary button--inline update__item go-to-next__cancel"
          onClick={this.onUpdateName}
          disabled={name === fullName || Boolean(error)}
        >
          Update Spelling
        </PureButton>
      </div>
    );
  }
}
