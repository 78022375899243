import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';
import { Tips } from '@c/tips';
import { AppLocation } from 'utils/enums';

import Layout from '@c/layout/Layout';
import Typography from '@wui/basics/typography';

import SecurityBadge from '@a/images/security-badge.svg';

const styles = theme => ({
  content: {
    marginTop: 32,
    fontWeight: 'normal',

    [theme.breakpoints.up('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    marginBottom: 16,

    [theme.breakpoints.up('sm')]: theme.typography.h1,
  },
  tip: {
    display: 'flex',
    alignItems: 'center',

    '& img': {
      height: 48,
      marginRight: 16,
    },
  },
  spacer: {
    height: 48,
  },
});

class WelcomeBack extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static contextTypes = {
    page: PropTypes.object,
    testator: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout wide={true} nextButtonText="Let's go!" appLocation={AppLocation.ABOUT_YOU}>
        <Tips>
          <div className={classes.tip}>
            <img src={SecurityBadge} alt="" />

            <div>
              <Typography variant="h6">Secure and Confidential</Typography>
              <Typography variant="body1">We never share your information.</Typography>
            </div>
          </div>
        </Tips>

        <Typography
          component="div"
          className={classes.content}
          variant={!this.isTabletOrSmallerDisplay ? 'h5' : 'h6'}
        >
          <Typography variant="h2" className={classes.title}>
            Welcome Back!
          </Typography>
          It's a great time to write your will — our site is now better than ever.
          <br />
          <br />
          We're here to help if you need anything!
        </Typography>

        <div className={classes.spacer} />
      </Layout>
    );
  }
}

export default withStyles(styles)(withIsMobileDisplay(WelcomeBack));
