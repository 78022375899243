import React from 'react';

import { Tip, Tips } from '@c/tips';

export default class RealEstateTips extends React.Component {
  render() {
    return (
      <Tips>
        <Tip title="Real Estate">
          Real estate plays an important role in an estate plan. It is often subject to special
          state and county requirements.
        </Tip>
      </Tips>
    );
  }
}
