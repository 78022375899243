import React from 'react';
import { Link } from 'react-router-dom';
import MobileNav from 'ui/MobileNav';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { Urls } from 'urls';
import { documentBundleIncludes, joinClass, getPhoneNumber, getEmail } from 'utils';
import PureButton from 'ui/components/pure-controls/PureButton';
import { NavDropDown } from 'ui/NavDropDown';
import { DocumentBundle } from 'utils/enums';
import CheckoutV6 from 'material-ui/pages/checkout-v6/CheckoutV6';
import SubscriptionPlanPricing from 'ui/pricing/SubscriptionPlanPricing';
import Popup from 'ui/components/Popup';
import { withCurrentPageClass } from 'utils/withCurrentPageClass';
import classNames from 'classnames';

import './Nav.scss';
import './SignupNav.scss';

export class Nav extends withCurrentPageClass(ClientDataReferer) {
  constructor(props, context) {
    super(props, context);

    this.state = {
      accountOpen: false,
      showConfirmationPopup: false,
      rolePickerActive: false,
    };
  }

  accountClick = () => {
    this.setState({ accountOpen: !this.state.accountOpen });
  };

  login = async e => {
    const { fromPortunus } = this.serverData;
    const { history } = this.context;
    e.preventDefault();
    if (fromPortunus) {
      window.location = window.WillingConfig.membersAppLink;
    } else {
      history.push(Urls.login);
    }
  };

  logout = async e => {
    e.preventDefault();
    await this.clientDataHolder.logout();
  };

  get isOnCheckout() {
    return (
      window.location.pathname === CheckoutV6.url ||
      window.location.pathname === SubscriptionPlanPricing.url
    );
  }

  get isOnLogin() {
    return window.location.pathname === Urls.login;
  }

  get isOnPricing() {
    return (
      window.location.pathname === CheckoutV6.url ||
      window.location.pathname === SubscriptionPlanPricing.url
    );
  }

  get hideForCustomDisplay() {
    return (
      this.currentPageClass &&
      (Boolean(this.currentPageClass.customDisplay(this.context.experiments)) ||
        this.currentPageClass.isMaterial ||
        this.currentPageClass.hideNav)
    );
  }

  get seenStorageNotification() {
    return this.clientData._isKeyValid('storageNotification');
  }

  get isOnDocVerification() {
    return window.location.pathname.startsWith(Urls.documentVerificationViewer);
  }

  setSeenStorageNotification = () => {
    this.clientData._setKeyValid('storageNotification');

    if (this.triggerValidation()) {
      this.clientDataHolder.persistClientData();
    }

    this.forceUpdate();
  };

  renderSupport() {
    if (this.clientDataHolder.permissions.length >= 1) {
      return null;
    }

    const phoneNumber = getPhoneNumber(this.clientData, this.serverData);
    if (this.clientDataHolder.supportInfo.showSupportPhoneNumber && this.serverData.fromPortunus) {
      return <div className="contact-info">Questions? Call us at {phoneNumber}</div>;
    }
    const email = getEmail();
    return (
      <div className="contact-info">
        Questions? Email us at <a href={`mailto:${email}`}>{email}</a>
      </div>
    );
  }

  renderDocumentBundle = () => (
    <div className="top-nav-link">
      <Link to={`${Urls.checkoutPicker}`}>
        {this.serverData.hasSubscription &&
        documentBundleIncludes(this.serverData.documentBundle, DocumentBundle.HOMEOWNERS)
          ? 'Document Bundles'
          : 'Upgrade'}
      </Link>
    </div>
  );

  renderSubscriptionPlan = () => {
    if (!this.serverData.documentBundle || !this.serverData.subscriptionPlan) {
      return '';
    }

    return (
      <div className="top-nav-link">
        <Link to={Urls.subscriptionPicker}>Subscription Plans</Link>
      </div>
    );
  };

  get executionRole() {
    const splitPath = window.location.pathname.split('/')[1];

    if (['notary', 'witness'].includes(splitPath)) {
      return splitPath;
    }

    return 'client';
  }

  renderMode = () => {
    const permissions = this.clientDataHolder.permissions;

    if (permissions.length === 1) {
      return null;
    }

    const { rolePickerActive } = this.state;

    return (
      <div
        className="mode-select"
        onClick={() => this.setState({ rolePickerActive: !rolePickerActive })}
      >
        Role: {this.executionRole} &#9660;
        {rolePickerActive && (
          <NavDropDown clickOutside={() => this.setState({ rolePickerActive: false })}>
            {permissions.map(([url, name]) => (
              <Link to={url} key={name}>
                {name}
              </Link>
            ))}
          </NavDropDown>
        )}
      </div>
    );
  };

  renderMyDocuments() {
    if (!this.serverData.hasNotarizedOnline) {
      return null;
    }

    return (
      <div className="my-documents">
        {!this.seenStorageNotification && (
          <div className="notification">
            <div className="caret" />
            Access your documents at any time here.
            <br />
            <PureButton onClick={this.setSeenStorageNotification} className="button--tertiary">
              Close
            </PureButton>
          </div>
        )}
        <Link to={Urls.storage}>My documents</Link>
      </div>
    );
  }

  renderRight = () => {
    if (this.isOnDocVerification) {
      return <div className="navbar__right navbar-info">{this.renderSupport()}</div>;
    }

    if (this.clientDataHolder.isAuthenticated) {
      const personalDetailsUrl =
        this.clientDataHolder.permissions.length === 1 ? (
          ''
        ) : (
          <div className="top-nav-link">
            <Link to="/notary-details/">Personal Details</Link>
          </div>
        );

      return (
        <div className="navbar__right navbar-info">
          <div id="navbar-extension" />
          {this.renderSupport()}
          {this.renderMode()}
          {!this.clientData.covidEmployer &&
            !this.serverData.fromPortunus &&
            this.renderDocumentBundle()}
          {!this.clientData.covidEmployer &&
            !this.serverData.fromPortunus &&
            this.renderSubscriptionPlan()}
          {this.renderMyDocuments()}
          {personalDetailsUrl}
          <div className="navbar-logout">
            <Link to="#" onClick={this.logout}>
              Logout
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="navbar__right">
          <Link to={Urls.login} className="right__item">
            <PureButton onClick={this.login} className="button--tertiary button--nopadding">
              Log in
            </PureButton>
          </Link>
        </div>
      );
    }
  };

  handleLogoClick = e => {
    e.preventDefault();

    if (this.serverData.fromPortunus) {
      window.location = `${window.WillingConfig.membersAppLink}/services`;
      return;
    }

    this.context.history.push(this.context.clientDataHolder.dashboardUrl);
  };

  renderConfirmationPopup() {
    const closeFn = () => this.setState({ showConfirmationPopup: false });
    const leavePageFn = () => {
      this.setState({ showConfirmationPopup: false });
      this.context.history.push(this.context.clientDataHolder.dashboardUrl);
    };

    return (
      <div className="popup-fuzz popup-fuzz--confirmation-popup">
        <Popup onCloseClick={closeFn}>
          <h4 className="center">Are you sure?</h4>
          <p className="center small">
            You're almost done! You can make changes to your documents after you've purchased.
          </p>
          <div className="button-options">
            <PureButton className="button--primary" onClick={closeFn}>
              Stay and finish
            </PureButton>
            <PureButton className="button--tertiary" onClick={leavePageFn}>
              Leave this page
            </PureButton>
          </div>
        </Popup>
      </div>
    );
  }

  render() {
    if (this.hideForCustomDisplay) {
      return '';
    }

    let className = 'navbar';

    if (this.isOnCheckout) {
      className = joinClass(className, 'navbar--checkout');
    } else if (!this.clientDataHolder.isAuthenticated && !this.isOnDocVerification) {
      // If we're not authenticated there's only two places we can be - we want app nav on one
      className = joinClass(className, 'navbar--signup');
    }

    const wrapperClassName = joinClass(
      'nav-container',
      this.clientDataHolder.serverData.impersonating && 'impersonating',
    );

    const brandClass = classNames('navbar__brand', {
      mlp: this.serverData.fromPortunus,
    });

    const logoAlt = 'Metlife logo home page';

    return (
      <div className={wrapperClassName}>
        {this.state.showConfirmationPopup && this.renderConfirmationPopup()}
        <MobileNav handleLogoClick={this.handleLogoClick} />

        <nav className={className}>
          <div className="navbar__left">
            {this.clientDataHolder.isAuthenticated ? (
              <Link to="/" aria-label={logoAlt} onClick={this.handleLogoClick}>
                <span className={brandClass} />
              </Link>
            ) : (
              <a href="/" aria-Label={logoAlt}>
                <span className={brandClass} />
              </a>
            )}
          </div>
          {!this.isOnRegister && this.renderRight()}
        </nav>
      </div>
    );
  }
}
