import React, { useState, useEffect } from 'react';
import { useGlobalContext } from 'hooks';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import OpenTokPublisher from 'material-ui/components/OpenTokPublisher';
import { getDevices } from '../utils';

import Grid from '@wui/layout/grid';
import ButtonFooter from '../ButtonFooter';
import Failed from './Failed';

const useStyles = makeStyles({
  root: {
    maxWidth: 571,
  },
  video: {
    height: 296,
  },
});

const Fields = ({ goToNext }) => {
  const { clientData, updateClientData } = useGlobalContext();
  const [videoDevices, setVideoDevices] = useState(null);
  const [videoDeviceIndex, setVideoDeviceIndex] = useState(null);
  const [isSuccess, setIsSuccess] = useState(null);
  const [publisherOptions, setPublisherOptions] = useState(null);

  const classes = useStyles();

  const incrementDeviceIndex = () => {
    setVideoDeviceIndex(v => (v === null ? 0 : v + 1));
  };

  useEffect(() => {
    const initTechCheck = async () => {
      const devices = await getDevices();
      const foundVideoDevices = devices.filter(d => d.kind === 'videoInput');

      return foundVideoDevices;
    };

    initTechCheck().then(newDevices => {
      setVideoDevices(newDevices);
      incrementDeviceIndex();
    });
  }, []);

  useEffect(() => {
    if (!videoDevices || !videoDevices.length || videoDeviceIndex === null) {
      return;
    }

    const videoDevice = videoDevices[videoDeviceIndex];

    setPublisherOptions({
      insertMode: 'append',
      width: '100%',
      height: '100%',
      videoSource: videoDevice.deviceId,
      fitMode: 'contain',
      style: { buttonDisplayMode: 'off' },
    });
  }, [videoDevices, videoDeviceIndex]);

  useEffect(() => {
    if (isSuccess) {
      goToNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const acceptSource = () => {
    const source = videoDevices[videoDeviceIndex].deviceId;

    updateClientData(() => {
      clientData.setRawValue('acceptedVideoSource', source);
    });

    setIsSuccess(true);
  };

  const rejectSource = () => {
    if (videoDeviceIndex < videoDevices.length - 1) {
      incrementDeviceIndex();
    } else {
      setIsSuccess(false);
    }
  };

  const retry = () => {
    setIsSuccess(null);
    setVideoDeviceIndex(0);
  };

  if (isSuccess === false) {
    return <Failed retry={retry} />;
  }

  return (
    <Grid container direction="row" wrap="nowrap" justify="center">
      <div className={classes.root}>
        <Grid container direction="column">
          <div className={classes.video} id="publisher" />

          {publisherOptions && <OpenTokPublisher options={publisherOptions} />}

          <ButtonFooter
            prompt="Do you see yourself in the frame above?"
            acceptAction={acceptSource}
            rejectAction={rejectSource}
          />
        </Grid>
      </div>
    </Grid>
  );
};

Fields.propTypes = {
  goToNext: PropTypes.func.isRequired,
};

export default observer(Fields);
