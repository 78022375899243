import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';

import { getFileSections } from 'ui/sections/sections';
import {
  fileSectionsWeight,
  notificationsWeight,
} from 'material-ui/components/planHealth/PlanHealthMeter';
import SectionSign from 'ui/sections/sign/SectionSign';
import SectionNotify from 'ui/sections/file/notify/SectionNotify';
import SectionsDisplay from 'material-ui/components/dashboard/SectionsDisplay';
import DashboardLayout from '@c/dashboard/DashboardLayout';
import { digitalEstatePlanningService } from '@willing-shared/utils/env';
import { useDocumentTitle, useGlobalContext } from 'hooks';
import Urls from 'urls';

import Header from './Header';
import DigexDocumentList from 'material-ui/pages/PostPurchaseDashboard/DigexDocumentList';
import Grid from '@material-ui/core/Grid';
import { API } from 'API';

const goToMembers = () => {
  window.location = `${window.WillingConfig.membersAppLink}/services`;
};

const PostPurchaseDashboard = ({ title }) => {
  useDocumentTitle(title);
  const { clientData, serverData, clientDataHolder } = useGlobalContext();
  const [documentsUrl, setDocumentsUrl] = useState(false);
  const intervalRef = useRef(null);

  // This is a bit ugly, but we need to check that the document has been processed and uploaded to S3 before we allow
  // the user to attempt to download them.
  const updateDocumentsUrl = useRef(async () => {
    try {
      const {
        data: { urls },
      } = await API.getNotarizationEvidenceUrls();
      if (urls.downloadPdfUrl) {
        setDocumentsUrl(urls.downloadPdfUrl);
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } catch {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  });

  useEffect(() => {
    if (!serverData.hasNotarizedOnline) {
      return () => null;
    }
    intervalRef.current = setInterval(() => updateDocumentsUrl.current(), 2000);
    updateDocumentsUrl.current();
    return () => {
      if (intervalRef.current) {
        window.clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [serverData.hasNotarizedOnline]);

  const fileSections = useMemo(() => {
    const sections =
      clientData.covidEmployer || serverData.isFreeLook || digitalEstatePlanningService
        ? [SectionSign]
        : [SectionSign].concat(getFileSections(clientData, serverData));
    const fileSectionsPercent = fileSectionsWeight * 100;
    const notificationsPercent = notificationsWeight * 100;

    const percentageGiven = Math.floor(fileSectionsPercent / sections.length);
    const isFractional = fileSectionsPercent % sections.length !== 0;

    const percentageText = isNotify => {
      const prefix = isFractional ? '~' : '';
      const percentage = isNotify ? notificationsPercent + percentageGiven : percentageGiven;

      if (clientData.covidEmployer || serverData.fromPortunus) {
        return '';
      }
      return `Increase your plan health by ${prefix}${percentage}%`;
    };

    return sections.map(
      s =>
        class extends s {
          static description = percentageText(s === SectionNotify);
        },
    );
  }, [clientData, serverData]);

  // Send anyone who has not completed their plan to plan dashboard
  if (!serverData.hasNotarizedOnline && !clientData.isPlanValid()) {
    return <Redirect to={clientDataHolder.dashboardUrl} />;
  }
  const showExitButton =
    serverData.fromPortunus &&
    clientData.covidEmployer !== 'upwise' &&
    !serverData.isFreeLook &&
    !digitalEstatePlanningService;

  const buttonSection = (
    <>
      <Spacer v={32} />
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={2}>
        {showExitButton && (
          <Grid item>
            <Button variant="contained" color="primary" onClick={goToMembers}>
              Exit to main menu
            </Button>
          </Grid>
        )}
        {serverData.hasNotarizedOnline && (
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!documentsUrl}
                  href={documentsUrl}
                >
                  {documentsUrl ? 'Download All' : 'Loading...'}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" color="primary" component={RouterLink} to={Urls.storage}>
                  View All
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );

  return (
    <DashboardLayout showPlanHealth={false}>
      <Header />

      <Spacer v={32} smDown />
      <Spacer v={48} mdUp />

      {serverData.hasNotarizedOnline ? (
        <DigexDocumentList />
      ) : (
        <SectionsDisplay sections={fileSections} />
      )}

      {buttonSection}

      {clientData.covidEmployer === 'upwise' && (
        <>
          <Spacer v={32} />
          <Button variant="contained" color="primary" href="#close">
            Return to Upwise
          </Button>
        </>
      )}
    </DashboardLayout>
  );
};

PostPurchaseDashboard.propTypes = {
  title: PropTypes.string,
};
export default observer(PostPurchaseDashboard);
