import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { Urls } from 'urls';

import App from 'material-ui/WuiApp';
import TwoColumnContent from '@willing-shared/components/layout/TwoColumnContent';
import PureTextField from '@wui/input/textbox';
import StyledButton from '@willing-shared/components/StyledButton';
import StyledPaper from '@willing-shared/components/layout/StyledPaper';

import ChecklistIcon from 'material-ui/assets/images/checklist.svg';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';

const styles = theme => ({
  textItem: {
    marginBottom: 32,
  },
  enterPlanButton: {
    marginTop: 32,
  },
  buttonContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
  },
  checklistIcon: {
    maxWidth: '100%',
  },
});

class DocumentVerificationLanding extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
    title: PropTypes.string,
  };

  static contextTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
  };

  static url = Urls.documentVerificationLanding;

  constructor(props) {
    super(props);

    this.state = {
      documentId: '',
      pin: '',
      redirectToViewer: false,
    };
  }

  componentDidMount() {
    const { enqueueSnackbar, title } = this.props;
    const { state } = this.context.location;

    document.title = title || DEFAULT_DOCUMENT_TITLE;
    if (state && state.errorMessage) {
      enqueueSnackbar(state.errorMessage, { variant: 'error' });
    }
  }

  updateField = field => e => this.setState({ [field]: e.target.value });

  renderRight = () => (
    <StyledPaper>
      <img alt="" src={ChecklistIcon} className={this.props.classes.checklistIcon} />
      <Typography variant="h4">Are your wishes covered?</Typography>

      <Typography variant="subtitle1">
        If you don't already have a plan in place, now is the time. Document your wishes and invite
        loved ones to your Family Center.
      </Typography>

      <StyledButton
        variant="contained"
        color="primary"
        className={this.props.classes.enterPlanButton}
        component="a"
        href={Urls.login}
      >
        Enter My Plan
      </StyledButton>
    </StyledPaper>
  );

  render() {
    const { documentId, pin, redirectToViewer } = this.state;
    const { classes } = this.props;

    if (redirectToViewer) {
      const params = new URLSearchParams({ documentId });
      return (
        <Redirect
          to={{
            pathname: Urls.documentVerificationViewer,
            search: params.toString(),
            state: { pin },
          }}
        />
      );
    }
    return (
      <App>
        <TwoColumnContent right={this.renderRight()}>
          <Typography variant="h2" className={classes.textItem}>
            Verify a Document
          </Typography>

          <Typography variant="subtitle1" className={classes.textItem}>
            Enter the Document ID and Session PIN below to verify a document
          </Typography>

          <PureTextField
            label="Document ID"
            value={documentId}
            onChange={this.updateField('documentId')}
          />

          <PureTextField label="PIN" value={pin} onChange={this.updateField('pin')} />

          <div className={classes.buttonContainer}>
            <StyledButton
              variant="contained"
              color="primary"
              disabled={!documentId || !pin}
              onClick={() => {
                this.setState({ redirectToViewer: true });
              }}
            >
              Next
            </StyledButton>
          </div>
        </TwoColumnContent>
      </App>
    );
  }
}

export default withStyles(styles)(withSnackbar(DocumentVerificationLanding));
