// The position of the baseline indicator in the signature input relative to
//   the top of the canvas
const SIGNATURE_UI_BASELINE_OFFSET = 2 / 3;
const SIGNATURE_HORIZONTAL_PADDING = 0.05;

// This class is used to trim extra whitespace from canvas elements and to
//   apply padding around the trimmed content.
export class CanvasTrimmer {
  constructor(canvas) {
    this.context = canvas.getContext('2d');
    this.width = canvas.width;
    this.height = canvas.height;
    this.data = this.context.getImageData(0, 0, this.width, this.height).data;
  }

  // A pixel is considered "Filled" if it has a non-zero alpha
  isPixelFilled = (row, col) => Boolean(this.data[(this.width * row + col) * 4 + 3]);

  firstFilledCol(reverse) {
    const offset = reverse ? -1 : 1;
    const start = reverse ? this.width - 1 : 0;
    const condition = i => (reverse ? i >= 0 : i < this.width);

    for (let col = start; condition(col); col += offset) {
      for (let row = 0; row < this.height; row++) {
        if (this.isPixelFilled(row, col)) {
          return col;
        }
      }
    }
    throw new Error("Can't trim an empty canvas");
  }

  firstFilledRow(reverse) {
    const offset = reverse ? -1 : 1;
    const start = reverse ? this.height - 1 : 0;
    const condition = i => (reverse ? i >= 0 : i < this.height);

    for (let row = start; condition(row); row += offset) {
      for (let col = 0; col < this.width; col++) {
        if (this.isPixelFilled(row, col)) {
          return row;
        }
      }
    }
    throw new Error("Can't trim an empty canvas");
  }

  get contentBoundaries() {
    // Since this is somewhat costly to calculate we cache the value
    //   after the first call
    this.cachedSignatureBoundaries = this.cachedSignatureBoundaries || {
      rowStart: this.firstFilledRow(false),
      rowEnd: this.firstFilledRow(true),
      colStart: this.firstFilledCol(false),
      colEnd: this.firstFilledCol(true),
    };
    return this.cachedSignatureBoundaries;
  }

  get contentWidth() {
    const { colStart, colEnd } = this.contentBoundaries;
    return colEnd - colStart + 1;
  }

  get contentHeight() {
    const { rowStart, rowEnd } = this.contentBoundaries;
    return rowEnd - rowStart + 1;
  }

  // This method calculates the desired padding for a signature captured
  //   using the SignaturePopup UI. The top and bottom padding are
  //   calculated so that the baseline in the UI will be at 50% of the
  //   height of the resulting image. This is to help keep the baseline
  //   consistent when the image is scaled and is consistent with how
  //   font-based signatures are padded.
  get paddingForSignature() {
    const { rowStart, rowEnd } = this.contentBoundaries;

    // baselinePosition is the row in the canvas which coincides with the
    //   baseline in the signature drawing interface.
    const baselinePosition = Math.min(this.height * SIGNATURE_UI_BASELINE_OFFSET, rowEnd);
    const aboveLine = baselinePosition - rowStart;
    const belowLine = rowEnd - baselinePosition;

    const horizontalPadding = Math.floor(SIGNATURE_HORIZONTAL_PADDING * this.contentWidth);

    return {
      top: Math.floor(Math.max(0, belowLine - aboveLine)),
      bottom: Math.floor(Math.max(0, aboveLine - belowLine)),
      left: horizontalPadding,
      right: horizontalPadding,
    };
  }

  trimmedCanvasWithPadding(padding) {
    const {
      contentWidth,
      contentHeight,
      contentBoundaries: { colStart, rowStart },
    } = this;

    // We create a new canvas to avoid mutating the one we received in the constructor
    const canvas = document.createElement('canvas');

    // The new canvas is sized to contain the trimmed content of the
    //   original canvas and the desired padding
    canvas.width = contentWidth + padding.left + padding.right;
    canvas.height = contentHeight + padding.top + padding.bottom;

    // Copy the trimmed image from the original canvas and place it in the
    //   appropriate position within the new one
    const context = canvas.getContext('2d');
    const trimmedData = this.context.getImageData(colStart, rowStart, contentWidth, contentHeight);
    context.putImageData(trimmedData, padding.left, padding.top);

    return canvas;
  }

  // This method is used to apply padding to canvases generated by our
  //   DrawSignatureInput UI to ensure that they are positioned consistently
  //   when rendered in the documents.
  static trimSignature(signatureCanvas) {
    const trimmer = new this(signatureCanvas);
    return trimmer.trimmedCanvasWithPadding(trimmer.paddingForSignature);
  }
}
