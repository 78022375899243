import React from 'react';

const RecordingIndicator = () => (
  <div className="recording">
    <div className="recording__indicator blink" />
    <div className="recording__label">Recording</div>
  </div>
);

export default RecordingIndicator;
