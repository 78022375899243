import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FailurePage from '../Failure';

import Typography from '@wui/basics/typography';

const tips = [
  'Try moving closer to the router',
  'Turn off applications or other systems that may be slowing down your internet',
  'Try switching to a different internet source',
];

const Failed = ({ retry }) => (
  <FailurePage
    message="Internet check failed"
    details="We detected some internet connectivity issues that could impact your experience. We recommend attempting the following fixes:"
    buttonText="Retry internet test"
    retry={retry}
  >
    <ul>
      {tips.map(tip => (
        <li key={tip}>
          <Typography>{tip}</Typography>
        </li>
      ))}
    </ul>
  </FailurePage>
);

Failed.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default observer(Failed);
