import PropTypes from 'prop-types';

const propTypes = {
  // Whether or not the control can be changed.
  disabled: PropTypes.bool,

  // Text displayed below the input field
  //   (when there are no errors) to help
  //   the user fill in the field.
  helperText: PropTypes.string,

  // The name that is displayed for the field.
  label: PropTypes.string.isRequired,

  // Whether or not to use the raw methods when
  //   getting and setting values for this
  //   field.
  raw: PropTypes.bool,

  // Whether or not standard validations should be
  //   used for this field. This is useful when
  //   modifying non-raw paths that use getters
  //   and setters.
  skipStandardValidation: PropTypes.bool,

  // A callback to trigger when the value has
  //   changed.
  onChange: PropTypes.func,

  // An array of functions that performs additional
  //   validations on the field. The functions should
  //   return an array of error messages if there are
  //   any errors. Otherwise, return an empty array.
  additionalValidations: PropTypes.arrayOf(PropTypes.func),
};

const defaultProps = {
  raw: true,
  onChange: () => {},
  additionalValidations: [],
  skipStandardValidation: false,
  disabled: false,
};

export { propTypes, defaultProps };
