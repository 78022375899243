import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import FormHelperText from '@material-ui/core/FormHelperText';

import Typography from '@wui/basics/typography';
import Textbox from '@wui/input/textbox';
import Checkbox from '@wui/input/checkbox';
import Form from '@wui/layout/form';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Modal from '@wui/basics/modal';

import WuiApp from '@/WuiApp';
import Layout from '@c/layout/Layout';
import { API } from 'API';
import Urls from 'urls';
import { useGlobalContext, useInputFieldState, useDocumentTitle } from 'hooks';
import SectionSign from 'ui/sections/sign/SectionSign';
import NotEnrolledModal from 'ui/components/NotEnrolledModal';

const termsLabel =
  'By submitting my request for an attorney to review my documents I understand that the documents will be ' +
  'accessed by MetLife Legal Plans and shared with the attorney via email to facilitate the scheduling of the service.';

const phoneNumberDigits = 10;
const formatPhoneNumber = phoneNumber => {
  const numbers = phoneNumber.replace(/[\D]/g, '');

  const pieces = [numbers.slice(0, 3), numbers.slice(3, 6), numbers.slice(6, 10)];
  if (pieces[1]) {
    pieces[0] = `(${pieces[0]}) `;
  }
  if (pieces[2]) {
    pieces[1] += '-';
  }
  return pieces.join('');
};

const useStyles = makeStyles({
  checkbox: {
    alignSelf: 'flex-start',
  },
});

const AttorneyReview = ({ title }) => {
  useDocumentTitle(title);
  const { clientData, serverData, clientDataHolder } = useGlobalContext();
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = useInputFieldState(clientData.email || '');
  const [phoneNumber, setPhoneNumber] = useState(
    formatPhoneNumber(clientData.profile.phoneNumber) || '',
  );
  const [agree, setAgree] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(
    serverData.attorneyReviewRequested,
  );
  const [showNotEnrolledModal, setShowNotEnrolledModal] = useState(false);

  useEffect(() => {
    API.sendUserEvent('ATTORNEY_REVIEW_SEEN');
  }, []);

  if (!serverData.depByDefault) {
    return <Redirect to={SectionSign.url} />;
  }

  if (!clientData.isPlanValid()) {
    return <Redirect to={Urls.planDashboard} />;
  }

  if (clientData.minorChildBeneficiaries.length) {
    return <Redirect to={Urls.depByDefaultSummary} />;
  }

  const phoneNumbersOnly = phoneNumber.replace(/[\D]/g, '');

  const validateForm = () => {
    const newErrors = {};
    if (!email) {
      newErrors.email = 'Please enter your email';
    }
    if (!phoneNumbersOnly) {
      newErrors.phoneNumber = 'Please enter your phone number';
    } else if (phoneNumbersOnly.length !== phoneNumberDigits) {
      newErrors.phoneNumber = `Enter a valid ${phoneNumberDigits}-digit US phone number`;
    }
    if (!agree) {
      newErrors.agree = 'Please check the box to indicate that you agree';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    try {
      await API.requestAttorneyReview({ email, phoneNumber: phoneNumbersOnly });
      await clientDataHolder._loadClientData();
      setShowConfirmationModal(true);
    } catch (error) {
      if (error.errorCode === 'not_authenticated') {
        setShowNotEnrolledModal(true);
      } else {
        setErrors({
          nonFieldErrors: 'An unknown error has occurred, please refresh the page and try again',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneNumberChange = event => {
    setPhoneNumber(formatPhoneNumber(event.target.value));
  };

  return (
    <WuiApp>
      <NotEnrolledModal
        open={showNotEnrolledModal}
        closeModal={() => setShowNotEnrolledModal(false)}
      />
      <Modal
        open={showConfirmationModal}
        onClose={() => history.push(Urls.depByDefaultSummary)}
        title="Call back Confirmed"
      >
        <Typography variant="body1">
          A representative will call you to schedule your Attorney Review. You will receive a call
          back within one hour during normal business hours (M-F 8am-3pm PST) or the next business
          day.
        </Typography>
      </Modal>
      <Layout hideRight showBackButton>
        <Typography variant="h2">Attorney Review</Typography>
        <Spacer v={8} />
        <Typography variant="body1">
          You're one step away from reviewing your estate plan with one of our professional network
          attorneys.
        </Typography>
        <Spacer v={16} />
        <Typography variant="body1">
          Please take a moment to confirm your phone number and email address. A representative will
          call you within one hour during normal business hours (M-F 8am-3pm PST) or the next
          business day to schedule your Attorney Review.
        </Typography>
        <Spacer v={24} />
        <Form onSubmit={handleSubmit} error={errors.nonFieldErrors}>
          <Textbox
            name="email"
            type="email"
            label="Email"
            autoComplete="off"
            value={email}
            onChange={setEmail}
            error={errors.email}
          />
          <Textbox
            name="phoneNumber"
            type="tel"
            label="Phone Number"
            autoComplete="off"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            error={errors.phoneNumber}
          />
          <Spacer v={8} />
          <Checkbox
            label={termsLabel}
            checked={agree}
            onChange={e => setAgree(e.target.checked)}
            error={errors.agree}
            classes={{ root: classes.checkbox }}
          />
          <FormHelperText error>{errors.agree || <span>&nbsp;</span>}</FormHelperText>
          <Spacer v={8} />
          <Button type="submit" variant="contained" color="primary" disabled={loading} noMinWidth>
            {loading ? 'Loading' : 'Submit'}
          </Button>
        </Form>
      </Layout>
    </WuiApp>
  );
};

AttorneyReview.propTypes = {
  title: PropTypes.string,
};

export default observer(AttorneyReview);
