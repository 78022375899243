import { API } from 'API';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import React from 'react';

import './CallRoom.scss';

const CallRoomMixin = superclass =>
  class extends superclass {
    static updateSessionDelay = 1000;

    componentDidMount() {
      super.componentDidMount();

      this.periodicSessionUpdate();
    }

    componentWillUnmount() {
      clearTimeout(this.updateSessionTimoutId);
      super.componentWillUnmount();
    }

    periodicSessionUpdate() {
      this.updateSession().finally(() => {
        this.updateSessionTimoutId = setTimeout(
          this.periodicSessionUpdate.bind(this),
          this.constructor.updateSessionDelay,
        );
      });
    }

    updateSession() {
      throw new Error('Subclasses of CallRoomMixin must implement updateSession');
    }

    cancelCall = () => this.setState({ cancelCall: true });

    rejectCancelCall = () => this.setState({ cancelCall: false });

    confirmCancelCall = () => {
      API.postNotarizationFailSession(this.sessionId);
    };

    get sessionId() {
      return this.props.match.params.sessionId;
    }

    renderCancelCallPopup() {
      return (
        <div className="popup-fuzz">
          <Popup onCloseClick={this.rejectCancelCall} showCloseIcon>
            <div className="witness-popup">
              <p>Are you sure you want to cancel this call?</p>
              <PureButton onClick={this.confirmCancelCall} className="button button--primary">
                Yes
              </PureButton>
              <PureButton onClick={this.rejectCancelCall} className="button button--red">
                No
              </PureButton>
            </div>
          </Popup>
        </div>
      );
    }
  };

export default CallRoomMixin;
