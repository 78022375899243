import React from 'react';
import WizardPage from 'ui/components/wizard/WizardPage';

import WuiApp from '@/WuiApp';
import { slugs } from 'urls';
import MaterialRealEstate from '@s/about-you/RealEstate';

export default class RealEstate extends WizardPage {
  static baseUrl = slugs.realEstate;

  static customDisplay() {
    return () => (
      <WuiApp wrapContent={false}>
        <MaterialRealEstate />
      </WuiApp>
    );
  }
}
