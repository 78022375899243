import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import { API } from 'API';
import { NotarizationSessionList } from 'ui/execution/NotarizationSessionList';
import Card from 'ui/components/Card';
import SectionNotaryDetails from 'ui/execution/details/SectionNotaryDetails';

import Ring from '!a/ring.mp3';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
export default class NotarySessionManager extends ClientDataReferer {
  static propTypes = {
    title: PropTypes.string,
  };
  constructor(props, context) {
    super(props, context);

    this.state = {
      sessions: [],
    };
  }

  componentDidMount() {
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
    this.initAudio();
    this.periodicSessionsUpdate();
  }

  componentWillUnmount() {
    clearTimeout(this.updateSessionsTimoutId);
    this.audio.pause();
    this.audio = null;
  }

  periodicSessionsUpdate() {
    this.updateSessions().finally(() => {
      this.updateSessionsTimoutId = setTimeout(this.periodicSessionsUpdate.bind(this), 1000);
    });
  }

  updateSessions = async () => {
    const resp = await API.getNotarizationAdminSessions();
    if (resp.data.sessions.some(s => s.status === 'waiting_to_start')) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
    this.setState({
      sessions: resp.data.sessions,
    });
  };

  render() {
    if (!this.clientData.isSectionValid(SectionNotaryDetails)) {
      return <Redirect to={SectionNotaryDetails.url} />;
    }

    return (
      <div className="cardwrapper cardwrapper_full_height">
        <Card className="card--fixed-height">
          <NotarizationSessionList sessions={this.state.sessions} />
        </Card>
      </div>
    );
  }

  initAudio = () => {
    this.audio = new Audio(Ring);
    this.audio.loop = true;
  };
}
