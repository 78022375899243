import React from 'react';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';

import { useGlobalContext, useScreenSize } from 'hooks';
import AuraCTA from 'material-ui/components/AuraCTA';

const Header = () => {
  const { isPhoneDisplay } = useScreenSize();
  const { serverData } = useGlobalContext();

  const align = isPhoneDisplay ? 'center' : 'left';

  let copy = {
    title: 'Complete the following steps to finalize your plan.',
    description: '',
  };

  if (serverData.hasNotarizedOnline) {
    copy = {
      title: 'Review Your Documents',
      description:
        'You can view your estate plan below and make updates at any time. If you make any changes, you will need to sign and notarize the updated documents to make them legally valid.',
    };
  } else if (serverData.fromPortunus) {
    copy = {
      title: 'Manage your plan',
      description:
        'You can view your estate plan below and make updates at any time. If you make any changes, you will need to print and sign the updated documents to make them legally valid.',
    };
  }

  return (
    <Grid
      container
      direction="row"
      wrap="wrap-reverse"
      alignContent="center"
      alignItems="center"
      spacing={4}
    >
      {serverData.hasNotarizedOnline && (
        <Grid item xs={12}>
          <AuraCTA />
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="h2" align={align}>
          {copy.title}
        </Typography>
        {copy.description && (
          <Typography variant="body1" align={align}>
            {copy.description}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Header;
