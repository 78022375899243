import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import BoundTextField from 'material-ui/components/BoundTextField';
import SideBySide from 'material-ui/components/layout/SideBySide';
import { Relationship, Gender } from 'models/client-data/enums';
import { useGlobalContext } from 'hooks';

const genericOptions = Relationship.SELECTABLE_CHOICES.reduce((a, c) => {
  a[c[0]] = c[1];
  return a;
}, {});

const RelationshipInput = ({ obj, sideTitle }) => {
  const { testator } = useGlobalContext();
  const testatorRelationshipPath = useMemo(
    () =>
      testator.clientRelationship === Relationship.SPOUSE
        ? 'spouseRelationship'
        : 'clientRelationship',
    [testator],
  );

  const path = useMemo(
    () => (obj.clientRelationship === Relationship.CHILD ? 'gender' : testatorRelationshipPath),
    [obj, testatorRelationshipPath],
  );

  const childOptions = useMemo(() => {
    const ownChildrenUUIDs = testator.ownChildren.map(c => c.personUUID);

    return {
      [Gender.MALE]: ownChildrenUUIDs.includes(obj.personUUID) ? 'Son' : 'Stepson',
      [Gender.FEMALE]: ownChildrenUUIDs.includes(obj.personUUID) ? 'Daughter' : 'Stepdaughter',
    };
  }, [testator, obj]);

  const options = useMemo(
    () => (obj.clientRelationship === Relationship.CHILD ? childOptions : genericOptions),
    [obj, childOptions],
  );

  return (
    <React.Fragment>
      <SideBySide left={obj.name} right={sideTitle} />

      <BoundTextField label="Relationship" options={options} obj={obj} path={path} />
    </React.Fragment>
  );
};

RelationshipInput.propTypes = {
  obj: PropTypes.object.isRequired,
  sideTitle: PropTypes.string,
};

RelationshipInput.defaultProps = {
  sideTitle: '',
};

export default observer(RelationshipInput);
