import WizardSection from 'ui/components/wizard/WizardSection';
import MaterialGuardians from 'material-ui/sections/guardians/MaterialGuardians';
import SpouseGuardians from 'material-ui/sections/guardians/SpouseGuardians';
import { AppLocation } from 'utils/enums';
import Urls from 'urls';

export default class SectionGuardians extends WizardSection {
  static url = Urls.sectionGuardians;
  static title = 'Guardians';
  static description = 'Name a guardian to look after your minor children.';
  static mobileDescription = 'Caring for your children';
  static appLocation = AppLocation.GUARDIANS;
  static isPlanSection = true;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    if (clientData.hasMinorChildren) {
      visit(MaterialGuardians);
    }

    if (clientData.isPlanningForSpouse && clientData.spouse.hasMinorChildren) {
      visit(SpouseGuardians);
    }
  }
}
