import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';

import WuiApp from 'material-ui/WuiApp';
import Layout from '@c/layout/Layout';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';
import { getStateFullName, joinClass } from 'utils';
import { DIGEX_CALENDLY_LINK } from 'utils/constants';

import UnlimitedChanges from '@a/images/checkout-v6/unlimited-changes.svg';
import GreenTick from '@a/images/green-tick.svg';

const styles = {
  header: {},
  listItem: {
    margin: [[20, 0]],

    '&$header': {
      marginBottom: 32,
    },
  },
  iconContainer: {
    padding: [[5, 30, 0, 0]],

    '$header &': {
      padding: [[0, 0, 0, 40]],
    },
  },
  icon: {
    width: 25,

    '$header &': {
      width: 96,
    },
  },
};

const ImageListItem = ({ image, title, text, classes, titleVariant, bodyVariant, isHeader }) => (
  <Grid
    container
    direction={isHeader ? 'row-reverse' : 'row'}
    wrap="nowrap"
    className={joinClass(classes.listItem, isHeader && classes.header)}
  >
    <Grid item className={classes.iconContainer}>
      <img src={image} className={classes.icon} />
    </Grid>
    <Grid container item direction="column">
      <Typography variant={titleVariant}>{title}</Typography>
      <Typography variant={bodyVariant}>{text}</Typography>
    </Grid>
  </Grid>
);

ImageListItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleVariant: PropTypes.string,
  bodyVariant: PropTypes.string,
  text: PropTypes.string.isRequired,
  isHeader: PropTypes.bool,
  classes: PropTypes.object,
};

ImageListItem.defaultProps = {
  titleVariant: 'h6',
  bodyVariant: 'body2',
  isHeader: false,
  classes: {},
};

const StyledImageListItem = withStyles(styles)(ImageListItem);

class DigexIntro extends NotarizationWizardPageMixin(MuiWizardPage) {
  static baseUrl = 'intro/';
  static notarizationSection = NotarizationStatus.TECH_CHECK;

  renderActionButtons = () => (
    <React.Fragment>
      <Button color="primary" variant="contained" onClick={() => this.goToNext()}>
        I'm ready to sign now
      </Button>
      <Spacer h={16} v={16} />
      {!this.serverData.isFreeLook && (
        <Button variant="outlined" onClick={() => window.location.replace(DIGEX_CALENDLY_LINK)}>
          Schedule for later
        </Button>
      )}
    </React.Fragment>
  );

  render() {
    return (
      <WuiApp wrapContent={false}>
        <Layout wide hideRight showBackButton buttonRenderer={this.renderActionButtons}>
          <StyledImageListItem
            image={UnlimitedChanges}
            title="Preparing to sign and notarize"
            titleVariant="h2"
            bodyVariant="body1"
            text="We want you to have a successful signing experience. Here are a few things to keep in mind before we proceed."
            isHeader
          />

          {this.clientData.isPlanningForSpouse && (
            <StyledImageListItem
              image={GreenTick}
              title="Make sure your spouse is present in the room"
              text="We will be signing documents for both you and your spouse today. It is important that your spouse is present during this digital notarization session."
            />
          )}
          <StyledImageListItem
            image={GreenTick}
            title="Be prepared to sign all documents"
            text="Please review all your documents in advance and be ready to sign everything that has been created."
          />
          <StyledImageListItem
            image={GreenTick}
            title="Set aside at least 30 minutes"
            text="Please ensure that you are uninterrupted during the time needed to complete the notary process. Note you will be required to be in the video frame throughout the call."
          />
          <StyledImageListItem
            image={GreenTick}
            title={`Notarization can only occur if you are present in ${getStateFullName(
              this.clientData.address.state,
            )}`}
            text="During the call you will be asked to verify your location."
          />
          <StyledImageListItem
            image={GreenTick}
            title="Have a valid drivers license ready"
            text="You will be required to verify your identity. Please have a driver’s license ready and make sure it is not expired. Make sure your license matches your state of residency."
          />
          <StyledImageListItem
            image={GreenTick}
            title="Ensure no one else is present"
            text="Only those who are signing documents are allowed to be present in the room. The video call will be conducted in English."
          />
          <Spacer v={0} />
        </Layout>
      </WuiApp>
    );
  }
}

export default DigexIntro;
