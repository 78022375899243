import React from 'react';
import PropTypes from 'prop-types';
import { joinClass, sleep } from 'utils';
import LoadingSuccess from 'icons/svg/icon-loading-success.svg';

import './AnimatedLoader.scss';

// Should match css transition values in AnimatedLoader.scss
const fadeOutDelays = {
  progressText: 500,
  loader: 1000,
};

export default class AnimatedLoader extends React.Component {
  static propTypes = {
    progressSteps: PropTypes.arrayOf(PropTypes.string).isRequired,
    successText: PropTypes.string.isRequired,
    // Duration to show each state. Includes fade in time, but not fade out time.
    progressDelay: PropTypes.number,
    onComplete: PropTypes.func,
    completeOnClick: PropTypes.bool,
  };

  static defaultProps = {
    progressDelay: 2000,
    onComplete: () => {},
    completeOnClick: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      progressText: '',
      showLoader: false,
      showCompleted: false,
    };
  }

  componentDidMount() {
    // SetTimeout 0 is used so that the loader is first rendered hidden,
    //   which is needed for it to fade in.
    setTimeout(this.animate, 0);
  }

  animate = async () => {
    const { progressSteps, progressDelay, onComplete } = this.props;

    this.setState({ showLoader: true });

    for (let i = 0; i < progressSteps.length; i++) {
      this.setState({
        progressText: progressSteps[i],
        showProgressText: true,
      });
      await sleep(progressDelay);
      if (i < progressSteps.length - 1) {
        this.setState({ showProgressText: false });
        await sleep(fadeOutDelays.progressText);
      }
    }

    this.setState({ showLoader: false });
    await sleep(fadeOutDelays.loader);
    this.setState({ showCompleted: true });
    await sleep(progressDelay);

    onComplete();
  };

  handleClick = () => {
    const { completeOnClick, onComplete } = this.props;

    if (completeOnClick) {
      onComplete();
    }
  };

  render() {
    const { progressText, showProgressText, showLoader, showCompleted } = this.state;
    const { successText } = this.props;
    return (
      <React.Fragment>
        {showCompleted ? (
          <div className={joinClass('loading-animation', showCompleted && 'active')}>
            <img alt="Success" className="success-icon" src={LoadingSuccess} />
            <h3 className="active">{successText}</h3>
          </div>
        ) : (
          <div
            className={joinClass('loading-animation', showLoader && 'active')}
            onClick={this.handleClick}
          >
            <div className="bars">
              <div />
              <div />
              <div />
              <div />
            </div>
            <h3 className={joinClass(showProgressText && 'active')}>{progressText}</h3>
          </div>
        )}
      </React.Fragment>
    );
  }
}
