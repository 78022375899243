import { useState, useEffect, useRef, useCallback } from 'react';
import { useGlobalContext, useObjOrTestator } from 'hooks';

const useValidation = ({
  obj: externObj = null,
  path,
  additionalValidations = [],
  disabled = false,
  skipStandardValidation = false,
}) => {
  // Used internally to build other structures
  const { clientDataHolder } = useGlobalContext();
  const obj = useObjOrTestator(externObj);
  const validationEnabled = !disabled;

  // Set up the values that we return for use
  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const domNode = useRef(null);

  const getValidationErrors = useCallback(() => {
    if (!validationEnabled) {
      return [];
    }

    const errors = [];

    if (!skipStandardValidation && path) {
      errors.push(...obj.getValidationErrors(path));
    }

    for (const validator of additionalValidations) {
      errors.push(...validator());
    }

    return errors;
    // This is informed through side effects of the type system, not memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj, path, validationEnabled]);

  const hasValidationErrors = showValidationErrors && getValidationErrors().length > 0;

  // Stop showing errors when the field has been changed
  useEffect(() => {
    if (!getValidationErrors().length) {
      setShowValidationErrors(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [obj, path, showValidationErrors, hasValidationErrors]);

  // Subscribe to the validation event queue and unsubscribe on cleanup
  useEffect(() => {
    const validateCallback = e => {
      if (!validationEnabled) {
        return;
      }

      e.isValid = e.isValid && getValidationErrors().length === 0;

      if (!e.isValid && !e.firstControlWithError) {
        e.firstControlWithError = {
          focusOnError: () => {
            if (!domNode.current) {
              return;
            }

            domNode.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest',
            });

            domNode.current.focus();

            setShowValidationErrors(true);
          },
        };
      }

      if (e.showValidationErrors || e.forceShowValidationErrors) {
        setShowValidationErrors(e.showValidationErrors);
      }
    };

    clientDataHolder.validateAllCallbacks.subscribe(validateCallback);

    return () => {
      clientDataHolder.validateAllCallbacks.unsubscribe(validateCallback);
    };
  }, [obj, path, validationEnabled, showValidationErrors, getValidationErrors, clientDataHolder]);

  return {
    domNode, // Assigned as the ref of the child component, I.E. Input
    getValidationErrors,
    hasValidationErrors,
    showValidationErrors,
    setShowValidationErrors,
  };
};

export default useValidation;
