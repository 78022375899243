import React from 'react';

import BequestInterface from './BequestInterface';

export default class SpouseBequestInterface extends BequestInterface {
  /*
   *   This class exists because the wizard is built around having a static forSpouse variable.
   */
  static forSpouse = true;
}
