import React from 'react';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';

import './IntroVerification.scss';

export default class IntroVerification extends React.Component {
  static propTypes = {
    tips: PropTypes.array.isRequired,
    side: PropTypes.string.isRequired,
    nextStep: PropTypes.func.isRequired,
    handleCaptureResult: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      showAcceptedInfo: false,
    };
  }

  captureImage = () => {
    const { handleCaptureResult, side, loading, setLoading } = this.props;
    const imageType = side === 'front' ? 'DL_Front' : 'DL_Back';

    if (loading) {
      return;
    }

    if (isMobile) {
      window.MitekMobileWeb.captureAndProcessImage(
        handleCaptureResult,
        imageType,
        null,
        setLoading,
      );
    } else {
      this.props.nextStep();
    }
  };

  renderAcceptedIds() {
    return (
      <div className="intro__accepted-forms">
        <span>Accepted forms of ID:</span>
        <ul>
          <li>Government Issued Photo ID</li>
        </ul>
      </div>
    );
  }

  renderTips() {
    return (
      <div className="intro__tips">
        <span>Tips:</span>
        <ul>
          {this.props.tips.map((tip, i) => (
            <li key={i}>{tip}</li>
          ))}
        </ul>
      </div>
    );
  }

  showAcceptedInfo = () => this.setState({ showAcceptedInfo: true });

  hideAcceptedInfo = () => this.setState({ showAcceptedInfo: false });

  render() {
    const { side } = this.props;

    if (isMobile && this.props.loading) {
      return <div className="loading intro-verification__loading" />;
    }

    return (
      <div className="intro-verification">
        {isMobile ? (
          <div>
            <h1 className="verification__title">Upload your ID</h1>
            <p className="verification__description">Now, let's upload the {side} of your id.</p>
          </div>
        ) : (
          <div>
            <h1 className="verification__title">Photo ID Capture</h1>
            <p className="verification__description">
              Show the {side.toUpperCase()} of your ID to your webcam and position it within the
              frame. Press any key to take a picture.
            </p>
          </div>
        )}

        {this.state.showAcceptedInfo && (
          <div className="popup-fuzz document-pop">
            <Popup onCloseClick={this.hideAcceptedInfo} showCloseIcon>
              {this.renderAcceptedIds()}
            </Popup>
          </div>
        )}

        {this.renderTips()}

        {this.renderAcceptedIds()}

        <div className="intro__actions">
          <PureButton onClick={this.showAcceptedInfo} className="button--tertiary actions__button">
            view accepted forms of id
          </PureButton>
          <PureButton onClick={this.captureImage} className="button--primary actions__button">
            Capture {side} of ID
          </PureButton>
        </div>
      </div>
    );
  }
}
