import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Panel from '@wui/layout/panel';
import Spacer from '@wui/layout/spacer';

const useStyles = makeStyles({
  text: {},
  title: {},
  wrap: {
    padding: 21,

    '& $text': {
      lineHeight: 1.3125,
    },

    '& $title': {
      margin: 0,
    },
  },
  image: {
    margin: [[0, 'auto']],
    maxWidth: '100%',
  },
});

const DepStep = ({ title, text, image, className }) => {
  const classes = useStyles();

  return (
    <Panel paddingless className={className}>
      <div className={classes.wrap}>
        <Grid container direction="row" justify="center">
          <img src={image} className={classes.image} />
        </Grid>
        <Spacer v={8} />
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
        <Spacer v={8} />
        <Typography className={classes.text}>{text}</Typography>
      </div>
    </Panel>
  );
};

DepStep.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

export default observer(DepStep);
