import React from 'react';
import ReactDOM from 'react-dom';

export class NavExtension extends React.Component {
  el = document.createElement('div');

  componentDidMount() {
    this.targetEl = document.getElementById('navbar-extension');
    this.targetEl.appendChild(this.el);
  }

  componentWillUnmount() {
    this.targetEl.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}
