import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Urls } from 'urls';
import { useGlobalContext } from 'hooks';

const SubscriptionPlan = () => {
  const { serverData } = useGlobalContext();

  if (!serverData.documentBundle) {
    return null;
  }

  return (
    <li>
      <Link to={Urls.subscriptionPicker}>Subscription Plans</Link>
    </li>
  );
};

export default observer(SubscriptionPlan);
