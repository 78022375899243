import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { Tips, Tip } from 'material-ui/components/tips/index';
import AddressInput from 'material-ui/components/AddressInput';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup';
import { useGlobalContext } from 'hooks';

const AgentAddressInput = ({ people, skipStandardValidation, heading, subtext }) => {
  const { testator } = useGlobalContext();
  const subHeading = testator.isMarried ? testator.name : '';

  return (
    <React.Fragment>
      <Tips>
        <Tip title="Providing Addresses">
          <p>
            Nothing happens with an address entered here until after you pass away. If you don't
            have an address handy, you can use your own as a placeholder.
          </p>
        </Tip>

        <Tip title="International Addresses">
          This program does not currently accept international addresses. Instead, you can use your
          own address.
        </Tip>
      </Tips>

      <SpacedFieldGroup masterHeading={heading} subHeading={subHeading} caption={subtext}>
        {people.map((p, i) => (
          <AddressInput
            obj={p}
            key={i}
            sideText={`${i + 1} of ${people.length}`}
            skipStandardValidation={skipStandardValidation}
          />
        ))}
      </SpacedFieldGroup>
    </React.Fragment>
  );
};

AgentAddressInput.propTypes = {
  subtext: PropTypes.string.isRequired,
  people: PropTypes.array.isRequired,
  heading: PropTypes.string,
  skipStandardValidation: PropTypes.bool,
};

AgentAddressInput.defaultProps = {
  subHeading: '',
  skipStandardValidation: false,
  heading: 'Agent Addresses',
};

export default observer(AgentAddressInput);
