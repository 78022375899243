import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Portal } from 'ui/components/portal/Portal';
import { UpdateModal } from './update/UpdateModal';

import './DocumentModal.scss';
import { Relationship } from 'models/client-data/enums';

export default class DocumentModal extends PureComponent {
  static propTypes = {
    activePerson: PropTypes.object.isRequired,
    reviewNode: PropTypes.object.isRequired,
    toggleDocumentPopUp: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      update: false,
    };
  }

  toggleUpdate = () => this.setState({ update: !this.state.update });

  handleReplace = data => this.props.toggleDocumentPopUp('replace', data);
  handleUpdatePopUp = (type, data) => this.props.toggleDocumentPopUp(type, data);

  onKeyDown = handler => event => {
    if (event.charCode === 13) {
      event.stopPropagation();
      handler();
    }
  };

  render() {
    const { activePerson, reviewNode } = this.props;
    const { dataset } = activePerson;
    const { update } = this.state;
    let styles = {};
    let divStyles = { marginLeft: '1px' };
    let className = '';

    if (!activePerson) {
      return null;
    }

    const position =
      reviewNode.offsetWidth - activePerson.offsetLeft > reviewNode.offsetWidth / 2
        ? 'left'
        : 'right';

    if (
      activePerson.offsetHeight < 46 ||
      (activePerson.offsetHeight > 46 && activePerson.offsetHeight <= 67)
    ) {
      divStyles = {};
      className = `portal__position--${position}`;
    }

    if (reviewNode.offsetWidth <= 600) {
      styles.top = `${activePerson.offsetTop + activePerson.offsetHeight}px`;
      if (reviewNode.offsetWidth < 544) {
        className = 'portal__position--middle-small';
      } else {
        className = 'portal__position--middle-large';
      }
    }

    return (
      <Portal container={activePerson} className={className} styles={styles}>
        <div className="modal__content doc__action--stop" style={divStyles}>
          {update && (
            <div className="content__items">
              <UpdateModal
                dataset={dataset}
                handleUpdatePopUp={this.handleUpdatePopUp}
                review={reviewNode}
              />
            </div>
          )}
          {!update && (
            <ul className="content__items">
              <li
                className="content__item go-to-next__cancel"
                onClick={this.toggleUpdate}
                onKeyPress={this.onKeyDown(this.toggleUpdate)}
                role="button"
                tabIndex="0"
              >
                Update spelling
              </li>
              {(dataset.role || dataset.clientRelationship !== Relationship.SELF) && (
                <li
                  className="content__item go-to-next__cancel"
                  onClick={() => this.handleReplace(dataset)}
                  onKeyPress={this.onKeyDown(() => this.handleReplace(dataset))}
                  role="button"
                  tabIndex="0"
                >
                  Replace with somebody else
                </li>
              )}
            </ul>
          )}
        </div>
      </Portal>
    );
  }
}
