import React from 'react';
import { Redirect } from 'react-router-dom';
import { Urls } from 'urls';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import SubscriptionOptions from '@/pages/Checkout/SubscriptionOptions';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
export default class SubscriptionPlanPricing extends ClientDataReferer {
  /* this component is for showing subscription plan products and their prices */

  static url = '/subscription-plan-pricing/';

  static BENEFITS_PLUS = {
    'File your documents automatically': [
      'Let us handle the filing of your documents with the appropriate people and institutions at no charge.',
    ],
    'Free unlimited changes': [
      'Make changes or updates to your will and other documents anytime so everything always stays right.',
    ],
    'Keep your documents safe and secure': [
      'Securely store your documents in our vault so you and trusted loved ones can access them when needed.',
    ],
    'Help your loved ones carry out your wishes': [
      `Give your family free access to our team of experts who will help transfer your assets and carry out your
                wishes.`,
    ],
  };
  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }

  render() {
    if (!this.serverData.subscriptionPlan) {
      return <Redirect to={Urls.planDashboard} />;
    }
    return <SubscriptionOptions />;
  }
}
