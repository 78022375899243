import React from 'react';
import PropTypes from 'prop-types';

import WizardPopUpChanges from 'ui/components/wizard/popup/WizardPopUpChanges';
import ReplacePopUp from './popUps/ReplacePopUp';
import UpdatePopUp from './popUps/UpdatePopUp';
import ConfirmUpdatePopUp from './popUps/ConfirmUpdatePopUp';

import './DocumentPopUp.scss';
import { ExcessChanges } from 'utils/enums';

const getComponent = type => {
  switch (type) {
    case 'replace':
      return ReplacePopUp;
    case 'update':
      return UpdatePopUp;
    case 'confirm_update':
      return ConfirmUpdatePopUp;
    case 'excess_name_changes':
      return WizardPopUpChanges;
    default:
      return null;
  }
};

const DocumentPopUp = ({ type, ...rest }) => {
  const PopUpComponent = getComponent(type);
  if (!PopUpComponent) {
    return null;
  }
  const wizardProps = {};
  if (type === ExcessChanges.NAME) {
    wizardProps.popupCode = type;
  }
  return <PopUpComponent {...rest} {...wizardProps} />;
};

DocumentPopUp.propTypes = {
  type: PropTypes.string.isRequired,
};

export default DocumentPopUp;
