import React from 'react';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    border: 'none',
    boxShadow: 'none',
    padding: theme.layout.paperPadding,
  },

  outlined: {
    boxShadow: theme.shadow.standard,
    border: [[1, 'solid', theme.palette.custom.border]],
  },

  ignoreMobileGutters: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: [-theme.layout.leftSideMobileGutter, '!important'],
      marginRight: [-theme.layout.leftSideMobileGutter, '!important'],
    },
  },
});

export default withStyles(styles)(({
  classes,
  children,
  className,
  withoutOutline,
  ignoreMobileGutters,
  ...props
}) => {
  const rootClassName = classNames(
    className,
    classes.root,
    {
      [classes.outlined]: !withoutOutline,
      [classes.ignoreMobileGutters]: ignoreMobileGutters,
    },
  );

  return (
    <Paper
      {...props}
      className={rootClassName}
    >
      {children}
    </Paper>
  );
});
