import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@wui/basics/typography';
import { withStyles } from '@material-ui/core/styles';
import { getStateFullName } from 'utils';
import { AppLocation } from 'utils/enums';

import NoProbate from '@a/images/no-probate.svg';
import WithProbate from '@a/images/with-probate.svg';

import Layout from '@c/layout/Layout';
import RealEstateTips from './RealEstateTips';

const styles = {
  content: {
    textAlign: 'center',

    '& img': {
      maxHeight: 130,
      display: 'block',
      maxWidth: '100%',
      margin: [[35, 'auto', 12]],
    },
  },
  spacer: {
    height: 64,
  },
};

class PlanNotice extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static contextTypes = {
    page: PropTypes.object,
    testator: PropTypes.object,
  };

  render() {
    const { classes } = this.props;
    const residence = this.context.testator.primaryResidence;
    const stateFullName = residence ? getStateFullName(residence.address.state) : 'your state';

    return (
      <Layout wide={true} appLocation={AppLocation.REAL_ESTATE}>
        <RealEstateTips />

        <Typography component="div" variant="subtitle1" className={classes.content}>
          <Typography variant="h2">
            A Homeowner - Very Impressive, {this.context.testator.firstName}!
          </Typography>
          Your home plays a central role in your estate. Unless specific steps are taken, it will
          pass through a long, expensive court process known as "probate."
          <img src={NoProbate} alt="" />
          <Typography variant="h2">Probate</Typography>
          <b>Attorneys' Fees and Court Fees:</b> ~$10,000, paid from your estate
          <br />
          <b>Time:</b> 8-12 months until final court order
          <div className={classes.spacer} />
          <Typography variant="h2">We prevent this!</Typography>
          This application can keep your property out of probate. For {stateFullName}, our{' '}
          <b>Homeowners Bundle</b> includes probate-avoidance documents
          {this.context.testator.ownAndJointRealEstates.length > 1
            ? ', and can also cover your other properties'
            : ''}
          , so your heirs can inherit your home without court intervention!
          <img src={WithProbate} alt="" />
          <Typography variant="h2">Probate Avoided</Typography>
          <b>Attorneys' Fees and Court Fees:</b> None
          <br />
          <b>Time:</b> 2 weeks, no court intervention
          <div className={classes.spacer} />
        </Typography>
      </Layout>
    );
  }
}

export default withStyles(styles)(PlanNotice);
