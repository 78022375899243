import { DocumentBundle } from 'utils/enums';
import { documentBundleIncludes, statesThatProhibitAHDs } from 'utils';

import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import SectionHealthcare from 'ui/sections/healthcare/SectionHealthcare';
import SectionExecutors from 'ui/sections/executors/SectionExecutors';
import SectionGuardians from 'ui/sections/guardians/SectionGuardians';
import SectionAssetBeneficiaries from 'ui/sections/asset-beneficiaries/SectionAssetBeneficiaries';
import SectionRecord from 'ui/sections/file/record/SectionRecord';
import SectionUpdate from 'ui/sections/file/update/SectionUpdate';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import SectionTransfer from 'ui/sections/file/transfer/SectionTransfer';
import SectionNotify from 'ui/sections/file/notify/SectionNotify';
import SectionFund from 'ui/sections/file/fund/SectionFund';
import SectionSign from 'ui/sections/sign/SectionSign';
import SectionNotarize from 'ui/sections/notarize/SectionNotarize';
import SectionNotaryDetails from 'ui/execution/details/SectionNotaryDetails';

/** Pass null to get all sections or pass clientData to get the specific sections for the client */
export function getPlanSections(clientData, experimentsContext) {
  const sections = [SectionAboutYou],
    needsGuardians =
      !clientData ||
      clientData.ownChildren.some(c => c.isMinor) ||
      (clientData.isPlanningForSpouse && clientData.spouse.ownChildren.some(c => c.isMinor));
  if (needsGuardians) {
    sections.push(SectionGuardians);
  }
  const assetsLast = experimentsContext && experimentsContext.isEnabled('assets-last');
  if (!assetsLast) {
    sections.push(SectionAssetBeneficiaries);
  }
  sections.push(SectionExecutors);
  if (!clientData || !statesThatProhibitAHDs.includes(clientData.address.state)) {
    sections.push(SectionHealthcare);
  }
  if (assetsLast) {
    sections.push(SectionAssetBeneficiaries);
  }
  return sections;
}

export function getFileSections(clientData, serverData) {
  const sections = [SectionNotify, SectionUpdate];

  if (serverData && serverData.fromPortunus) {
    return [];
  }

  // If digital execution complete the user will only need these two sections
  if (serverData && serverData.hasNotarizedOnline) {
    return sections;
  }

  const avoidingProbate = !clientData || clientData.eitherTestatorAvoidsProbate;
  const hasHomeowners =
    !serverData || documentBundleIncludes(serverData.documentBundle, DocumentBundle.HOMEOWNERS);

  if (avoidingProbate && hasHomeowners) {
    if (!serverData || Object.values(serverData.assets).some(asset => asset.qcdCreationMethod)) {
      sections.push(SectionFund);
    }
    sections.push(SectionRecord);
  }
  if (avoidingProbate) {
    if (!serverData || !serverData.assets) {
      sections.push(SectionTransfer);
    } else {
      for (const uuid of Object.keys(serverData.assets)) {
        if (serverData.assets[uuid].qcdCreationMethod === 'attorney') {
          sections.push(SectionTransfer);
          break;
        }
      }
    }
  }

  return sections;
}

export function getApplicableSections(clientData, serverData, experimentsContext) {
  return {
    plan: getPlanSections(clientData, experimentsContext),
    file: getFileSections(clientData, serverData),
    sign: [SectionSign],
    notarize: [SectionNotarize],
    documents: [SectionDocuments],
    notaryDetails: [SectionNotaryDetails],
  };
}

export function allPlanSectionsValid(clientData) {
  // check if all plan sections have been completed
  const sections = getPlanSections(clientData);
  return !sections.some(s => !clientData.isSectionValid(s));
}
