import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import CustomIcon from '@wui/basics/customIcon';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Modal from '@wui/basics/modal';
import { makeStyles } from '@material-ui/core/styles';

import { ReactComponent as GreenTickIcon } from '@a/images/green-tick.svg';
import { ReactComponent as AuraLogo } from '@a/images/aura-logo.svg';
import { useGlobalContext } from 'hooks';
import { API } from 'API';

const AURA_STATUS_PREMIUM = 'premium';
const AURA_STATUS_STANDALONE = 'standalone';
const subtitleByStatus = {
  [AURA_STATUS_PREMIUM]: 'The Aura Vault is included with your MetLife legal plan.',
  [AURA_STATUS_STANDALONE]:
    'Available for those enrolled in Identity and Fraud Protection through their employer.',
};

const useStyles = makeStyles(theme => ({
  card: {
    minWidth: 275,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 600,
    marginRight: theme.spacing(1),
  },
  subTitle: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    fontStyle: 'italic',
  },
  badge: {
    display: 'inline-flex',
    backgroundColor: '#72E6C3',
    color: '#2A2C2E',
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    padding: theme.spacing(0.25, 1),
    borderRadius: 4,
  },
  iconSpacing: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  auraIcon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
}));

const AuraVaultCard = () => {
  const classes = useStyles();
  const { serverData, analyticsEvents } = useGlobalContext();
  const [auraStatus, setAuraStatus] = useState(serverData.auraStatus);
  const [auraLoading, setAuraLoading] = useState(true);
  const [redirectLoading, setRedirectLoading] = useState(false);
  const [auraModalOpen, setAuraModalOpen] = useState(false);

  useEffect(() => {
    if (!serverData.hasNotarizedOnline || !auraLoading) {
      return;
    }
    API.getAuraStatus()
      .then(response => {
        setAuraStatus(response.data.auraStatus);
      })
      .finally(() => setAuraLoading(false));
  }, [serverData.hasNotarizedOnline, auraLoading]);

  useEffect(() => {
    if (auraLoading || !auraStatus) {
      return;
    }
    analyticsEvents.gtag('event', `${auraStatus}-aura-prompt-displayed`, {
      event_category: 'aura-integration',
      aura_status: auraStatus,
      value: auraStatus,
    });
    API.getRecordAuraPrompt();
  }, [auraLoading, auraStatus, analyticsEvents]);

  if (auraLoading && !auraStatus) {
    return null;
  }

  if (![AURA_STATUS_PREMIUM, AURA_STATUS_STANDALONE].includes(auraStatus)) {
    return null;
  }

  const goToAura = async () => {
    try {
      analyticsEvents.gtag('event', `${auraStatus}-aura-handoff-initiated`, {
        event_category: 'aura-integration',
        aura_status: auraStatus,
        value: auraStatus,
      });
      setRedirectLoading(true);
      const response = await API.getAuraRedirect();

      if (response.data.url) {
        window.open(response.data.url, '_blank').focus();
      }
    } finally {
      setAuraModalOpen(false);
      setRedirectLoading(false);
    }
  };

  return (
    <Card className={classes.card}>
      <Modal open={auraModalOpen} onClose={() => setAuraModalOpen(false)} title="Our Commitment">
        MetLife Legal Plans is the qualified custodian of your electronic will. A copy of your will
        and estate planning documents is available to download and store in Aura's digital vault.
        However, the electronic copy of your will held securely at MetLife Legal Plans remains the
        official document from which a certified paper copy may be requested. By clicking, "Continue
        to Aura," you are indicating you understand that the official copy of your will remains with
        MetLife Legal Plans until you, someone designated in writing by you, or the representative
        of your estate requests the certified paper original copy.
        <br />
        <br />
        Clicking "Continue to Aura" will take you to Aura's website. Aura is not a corporate
        affiliate of MetLife, and its website is governed by its own terms of service and privacy
        policy.
        <br />
        <br />
        <Button variant="contained" color="primary" onClick={goToAura} disabled={redirectLoading}>
          {redirectLoading ? 'Loading...' : 'Continue to Aura'}
        </Button>
      </Modal>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item>
            <CustomIcon src={AuraLogo} />
          </Grid>
          <Grid item xs>
            <div className={classes.titleContainer}>
              <Typography key="title" className={classes.title} gutterBottom>
                Safeguard These Documents with the Aura Vault
              </Typography>
              {auraStatus === AURA_STATUS_PREMIUM && (
                <Typography key="recommended" className={classes.badge}>
                  Recommended
                </Typography>
              )}
            </div>
            <Typography className={classes.subTitle}>{subtitleByStatus[auraStatus]}</Typography>
            <div className={classes.iconSpacing}>
              <CustomIcon src={GreenTickIcon} />
              <Spacer h={10} />
              <Typography variant="body2">
                Encryption technology generally used by major banks and the military to protect your
                files.
              </Typography>
            </div>
            <div className={classes.iconSpacing}>
              <CustomIcon src={GreenTickIcon} />
              <Spacer h={10} />
              <Typography variant="body2">
                Securely share important documents like your estate plan with your family.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => setAuraModalOpen(true)}
        >
          Continue to Aura
        </Button>
      </CardActions>
    </Card>
  );
};

export default AuraVaultCard;
