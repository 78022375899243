import React, { useState } from 'react';
import { observer } from 'mobx-react';

import NameConflictPopup from 'material-ui/components/NameConflictPopup';
import { handleRename } from 'utils/personMerging';
import { useGlobalContext } from 'hooks';

const withHandleNameConflicts = ChildComponent =>
  observer(({ children, ...props }) => {
    const [resolveNamePopup, setResolveNamePopup] = useState(null);
    const [person, setPerson] = useState({});
    const context = useGlobalContext();

    const confirmRenameConflict = newPerson => () =>
      new Promise(resolve => {
        setResolveNamePopup(() => result => {
          setResolveNamePopup(null);
          setPerson({});
          resolve(result);
        });

        setPerson(newPerson);
      });

    const handleNameConflicts = newPerson => () => {
      handleRename(context, newPerson, confirmRenameConflict(newPerson));
    };

    return (
      <React.Fragment>
        <NameConflictPopup resolve={resolveNamePopup} person={person} />
        <ChildComponent {...props} handleNameConflicts={handleNameConflicts}>
          {children}
        </ChildComponent>
      </React.Fragment>
    );
  });

export default withHandleNameConflicts;
