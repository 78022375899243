import React from 'react';
import { observer } from 'mobx-react';
import { isIOS } from 'react-device-detect';

import { useGlobalContext } from 'hooks';

import { Urls } from 'urls';
import FailurePage from '../Failure';

import GoogleChromeChip from './GoogleChromeChip';

const Failed = () => {
  const { history } = useGlobalContext();

  const retry = () => history.push(Urls.signSection);
  const details =
    "The browser you're using is not supported. " +
    (isIOS
      ? 'We recommend using Safari for the best experience.'
      : 'We recommend downloading Google Chrome for the best experience.');
  const buttonText = isIOS ? "I'll change later" : "I'll download later";

  return (
    <FailurePage
      message="Browser not supported"
      retry={retry}
      details={details}
      buttonText={buttonText}
    >
      <GoogleChromeChip />
    </FailurePage>
  );
};

export default observer(Failed);
