import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';

import PanelTitle from '@wui/layout/panelTitle';
import Grid from '@wui/layout/grid';
import Typography from '@wui/basics/typography';
import Panel from '@wui/layout/panel';

const styles = theme => ({
  panelTitle: {
    margin: [0, '!important'],
    width: ['auto', '!important'],
    borderBottom: 'none',
  },
  grid: {
    [theme.breakpoints.desktop]: {
      padding: [[0, 8]],
    },
  },
});

class TableHeader extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    firstLabel: PropTypes.string,
    secondLabel: PropTypes.string,
  };

  static defaultProps = {
    firstLabel: 'NEXT STEPS',
    secondLabel: 'STATUS',
  };

  render() {
    const { classes, firstLabel, secondLabel } = this.props;

    return (
      <Panel tableRow paddingless>
        <PanelTitle simple className={classes.panelTitle}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            spacing={5}
            className={classes.grid}
            role="row"
            aria-label=""
          >
            <Grid item xs={6} role="columnheader">
              <Typography variant="caption" bold component="div">
                {firstLabel}
              </Typography>
            </Grid>

            <Hidden only="xs">
              <Grid item xs={6} role="columnheader">
                <Typography variant="caption" bold component="div">
                  {secondLabel}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </PanelTitle>
      </Panel>
    );
  }
}

export default withStyles(styles)(TableHeader);
