import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Elements, injectStripe } from 'react-stripe-elements';
import { Redirect } from 'react-router-dom';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';
import Urls from '!/urls';

import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import WuiApp from 'material-ui/WuiApp';
import { DocumentType, DocumentBundle } from 'utils/enums';
import { API } from 'API';
import { fetchDocumentTypes, formatMoney, documentBundleIncludes } from 'utils';
import PaymentSuccess from 'material-ui/pages/Checkout/Success';
import Spacer from '@wui/layout/spacer';
import Button from '@wui/input/button';
import Tabs from '@wui/layout/tabs';
import Typography from '@wui/basics/typography';
import TabDivider from '@wui/layout/tabDivider';
import Grid from '@wui/layout/grid';
import VerticalDivider from '@wui/layout/verticalDivider';
import ExplodingDiscountBanner from 'ui/ExplodingDiscountBanner';

import MobileCheckoutDrawer from './MobileCheckoutDrawer';
import PropertySelector from './PropertiesAndTotal';
import BenefitsDisplay from './BenefitsDisplay';
import DocumentList from './DocumentList';
import CheckoutForm from './CheckoutForm';
import TabLabel from './TabLabel';
import TabSelect from './TabSelect';
import CompareBundles from './CompareBundles';
import StickyPurchaseCTA from './StickyPurchaseCTA';
import { getBundleDescription, getUnrestrictedDocuments, getDocumentName } from './benefits';
import ProbatePopup from './ProbatePopup';

import CompareIcon from '@a/images/checkout-v6/compare.svg';
import BetterBusinessBureauIcon from '@a/images/checkout-v6/better-business-bureau.png';
import AmericaIcon from '@a/images/checkout-v6/america.png';
import CreditCardIcon from '@a/images/checkout-v6/credit-card.svg';

import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
const styles = theme => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  root: {
    padding: '16px 0',
    width: '100%',
    maxWidth: 1440,

    [theme.breakpoints.desktop]: {
      padding: '24px 64px',
    },
  },
  tabContent: {
    padding: [[32, 24]],

    [theme.breakpoints.tablet]: {
      maxWidth: 464,
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: [[48, 32, 32]],
    },

    [theme.breakpoints.desktop]: {
      padding: [[64, 96, 32]],
    },
  },
  tabMain: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',

      '& > :first-child': {
        flex: '1',
        marginRight: 96,
      },
      '& > :nth-child(2)': {
        width: 464,
      },
    },
  },
  compareOptionsButton: {
    marginLeft: 32,
    marginTop: 20,
    whiteSpace: 'nowrap',

    '& img': {
      marginRight: 8,
    },
  },
  featured: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.phone]: {
      flexDirection: 'column',
    },

    '& img': {
      marginLeft: 48,
      height: 32,

      '&:first-child': {
        [theme.breakpoints.tablet]: {
          marginLeft: 0,
        },
      },

      [theme.breakpoints.phone]: {
        margin: [[24, 0, 0, 0]],
      },
    },
  },
  creditCardIcon: {
    height: 30,
    width: 30,
    marginRight: 4,
  },
  bbbIcon: {
    height: 30,
  },
  americaIcon: {
    height: 30,
    marginRight: 4,
  },
  credibility: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.desktop]: {
      justifyContent: 'left',
    },
  },
});

class CheckoutV6Base extends ClientDataReferer {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,
    stripe: PropTypes.shape({}).isRequired,
    title: PropTypes.string,
  };

  static contextTypes = {
    ...ClientDataReferer.contextTypes,
    headerHeight: PropTypes.shape({}),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedBundle: null,
      unrestrictedDocuments: [],
      includedRealEstate: [],
      initialPrices: {},
      currentPriceInfo: {},

      // CheckoutForm props
      showCheckoutForm: false,
      couponCode: '',
      couponSuccess: '',
      couponError: false,
      isPaymentInfoPending: false,

      showRedirect: false,
      showTabSelect: false,
      showStickyPurchaseCTA: false,
      showProbateWarning: false,
    };

    this.checkoutFormRef = React.createRef();
    this.payButtonRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
    const params = new URLSearchParams(window.location.search);
    const requestedBundle = params.get('b');

    const recommendedBundle = this.clientData.recommendedDocumentBundle || DocumentBundle.SINGLE;

    this.setState({
      selectedBundle: requestedBundle || this.serverData.documentBundle || recommendedBundle,
      // Keep track of this in case the user went directly to checkout
      recommendedBundle,
      includedRealEstate: this.clientData.realEstates.map(a => a.assetUUID),
    });

    this.fetchUnrestrictedDocuments();
    this.getInitialPrices();

    this.clientDataHolder.sawDocumentBundlePricing = true;

    window.addEventListener('scroll', this.updateStickyPurchaseCTA);
    window.addEventListener('resize', this.updateStickyPurchaseCTA);
    this.updateStickyPurchaseCTA();
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    window.removeEventListener('scroll', this.updateStickyPurchaseCTA);
    window.removeEventListener('resize', this.updateStickyPurchaseCTA);
  }

  // Make all relevant changes update the price as a reaction
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.includedRealEstate !== this.state.includedRealEstate ||
      prevState.selectedBundle !== this.state.selectedBundle
    ) {
      this.updateCurrentPrice();
    }
  }

  updateCurrentPrice = async () => {
    const { selectedBundle, includedRealEstate: realEstate, couponCode } = this.state;
    const includedRealEstate = documentBundleIncludes(selectedBundle, DocumentBundle.HOMEOWNERS)
      ? realEstate
      : [];

    this.setState({ isPaymentInfoPending: true });

    const currentPriceInfo = await API.getPriceInfo(
      couponCode,
      selectedBundle,
      '',
      includedRealEstate,
    );

    this.setState({ currentPriceInfo, isPaymentInfoPending: false });
  };

  couponSuccessMessage({ success, couponDiscount }) {
    return success
      ? 'Groupon applied successfully'
      : `${formatMoney(couponDiscount / 100)} discount applied`;
  }

  applyCoupon = async couponCode => {
    const { selectedBundle, includedRealEstate } = this.state;
    const couponErrorState = { couponError: true, couponSuccess: null };

    this.setState({ isPaymentInfoPending: true });

    try {
      const data = await API.applyCoupon(couponCode, selectedBundle, '', includedRealEstate);
      // The success key is used to indicate that the coupon
      //   was redeemed for a document bundle
      if (data.success) {
        await this.clientDataHolder.persistClientData();
      }
      if (data.couponDiscount || data.success) {
        this.setState({
          couponCode,
          currentPriceInfo: data,
          showRedirect: data.discountedPrice <= 0,
          couponSuccess: this.couponSuccessMessage(data),
          couponError: false,
        });
      } else {
        this.setState(couponErrorState);
      }
    } catch {
      this.setState(couponErrorState);
    }

    this.setState({ isPaymentInfoPending: false });
  };

  // Only fetch all of this once on mount to avoid many server round trips
  fetchUnrestrictedDocuments = async () => {
    const documents = [...new Set(await fetchDocumentTypes())];

    const unrestrictedDocuments = getUnrestrictedDocuments(this.clientData, documents);

    this.setState({ unrestrictedDocuments }, this.updateStickyPurchaseCTA);
  };

  getDocumentsForBundle(bundle) {
    const { unrestrictedDocuments } = this.state;
    if (documentBundleIncludes(bundle, DocumentBundle.HOMEOWNERS)) {
      return unrestrictedDocuments;
    }
    return unrestrictedDocuments.filter(
      doc => ![DocumentType.DEED, DocumentType.TRUST].includes(doc),
    );
  }

  getInitialPrices = async () => {
    const responses = this.documentBundles.map(bundle => API.getPriceInfo('', bundle, '', []));

    const initialPrices = {};
    const values = await Promise.all(responses);
    values.forEach(({ documentBundle, documentBundlePrice, upgradeDiscount }) => {
      initialPrices[documentBundle] = documentBundlePrice - upgradeDiscount;
    });

    this.setState({ initialPrices });
  };

  handleExplodingDiscountExpiration = () => {
    // We delay this update because the client and server can be slightly
    //   off in the timing of the exploding discount expiration.
    setTimeout(this.updateCurrentPrice, 1000);
  };

  updateStickyPurchaseCTA = () => {
    if (!this.payButtonRef.current) {
      return;
    }

    const { showStickyPurchaseCTA } = this.state;
    const {
      headerHeight: { value: headerHeight },
    } = this.context;
    // We compare the height of the bottom of the pay button with the
    //   bottom of the header when checking if it is visible because
    //   the header covers the button.
    const shouldShow =
      this.payButtonRef.current.getBoundingClientRect().bottom < headerHeight ||
      this.payButtonRef.current.getBoundingClientRect().top > window.innerHeight;

    if (shouldShow !== showStickyPurchaseCTA) {
      this.setState({ showStickyPurchaseCTA: shouldShow });
    }
  };

  get hasHomeowners() {
    return documentBundleIncludes(this.serverData.documentBundle, DocumentBundle.HOMEOWNERS);
  }

  get showAdditionalProperties() {
    const { currentPriceInfo } = this.state;

    return (
      documentBundleIncludes(currentPriceInfo.documentBundle, DocumentBundle.HOMEOWNERS) &&
      this.clientData.realEstates.length > (this.hasHomeowners ? 0 : 1)
    );
  }

  get hasItemization() {
    const { currentPriceInfo } = this.state;
    return Boolean(
      this.showAdditionalProperties ||
        currentPriceInfo.documentBundleDiscount ||
        currentPriceInfo.couponDiscount,
    );
  }

  get documentBundles() {
    const bundles = [DocumentBundle.HOMEOWNERS];
    if (
      this.clientData.isPlanningForSpouse ||
      this.serverData.documentBundle === DocumentBundle.COUPLES
    ) {
      bundles.push(DocumentBundle.COUPLES);
    }
    bundles.push(DocumentBundle.SINGLE);

    return bundles;
  }

  getBundleNoticeText(bundle) {
    const { recommendedBundle } = this.state;

    if (this.serverData.documentBundle === bundle) {
      return 'Your current bundle';
    } else if (documentBundleIncludes(this.serverData.documentBundle, bundle)) {
      return 'Included in your bundle';
    } else if (bundle === recommendedBundle) {
      return 'Recommended';
    } else if (bundle === DocumentBundle.ULTIMATE) {
      return 'Extra Value';
    }
    return null;
  }

  get purchaseCTA() {
    const { selectedBundle } = this.state;
    const ownedBundle = this.serverData.documentBundle;

    if (documentBundleIncludes(ownedBundle, selectedBundle)) {
      return 'Continue';
    } else if (ownedBundle) {
      return 'Upgrade';
    }
    return isWidthDown('xs', this.props.width) ? 'Buy' : 'Buy this plan';
  }

  scrollCheckoutFormIntoView = () => {
    const { width } = this.props;
    const {
      headerHeight: { value: headerHeight },
    } = this.context;
    if (isWidthUp('sm', width) && this.checkoutFormRef.current) {
      // When the checkout form is opened, we scroll so that the
      //   form is on screen. The top of the checkout form needs
      //   to clear the height of the header and some extra
      //   space is added for aesthetic purposes.
      const fromTop = headerHeight + 50;
      const top =
        this.checkoutFormRef.current.getBoundingClientRect().top + window.pageYOffset - fromTop;
      window.scroll({ top, behavior: 'smooth' });
    }
  };

  setCheckoutFormVisible = () => {
    const { selectedBundle } = this.state;
    const ownedBundle = this.serverData.documentBundle;

    const needsHomeowners = documentBundleIncludes(
      this.clientData.recommendedDocumentBundle,
      DocumentBundle.HOMEOWNERS,
    );

    const selectedHomeowners = documentBundleIncludes(selectedBundle, DocumentBundle.HOMEOWNERS);

    if (documentBundleIncludes(ownedBundle, selectedBundle)) {
      this.setState({ showRedirect: true });
      return;
    }

    if (needsHomeowners && !selectedHomeowners) {
      this.setState({ showProbateWarning: true });
    } else {
      this.setState({ showCheckoutForm: true }, this.scrollCheckoutFormIntoView);
    }
  };

  getTabContent = bundle => {
    const { classes, stripe, width } = this.props;
    const {
      currentPriceInfo,
      includedRealEstate,
      showCheckoutForm,
      couponCode,
      couponError,
      couponSuccess,
      isPaymentInfoPending,
    } = this.state;

    const documents = this.getDocumentsForBundle(bundle).map(
      getDocumentName(bundle, this.clientData),
    );

    const toggleProperty = uuid => {
      const realEstateSet = new Set(includedRealEstate);

      if (realEstateSet.has(uuid)) {
        realEstateSet.delete(uuid);
      } else {
        realEstateSet.add(uuid);
      }
      const newIncludedRealEsate = this.clientData.realEstates
        .map(a => a.assetUUID)
        .filter(assetUUID => realEstateSet.has(assetUUID));

      this.setState({ includedRealEstate: newIncludedRealEsate });
    };

    const checkoutFormProps = {
      priceInfo: currentPriceInfo,
      applyCoupon: this.applyCoupon,
      bundle,
      onSuccess: () => this.setState({ showRedirect: true }),
      stripe,
      includedRealEstate,
      couponError,
      couponSuccess,
      couponCode,
      isPaymentInfoPending,
    };

    // It is important that the checkout element that is displayed
    //   is based off of the same logic because there is a possibility
    //   that it could be rendered twice if props are used due to
    //   differences in component prop propagation. If rendered
    //   twice, Stripe throws an error.
    const showMobileCheckout = !isWidthUp('sm', width);

    return (
      <div className={classes.tabContent}>
        <div className={classes.tabMain}>
          <div>
            <BenefitsDisplay bundle={bundle} />
          </div>
          <div>
            <Spacer v={8} mdUp />
            <Spacer v={48} smDown />
            <DocumentList
              price={this.hasItemization ? currentPriceInfo.documentBundlePrice : null}
              documents={documents}
              checkoutOpen={showCheckoutForm}
              bundle={bundle}
            />
            <PropertySelector
              allProperties={this.clientData.realEstates}
              selectedProperties={includedRealEstate}
              toggleProperty={toggleProperty}
              priceInfo={currentPriceInfo}
              hasHomeowners={this.hasHomeowners}
              hasItemization={this.hasItemization}
              showAdditionalProperties={this.showAdditionalProperties}
              pricePerProperty={this.serverData.pricePerProperty}
            />
            <div ref={this.checkoutFormRef}>
              {!showMobileCheckout && showCheckoutForm && <CheckoutForm {...checkoutFormProps} />}
            </div>
            {!showCheckoutForm && (
              <div ref={this.payButtonRef}>
                <Spacer v={24} />
                <Button
                  onClick={this.setCheckoutFormVisible}
                  variant="contained"
                  color="primary"
                  fullWidth={this.isTabletOrSmallerDisplay}
                  className="purchaseCTA"
                >
                  {this.purchaseCTA}
                </Button>
              </div>
            )}
            {showMobileCheckout && showCheckoutForm && (
              <MobileCheckoutDrawer
                {...checkoutFormProps}
                open={showCheckoutForm}
                close={() => this.setState({ showCheckoutForm: false })}
              />
            )}
          </div>
        </div>

        {!showCheckoutForm && <Spacer v={40} />}
        {showCheckoutForm && <Spacer mdUp v={40} />}

        <Hidden smDown>
          {!showCheckoutForm && (
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              onClick={this.setCheckoutFormVisible}
            >
              <img alt="" src={CreditCardIcon} className={classes.creditCardIcon} />
              <Typography>All major credit cards</Typography>
            </Grid>
          )}
          <TabDivider halfMargin />
        </Hidden>

        <div className={classes.credibility}>
          <img
            alt="BBB Accredited Business seal"
            src={BetterBusinessBureauIcon}
            className={classes.bbbIcon}
          />
          <VerticalDivider />
          <img alt="" src={AmericaIcon} className={classes.americaIcon} />
          <Typography>500,000 wills made</Typography>
        </div>
      </div>
    );
  };

  isRecommended = bundle =>
    bundle === this.state.recommendedBundle &&
    !documentBundleIncludes(this.serverData.documentBundle, bundle);

  isExtraValue = bundle =>
    bundle === DocumentBundle.ULTIMATE &&
    !documentBundleIncludes(this.serverData.documentBundle, bundle);

  get tabs() {
    return this.bundles.map(bundle => ({
      id: bundle.id,
      name: <TabLabel mainText={bundle.name} sideText={bundle.price} />,
      content: this.getTabContent(bundle.id),
      special: bundle.special,
      primary: bundle.primary,
      noticeText: bundle.noticeText,
    }));
  }

  get bundles() {
    const { initialPrices, unrestrictedDocuments } = this.state;

    return this.documentBundles.map(bundle => ({
      id: bundle,
      name: DocumentBundle.displayName(bundle),
      price: formatMoney(initialPrices[bundle] / 100),
      special: this.isRecommended(bundle),
      primary: this.isExtraValue(bundle),
      noticeText: this.getBundleNoticeText(bundle),
      unrestrictedDocuments,
      description: getBundleDescription(bundle, this.clientData),
    }));
  }

  selectBundle = (idx, forceSelect = false) => {
    if (!forceSelect && this.isTabletOrSmallerDisplay) {
      this.setState({ showTabSelect: true });
    } else {
      this.setState({
        selectedBundle: this.documentBundles[idx],
        showTabSelect: false,
        isComparingOptions: false,
        showCheckoutForm: false,
      });
    }
  };

  hideSelect = () => {
    this.setState({ showTabSelect: false });
  };

  get compareOptionsButton() {
    if (isWidthDown('md', this.props.width)) {
      return null;
    }
    return (
      <Button
        variant="outlined"
        size="small"
        className={this.props.classes.compareOptionsButton}
        transparent
        onClick={this.toggleCompareOptions}
      >
        <img alt="" src={CompareIcon} />
        Compare Options
      </Button>
    );
  }

  renderProbateWarning = () => {
    const { selectedBundle, showProbateWarning } = this.state;

    const showForm = {
      showProbateWarning: false,
      showCheckoutForm: true,
    };

    if (!selectedBundle) {
      return null;
    }

    if (
      !documentBundleIncludes(this.clientData.recommendedDocumentBundle, DocumentBundle.HOMEOWNERS)
    ) {
      return null;
    }

    return (
      <ProbatePopup
        open={showProbateWarning}
        close={() => this.setState({ showProbateWarning: false })}
        proceedWithSelected={() => this.setState(showForm, this.scrollCheckoutFormIntoView)}
        proceedWithHomeowners={() =>
          this.setState(
            {
              ...showForm,
              selectedBundle: DocumentBundle.HOMEOWNERS,
            },
            this.scrollCheckoutFormIntoView,
          )
        }
        selectedFriendlyName={DocumentBundle.displayName(selectedBundle)}
        primaryResidence={this.clientData.primaryResidence}
      />
    );
  };

  toggleCompareOptions = () => {
    this.setState({ isComparingOptions: !this.state.isComparingOptions });
  };

  renderContent() {
    const { classes } = this.props;
    const {
      selectedBundle,
      showRedirect,
      showTabSelect,
      showStickyPurchaseCTA,
      currentPriceInfo,
      isComparingOptions,
    } = this.state;

    // Tabs will fail if we don't definitively give it a tab to start on
    if (!selectedBundle) {
      return null;
    }

    if (showRedirect) {
      return <Redirect to={PaymentSuccess.url} />;
    }

    if (showTabSelect) {
      return (
        <div className={classes.root}>
          <TabSelect
            bundles={this.bundles}
            hideSelect={this.hideSelect}
            selectTab={this.selectBundle}
          />
        </div>
      );
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.root}>
          <Typography variant="subtitle1" bold>
            Choose your document bundle:
          </Typography>
          <Spacer v={24} />
          {isComparingOptions ? (
            <CompareBundles
              bundles={this.bundles}
              selectBundle={this.selectBundle}
              toggleCompareOptions={this.toggleCompareOptions}
            />
          ) : (
            <Tabs
              tabs={this.tabs}
              selected={this.documentBundles.indexOf(selectedBundle)}
              onSelect={this.selectBundle}
              nextToTabs={this.compareOptionsButton}
              noContentPadding
            />
          )}
          {this.renderProbateWarning()}
          {showStickyPurchaseCTA && !isComparingOptions && (
            <StickyPurchaseCTA
              bundle={DocumentBundle.displayName(selectedBundle)}
              price={currentPriceInfo.discountedPrice}
              onClick={this.setCheckoutFormVisible}
              cta={this.purchaseCTA}
              className="purchaseCTA"
            />
          )}
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <ExplodingDiscountBanner onFinish={this.handleExplodingDiscountExpiration} />
        {this.renderContent()}
      </React.Fragment>
    );
  }
}

const CheckoutV6 = withStyles(styles)(injectStripe(withIsMobileDisplay(CheckoutV6Base)));

// Done so we can use the theme provider for the checkout page itself
const Wrapper = () => (
  <WuiApp>
    <Elements>
      <CheckoutV6 />
    </Elements>
  </WuiApp>
);

Wrapper.url = Urls.checkout;

export default Wrapper;
