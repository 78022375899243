import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useLocation, useHistory } from 'react-router-dom';
import { useGlobalContext } from 'hooks';

import { Urls } from 'urls';

const PasswordResetRedirect = () => {
  const {
    clientData: {
      profile: { needsPasswordReset },
    },
  } = useGlobalContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (needsPasswordReset && location.pathname !== Urls.account) {
      history.push(Urls.account);
    }
  }, [location, history, needsPasswordReset]);

  return null;
};

export default observer(PasswordResetRedirect);
