import { Lineage } from 'models/client-data/enums';
import { Relationship } from './client-data/enums';

export class PeopleBuilder {
  constructor() {
    this.people = {};
  }

  getPeople() {
    return Object.values(this.people);
  }

  add(person) {
    if (person && person.name) {
      this.people[person.personUUID] = person;
    }
  }

  remove(person) {
    if (person) {
      delete this.people[person.personUUID];
    }
  }

  addSpouse(clientData) {
    if (clientData.isMarried) {
      this.add(clientData.spouse);
    }
  }

  addChildren(clientData) {
    for (const child of clientData.children) {
      if (!child.disinherited) {
        this.add(child);
      }
    }
  }

  removeMinorChildren(clientData) {
    for (const child of clientData.children) {
      if (child.isMinor) {
        this.remove(child);
      }
    }
  }

  removeDisinheritedChildren(clientData) {
    for (const child of clientData.children) {
      if (child.disinherited) {
        this.remove(child);
      }
    }
  }

  addGuardians(clientData) {
    for (const guardian of clientData.guardians) {
      this.add(guardian);
    }
  }

  addExecutors(clientData) {
    for (const executor of clientData.executors) {
      this.add(executor);
    }
  }

  addHealthcareAgents(clientData) {
    for (const healthcareAgent of clientData.healthcareAgents) {
      this.add(healthcareAgent);
    }
  }

  addAssetBeneficiaries(clientData) {
    for (const asset of clientData.assets) {
      for (const primary of asset.primaryBeneficiaries) {
        this.add(primary);
        for (const alternate of primary.alternateBeneficiaries) {
          this.add(alternate);
        }
      }
    }
  }

  addAssetOwners(clientData) {
    for (const asset of clientData.assets) {
      if (asset.owners) {
        for (const owner of asset.owners) {
          this.add(owner);
        }
      }
    }
  }

  addAll(clientData) {
    this.add(clientData);
    this.addSpouse(clientData);
    this.addChildren(clientData);
    this.addGuardians(clientData);
    this.addExecutors(clientData);
    this.addHealthcareAgents(clientData);
    this.addAssetBeneficiaries(clientData);
    this.addAssetOwners(clientData);
    this.removeDisinheritedChildren(clientData);
  }

  static getFamily(clientData) {
    const builder = new PeopleBuilder();
    builder.add(clientData);
    builder.addSpouse(clientData);
    builder.addChildren(clientData);

    return builder.getPeople();
  }

  static getGuardians(clientData, testator, primary) {
    const builder = new PeopleBuilder();
    builder.addAll(clientData);

    builder.remove(testator);
    builder.removeMinorChildren(clientData);

    // spouse should be removed if the child is already in their lineage
    if (testator.children.some(c => c.lineage === Lineage.BOTH)) {
      builder.remove(testator.spouse);
    }

    if (primary) {
      builder.remove(primary);
    }

    return builder.getPeople();
  }

  static getHealthcareAgents(clientData, testator, primary) {
    const builder = new PeopleBuilder();
    builder.addAll(clientData);

    builder.removeMinorChildren(clientData);
    builder.remove(testator);
    if (primary) {
      builder.remove(primary);
    }
    return builder.getPeople();
  }

  static getExecutors(clientData, testator, primary) {
    const builder = new PeopleBuilder();
    builder.addAll(clientData);

    builder.removeMinorChildren(clientData);
    builder.remove(testator);
    if (primary) {
      builder.remove(primary);
    }
    return builder.getPeople();
  }

  static getAssetBeneficiaries(clientData, testator, primary) {
    const builder = new PeopleBuilder();
    builder.addAll(clientData);

    builder.remove(testator);
    if (primary) {
      builder.remove(primary);
    }
    return builder.getPeople();
  }

  static getAssetOwners(clientData) {
    const builder = new PeopleBuilder();
    builder.addAll(clientData);

    builder.removeMinorChildren(clientData);
    return builder.getPeople();
  }

  static getAll(clientData) {
    const builder = new PeopleBuilder();
    builder.addAll(clientData);

    return builder.getPeople();
  }
}

export const validatePersonNameUniqueness = (clientData, person, onlyFamily = false) => () => {
  const allPeople = onlyFamily
    ? PeopleBuilder.getFamily(clientData)
    : PeopleBuilder.getAll(clientData);
  for (const otherPerson of allPeople) {
    if (otherPerson.name === person.name && otherPerson.personUUID !== person.personUUID) {
      if (
        [Relationship.SELF, Relationship.SPOUSE, Relationship.CHILD].includes(
          otherPerson.clientRelationship,
        )
      ) {
        return [`${person.name} was already added as your ${otherPerson.clientRelationship}.`];
      }
      return [`${person.name} already exists.`];
    }
  }
  return [];
};

// Run the validation from a field type on a specific
//   value. Return an array of errors.
export function runTypeValidation(type, value) {
  const errors = [];
  const field = new type();
  value = field.convert(value, errors.push.bind(errors));
  field.validate(value, errors.push.bind(errors));
  return errors;
}
