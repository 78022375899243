import React from 'react';

import { ProgressSections } from 'material-ui/components/progress';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import AgentAddressInput from 'material-ui/components/AgentAddressInput';
import { getStateFullName } from 'utils';

export default class HealthcareAgentAddressInput extends MuiWizardPage {
  static baseUrl = 'address-required/';
  static progressPage = ProgressSections.YOUR_WISHES.HEALTHCARE;

  renderPage() {
    const people = this.testator.addressRequiredHealthcareAgents;

    const subtext = `${getStateFullName(
      this.testator.clientData.address.state,
    )} requires the address
            of anyone who is designated as your healthcare agent.`;

    return <AgentAddressInput subtext={subtext} people={people} />;
  }
}
