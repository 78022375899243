import PropTypes from 'prop-types';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

const withIsMobileDisplay = superclass => withWidth()(class extends superclass {
  static propTypes = {
    ...superclass.propTypes,
    width: PropTypes.string.isRequired,
  };

  get isLaptopOrSmallerDisplay() {
    return isWidthDown('md', this.props.width);
  }

  get isTabletOrSmallerDisplay() {
    return isWidthDown('sm', this.props.width);
  }

  get isPhoneDisplay() {
    return isWidthDown('xs', this.props.width);
  }
});

export default withIsMobileDisplay;
