import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Hidden from '@material-ui/core/Hidden';
import { StickyContainer } from 'react-sticky';
import { withStyles } from '@material-ui/core/styles';

import TwoColumnContentLeft, { paddedStyleRules } from './TwoColumnContentLeft';

const styles = theme => ({
  wuiNavLayout: {},

  ...paddedStyleRules,
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  left: {
    height: '100%',
    flexGrow: 1,
    flexBasis: 0,
    background: '#fff',
    boxShadow: theme.shadow.standard,
    borderRight: `1px solid ${theme.palette.custom.border}`,
    position: 'relative',
  },
  right: {
    right: 0,
    bottom: 0,
    position: 'fixed',
    top: theme.layout.headerHeight,

    '$wuiNavLayout &': {
      overflow: 'hidden',
      padding: 24,
      margin: 0,
    },
  },
  rightSpacing: {
    minWidth: 311,
    maxWidth: 400,
    width: '26.5%',
    display: 'flex',
    margin: [[0, '4.5%']],
    flexDirection: 'column',

    '$wuiNavLayout &': {
      display: 'block',
      padding: 24,
      margin: 0,
      boxSizing: 'content-box',
    },
  },
});

class TwoColumnContent extends React.PureComponent {
  static propTypes = {
    right: PropTypes.node,
    padded: PropTypes.bool,
    leftFooter: PropTypes.bool,
    top: PropTypes.node,
    children: PropTypes.node.isRequired,
    classes: PropTypes.shape({}).isRequired,
    wide: PropTypes.oneOf([false, true, 1, 2]),
    wuiNavLayout: PropTypes.bool,
  };

  static defaultProps = {
    top: null,
    right: null,
    padded: true,
    leftFooter: false,
    wide: false,
    wuiNavLayout: false,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.updateRightScroll);
    window.addEventListener('resize', this.updateRightScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateRightScroll);
    window.removeEventListener('resize', this.updateRightScroll);
  }

  updateRightScroll = () => {
    const { wuiNavLayout } = this.props;
    if (!wuiNavLayout || !this.rightColRef || !this.leftColRef || !this.spacerRef) {
      return;
    }

    if (this.rightColRef.scrollHeight > this.leftColRef.offsetHeight) {
      this.rightColRef.style.position = 'static';
      this.spacerRef.style.display = 'none';
    } else {
      this.rightColRef.style.position = 'fixed';
      this.spacerRef.style.display = 'block';
    }

    this.rightColRef.scrollTop = document.documentElement.scrollTop;
  };

  setRef = refName => (element) => {
    this[`${refName}Ref`] = element;
    this.updateRightScroll();
  };

  render() {
    const {
      wide,
      right,
      padded,
      classes,
      children,
      leftFooter,
      top,
      wuiNavLayout,
    } = this.props;

    const showLeftFooter = leftFooter && !this.isTabletOrSmallerDisplay;

    const className = classNames(
      classes.root,
      {
        [classes.wuiNavLayout]: wuiNavLayout,
        [classes.withLeftFooter]: showLeftFooter,
      },
    );

    return (
      <StickyContainer className={className}>
        <div className={classes.left}>
          {top}
          <div ref={this.setRef('leftCol')}>
            <TwoColumnContentLeft
              wide={wide}
              padded={padded}
              leftFooter={leftFooter}
            >
              {children}
            </TwoColumnContentLeft>
          </div>
        </div>
        {right && (
          <Hidden mdDown>
            <div
              className={classNames(classes.rightSpacing, classes.right, classes.padded, 'mui-fixed')}
              ref={this.setRef('rightCol')}
            >
              {right}
            </div>
            <div
              ref={this.setRef('spacer')}
              className={classNames(classes.rightSpacing, classes.padded)}
            />
          </Hidden>
        )}
      </StickyContainer>
    );
  }
}

export default withStyles(styles)(TwoColumnContent);
