import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Urls } from 'urls';
import { useGlobalContext } from 'hooks';
import { DocumentBundle as DocumentBundleEnum } from 'utils/enums';

const DocumentBundle = () => {
  const { serverData } = useGlobalContext();

  return (
    <li>
      <Link to={Urls.checkoutPicker}>
        {serverData.documentBundle !== DocumentBundleEnum.ULTIMATE ? 'Upgrade' : 'Document Bundles'}
      </Link>
    </li>
  );
};

export default observer(DocumentBundle);
