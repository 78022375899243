import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  root: {
    marginBottom: 48,
  },
  divider: {
    margin: [[5, 0]],
  },
  content: {
    maxWidth: 400,
  },
});

class Section extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    text: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
  };

  static willRender(section) {
    let { children } = section.props;

    if (!children) {
      return false;
    } else if (!Array.isArray(children)) {
      children = [children];
    }

    // The section will be rendered if there are children
    //   and 1 or more of them have content.
    return children.length > 0 && children.some(c => c);
  }

  render() {
    if (!this.constructor.willRender(this)) {
      return null;
    }

    const { children, classes, text } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="subtitle1" component="span" color="textSecondary">
          {text}
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.content}>{children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(Section);
