import React from 'react';
import PropTypes from 'prop-types';

import { MEMBERS_PHONE_NUMBER } from '/utils/constants';

import WuiTheme from '@willing-shared/WuiTheme';
import Typography from '@wui/basics/typography';
import Modal from '@wui/basics/modal';
import Spacer from '@wui/layout/spacer';
import Button from '@wui/input/button';

export default class NotEnrolledModal extends React.Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    return (
      <WuiTheme>
        <Modal
          open={this.props.open}
          onClose={() => this.props.closeModal()}
          title="You Have Not Enrolled"
        >
          <Typography variant="h8" align="left">
            The plan you are eligible for covers this matter for enrolled members. If you wish to
            view your digital estate plan, please call {MEMBERS_PHONE_NUMBER} for details on
            enrolling.
          </Typography>

          <Spacer v={24} />

          <Button variant="outlined" onClick={() => this.props.closeModal()}>
            Dismiss
          </Button>
        </Modal>
      </WuiTheme>
    );
  }
}
