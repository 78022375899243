import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@wui/basics/typography';
import Checkbox from '@wui/input/checkbox';
import Spacer from '@wui/layout/spacer';
import TabDivider from '@wui/layout/tabDivider';

import { formatMoney } from 'utils';

const styles = theme => ({
  root: {
    width: '100%',
    padding: 16,
    backgroundColor: theme.palette.grey.unselectedTab,
    borderRadius: '0 0 3px 3px',

    [theme.breakpoints.desktop]: {
      padding: [[16, 24]],
    },
  },
  title: {
    fontWeight: 'bold',
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 8,

    '&:last-child': {
      marginBottom: 0,
    },

    '& > :first-child': {
      marginRight: 16,
    },
  },
  priceText: {
    color: theme.palette.blue.textboxFocus,
    fontWeight: 'bold',
  },
});

class PropertiesAndTotal extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({}).isRequired,

    // The available properties to render
    allProperties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,

    // Used for calculating which boxes should be checked
    selectedProperties: PropTypes.arrayOf(PropTypes.string).isRequired,

    // Checkbox toggle function
    toggleProperty: PropTypes.func.isRequired,

    // Used for determining final total and whether or not to show the selector
    priceInfo: PropTypes.shape({}).isRequired,

    // Used to determine whether to show an included property
    hasHomeowners: PropTypes.bool.isRequired,

    showAdditionalProperties: PropTypes.bool.isRequired,
    hasItemization: PropTypes.bool.isRequired,
    pricePerProperty: PropTypes.number.isRequired,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
  };

  renderProperty = (asset, idx) => {
    const {
      classes,
      selectedProperties,
      toggleProperty,
      hasHomeowners,
      pricePerProperty,
    } = this.props;

    const displayPrice = uuid => {
      if (!asset.deedLocked) {
        return 'Paid';
      } else if (!hasHomeowners && selectedProperties[0] === uuid) {
        return 'Included';
      } else if (selectedProperties.includes(uuid)) {
        return `+${formatMoney(pricePerProperty / 100)}`;
      }

      return '';
    };

    return (
      <div className={classes.listItem} key={idx}>
        <Checkbox
          label={asset.address.street}
          onChange={() => toggleProperty(asset.assetUUID)}
          checked={selectedProperties.includes(asset.assetUUID)}
          disabled={!asset.deedLocked}
        />

        <Typography style={{ lineHeight: '24px' }}>{displayPrice(asset.assetUUID)}</Typography>
      </div>
    );
  };

  renderAdditionalProperties = () => {
    const { classes, allProperties, showAdditionalProperties } = this.props;

    if (!showAdditionalProperties) {
      return null;
    }

    return (
      <React.Fragment>
        <Typography className={classes.title}>Protect Additional Properties</Typography>

        <Spacer v={8} />
        {allProperties.map(this.renderProperty)}
      </React.Fragment>
    );
  };

  renderDiscount(label, amount) {
    const { classes } = this.props;
    if (!amount) {
      return null;
    }
    return (
      <div className={classes.listItem}>
        <Typography className={classes.title}>{label}</Typography>
        <Typography>-{formatMoney(amount / 100)}</Typography>
      </div>
    );
  }

  render() {
    const { classes, priceInfo, hasItemization } = this.props;
    const { explodingDiscountPercentOff } = this.context.clientDataHolder.serverData;

    return (
      <div className={classes.root}>
        {this.renderAdditionalProperties()}

        {this.renderDiscount('Credit for Current Bundle', priceInfo.documentBundleDiscount)}

        {this.renderDiscount('Discount', priceInfo.couponDiscount)}

        {this.renderDiscount(
          `${explodingDiscountPercentOff}% Limited Time Discount`,
          priceInfo.explodingDiscount,
        )}

        {hasItemization && (
          <React.Fragment>
            <Spacer v={16} />
            <TabDivider noMargin />
            <Spacer v={16} />
          </React.Fragment>
        )}

        <div className={classes.listItem}>
          <Typography className={classes.title}>Your Total</Typography>

          <Typography className={classes.priceText} variant="subtitle1">
            {formatMoney(priceInfo.discountedPrice / 100)}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PropertiesAndTotal);
