import React from 'react';
import PropTypes from 'prop-types';

import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';

import '../DocumentPopUp.scss';

const ConfirmUpdatePopUp = ({ onCloseClick, personData }) => (
  <div className="popup-fuzz document-pop">
    <Popup onCloseClick={onCloseClick} className="doc__action--stop" showCloseIcon={false}>
      <h4 className="center">Spelling has been updated!</h4>
      <p className="center small">
        {personData.oldFullName} has been changed to {personData.fullName} in all of your documents.
      </p>
      <div className="document-pop__actions">
        <PureButton onClick={onCloseClick} className="button--primary center actions__button">
          Nice!
        </PureButton>
      </div>
    </Popup>
  </div>
);

ConfirmUpdatePopUp.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  personData: PropTypes.object.isRequired,
};

export default ConfirmUpdatePopUp;
