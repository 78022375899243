import WizardSection from 'ui/components/wizard/WizardSection';
import PageVisit from 'ui/components/wizard/PageVisit';
import Review from './pages/Review';
import DocumentLoader from './pages/DocumentLoader';
import { Urls } from 'urls';

export default class SectionDocuments extends WizardSection {
  static showFooter = false;
  static url = Urls.documents;
  static showCloseButton = false;

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(DocumentLoader);
    visit(Review);
  }

  static get reviewUrl() {
    return new PageVisit(this, Review).url;
  }
}
