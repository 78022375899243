import React from 'react';

export default function EmailWithWrapping({ email }) {
  const parts = email.split(/([@.]+)/);

  return parts.reduce((r, a) => r.concat(<wbr />, a), [])
    // The email content will never change, so it is okay
    //   to use the part index as the key here.
    // eslint-disable-next-line react/no-array-index-key
    .map((p, i) => <React.Fragment key={i}>{p}</React.Fragment>);
}
