import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import Spacer from '@wui/layout/spacer';

import Facebook from './Facebook';
import Google from './Google';

const ERRORS = {
  // These errors are returned by Google.
  idpiframe_initialization_failed: 'Cookies must be enabled for you to login using this method.',
  popup_closed_by_user: "Please try again and don't close any popup windows that may have opened.",
  access_denied: 'Please grant the requested permissions the popup window.',
};

// Some Facebook errors have equivalent meanings to those that Google
//   supplies, so map from one to another.
const FACEBOOK_TO_GOOGLE = {
  not_authorized: 'access_denied',
};

const OauthLogin = ({ disabled, handleError, handleOauth }) => {
  const [error, setError] = useState(null);

  const onFailure = data => {
    // `data.error` is returned by Google. `data.status` is returned by Facebook.
    //    Translate the error from Facebook to the Google equivalent so we don't
    //    have to copy the error message itself.
    data.error = FACEBOOK_TO_GOOGLE[data.status] || data.error;

    let newError = ERRORS[data.error] || 'There was an error. Please try again.';

    if (handleError) {
      handleError(newError);
    } else {
      setError(newError);
    }
  };

  return (
    <React.Fragment>
      <GoogleLogin
        buttonText="Google"
        scope="openid email"
        onFailure={onFailure}
        clientId="685797762223-edandm45c2023v4bvcqanggdkhgb3ims.apps.googleusercontent.com"
        onSuccess={token =>
          handleOauth(
            {
              email: token.getBasicProfile().getEmail(),
              accessToken: token.getAuthResponse().id_token,
            },
            'google',
          )
        }
        render={Google({ disabled })}
      />

      <Spacer v={14} />

      <FacebookLogin
        fields="email"
        autoLoad={false}
        appId="275311629877038"
        icon="facebook-login__icon"
        onFailure={onFailure}
        callback={token => handleOauth(token, 'facebook')}
        render={Facebook({ disabled })}
      />

      {error && (
        <div className="row__item">
          <div className="error-display">{error}</div>
        </div>
      )}
    </React.Fragment>
  );
};

OauthLogin.propTypes = {
  disabled: PropTypes.bool,

  // This is used to display an error to the user as the
  //   parent component desires. If unsupplied, the error
  //   will be shown within this component.
  // The function is passed a string containing the error.
  handleError: PropTypes.func,

  // This is used to perform an action with the token
  //   supplied by the provider.
  // It should be passed two arguments, the token and
  //   the name of the provider.
  handleOauth: PropTypes.func.isRequired,
};

OauthLogin.defaultProps = {
  disabled: false,
};

export default observer(OauthLogin);
