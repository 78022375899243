import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@wui/basics/modal';
import Typography from '@wui/basics/typography';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';

const NameConflictPopup = ({ resolve, person }) => (
  <Modal open={Boolean(resolve)} onClose={() => resolve(false)} title="This person already exists.">
    <Typography variant="intro">
      You will need to redo certain sections if you name {person.name} as your{' '}
      {person.clientRelationship}. Are you sure you want to proceed?
    </Typography>
    <Spacer v={24} />
    <Button variant="contained" color="primary" onClick={() => resolve(true)}>
      Proceed
    </Button>
    <Spacer h={16} inline />
    <Button variant="outlined" onClick={() => resolve(false)}>
      Nevermind
    </Button>
  </Modal>
);

NameConflictPopup.propTypes = {
  resolve: PropTypes.func,
  person: PropTypes.object.isRequired,
};

NameConflictPopup.defaultProps = {
  resolve: null,
};

export default NameConflictPopup;
