import { debugLog } from 'debug';
import { WType, ClientDataObject } from 'models/type-system';

export class WBoolean extends WType {
  constructor(defaultValue) {
    super(() => defaultValue || null);
  }

  convert(value, addError) {
    if (value === '' || value === null) {
      addError('Please select a value.');
      return null;
    } else if (typeof value !== 'boolean') {
      addError('Value is not a boolean.');
      return null;
    }
    return value;
  }
}

export class WString extends WType {
  constructor(defaultValue) {
    super(() => {
      if (!defaultValue) {
        return '';
      } else if (typeof defaultValue === 'string') {
        return defaultValue;
      } else {
        return defaultValue();
      }
    });
  }

  convert(value, addError) {
    if (typeof value !== 'string') {
      addError('Value is not a string.');
    }

    // Remove any NULL characters that may have
    //   been mistakenly copied and pasted into
    //   a textbox.
    return value ? value.replace(/\0/g, '') : value;
  }
}

export class WInteger extends WType {
  constructor(defaultValue) {
    super(() => defaultValue || '');
  }

  convert(value, addError) {
    const converted = parseInt(value, 10);
    if (!isFinite(converted) || isNaN(converted)) {
      addError('Please enter a valid number.');
      return null;
    }
    return converted;
  }
}

export class WEnum extends WType {
  static DISPLAY_NAME_MAP = {};
  static DISABLED_SELECTION_TYPES = [];

  constructor(defaultValue, values) {
    super(() => defaultValue);
    this.values = new Set(values);
  }

  static get values() {
    return Array.from(new this().values);
  }

  convert(value, addError) {
    super.convert(value, addError);
    if (typeof value === 'string') {
      return value;
    }
    addError('Value is not a string.');
    return null;
  }

  validate(value, addError) {
    super.validate(value, addError);
    if (value === '' || value === null) {
      addError('Please select a value.');
    } else if (!this.values.has(value)) {
      addError('Please select an allowed value.');
    }
  }

  static normalize(value) {
    if (!value) {
      return '';
    }

    const lowercase = value.toLowerCase();
    const values = Array.from(new this().values);
    const lowercaseValues = values.map(v => v.toLowerCase());
    const index = lowercaseValues.indexOf(lowercase);

    if (index >= 0) {
      return values[index];
    }

    return '';
  }
}

export class WObject extends WType {
  constructor(innerType) {
    if (!innerType) {
      throw 'Falsy inner type passed to WObject';
    }
    super(() => new innerType());
    this.innerType = innerType;
  }

  convert(value, addError) {
    super.convert(value, addError);
    if (value instanceof this.innerType) {
      return value;
    }
    addError('Value is not of the desired type.');
    return null;
  }

  validate(value, addError) {
    value.validate(addError);
  }

  updateInPlace(existing, pojo) {
    existing.updateInPlace(pojo);
    return existing;
  }

  fromPOJO(value) {
    return this.innerType.fromPOJO(value);
  }

  toPOJO(value) {
    return value.toPOJO();
  }

  walkObjects(value, cb) {
    super.walkObjects(value, cb);
    value.walkObjects(cb);
  }
}

export class WArray extends WType {
  constructor(innerType, makeDefault) {
    super(makeDefault || (() => []));
    this.innerType = innerType;

    // Make sure that the ClientDataObject type in the
    //   array sets the `keyFieldName`.
    if (!this.clientDataObjectType.keyFieldName) {
      throw new Error(
        `${this.clientDataObjectType.name} must set keyFieldName when used in a WArray!`,
      );
    }
  }

  // The first innerType is a `WObject`, and the second is the
  //   actual object class.
  get clientDataObjectType() {
    return this.innerType.innerType;
  }

  convert(value, addError) {
    super.convert(value, addError);
    if (value instanceof Array) {
      return value;
    }
    addError('Value is not an array.');
    return null;
  }

  validate(value, addError) {
    for (const child of value) {
      this.innerType.validate(child, addError);
    }
  }

  updateInPlace(existing, pojo) {
    // Only update those objects that were sent to the backend.
    const filtered = existing.filter(c => !c.pojoArrayIgnores);

    // Use the keys of the object to indicate what type the object is
    //   since that information is lost when uglifying the code.
    const objectType = Object.keys(existing[0] || pojo[0] || {}).sort();

    if (filtered.length !== pojo.length) {
      debugLog(`Length mismatch when updating array (${objectType}!`);
    }

    for (const child of filtered) {
      // The order of the arrays might not be the same, so we use
      //   a key from the object to indicate that an object is
      //   the same as another. For this to work, the object class
      //   definition must have a `keyFieldName` variable set.
      const key = child.keyForUpdate;

      // Get the data from the updates for the current object.
      const updatePojo = pojo.find(o => this.clientDataObjectType.keyForUpdate(o) === key);

      if (typeof updatePojo !== 'undefined') {
        child.updateInPlace(updatePojo);
      } else {
        debugLog(`Missing update for object with keys ${objectType}`);
      }
    }

    // We are using the existing array - the value instances are
    //   also the same, but they have their internal values updated.
    return existing;
  }

  fromPOJO(value) {
    const objs = [];

    for (const child of value) {
      objs.push(this.innerType.fromPOJO(child));
    }
    return objs;
  }

  toPOJO(value) {
    const result = [];

    // Only send objects to the backend that aren't ignored.
    const filtered = value.filter(c => !c.pojoArrayIgnores);

    for (const [index, child] of filtered.entries()) {
      const pojo = this.innerType.toPOJO(child);

      // The backend needs an index so storing the objects in the DB can preserve the order. This is the easiest
      // place to generate that. It's not in fields, so discarded on retrieve.
      if (this.innerType instanceof WObject) {
        pojo.index = index;
      }
      result.push(pojo);
    }

    return result;
  }

  walkObjects(value, cb) {
    super.walkObjects(value, cb);
    for (const child of value) {
      this.innerType.walkObjects(child, cb);
    }
  }
}

export class WDate extends ClientDataObject {
  static fields = {
    year: new WInteger(),
    month: new WInteger(),
    day: new WInteger(),
  };

  constructor(value) {
    let data = {};

    if (value instanceof Date) {
      data = {
        year: parseInt(value.getFullYear(), 10),
        month: parseInt(value.getMonth() + 1, 10),
        day: parseInt(value.getDate(), 10),
      };
    } else if (value) {
      data = {
        year: parseInt(value.year, 10),
        month: parseInt(value.month, 10),
        day: parseInt(value.day, 10),
      };
    }
    super(data);
  }

  static toDate(d) {
    if (!d.year || !d.month || !d.day) {
      return null;
    }

    const thisYear = parseInt(d.year, 10),
      thisMonth = parseInt(d.month, 10),
      thisDay = parseInt(d.day, 10);

    return new Date(thisYear, thisMonth - 1, thisDay);
  }

  get date() {
    return this.constructor.toDate(this);
  }

  get today() {
    // today just after midnight
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }

  get tomorrow() {
    // tomorrow just after midnight
    const d = this.today;
    d.setDate(d.getDate() + 1);
    return d;
  }

  convert(value, addError) {
    if (value instanceof Date) {
      if (!isNaN(value.getTime())) {
        return value;
      }
    } else if (typeof value === 'string') {
      const d = new Date(value);
      if (!isNaN(d.getTime())) {
        return d;
      }
    }
    addError('Please select a valid date.');
    return null;
  }

  validate(addError) {
    addError = addError || (() => {});
    super.validate(addError);

    if (!this.year || !this.month || !this.day) {
      addError('Please select a valid date.');
      return;
    }

    // check that the date is not something like February 32nd
    const d = new Date(this.year, this.month - 1, this.day);
    if (
      this.year !== d.getFullYear() ||
      this.month !== d.getMonth() + 1 ||
      this.day !== d.getDate()
    ) {
      addError('Please select a valid date.');
    }
  }
}

export class WPastDate extends WDate {
  validate(addParentError) {
    addParentError = addParentError || (() => {});
    super.validate(addParentError);

    if (this.date > this.tomorrow) {
      addParentError('Please select a date in the past.');
    }
  }
}

export class WFutureDate extends WDate {
  validate(addParentError) {
    addParentError = addParentError || (() => {});
    super.validate(addParentError);

    if (this.date < this.today) {
      addParentError('Please select a date in the future.');
    }
  }
}
