import React from 'react';
import { observer } from 'mobx-react';

import Button from '@wui/input/button';

import { API } from 'API';

const StartAdminSessionButton = () => {
  const handleClick = async () => {
    await API.startAdminSession();
    window.location.href = '/admin/';
  };

  return (
    <Button variant="contained" color="primary" onClick={handleClick}>
      Start Admin Session
    </Button>
  );
};

export default observer(StartAdminSessionButton);
