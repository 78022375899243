import React from 'react';
import PropTypes from 'prop-types';

import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';

import '../DocumentPopUp.scss';

const UpdatePopUp = ({ onCloseClick, onUpdatePerson, onCancel, personData, isUpdatingAction }) => (
  <div className={`popup-fuzz document-pop ${isUpdatingAction && 'document-pop__disable'}`}>
    <Popup onCloseClick={onCloseClick} className="doc__action--stop">
      <h4 className="center">Update {personData.oldName}'s name everywhere</h4>
      <p className="center small">
        We will change {personData.oldFullName} to {personData.fullName} across all of your legal
        documents.
      </p>
      <div className="document-pop__actions">
        {!isUpdatingAction && (
          <PureButton onClick={onCancel} className="button--tertiary actions__button">
            Cancel
          </PureButton>
        )}
        <PureButton onClick={onUpdatePerson} className="button--primary actions__button">
          {isUpdatingAction ? 'Updating...' : 'Update Everywhere'}
        </PureButton>
      </div>
    </Popup>
  </div>
);

UpdatePopUp.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onUpdatePerson: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  personData: PropTypes.object.isRequired,
  isUpdatingAction: PropTypes.bool.isRequired,
};

export default UpdatePopUp;
