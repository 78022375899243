import React from 'react';
import PropTypes from 'prop-types';

import PureButton from 'ui/components/pure-controls/PureButton';
import Popup from 'ui/components/Popup';
import { API } from 'API';

import './IdViewer.scss';

const idSides = ['front', 'back'];

export class IdViewer extends React.Component {
  static propTypes = {
    session: PropTypes.object.isRequired,
    testatorString: PropTypes.string.isRequired,
    approveId: PropTypes.func.isRequired,
    rejectId: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      expandedId: null,
      idUrls: null,
      error: false,
    };
  }

  componentDidMount() {
    this.fetchImageUrls();
  }

  fetchImageUrls() {
    const { session } = this.props;

    if (!session || !session.id) {
      this.setState({ error: true });
    } else {
      API.getNotarizationAdminIdImageUrls(session.id)
        .then(result => {
          this.setState({ idUrls: result.data });
        })
        .catch(() => this.setState({ error: true }));
    }
  }

  tryAgain() {
    this.setState({ error: false });
    this.fetchImageUrls();
  }

  renderExpandedId() {
    const { testatorString } = this.props;
    const { idUrls, expandedId } = this.state;
    const closeFn = () => this.setState({ expandedId: null });

    return (
      <div className="popup-fuzz" onClick={closeFn}>
        <Popup onCloseClick={closeFn} className="popup--id-viewer">
          <img alt="Customer ID" src={idUrls[testatorString][expandedId]} />
        </Popup>
      </div>
    );
  }

  render() {
    const { session, testatorString, approveId, rejectId, loading } = this.props;
    const { expandedId, idUrls, error } = this.state;

    if (error) {
      return (
        <div className="id-viewer">
          <h4>There was an error loading the ID</h4>
          <PureButton onClick={this.tryAgain.bind(this)} className="button--primary">
            Try again
          </PureButton>
        </div>
      );
    }

    if (!session || !idUrls) {
      return null;
    }

    return (
      <div className="id-viewer">
        {expandedId && this.renderExpandedId()}
        <h4>Customer ID:</h4>
        <div className="id-viewer__ids">
          {idSides.map(side => (
            <img
              alt="Customer ID"
              key={side}
              src={idUrls[testatorString][side]}
              onClick={() => this.setState({ expandedId: side })}
            />
          ))}
        </div>
        <div className="id-viewer__actions">
          <PureButton
            onClick={rejectId}
            loading={loading}
            className="button--tertiary button--error"
          >
            Decline
          </PureButton>
          <PureButton onClick={approveId} loading={loading} className="button--primary">
            Approve
          </PureButton>
        </div>
      </div>
    );
  }
}
