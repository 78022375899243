import React from 'react';
import PropTypes from 'prop-types';

export default class ValidationDisplay extends React.PureComponent {
  static propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  render() {
    if (!this.props.errors.length) {
      return null;
    }
    return (
      <div className="error-display">
        {this.props.errors.map(message => (
          <div key={message}>{message}</div>
        ))}
      </div>
    );
  }
}
