import { WEnum } from 'models/types';
import { STATES } from 'utils';

export class State extends WEnum {
  constructor() {
    super('', Object.keys(STATES));
  }
}

export class Lineage extends WEnum {
  static CLIENT = 'client';
  static BOTH = 'both';
  static SPOUSE = 'spouse';

  constructor() {
    super('', [Lineage.CLIENT, Lineage.BOTH, Lineage.SPOUSE]);
  }
}

export class AssetOwnership extends WEnum {
  static OTHERS_SUFFIX = 'and_others';
  static ADDITIONAL_OWNERS_DATA = 'ADDITIONAL_OWNERS';

  static BOTH = 'both';
  static CLIENT = 'client';
  static SPOUSE = 'spouse';
  static BOTH_AND_OTHERS = AssetOwnership.addAdditionalOwners(AssetOwnership.BOTH);
  static CLIENT_AND_OTHERS = AssetOwnership.addAdditionalOwners(AssetOwnership.CLIENT);
  static SPOUSE_AND_OTHERS = AssetOwnership.addAdditionalOwners(AssetOwnership.SPOUSE);

  constructor() {
    super('', [
      AssetOwnership.BOTH,
      AssetOwnership.CLIENT,
      AssetOwnership.SPOUSE,
      AssetOwnership.BOTH_AND_OTHERS,
      AssetOwnership.CLIENT_AND_OTHERS,
      AssetOwnership.SPOUSE_AND_OTHERS,
    ]);
  }

  static splitOwnershipType(jointOwnershipType) {
    if (!jointOwnershipType) {
      return [null, false];
    }

    // Split on first underscore only.
    const parts = jointOwnershipType.split(/_(.+)/);

    return [parts[0], parts[1] === AssetOwnership.OTHERS_SUFFIX];
  }

  static addAdditionalOwners(ownershipType) {
    return `${ownershipType}_${AssetOwnership.OTHERS_SUFFIX}`;
  }
}

export class Gender extends WEnum {
  static MALE = 'male';
  static FEMALE = 'female';

  constructor() {
    super('', [Gender.MALE, Gender.FEMALE]);
  }
}

export class MaritalStatus extends WEnum {
  static MARRIED = 'married';
  static SINGLE = 'single';
  static DIVORCED = 'divorced';
  static WIDOWED = 'widowed';

  constructor() {
    super('', [
      MaritalStatus.MARRIED,
      MaritalStatus.SINGLE,
      MaritalStatus.DIVORCED,
      MaritalStatus.WIDOWED,
    ]);
  }
}

export class AgentType extends WEnum {
  static PRIMARY = 'primary';
  static ALTERNATE = 'alternate';

  static get values() {
    return [AgentType.PRIMARY, AgentType.ALTERNATE];
  }

  constructor() {
    super('', AgentType.values);
  }
}

export class AgentOwner extends WEnum {
  static CLIENT = 'client';
  static SPOUSE = 'spouse';

  constructor() {
    super('', [AgentOwner.CLIENT, AgentOwner.SPOUSE]);
  }
}

export class LifeSupport extends WEnum {
  static YES = 'yes';
  static NO = 'no';
  static AGENT = 'agent';

  constructor() {
    super('', [LifeSupport.YES, LifeSupport.NO, LifeSupport.AGENT]);
  }
}

export class PainTreatment extends WEnum {
  static YES = 'yes';
  static NO = 'no';
  static AGENT = 'agent';

  constructor() {
    super('', [PainTreatment.YES, PainTreatment.NO, PainTreatment.AGENT]);
  }
}

export class AssetType extends WEnum {
  static REAL_ESTATE = 'real_estate';
  static VEHICLE = 'vehicle';
  static BANK_ACCOUNT = 'bank_account';
  static INVESTMENT_ACCOUNT = 'investment_account';
  static LIFE_INSURANCE = 'life_insurance';
  static BUSINESS = 'business';
  static JEWELRY = 'jewelry';
  static ART = 'art';
  static OTHER = 'other';
  static CASH_GIFT = 'cash_gift';
  static RESIDUAL = 'residual';
  static RESIDUAL_JOINT = 'residual_joint';

  static DISABLED_SELECTION_TYPES = [
    AssetType.INVESTMENT_ACCOUNT,
    AssetType.LIFE_INSURANCE,
    AssetType.RESIDUAL_JOINT,
    AssetType.RESIDUAL,
  ];

  static DISPLAY_NAME_MAP = {
    [AssetType.ART]: 'Art',
    [AssetType.OTHER]: 'Other',
    [AssetType.VEHICLE]: 'Vehicle',
    [AssetType.JEWELRY]: 'Jewelry',
    [AssetType.BUSINESS]: 'Business',
    [AssetType.CASH_GIFT]: 'Cash Gift',
    [AssetType.REAL_ESTATE]: 'Real Estate',
    [AssetType.LIFE_INSURANCE]: 'Life Insurance',
    [AssetType.BANK_ACCOUNT]: 'Financial Account',
    [AssetType.INVESTMENT_ACCOUNT]: 'Investment Account',
  };

  constructor() {
    super('', [
      AssetType.REAL_ESTATE,
      AssetType.VEHICLE,
      AssetType.BANK_ACCOUNT,
      AssetType.INVESTMENT_ACCOUNT,
      AssetType.LIFE_INSURANCE,
      AssetType.BUSINESS,
      AssetType.JEWELRY,
      AssetType.ART,
      AssetType.OTHER,
      AssetType.CASH_GIFT,
      AssetType.RESIDUAL,
      AssetType.RESIDUAL_JOINT,
    ]);
  }
}

export class BankAccountType extends WEnum {
  static BANK = 'bank';
  static SAVINGS = 'savings';
  static CHECKING = 'checking';
  static INSURANCE = 'insurance';
  static RETIREMENT = 'retirement';
  static INVESTMENT = 'investment';
  static OTHER = 'other';

  static DISABLED_SELECTION_TYPES = [BankAccountType.BANK];

  static DISPLAY_NAME_MAP = {
    [BankAccountType.BANK]: 'Bank',
    [BankAccountType.OTHER]: 'Other',
    [BankAccountType.SAVINGS]: 'Savings',
    [BankAccountType.CHECKING]: 'Checking',
    [BankAccountType.INSURANCE]: 'Insurance',
    [BankAccountType.RETIREMENT]: 'Retirement',
    [BankAccountType.INVESTMENT]: 'Investment',
  };

  constructor() {
    super('', [
      BankAccountType.BANK,
      BankAccountType.SAVINGS,
      BankAccountType.CHECKING,
      BankAccountType.INSURANCE,
      BankAccountType.RETIREMENT,
      BankAccountType.INVESTMENT,
      BankAccountType.OTHER,
    ]);
  }
}

export class ShareDistribution extends WEnum {
  static EQUAL_SHARES = 'equal_shares';
  static CUSTOM = 'custom';

  static valid(distribution, beneficiaries) {
    // If the distribution is equal shares, then the
    //   percentages of the beneficiaries don't matter.
    if (distribution === ShareDistribution.EQUAL_SHARES) {
      return true;
    }

    return (
      beneficiaries.reduce(
        // If the percentage isn't a number, we default to a large
        //   number to ensure that any other values adding up to
        //   100 does not indicate success.
        (total, c) => total + (Number(c.sharePercentage) || 1000),
        0,
      ) === 100
    );
  }

  static validByShares(shares) {
    // If every share is equal, then everything is good.
    if (shares.every(share => share === ShareDistribution.EQUAL_SHARES)) {
      return true;
    }

    // Otherwise, the shares are all numbers, or a mix of numbers
    //   and equal shares, which will be handled by the standard
    //   validation.
    return this.valid(
      ShareDistribution.CUSTOM,
      shares.map(sharePercentage => ({ sharePercentage })),
    );
  }

  constructor() {
    super(ShareDistribution.EQUAL_SHARES, [
      ShareDistribution.EQUAL_SHARES,
      ShareDistribution.CUSTOM,
    ]);
  }
}

export class Relationship extends WEnum {
  static SELF = 'self';
  static SPOUSE = 'spouse';
  static CHILD = 'child';

  static CHOICES = [
    ['self', 'Self'],
    ['spouse', 'Spouse'],
    ['ex_spouse', 'Ex-spouse'],
    ['mother', 'Mother'],
    ['father', 'Father'],
    ['child', 'Child'],
    ['sister', 'Sister'],
    ['brother', 'Brother'],
    ['aunt', 'Aunt'],
    ['uncle', 'Uncle'],
    ['niece', 'Niece'],
    ['nephew', 'Nephew'],
    ['cousin', 'Cousin'],
    ['grandmother', 'Grandmother'],
    ['grandfather', 'Grandfather'],
    ['granddaughter', 'Granddaughter'],
    ['grandson', 'Grandson'],
    ['stepsister', 'Stepsister'],
    ['stepbrother', 'Stepbrother'],
    ['stepmother', 'Stepmother'],
    ['stepfather', 'Stepfather'],
    ['stepson', 'Stepson'],
    ['stepdaughter', 'Stepdaughter'],
    ['sister_in_law', 'Sister-in-law'],
    ['brother_in_law', 'Brother-in-law'],
    ['mother_in_law', 'Mother-in-law'],
    ['father_in_law', 'Father-in-law'],
    ['daughter_in_law', 'Daughter-in-law'],
    ['son_in_law', 'Son-in-law'],
    ['friend', 'Friend'],
  ];

  static NON_SELECTABLE_CHOICES = [Relationship.SELF, Relationship.SPOUSE, Relationship.CHILD];
  static SELECTABLE_CHOICES = Relationship.CHOICES.filter(
    c => !Relationship.NON_SELECTABLE_CHOICES.includes(c[0]),
  );

  constructor() {
    super(
      '',
      Relationship.CHOICES.map(c => c[0]),
    );
  }
}

export class NotifiedPersonPermissions extends WEnum {
  static NOTIFY = 'notify';
  static ACCESS = 'access';
  static TIME_OF_NEED = 'time_of_need';

  static UNSET_DISPLAY_NAME = 'TBD';

  static displayName(permission) {
    switch (permission) {
      case NotifiedPersonPermissions.NOTIFY:
        return 'Notify Only';
      case NotifiedPersonPermissions.ACCESS:
        return 'Full Access';
      case NotifiedPersonPermissions.TIME_OF_NEED:
        return 'Time-of-Need Only (death)';
      default:
        return NotifiedPersonPermissions.UNSET_DISPLAY_NAME;
    }
  }

  constructor() {
    super('', [
      NotifiedPersonPermissions.NOTIFY,
      NotifiedPersonPermissions.ACCESS,
      NotifiedPersonPermissions.TIME_OF_NEED,
    ]);
  }
}

const NOTIFIED_PERSON_STATUS_ACCEPTED = 'accepted';
const NOTIFIED_PERSON_STATUS_DECLINED = 'declined';

export class NotifiedPersonNotificationStatus {
  static SENT = 'sent';
  static PENDING = 'pending';
  static DECLINED = 'declined';
  static NOT_SENT = 'not_sent';
  static CONFIRMED = 'confirmed';

  static SORT_ORDER = [
    NotifiedPersonNotificationStatus.DECLINED,
    NotifiedPersonNotificationStatus.PENDING,
    NotifiedPersonNotificationStatus.SENT,
    NotifiedPersonNotificationStatus.CONFIRMED,
    NotifiedPersonNotificationStatus.NOT_SENT,
  ];

  static get(notifiedPerson) {
    if (!notifiedPerson.pk) {
      return this.NOT_SENT;
    } else if (notifiedPerson.permissions === NotifiedPersonPermissions.NOTIFY) {
      return this.SENT;
    }

    switch (notifiedPerson.status) {
      case NOTIFIED_PERSON_STATUS_ACCEPTED:
        return this.CONFIRMED;
      case NOTIFIED_PERSON_STATUS_DECLINED:
        return this.DECLINED;
      default:
        return this.PENDING;
    }
  }
}

export class NotifiedPersonStatus extends WEnum {
  static PENDING = 'pending';
  static ACCEPTED = NOTIFIED_PERSON_STATUS_ACCEPTED;
  static DECLINED = NOTIFIED_PERSON_STATUS_DECLINED;

  static displayName(notifiedPerson) {
    switch (notifiedPerson.notificationStatus) {
      case NotifiedPersonNotificationStatus.SENT:
        return 'Sent';
      case NotifiedPersonNotificationStatus.DECLINED:
        return 'Declined';
      case NotifiedPersonNotificationStatus.NOT_SENT:
        return 'Not Sent';
      case NotifiedPersonNotificationStatus.CONFIRMED:
        return 'Confirmed';
      default:
        return 'Awaiting Confirmation';
    }
  }

  constructor() {
    super(NotifiedPersonStatus.PENDING, [
      NotifiedPersonStatus.PENDING,
      NotifiedPersonStatus.ACCEPTED,
      NotifiedPersonStatus.DECLINED,
    ]);
  }
}

export class SignatureFont extends WEnum {
  static AMALFI = 'amalfi';
  static BALLPOINT = 'ballpoint';
  static STYLEDEDIT = 'stylededit';
  static OLEANDER = 'whiteoleander';

  static CHOICES = [
    SignatureFont.AMALFI,
    SignatureFont.BALLPOINT,
    SignatureFont.STYLEDEDIT,
    SignatureFont.OLEANDER,
  ];

  constructor() {
    super(SignatureFont.OLEANDER, SignatureFont.CHOICES);
  }
}

export class IncomeRange extends WEnum {
  static BELOW_25 = '<25';
  static BTW_25_49 = '25<>49';
  static BTW_50_74 = '50<>74';
  static BTW_75_99 = '75<>99';
  static BTW_100_149 = '100<>149';
  static BTW_150_199 = '150<>199';
  static BTW_200_249 = '200<>249';
  static ABOVE_250 = '>250';
  static NOT_GIVEN = 'not_given';

  static CHOICES = [
    IncomeRange.BELOW_25,
    IncomeRange.BTW_25_49,
    IncomeRange.BTW_50_74,
    IncomeRange.BTW_75_99,
    IncomeRange.BTW_100_149,
    IncomeRange.BTW_150_199,
    IncomeRange.BTW_200_249,
    IncomeRange.ABOVE_250,
    IncomeRange.NOT_GIVEN,
  ];

  constructor() {
    super('', IncomeRange.CHOICES);
  }

  static get DISPLAY_NAME_MAP() {
    const map = this.CHOICES.slice(0, -1).reduce((a, c) => {
      a[c] = c.replace('<>', 'k - ').replace('<', 'Below ').replace('>', 'Above ') + 'k';

      return a;
    }, {});

    map[this.NOT_GIVEN] = "I'd rather not say";

    return map;
  }
}
