import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';

import MobileListContainer from '@wui/layout/mobileListContainer';
import MobileCollapsedList from '@wui/layout/mobileCollapsedList';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import { formatMoney, documentBundleIncludes } from 'utils';
import { DocumentBundle } from 'utils/enums';
import CustomIcon from '@wui/basics/customIcon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const styles = theme => ({
  // Used to modify other classes
  checkoutOpen: {},

  root: {
    [theme.breakpoints.desktop]: {
      padding: '24px',
      boxShadow: theme.customShadows.standard,
      border: `1px solid ${theme.palette.grey.panelBorder}`,
    },
    '&$checkoutOpen': {
      [theme.breakpoints.desktop]: {
        boxShadow: 'none',
        border: 'none',
        backgroundColor: '#FAFAFA',
        paddingBottom: 0,
      },
    },
  },
  heading: {
    display: 'none',
    justifyContent: 'space-between',

    [theme.breakpoints.desktop]: {
      display: 'flex',

      '& > p': {
        fontWeight: 'bold !important',
      },
    },
  },
  listItem: {
    display: 'flex',
    marginLeft: 8,
    marginBottom: 4,

    '&:last-child': {
      marginBottom: 0,
    },

    '& > :first-child': {
      position: 'relative',
      top: '6px',
      marginRight: 8,
    },
  },
});

class DocumentList extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    // This will not have loaded from the API on first render, so avoid an exception
    price: PropTypes.number,
    documents: PropTypes.arrayOf(PropTypes.string).isRequired,
    bundle: PropTypes.string.isRequired,
    checkoutOpen: PropTypes.bool,
  };

  static defaultProps = {
    checkoutOpen: false,
    price: 0,
  };

  get check() {
    return (
      <CustomIcon
        src={CheckCircleIcon}
        color={theme => theme.palette.green.success}
        width={16}
        height={16}
      />
    );
  }

  get extraItems() {
    const { bundle, documents } = this.props;
    const result = [this.renderItem('State specific signing instructions', documents.length)];

    if (documentBundleIncludes(bundle, DocumentBundle.HOMEOWNERS)) {
      result.push(this.renderItem('Deed filing in your county', documents.length + 1));
    }

    return result;
  }

  renderItem = (name, idx) => (
    <div className={this.props.classes.listItem} key={idx}>
      {this.check}

      <Typography>{name}</Typography>
    </div>
  );

  render() {
    const { classes, price, documents, checkoutOpen } = this.props;
    const wrapperClass = classNames(classes.root, {
      [classes.checkoutOpen]: checkoutOpen,
    });

    return (
      <MobileListContainer title="Documents" className={wrapperClass}>
        <Hidden smDown>
          <div className={classes.heading}>
            <Typography>Included in this Bundle</Typography>
            <Typography>{price && formatMoney(price / 100)}</Typography>
          </div>
        </Hidden>

        <Spacer v={8} />

        <MobileCollapsedList>
          {documents.map(this.renderItem)}
          {this.extraItems}
        </MobileCollapsedList>
      </MobileListContainer>
    );
  }
}

export default withStyles(styles)(DocumentList);
