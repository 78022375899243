import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import parseGooglePlace from 'parse-google-place';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete';

import BoundTextField from './BoundTextField';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  suggestions: {
    // Ensure displayed above fields
    //   that are below it.
    zIndex: 100,

    left: 0,
    right: 0,
    position: 'absolute',
    top: theme.layout.inputHeight + 16,
  },
  suggestion: {
    padding: 4,
    cursor: 'pointer',
  },
  activeSuggestion: {
    background: theme.palette.custom.border,
  },
});

class GooglePlacesAutocomplete extends React.Component {
  // These are props that will be passed along to
  //   the BoundTextField, but are also needed
  //   locally.
  static forwardedProps = {
    obj: PropTypes.object,
    path: PropTypes.string.isRequired,
    skipStandardValidation: PropTypes.bool,
  };

  static propTypes = {
    onSelect: PropTypes.func,
    classes: PropTypes.object.isRequired,
    ...GooglePlacesAutocomplete.forwardedProps,
  };

  constructor(props, context) {
    super(props, context);

    this.state = { value: this.props.obj.getValue(this.props.path) };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.obj !== this.props.obj) {
      // Otherwise the street address would clone itself when the testator changes
      //   in tabbed MUI wizard pages
      this.setState({ value: this.props.obj.getValue(this.props.path) });
    }
  }

  selectSuggestion(description, placeId) {
    const { onSelect } = this.props;

    const doSelect = place => {
      const parsed = parseGooglePlace(place);

      this.setState({ value: parsed.address || description });
      onSelect({ ...parsed, description });
    };

    geocodeByPlaceId(placeId)
      .then(results => doSelect(results[0]))
      .catch(() => doSelect({}));
  }

  renderSuggestion(getSuggestionItemProps, suggestion) {
    const { classes } = this.props;

    const className = classNames(classes.suggestion, {
      [classes.activeSuggestion]: suggestion.active,
    });

    const props = getSuggestionItemProps(suggestion, {
      className,
      variant: 'body2',
      component: 'div',
    });

    return <Typography {...props}>{suggestion.description}</Typography>;
  }

  renderAutocomplete(textFieldProps, { getInputProps, getSuggestionItemProps, suggestions }) {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <BoundTextField {...textFieldProps} inputPropsModifier={getInputProps} autocomplete="off" />
        {suggestions.length > 0 && (
          <Paper square={true} className={classes.suggestions}>
            {suggestions.map(this.renderSuggestion.bind(this, getSuggestionItemProps))}
          </Paper>
        )}
      </div>
    );
  }

  render() {
    const textFieldProps = { ...this.props };

    // Don't include any props from this component in
    //   those we are sending to the BoundTextField.
    Object.keys(this.constructor.propTypes).forEach(k => {
      if (!Object.keys(this.constructor.forwardedProps).includes(k)) {
        delete textFieldProps[k];
      }
    });

    return (
      <PlacesAutocomplete
        onError={() => {}}
        value={this.state.value}
        onChange={value => this.setState({ value })}
        onSelect={this.selectSuggestion.bind(this)}
      >
        {this.renderAutocomplete.bind(this, textFieldProps)}
      </PlacesAutocomplete>
    );
  }
}

export default withStyles(styles)(GooglePlacesAutocomplete);
