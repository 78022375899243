import React from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import { CheckoutForm } from 'ui/components/payment/CheckoutForm';

export class CheckoutElement extends React.Component {
  static propTypes = {
    documentBundle: PropTypes.string,
    subscriptionPlan: PropTypes.string,
    updatePriceInfo: PropTypes.func,
  };

  render() {
    return (
      <Elements>
        <CheckoutForm {...this.props} />
      </Elements>
    );
  }
}
