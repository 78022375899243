import React from 'react';
import PropTypes from 'prop-types';

import WizardPage from 'ui/components/wizard/WizardPage';

import WuiApp from '@/WuiApp';
import MuiBequestInterface from '@s/assets/BequestInterface';

export default class BequestInterface extends WizardPage {
  /*
   *   This class represents the interface for specifying bequests. It divides
   *   the task into specific gifts and a residuary beneficiaries. Specific gifts
   *   associate a particular asset with a set of beneficiaries. A residuary
   *   beneficiary associates everything not explicitly bequeathed with a set
   *   of beneficiaries.
   *
   *   Note: All asset allocations must have a residuary clause, which names a
   *   residuary beneficiary for whom there are no dependent specific gifts.
   */

  static baseUrl = 'bequest-interface/';
  static showPrevNextButtons = false;

  static contextTypes = {
    section: PropTypes.object,
    ...WizardPage.contextTypes,
  };

  static customDisplay() {
    return () => (
      <WuiApp showFooter={false} wrapContent={false}>
        <MuiBequestInterface />
      </WuiApp>
    );
  }
}
