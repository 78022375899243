import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import WizardPage from 'ui/components/wizard/WizardPage';
import { API } from 'API';
import { NotarizationWizardPageMixin } from 'ui/components/wizard/NotarizationWizardPageMixin';
import { NotarizationStatus } from 'utils/enums';
import { joinClass, getPhoneNumber } from 'utils';
import mitekUtil from 'mitek';

import PureButton from 'ui/components/pure-controls/PureButton';
import FailurePage from 'ui/sections/notarize/pages/info/FailurePage';
import Popup from 'ui/components/Popup';
import BackCamera from './camera/BackCamera';
import IntroVerification from './intro/IntroVerification';

import './IdVerification.scss';

const idCaptureTips = [
  'Make sure that the ID has all four corners visible',
  'Make sure that the ID covers the majority of the image',
  'Make sure that there is no glare on the ID',
  'Make sure that you are in a well lit area',
  `Hold the ${isMobile ? 'device' : 'ID'} steady`,
];

export default class IdVerification extends NotarizationWizardPageMixin(WizardPage) {
  static baseUrl = 'id-verification/capture/';
  static showPrevNextButtons = false;
  static cardwrapperClass = 'id-verification__card-wrapper';
  static notarizationSection = NotarizationStatus.ID_VERIFICATION;
  static cardClass = 'card--max-height id-verification__card';
  static wizardPageClass = 'wizard-page--flex no-bottom-padding';
  static enterNavigation = false;
  static hideZendesk = true;

  static contextTypes = {
    section: PropTypes.object,
    ...WizardPage.contextTypes,
  };

  static initialState = {
    loading: false,
    step: 'intro',
    side: 'front',
    front: {
      img: null,
    },
    back: {
      img: null,
    },
    error: null,
    captureErrors: null,
    consecutiveFails: 0,
  };

  constructor(props, context) {
    super(props, context);
    this.publisher = null;
    this.state = {
      ...this.constructor.initialState,
    };

    this.inputRefs = {};
    this.inputRefs.front = React.createRef();
    this.inputRefs.back = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.context.section.mitekLoaded.then(mitekUtil.init);
  }

  get missingImgError() {
    return 'Please upload photos of both sides of your ID';
  }

  get failText() {
    if (isMobile) {
      return `We were unable to verify your ID. Please try again or give
                us a call at ${getPhoneNumber(this.clientData, this.serverData)}.`;
    }
    return `We were unable to verify your ID. Please try again or manually
            upload images of your ID instead. If you need help, please give us
            a call at ${getPhoneNumber(this.clientData, this.serverData)}.`;
  }

  goToNext = async () => {
    if (!this.state.front.img || !this.state.back.img) {
      this.setState({ error: this.missingImgError });
      return;
    }
    this.setState({ loading: true });
    const { front, back } = this.state;
    const forSpouse = this.testator.clientRelationship === 'spouse';

    try {
      const result = await API.postNotarizationVerifyId(
        forSpouse,
        front.img,
        back.img,
        back.displayImg,
      );
      if (!result.data.success) {
        this.setState({ loading: false, step: 'fail' });
        return;
      }
    } catch (err) {
      this.setState({ loading: false, step: 'fail' });
      return;
    }

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    super.goToNext();
  };

  tryAgain = () => {
    this.setState(this.constructor.initialState);
  };

  tryManual = () => {
    const newState = Object.assign({}, this.constructor.initialState, {
      step: 'manual',
    });
    this.setState(newState);
  };

  setLoading = () => {
    this.setState({ loading: true });
  };

  handleCaptureResult = (side, decodeErrors) => result => {
    if (this.state.captureErrors) {
      return;
    }
    this.setState({ loading: true });

    const { consecutiveFails, step } = this.state;
    decodeErrors = decodeErrors || mitekUtil.decodeDesktopErrorCodes;

    const captureErrors = decodeErrors(result.errors);
    if (captureErrors.length) {
      if (consecutiveFails >= 2) {
        this.setState({ step: 'fail' });
        return;
      }

      this.setState({
        captureErrors,
        loading: false,
        consecutiveFails: this.state.consecutiveFails + 1,
      });
      return;
    }
    if (step === 'manual') {
      this.setState({
        loading: false,
        [side]: {
          img: result.finalImage,
          displayImg: result.fullColorImage || null,
        },
        consecutiveFails: 0,
      });
    } else if (side === 'back') {
      this.setState(
        {
          [side]: {
            img: result.finalImage,
            displayImg: result.fullColorImage || null,
          },
          consecutiveFails: 0,
        },
        this.goToNext.bind(this),
      );
    } else {
      this.setState({
        loading: false,
        step: 'success',
        front: { img: result.finalImage },
        consecutiveFails: 0,
      });
    }
  };

  renderStep = step => {
    const { side, loading, step: currentStep } = this.state;

    if (step === 'intro') {
      return (
        <IntroVerification
          nextStep={() => this.setState({ step: 'picture' })}
          handleCaptureResult={this.handleCaptureResult(side, mitekUtil.decodeAllErrorCodes)}
          side={side}
          loading={loading}
          setLoading={this.setLoading}
          tips={idCaptureTips}
        />
      );
    }
    if (step === 'picture') {
      return (
        <BackCamera
          handleCaptureResult={this.handleCaptureResult(side)}
          side={side}
          videoSource={this.clientData.acceptedVideoSource}
          step={currentStep}
          loading={loading}
          setLoading={this.setLoading}
        />
      );
    }
    if (step === 'manual') {
      return this.renderManual();
    }
    if (step === 'fail') {
      return (
        <FailurePage
          headerText="ID Capture Failed"
          bodyText={this.failText}
          pointerHeaderText="ID capture tips:"
          pointers={idCaptureTips}
          buttonText="Try again"
          onRetry={this.tryAgain}
          hideIcon={true}
        >
          {!isMobile && (
            <PureButton onClick={this.tryManual} className="button--secondary button--inline">
              Upload manually
            </PureButton>
          )}
        </FailurePage>
      );
    }

    if (step === 'success') {
      return (
        <div className="capture-success center">
          <img
            alt="Front of ID"
            className="captured-img"
            src={`data:image/jpeg;base64,${this.state.front.img}`}
          />
          <h1>Front of ID captured successfully</h1>
          <p>Successfully captured the front of the ID. Next, let's capture the back.</p>
          <PureButton
            onClick={() => this.setState({ side: 'back', step: 'intro' })}
            className="button--primary button--inline button--no-right-margin"
            dataPath="next"
          >
            Capture back
          </PureButton>
        </div>
      );
    }

    return '';
  };

  saveSide = side => event => {
    const reader = new FileReader();
    const file = event.target.files[0];

    if (file.type && file.type.split && file.type.split('/')[0] !== 'image') {
      return;
    }
    this.setState({ error: null });
    reader.onload = readerEvent => {
      const img = readerEvent.target.result;
      const params = {
        imageData: img.split(',')[1],
        callback: this.handleCaptureResult(side, mitekUtil.decodeAllErrorCodes),
        documentType: side === 'front' ? 'DL_Front' : 'DL_Back',
      };
      window.MitekMobileWeb.processDataURL(params);
    };
    reader.readAsDataURL(file);
  };

  clickSide = side => () => {
    this.inputRefs[side].current.click();
  };

  renderUploader(side) {
    const imageData = this.state[side].displayImg || this.state[side].img;
    return (
      <div className="image-uploader">
        {this.state[side].img ? (
          <img alt="Uploaded ID" src={`data:image/jpeg;base64,${imageData}`} />
        ) : (
          <div className="image-uploader__placeholder" />
        )}
        <input type="file" ref={this.inputRefs[side]} onChange={this.saveSide(side)} />
        <p>ID {side}</p>
        <PureButton
          onClick={this.clickSide(side)}
          className="button--primary image-uploader__save-button"
        >
          Upload
        </PureButton>
      </div>
    );
  }

  renderManual() {
    return (
      <React.Fragment>
        <h1 className="verification__title">Upload Photo ID</h1>
        <p className="verification__description">
          Due to the image quality, we were unable to verify your photo ID.
        </p>
        <p className="verification__description">
          Please upload high quality photographs of your ID to proceed.
        </p>
        <div className="image-uploader-wrapper">
          {this.renderUploader('front')}
          {this.renderUploader('back')}
        </div>
        <div className="error-display">{this.state.error}</div>
        <PureButton
          className="button--primary verification__save-button"
          onClick={this.goToNext}
          disabled={this.state.loading}
        >
          Save
        </PureButton>
      </React.Fragment>
    );
  }

  renderCaptureErrors() {
    const { captureErrors } = this.state;
    const closeFn = () => this.setState({ captureErrors: null });
    return (
      <div className="popup-fuzz">
        <Popup onCloseClick={closeFn}>
          <h4 className="center">There was a problem processing your image</h4>
          {captureErrors.map(error => (
            <p key={error} className="small">
              {error}
            </p>
          ))}
          <PureButton className="button--primary center" onClick={closeFn}>
            Try again
          </PureButton>
        </Popup>
      </div>
    );
  }

  renderPage() {
    const { step, captureErrors } = this.state;
    return (
      <div className={joinClass('id-verification', `${step}-step`)}>
        {captureErrors && this.renderCaptureErrors()}
        {['intro', 'camera'].includes(step) ? (
          <React.Fragment>
            <div className={'intro ' + (step !== 'intro' ? 'not-current-step' : '')}>
              {this.renderStep('intro')}
            </div>
            <div className={'camera ' + (step !== 'picture' ? 'not-current-step' : '')}>
              {this.renderStep('picture')}
            </div>
          </React.Fragment>
        ) : (
          this.renderStep(step)
        )}
      </div>
    );
  }
}
