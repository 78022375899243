import React from 'react';

import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { ProgressSections } from 'material-ui/components/progress';
import { Tips, Tip } from 'material-ui/components/tips';
import { Executor } from 'models/client-data/agents';
import { PeopleBuilder } from 'models/utils';
import { requiresFelonExemptionStates, requiresResidentExecutorStates } from 'utils';

import PersonPage from 'material-ui/components/wizard-elements/PersonPage';

export default class MaterialExecutors extends MuiWizardPage {
  static progressPage = ProgressSections.YOUR_WISHES.EXECUTORS;

  get label() {
    return `Who do you trust to manage your finances when you pass away or are too sick to make
        decisions for yourself?`;
  }

  renderPage() {
    const needsResidentNotice = requiresResidentExecutorStates.includes(
      this.clientData.address.state,
    );
    const needsFelonExemption = requiresFelonExemptionStates.includes(
      this.clientData.address.state,
    );

    return (
      <React.Fragment>
        <Tips>
          <Tip title="Naming Executors">
            <p>An executor is also referred to as a "personal representative."</p>

            <p>
              This person will have authority under your last will and testament (after you pass
              away) and under your durable power of attorney for finances (when you're alive but
              unable to make decisions for yourself).
            </p>

            {needsResidentNotice && (
              <p>
                Note: In Florida, this person must be a resident of Florida unless related to you by
                blood, and cannot be a convicted felon.
              </p>
            )}

            {needsFelonExemption && (
              <p>
                Note: In {this.clientData.address.stateName}, this person cannot be a convicted
                felon.
              </p>
            )}
          </Tip>
        </Tips>

        <PersonPage
          title="Executors"
          agentName="Executor"
          agentClass={Executor}
          peopleBuilderMethod={PeopleBuilder.getExecutors}
          primaryPath="primaryExecutor"
          alternatePath="alternateExecutor"
          caption={this.label}
        />
      </React.Fragment>
    );
  }
}
