import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Button from '@wui/input/button';
import Modal from '@wui/basics/modal';

const GenericOpenTokError = ({ open, onClose }) => (
  <Modal open={open} onClose={onClose} title="Something went wrong">
    We were unable to perform the action. If the problem persists, please try again later.
    <br />
    <br />
    <Button variant="contained" color="primary" onClick={onClose}>
      Try Again
    </Button>
  </Modal>
);

GenericOpenTokError.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default observer(GenericOpenTokError);
