import React from 'react';
import PropTypes from 'prop-types';

import Modal from '@wui/basics/modal';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import DimensionLimiter from '@wui/layout/dimensionLimiter';

const OmbudsmanModal = ({ open, onClose, showManualSigningModal, orSpouse, digitalSign }) => (
  <Modal title="Are you in a nursing facility?" open={open} onClose={onClose}>
    <DimensionLimiter h={360}>
      <Typography variant="body1">
        Are you {orSpouse} currently a patient in a skilled nursing facility that provides the
        following basic services: skilled nursing care and supportive care?
      </Typography>
      <Spacer v={16} />
      <Button variant="outlined" noMinWidth onClick={showManualSigningModal}>
        Yes
      </Button>
      <Spacer h={16} inline />
      <Button variant="outlined" noMinWidth onClick={digitalSign}>
        No
      </Button>
    </DimensionLimiter>
  </Modal>
);

OmbudsmanModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showManualSigningModal: PropTypes.func.isRequired,
  orSpouse: PropTypes.string.isRequired,
  digitalSign: PropTypes.func.isRequired,
};

export default OmbudsmanModal;
