import React from 'react';
import PropTypes from 'prop-types';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import ValidationDisplay from 'ui/components/ValidationDisplay';

export default class CallbackValidationDisplay extends ClientDataReferer {
  static propTypes = Object.assign(
    {
      validateCallback: PropTypes.func,
    },
    ClientDataReferer.propTypes,
  );

  constructor(props, context) {
    super(props, context);
    this.state = {
      showValidationErrors: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.validateAllCallback = e => {
      e.isValid = e.isValid && this.getValidationErrors().length === 0;
      this.setState({ showValidationErrors: true });
    };
    this.clientDataHolder.validateAllCallbacks.subscribe(this.validateAllCallback);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.clientDataHolder.validateAllCallbacks.unsubscribe(this.validateAllCallback);
  }

  getValidationErrors() {
    return this.props.validateCallback();
  }

  render() {
    if (!this.state.showValidationErrors) {
      return null;
    }
    return <ValidationDisplay errors={this.getValidationErrors()} />;
  }
}
