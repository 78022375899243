import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Redirect, Link as RouterLink } from 'react-router-dom';

import { API } from 'API';
import OauthLogin from './OauthLogin';
import AuthLayout from './AuthLayout';
import { Urls } from 'urls';
import SectionAboutYou from 'ui/sections/about-you/SectionAboutYou';
import { AUTH_AXES_LOCKOUT, AUTH_COVID_LOGIN, getAxesErrorMessage } from '@willing-shared/api/user';

import Link from '@wui/basics/link';
import Grid from '@wui/layout/grid';
import Form from '@wui/layout/form';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Textbox from '@wui/input/textbox';
import Typography from '@wui/basics/typography';
import TabDivider from '@wui/layout/tabDivider';

import { useGlobalContext, useInputFieldState } from 'hooks';
import withLegalPlansRedirect from '@willing-shared/hocs/withLegalPlansRedirect';

const Login = ({ location }) => {
  const { clientData, clientDataHolder, analyticsEvents } = useGlobalContext();
  const [email, onChangeEmail] = useInputFieldState('');
  const [password, onChangePassword] = useInputFieldState('');
  const [validationError, setValidationError] = useState(null);
  const [inputErrors, setInputErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!email) {
      errors.email = 'Please enter your email';
    }

    if (!password) {
      errors.password = 'Please enter your password.';
    }

    setInputErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    try {
      const response = await API.login(email.toLowerCase(), password);
      clientDataHolder.onLoginSuccessful(response.data.googleAnalyticsId, response.data.isNew);
      analyticsEvents.sendIdentification(email.toLowerCase(), response.data.googleAnalyticsId);
    } catch (response) {
      const errorMessage = response.getErrorMessage({
        wrong_username_or_password: 'Invalid email address or password.',
        user_not_active:
          'Your user login has been deactivated. Please contact our customer support.',
        rate_limit_exceeded: 'Too many login attempts, try again in one hour.',
        [AUTH_AXES_LOCKOUT]: getAxesErrorMessage(response),
        [AUTH_COVID_LOGIN]:
          'Our Healthcare Heroes offer has ended. If you have any questions, please reach out to hello@willing.com',
      });
      setValidationError(errorMessage);
    }
  };

  const handleOauth = async (token, provider) => {
    try {
      const response = await API.postOauthLogin(token.email, token.accessToken, provider);
      clientDataHolder.onLoginSuccessful(response.data.googleAnalyticsId, response.data.isNew);
      analyticsEvents.sendIdentification(
        token.email.toLowerCase(),
        response.data.googleAnalyticsId,
      );
    } catch (response) {
      const errorMessage = response.getErrorMessage({
        wrong_username_or_password: 'Invalid email address or password.',
        user_not_active:
          'Your user login has been deactivated. Please contact our customer support.',
        already_exists:
          'A user with this email already exists, please login with your email and password.',
        missing_email_or_token: 'Could not authenticate with the external provider.',
        invalid_access_token:
          'Invalid access token, could not authenticate with the external provider.',
        rate_limit_exceeded: 'Too many login attempts, try again in one hour.',
        registrations_disabled: 'That account does not exist.',
      });
      setValidationError(errorMessage);
    }
  };

  if (clientDataHolder.isAuthenticated) {
    // send anyone who has not completed about-you to about-you
    if (!clientData.isSectionValid(SectionAboutYou)) {
      return <Redirect to={SectionAboutYou.url} />;
    }

    // send everyone else to one of the dashboards
    const { referrer } = location.state || {
      referrer: clientDataHolder.dashboardUrl,
    };
    return <Redirect to={referrer} />;
  }

  return (
    <AuthLayout>
      <Typography variant="h4">Login</Typography>
      <Form error={validationError} onSubmit={handleSubmit} noMargin>
        <Textbox
          name="email"
          type="email"
          label="Email"
          autoComplete="off"
          value={email}
          onChange={onChangeEmail}
          error={inputErrors.email}
        />
        <Textbox
          name="password"
          type="password"
          label="Password"
          autoComplete="off"
          value={password}
          onChange={onChangePassword}
          error={inputErrors.password}
        />
        <Spacer v={8} />
        <Button variant="contained" color="primary" type="submit" noMinWidth size="large">
          Login
        </Button>
      </Form>
      <Spacer v={16} />
      <Typography>
        Forgot your password?&nbsp;
        <Link to={Urls.resetPassword} component={RouterLink}>
          Reset Password
        </Link>
      </Typography>
      <Spacer v={24} />
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        spacing={2}
      >
        <Grid item xs={3}>
          <TabDivider noMargin />
        </Grid>
        <Grid item xs={1}>
          <Typography variant="intro">or</Typography>
        </Grid>
        <Grid item xs={3}>
          <TabDivider noMargin />
        </Grid>
      </Grid>
      <Spacer v={16} />
      <OauthLogin handleOauth={handleOauth} />
    </AuthLayout>
  );
};

Login.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default observer(withLegalPlansRedirect(Login));
