import React from 'react';
import PropTypes from 'prop-types';
import { API } from 'API';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

import './NotarizationSessionList.scss';
import { joinClass } from 'utils';
import PureButton from 'ui/components/pure-controls/PureButton';

export class NotarizationSessionList extends ClientDataReferer {
  static propTypes = {
    sessions: PropTypes.array,
    role: PropTypes.string,
  };

  static defaultProps = {
    sessions: [],
    role: 'notary',
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      selectedIndex: null,
    };
  }

  componentDidUpdate() {
    if (!this.eligibleSessions.length && this.state.selectedIndex !== null) {
      this.setState({ selectedIndex: null });
    }

    if (this.eligibleSessions.length && this.state.selectedIndex === null) {
      this.setState({ selectedIndex: 0 });
    }
  }

  joinAsNotary = async sessionId => {
    await API.postNotarizationJoinNotary(sessionId);
    this.clientDataHolder.props.history.push('/notary/call/' + sessionId);
  };

  joinAsWitness = async sessionId => {
    const result = await API.postNotarizationJoinWitness(sessionId);
    this.clientDataHolder.props.history.push(`/witness/call/${sessionId}/${result.data.role}`);
  };

  joinSession = sessionId =>
    this.props.role === 'notary' ? this.joinAsNotary(sessionId) : this.joinAsWitness(sessionId);

  selectItem = selectedIndex => () => this.setState({ selectedIndex });

  formatNames(session) {
    if (session.includesSpouse) {
      return `${session.clientName} & ${session.spouseName}`;
    }

    return session.clientName;
  }

  renderRight = () => {
    const sessions = this.eligibleSessions;
    const { selectedIndex } = this.state;

    if (!sessions.length) {
      return (
        <React.Fragment>
          <div />
          <div>
            <div className="item hourglass" />
            <h4 className="item">No call requests.</h4>
            <p className="item">There are no call requests at this time.</p>
          </div>
          <div />
        </React.Fragment>
      );
    }

    if (selectedIndex === null) {
      return (
        <React.Fragment>
          <div />
          <div>
            <div className="item hourglass" />
            <h4 className="item">Please select a call</h4>
            <p className="item">Unless otherwise noted, select the first call on the left</p>
          </div>
          <div />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div />
        <div>
          <div className="item camera" />
          <h4 className="item">Start a client call</h4>
          <p className="item">Click the button below to join this call.</p>
        </div>
        <PureButton
          onClick={() => this.joinSession(sessions[selectedIndex].id)}
          className="button--primary"
        >
          Join call
        </PureButton>
      </React.Fragment>
    );
  };

  getRoleSelection = (notary, witness) => {
    if (this.props.role === 'notary') {
      return notary;
    }

    return witness;
  };

  get eligibleSessions() {
    if (this.props.role === 'notary') {
      return this.props.sessions;
    }

    return this.props.sessions.filter(s => s.notaryId);
  }

  render() {
    const sessions = this.eligibleSessions;
    const { selectedIndex } = this.state;

    return (
      <div className="session-list">
        <div className="left">
          <div className="left__header">
            <strong>{this.getRoleSelection('Customer Queue', 'Call Request Queue')}</strong> |{' '}
            {sessions.length} {this.getRoleSelection('people', 'requests')}
          </div>

          <div className="left__list">
            {sessions.map((session, i) => (
              <div
                key={i}
                className={joinClass('list__item', i === selectedIndex ? 'selected' : '')}
                onClick={this.selectItem(i)}
              >
                <div className="labels">
                  <p className="name">{this.formatNames(session)}</p>
                  <p className="details">Status: {session.status}</p>
                </div>

                <div className="open-icon" />
              </div>
            ))}
          </div>
        </div>

        <div className="right">{this.renderRight()}</div>
      </div>
    );
  }
}
