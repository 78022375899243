import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import CustomIcon from '@wui/basics/customIcon';

import { ReactComponent as VisaLogo } from '@a/images/icon-visa.svg';
import { ReactComponent as MasterCardLogo } from '@a/images/icon-master-card.svg';
import { ReactComponent as AmexLogo } from '@a/images/icon-amex.svg';
import { ReactComponent as DiscoverLogo } from '@a/images/icon-discover.svg';

// Keys: Brands as we get them from stripe
// Values: Icons for them
const brands = {
  visa: VisaLogo,
  mastercard: MasterCardLogo,
  amex: AmexLogo,
  discover: DiscoverLogo,
};

class CardBrandDisplay extends React.Component {
  static propTypes = {
    brand: PropTypes.string,
  };

  static defaultProps = {
    brand: '',
  };

  renderCard = ([brand, logo]) => {
    const { brand: selectedBrand } = this.props;
    const brandMatches = brand === selectedBrand;

    return (
      <Grid item key={brand}>
        <CustomIcon src={logo} width={50} height={30} opacity={brandMatches ? 1 : 0.5} />
      </Grid>
    );
  };

  render() {
    return (
      <Grid container spacing={1}>
        {Object.entries(brands).map(this.renderCard)}
      </Grid>
    );
  }
}

export default CardBrandDisplay;
