import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import Header from '@c/layout/Header';
import FillAndCenter from '@c/layout/fillAndCenter';

const Loading = ({ children }) => (
  <React.Fragment>
    <Header />

    <FillAndCenter>
      <Typography
        variant="h4"
      >
        {children}
      </Typography>
    </FillAndCenter>
  </React.Fragment>
);

Loading.propTypes = {
  children: PropTypes.node,
};

Loading.defaultProps = {
  children: 'Loading...',
};

export default Loading;
