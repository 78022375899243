import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@wui/layout/tooltip';

import WuiTheme from '@willing-shared/WuiTheme';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';

function renderWuiSaveButton(props, { nextPending }) {
  const { tooltip, ...buttonProps } = props;
  const [Wrapper, wrapperProps] = tooltip ? [Tooltip, { title: tooltip }] : [React.Fragment, {}];

  return (
    <WuiTheme>
      <Grid item align="left">
        <Spacer v={16} />

        <Wrapper {...wrapperProps}>
          <div style={{ display: 'inline-block' }}>
            <Button size={buttonProps.size || 'large'} {...buttonProps} processing={nextPending} />
          </div>
        </Wrapper>

        {!buttonProps.noBottomSpacer && <Spacer v={100} />}
      </Grid>
    </WuiTheme>
  );
}

renderWuiSaveButton.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.node,
};

renderWuiSaveButton.defaultProps = {
  tooltip: null,
  children: 'Next',
};

export default renderWuiSaveButton;
