import React from 'react';
import { PropTypes } from 'prop-types';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';
import Grid from '@wui/layout/grid';

class TabLabel extends React.PureComponent {
  static propTypes = {
    mainText: PropTypes.string.isRequired,
    sideText: PropTypes.string,
  };

  static defaultProps = {
    sideText: '',
  };

  render() {
    const { mainText, sideText } = this.props;

    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        alignContent="center"
      >
        <Typography color="inherit" variant="h6">
          {mainText}
        </Typography>
        <Spacer h={12} />
        <Typography color="inherit" variant="body1">
          {sideText}
        </Typography>
      </Grid>
    );
  }
}

export default TabLabel;
