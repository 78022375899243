import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { API } from 'API';

import { validatePasswordStrength } from '@willing-shared/utils/validation';
import withLegalPlansRedirect from '@willing-shared/hocs/withLegalPlansRedirect';
import AuthLayout from 'material-ui/components/authentication/AuthLayout';

import Form from '@wui/layout/form';
import Button from '@wui/input/button';
import Textbox from '@wui/input/textbox';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';

import { useGlobalContext } from 'hooks';

const ResetPasswordComplete = ({ match }) => {
  const { clientDataHolder } = useGlobalContext();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
  const [validationError, setValidationError] = useState(null);
  const [inputErrors, setInputErrors] = useState({});

  const validateForm = () => {
    const errors = {};

    validatePasswordStrength(newPassword, errors, 'new');

    if (newPassword !== newPasswordConfirm) {
      errors.newPasswordConfirm = 'The two passwords you entered do not match.';
    }

    setInputErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      const { userId, token } = match.params;
      const response = await API.resetPasswordComplete(userId, token, newPassword);
      clientDataHolder.onLoginSuccessful(response.data.googleAnalyticsId);
    } catch (response) {
      const genericResponse =
        'Your reset password link is invalid or expired. You can try requesting a new one.';
      const errorMessage = response.getErrorMessage({
        invalid_token: genericResponse,
      });
      setValidationError(errorMessage);
    }
  };

  const onChange = setter => e => setter(e.target.value);

  if (clientDataHolder.isAuthenticated) {
    return <Redirect to={clientDataHolder.dashboardUrl} />;
  }

  return (
    <AuthLayout>
      <Typography variant="h4">Reset Password</Typography>
      <Form onSubmit={handleSubmit} error={validationError}>
        <Textbox
          name="newPassword"
          type="password"
          label="New Password"
          autoComplete="off"
          value={newPassword}
          onChange={onChange(setNewPassword)}
          error={inputErrors.password}
        />
        <Textbox
          name="newPasswordConfirm"
          type="password"
          label="Repeat New Password"
          autoComplete="off"
          value={newPasswordConfirm}
          onChange={onChange(setNewPasswordConfirm)}
          error={inputErrors.newPasswordConfirm}
        />
        <Spacer v={8} />
        <Button variant="contained" color="primary" type="submit">
          Reset
        </Button>
      </Form>
    </AuthLayout>
  );
};

ResetPasswordComplete.propTypes = {
  match: PropTypes.shape({}).isRequired,
};

export default observer(withLegalPlansRedirect(ResetPasswordComplete));
