import React from 'react';
import PropTypes from 'prop-types';

export default class HeaderHeightProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  static childContextTypes = {
    headerHeight: PropTypes.shape({}),
  };

  state = {
    height: null,
  };

  getChildContext() {
    const { height } = this.state;

    return {
      headerHeight: {
        value: height,
        set: this.setHeaderHeight,
      },
    };
  }

  setHeaderHeight = height => this.setState({ height });

  render() {
    const { children } = this.props;

    return children;
  }
}
