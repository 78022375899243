import React from 'react';
import PropTypes from 'prop-types';

export class NavDropDown extends React.Component {
  static propTypes = {
    clickOutside: PropTypes.func.isRequired,
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = e => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target) && e.target.localName !== 'a') {
      this.props.clickOutside();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {
    return (
      <div className="menu" ref={this.setWrapperRef} onClick={this.props.clickOutside}>
        {this.props.children}
      </div>
    );
  }
}
