import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import CheckoutV6 from 'material-ui/pages/checkout-v6/CheckoutV6';
import SubscriptionPlanPricing from 'ui/pricing/SubscriptionPlanPricing';
import { Urls } from 'urls';
import { useGlobalContext } from 'hooks';

import List from './List';

import './MobileNav.scss';

const MobileNav = ({ handleLogoClick }) => {
  const { clientDataHolder, serverData } = useGlobalContext();
  const [dropOpen, setDropOpen] = useState(false);
  const location = useLocation();

  useEffect(() => setDropOpen(false), [location]);

  const handleDropClick = () => setDropOpen(v => !v);

  const isOnCheckout =
    location.pathname === CheckoutV6.url || location.pathname === SubscriptionPlanPricing.url;

  const isOnRegister =
    location.pathname.startsWith(Urls.registerWithInfo) || location.pathname === Urls.register;

  const dropClass = classNames('dropdown', { 'dropdown--hidden': !dropOpen });
  const className = classNames('mobile-navbar', { 'mobile-navbar--white': isOnCheckout });
  const brandClass = classNames('mobile-navbar__brand', {
    'mobile-navbar__brand--mlp': serverData.fromPortunus,
  });

  return (
    <div className="mobilenav-container">
      <nav className={className}>
        <div className={isOnCheckout ? 'mobile-navbar__left' : 'mobile-navbar__center'}>
          {clientDataHolder.isAuthenticated ? (
            <Link to="/" onClick={handleLogoClick}>
              <span className={brandClass} />
            </Link>
          ) : (
            <a href="https://www.willing.com/">
              <span className={brandClass} />
            </a>
          )}
        </div>

        {!isOnRegister && (
          <div className="mobile-navbar__right" onClick={handleDropClick}>
            <span className="mobile-navbar__hamburger-icon" />
          </div>
        )}
      </nav>

      <div className={dropClass}>
        <div className="dropdown__close-button" onClick={handleDropClick} />
        <List />
      </div>
    </div>
  );
};

MobileNav.propTypes = {
  handleLogoClick: PropTypes.func.isRequired,
};

export default observer(MobileNav);
