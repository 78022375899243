import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Urls } from 'urls';
import SubscriptionPlanPricing from 'ui/pricing/SubscriptionPlanPricing';
import { useGlobalContext, useDocumentTitle } from 'hooks';

const SubscriptionPicker = ({ title }) => {
  useDocumentTitle(title);
  const { experiments, analyticsEvents, serverData } = useGlobalContext();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const events = [{ event: 'willing.page.loaded.subscription-picker' }];
    analyticsEvents.dataLayer.push(...events);
    setTimeout(() => setLoaded(true), experiments.delay);

    // We only want this on first mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loaded) {
    return null;
  }

  if (!serverData.subscriptionPlan) {
    return <Redirect to={Urls.planDashboard} />;
  }

  return <Redirect to={SubscriptionPlanPricing.url} />;
};

SubscriptionPicker.url = Urls.subscriptionPicker;
SubscriptionPicker.propTypes = {
  title: PropTypes.string,
};
export default observer(SubscriptionPicker);
