import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@wui/basics/typography';
import Grid from '@wui/layout/grid';

const Item = ({ label, name }) => (
  <Grid item xs={5} md={7}>
    <Grid container direction="row">
      <Grid item xs={12} md={8} container justify="center">
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={12} md={4} container justify="center">
        <Typography>{name}</Typography>
      </Grid>
    </Grid>
  </Grid>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.node.isRequired,
};

export default Item;
