import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Section from './Section';
import { Lineage } from '!m/client-data/enums';
import { capitalize } from '@willing-shared/utils/text';
import { getStateFullName } from 'utils';

const styles = {
  column: {
    '& > :last-child': {
      marginBottom: 0,
    },
  },
  row: {
    display: 'flex',
  },
  label: {
    marginRight: 8,
    display: 'inline-block',
  },
  realEstate: {
    marginBottom: 16,
  },
};

class AboutYouSummary extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static contextTypes = {
    clientDataHolder: PropTypes.object,
  };

  get cd() {
    return this.context.clientDataHolder.clientData;
  }

  label(text, value) {
    // If the value is an empty array, don't
    //   show the label.
    if (value && Array.isArray(value) && !value.length) {
      return null;

      // If a value is supplied, and it is empty,
      //   then don't show the label.
    } else if (typeof value !== 'undefined' && !value) {
      return null;
    }

    const { classes } = this.props;

    return (
      <div key={text} className={classes.row}>
        <Typography variant="body1" color="textSecondary" className={classes.label}>
          <b>{text}</b>
        </Typography>
        <div className={classes.column}>{value}</div>
      </div>
    );
  }

  child(child) {
    const properties = [];
    const displayName = child.name;

    if (child.isMinor) {
      properties.push('Minor');
    }

    if (child.disinherited) {
      properties.push('Exclude');
    }

    if (properties.length) {
      return `${displayName} (${properties.join(', ')})`;
    }

    return `${displayName}`;
  }

  children(lineage) {
    const children = this.cd.children.filter(c => c.lineage === lineage).map(this.child);

    // Insert a newline between each child,
    //   but not after the last one.
    return children.reduce((a, c, i) => {
      a.push(c);

      if (i !== children.length - 1) {
        a.push(<br key={i} />);
      }

      return a;
    }, []);
  }

  renderChildren() {
    if (!this.cd.children.length) {
      return null;
    } else if (this.cd.allChildrenHaveBothLineage) {
      return this.label('Children:', this.children(Lineage.BOTH));
    }

    return (
      <React.Fragment>
        {this.label('Shared Children:', this.children(Lineage.BOTH))}
        {this.label(`${this.cd.firstNames} Children:`, this.children(Lineage.CLIENT))}
        {this.label(`${this.cd.spouse.firstNames} Children:`, this.children(Lineage.SPOUSE))}
      </React.Fragment>
    );
  }

  address(realEstate) {
    const { classes } = this.props;
    let firstLine = realEstate.address.street;

    if (realEstate.isPrimaryResidence) {
      firstLine += ' (Primary Home)';
    }

    return (
      <div key={realEstate.assetUUID} className={classes.realEstate}>
        {firstLine}
        <br />
        {getStateFullName(realEstate.address.state)}
      </div>
    );
  }

  realEstateGroup(label, realEstates) {
    return this.label(
      label,
      realEstates.map(a => this.address(a)),
    );
  }

  renderRealEstate() {
    if (this.cd.jointlyOwnsAllRealEstate) {
      return this.cd.realEstates.map((a, i) => this.label(`Property ${i + 1}:`, this.address(a)));
    }

    return (
      <React.Fragment>
        {this.realEstateGroup('Jointly Owned:', this.cd.sharedRealEstates)}
        {this.realEstateGroup(`Owned by ${this.cd.firstName}:`, this.cd.ownRealEstates)}
        {this.realEstateGroup(
          `Owned by ${this.cd.spouse.firstName}:`,
          this.cd.spouse.ownRealEstates,
        )}
      </React.Fragment>
    );
  }

  renderSections() {
    const basic = (
      <Section text="Basic Info">
        {this.label('Name:', this.cd.name)}
        {this.label('Marital Status:', capitalize(this.cd.maritalStatus))}
        {this.label('State:', getStateFullName(this.cd.address.state))}
      </Section>
    );

    const family = (
      <Section text="Family">
        {this.label('Spouse:', this.cd.spouse.name)}
        {this.renderChildren()}
      </Section>
    );

    const realEstate = <Section text="Real Estate">{this.renderRealEstate()}</Section>;

    if (![basic, family, realEstate].some(Section.willRender)) {
      return 'In progress. Your summary will become available as soon as you start filling in this page.';
    }

    return (
      <React.Fragment>
        {basic}
        {family}
        {realEstate}
      </React.Fragment>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Typography variant="body1" component="div" className={classes.column}>
        {this.renderSections()}
      </Typography>
    );
  }
}

export default withStyles(styles)(AboutYouSummary);
