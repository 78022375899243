import { ClientDataObject } from 'models/type-system';
import { WFutureDate, WObject } from 'models/types';
import { WNonEmptyString } from 'models/hoc-types';
import { Person } from 'models/client-data/mixins';

export class NotarizationStaff extends ClientDataObject {
  static fields = {
    commissionNumber: new WNonEmptyString(),
    commissionExpirationDate: new WObject(WFutureDate),
    person: new WObject(Person),
  };
}
