import Urls from 'urls';
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import { useGlobalContext } from 'hooks';
import { getLoginUrl } from '@willing-shared/utils/tokens';
import IdleSessionHandler from '@willing-shared/components/IdleSessionHandler';

const ProtectedRoute = props => {
  const { allowed, redirectTo, ...rest } = props;
  const { clientDataHolder } = useGlobalContext();

  // In the case where the value of allowed is pending a response from the
  //   server, passing a null value will delay rendering either content or
  //   a redirect.
  if (allowed === null) {
    rest.render = () => null;
  } else if (!allowed) {
    window.location = getLoginUrl();
    return null;
  } else {
    const oldRender = rest.render;
    rest.render = routeProps => (
      <>
        <IdleSessionHandler expirationHandler={clientDataHolder.logout} />
        {oldRender(routeProps)}
      </>
    );
  }

  return <Route {...rest} />;
};

ProtectedRoute.propTypes = {
  allowed: PropTypes.bool,
  redirectTo: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
};

ProtectedRoute.defaultProps = {
  allowed: null,
  redirectTo: Urls.planDashboard,
};

export default ProtectedRoute;
