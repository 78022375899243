import { DocumentListPage } from '@/pages/documents/DocumentListPage';
import WizardSection from 'ui/components/wizard/WizardSection';
import { Urls } from 'urls';
import SectionNotarize from 'ui/sections/notarize/SectionNotarize';

export default class SectionSign extends WizardSection {
  static finalUrl = Urls.file;
  static url = Urls.signSection;
  static dashboardEditText = 'View';
  static title = 'Review and sign your documents';
  static description = 'Print, sign, and file your documents to make them legally binding.';

  static isComplete(clientData) {
    return clientData.isSectionValid(SectionNotarize);
  }

  static getPageFlow(clientData, serverData, experimentContext, visit) {
    visit(DocumentListPage);
  }
}
