import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@wui/input/fab';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  buttonContainer: {
    marginTop: 32,
    marginBottom: 16,
    cursor: 'pointer',
    alignItems: 'center',
    display: 'inline-flex',
  },
  disabled: theme.layout.disabledNode,
});

class AddButton extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    dataPath: PropTypes.string.isRequired,
  };

  render() {
    const { classes, disabled, onClick, text, dataPath } = this.props;

    const className = classNames(classes.buttonContainer, {
      [classes.disabled]: disabled,
    });

    return (
      <span onClick={onClick} className={className}>
        <Fab
          variant="outlined"
          color="primary"
          icon={AddIcon}
          disabled={disabled}
          className={classes.button}
          data-path={dataPath}
          label={text}
        />
      </span>
    );
  }
}

export default withStyles(styles)(AddButton);
