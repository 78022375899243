import { Address, PersonMixin } from 'models/client-data/mixins';
import { ClientDataObject } from 'models/type-system';
import {
  AgentOwner,
  AgentType,
  NotifiedPersonPermissions,
  NotifiedPersonStatus,
  NotifiedPersonNotificationStatus,
} from 'models/client-data/enums';
import { setAgent, getAgent } from 'utils';
import { WFullName, WEmail, WPhoneNumber } from 'models/hoc-types';
import { WBoolean, WInteger, WObject } from 'models/types';

export const AgentsAccessorsMixin = superclass =>
  class extends superclass {
    get primaryExecutor() {
      return getAgent(this.executors, AgentType.PRIMARY, this.ownerString);
    }

    set primaryExecutor(executor) {
      setAgent(this.executors, AgentType.PRIMARY, this.ownerString, executor);
    }

    get alternateExecutor() {
      return getAgent(this.executors, AgentType.ALTERNATE, this.ownerString);
    }

    set alternateExecutor(executor) {
      setAgent(this.executors, AgentType.ALTERNATE, this.ownerString, executor);
    }

    get primaryHealthcareAgent() {
      return getAgent(this.healthcareAgents, AgentType.PRIMARY, this.ownerString);
    }

    set primaryHealthcareAgent(agent) {
      setAgent(this.healthcareAgents, AgentType.PRIMARY, this.ownerString, agent);
    }

    get alternateHealthcareAgent() {
      return getAgent(this.healthcareAgents, AgentType.ALTERNATE, this.ownerString);
    }

    set alternateHealthcareAgent(agent) {
      setAgent(this.healthcareAgents, AgentType.ALTERNATE, this.ownerString, agent);
    }

    get primaryGuardian() {
      return getAgent(this.guardians, AgentType.PRIMARY, this.ownerString);
    }

    set primaryGuardian(agent) {
      setAgent(this.guardians, AgentType.PRIMARY, this.ownerString, agent);
    }

    get alternateGuardian() {
      return getAgent(this.guardians, AgentType.ALTERNATE, this.ownerString);
    }

    set alternateGuardian(agent) {
      setAgent(this.guardians, AgentType.ALTERNATE, this.ownerString, agent);
    }
  };

class Agent extends PersonMixin(ClientDataObject) {
  // In arrays of agents, the same person might be selected
  //   multiple times for the same role by both the client
  //   and the spouse, so extra information is needed when
  //   determining the key.
  static keyFieldName(p) {
    return `${p.owner}-${p.personUUID}`;
  }

  static fields = {
    type: new AgentType(),
    owner: new AgentOwner(),
  };

  static get nameGuidance() {
    return null;
  }
}

export class Executor extends Agent {}

export class HealthcareAgent extends Agent {
  static fields = {
    address: new WObject(Address),
    phone: new WPhoneNumber(),
  };
}

export class Guardian extends Agent {
  static get nameGuidance() {
    return 'Please name a person (other than a current parent/guardian)';
  }
}

export class NotifiedPerson extends Agent {
  static fields = {
    nameOverride: new WFullName(),
    permissions: new NotifiedPersonPermissions(),
    status: new NotifiedPersonStatus(),
    email: new WEmail(),
    lastNotifiedAt: new WInteger(),
    forceNotificationSend: new WBoolean(),
  };

  get notificationStatus() {
    return NotifiedPersonNotificationStatus.get(this);
  }
}
