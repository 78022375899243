import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import FailurePage from '../Failure';

const Failed = ({ retry }) => (
  <FailurePage
    message="No suitable audio output"
    details="We could not identify a suitable audio output. Please check your speakers and try again."
    buttonText="Retry Speaker Test"
    retry={retry}
  />
);

Failed.propTypes = {
  retry: PropTypes.func.isRequired,
};

export default observer(Failed);
