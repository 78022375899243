import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { isWidthDown } from '@material-ui/core/withWidth';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import WuiApp from '@/WuiApp';
import Link from '@wui/basics/link';
import Tabs from '@wui/layout/tabs';
import Button from '@wui/input/button';
import Spacer from '@wui/layout/spacer';
import Textbox from '@wui/input/textbox';
import Spinner from '@wui/basics/spinner';
import RadioGroup from '@wui/input/radioGroup';
import CustomIcon from '@wui/basics/customIcon';
import TabDivider from '@wui/layout/tabDivider';
import Typography from '@wui/basics/typography';
import GenericError from '@wui/basics/genericError';
import MaxPageWidth from '@wui/layout/maxPageWidth';
import TitleWithIcon from '@wui/layout/titleWithIcon';
import { handleChange } from '@willing-shared/utils/input';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { DocumentBundle, SubscriptionPlan } from 'utils/enums';
import SubscriptionLoader from 'ui/pricing/SubscriptionLoader';
import SectionNotify from 'ui/sections/file/notify/SectionNotify';
import withIsMobileDisplay from '@willing-shared/hocs/withIsMobileDisplay';

import WithWilling from '@a/images/with-willing.png';
import IllHandleIt from '@a/images/ill-handle-it.png';
import ConfettiHorn from '@a/images/confetti-horn.svg';
import CircleUnchecked from '@a/images/circle-unchecked.svg';
import WithWillingMobile from '@a/images/with-willing-mobile.png';
import IllHandleItMobile from '@a/images/ill-handle-it-mobile.png';

import { getCompanyName } from 'utils';
import Footer from './Footer';

const willingItems = {
  'Easy filing and storage':
    'We’ll make sure your documents are signed correctly and, when necessary, record them with your local county clerk.',
  'Keep your plan safe and secure':
    'Securely store your documents in our vault so you and trusted loved ones can access them when needed.',
  'Free unlimited changes':
    'Make changes to your will and other estate planning documents any time so everything stays up to date.',
  'Help your loved ones carry out your wishes':
    'Give your loved ones access to our team of customer service experts who will help them transfer your non-probate assets and carry out your wishes.',
};

class SubscriptionOptions extends React.Component {
  static contextTypes = {
    clientDataHolder: PropTypes.shape({}),
    experiments: PropTypes.object,
  };

  constructor(...args) {
    super(...args);

    this.state = {
      selected: 0,
      cancelReason: '',
      confirmCancel: null,
      showCancelFlow: false,
    };

    this.handleChange = handleChange.bind(this);
  }

  get headerImageSource() {
    const { selected } = this.state;

    if (this.isPhoneDisplay) {
      return selected === 0 ? WithWillingMobile : IllHandleItMobile;
    }

    return selected === 0 ? WithWilling : IllHandleIt;
  }

  scrollAfterTimeout(yScroll) {
    setTimeout(() => {
      window.scrollTo(0, yScroll);
    }, 100);
  }

  selectTab = value => () => {
    this.scrollAfterTimeout(0);
    this.setState({ selected: value });
  };

  resetCancelFlow = () => {
    this.setState({
      cancelReason: '',
      confirmCancel: null,
      showCancelFlow: false,
    });
  };

  changedCancelConfirmation = value => {
    if (value) {
      this.setState({ confirmCancel: true });
    } else {
      this.selectTab(0)();
      this.resetCancelFlow();
    }
  };

  showCancelFlow = event => {
    event.preventDefault();
    this.setState({ showCancelFlow: true });
  };

  renderError(hasError) {
    if (!hasError) {
      return null;
    }

    return (
      <React.Fragment>
        <Spacer v={10} />
        <GenericError />
      </React.Fragment>
    );
  }

  renderListItem(title, text, check = true) {
    const icon = check ? (
      <CustomIcon src={CheckCircleIcon} color={theme => theme.palette.green.success} />
    ) : (
      <img alt="" src={CircleUnchecked} />
    );

    return (
      <TitleWithIcon icon={icon} key={title} forceIndent variant="h5" title={title}>
        <Typography variant="intro">{text}</Typography>
      </TitleWithIcon>
    );
  }

  renderSubscriptionSuccess() {
    return (
      <Grid container spacing={2} direction="row" justify="space-between">
        <Grid item>
          <img alt="" src={ConfettiHorn} />
        </Grid>
        <Grid item xs>
          <Typography variant="h4">Great Choice!</Typography>

          <Typography variant="intro">
            You're in good hands. Our support team will reach out to help you wrap things up. In the
            meantime, feel free to continue to these next steps.
          </Typography>

          <Spacer v={24} />

          <Grid container spacing={2} alignItems="center">
            <Grid item sm="auto" xs={12}>
              <Button
                id="subscription-view-documents"
                fullWidth
                color="primary"
                variant="contained"
                to={this.context.clientDataHolder.dashboardUrl}
                component={RouterLink}
              >
                Next Steps
              </Button>
            </Grid>
            <Grid item sm="auto" xs={12}>
              <Button fullWidth variant="outlined" component={RouterLink} to={SectionNotify.url}>
                Notify Agents
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderTopSection(content) {
    return (
      <React.Fragment>
        <Grid container alignItems="center" wrap="wrap-reverse">
          <Grid sm item xs={12}>
            {content}
          </Grid>

          <Grid item xs={12} sm="auto">
            <DimensionLimiter h="100%" v={285}>
              <img alt="" src={this.headerImageSource} />
            </DimensionLimiter>
          </Grid>
        </Grid>

        <Spacer v={30} />
      </React.Fragment>
    );
  }

  renderSubscriptionInfo = ({
    error,
    loaded,
    success,
    processing,
    actionProps,
    displayName,
    displayPrice,
    ownsThisSubscriptionPlan,
  }) => {
    if (!loaded) {
      return <Spinner />;
    } else if (success) {
      return (
        <React.Fragment>
          {this.renderSubscriptionSuccess()}
          <TabDivider dashed expanded halfMargin />
        </React.Fragment>
      );
    }

    const header = ownsThisSubscriptionPlan
      ? 'You are already subscribed to this plan'
      : displayName;

    const descriptionVariant = this.isPhoneDisplay ? 'intro' : 'superhero';

    return this.renderTopSection(
      <React.Fragment>
        <Typography variant="h3">{header}</Typography>

        <Spacer v={10} />

        <DimensionLimiter h={500}>
          <Typography variant={descriptionVariant}>
            We'll make sure everything is done right and stays right for the people you love.
          </Typography>
        </DimensionLimiter>

        <Spacer v={24} />

        <Typography bold variant="body1">
          ${displayPrice}/Year
        </Typography>

        {!ownsThisSubscriptionPlan && <Typography>Charged today, cancel anytime</Typography>}

        <Spacer v={24} />

        <Grid container spacing={2} alignItems="center">
          <Grid item sm="auto" xs={12}>
            <Button
              id="subscription-continue-button"
              color="primary"
              variant="contained"
              component={RouterLink}
              processing={processing}
              fullWidth={width => isWidthDown('xs', width)}
              {...actionProps}
            >
              {ownsThisSubscriptionPlan ? 'Continue' : 'Sign Up'}
            </Button>
          </Grid>
          {ownsThisSubscriptionPlan && (
            <Grid item sm="auto" xs={12}>
              <Button fullWidth variant="outlined" onClick={this.selectTab(1)}>
                Cancel Subscription
              </Button>
            </Grid>
          )}
        </Grid>

        {this.renderError(error)}
      </React.Fragment>,
    );
  };

  renderWillingTabContent() {
    let subscriptionPlan = SubscriptionPlan.PEACE_NOTRIAL;
    const documentBundle = this.context.clientDataHolder.serverData.documentBundle;
    if (documentBundle === DocumentBundle.SINGLE) {
      subscriptionPlan = SubscriptionPlan.FOR_SINGLES;
    } else if (documentBundle === DocumentBundle.COUPLES) {
      subscriptionPlan = SubscriptionPlan.FOR_COUPLES;
    }

    return (
      <React.Fragment>
        <SubscriptionLoader subscriptionPlan={subscriptionPlan}>
          {this.renderSubscriptionInfo}
        </SubscriptionLoader>

        <Typography variant="intro">BENEFITS</Typography>

        <TabDivider noMargin />

        <Spacer v={24} />

        {Object.entries(willingItems).map(([title, content]) =>
          this.renderListItem(title, content),
        )}
      </React.Fragment>
    );
  }

  renderUnsubscribeSuccess() {
    return (
      <React.Fragment>
        <Typography variant="intro">You have successfully unsubscribed.</Typography>
      </React.Fragment>
    );
  }

  renderCancelFlow(actionProps, processing) {
    const { confirmCancel, cancelReason } = this.state;

    return (
      <React.Fragment>
        <RadioGroup
          disabled={processing}
          value={confirmCancel}
          label="Are you sure you want to cancel?"
          onChange={this.changedCancelConfirmation}
        />

        {confirmCancel && (
          <React.Fragment>
            <Typography variant="body1">What could we have done better?</Typography>

            <Textbox
              rows="4"
              autoFocus
              multiline
              label="Reason"
              name="cancelReason"
              value={cancelReason}
              disabled={processing}
              onChange={this.handleChange}
            />

            <Button
              color="primary"
              {...actionProps}
              variant="contained"
              component={RouterLink}
              processing={processing}
              disabled={!cancelReason}
              fullWidth={width => isWidthDown('xs', width)}
            >
              Submit
            </Button>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderUnsubscribeButtons(ownsThisSubscriptionPlan, processing) {
    if (!ownsThisSubscriptionPlan) {
      return (
        <Typography variant="medium" align={this.isPhoneDisplay ? 'center' : 'left'}>
          <Link href="#" disabled={processing} onClick={this.showCancelFlow}>
            Cancel Subscription
          </Link>
        </Typography>
      );
    }
    const companyName = getCompanyName(this.context.clientDataHolder.serverData);
    return (
      <Button variant="text" onClick={this.selectTab(0)}>
        <CustomIcon
          src={ArrowBackIcon}
          disabled={processing}
          color={theme => theme.palette.blue.textboxFocus}
        />
        See {companyName} Option
      </Button>
    );
  }

  renderUnsubscriptionInfo = ({
    error,
    loaded,
    success,
    processing,
    actionProps,
    ownsThisSubscriptionPlan,
  }) => {
    if (!loaded) {
      return <Spinner />;
    }

    const { showCancelFlow } = this.state;

    if (success || showCancelFlow || error) {
      return (
        <React.Fragment>
          {success && this.renderUnsubscribeSuccess()}
          {showCancelFlow && this.renderCancelFlow(actionProps, processing)}
          {this.renderError(error)}
          <TabDivider dashed expanded halfMargin />
        </React.Fragment>
      );
    }

    return this.renderTopSection(
      <React.Fragment>
        <DimensionLimiter h={500}>
          <Typography variant="superhero">
            I know what I am doing and will take it from here.
          </Typography>
        </DimensionLimiter>

        <Spacer v={24} />

        <Button
          id="continue-on-my-own"
          color="primary"
          variant="contained"
          to={this.context.clientDataHolder.dashboardUrl}
          disabled={processing}
          component={RouterLink}
          fullWidth={width => isWidthDown('xs', width)}
        >
          Continue
        </Button>

        <Spacer v={24} />

        {this.renderUnsubscribeButtons(ownsThisSubscriptionPlan, processing)}
      </React.Fragment>,
    );
  };

  renderUserTabContent() {
    const { cancelReason } = this.state;
    const changePeriod = DocumentBundle.changePeriod(
      this.context.clientDataHolder.serverData.documentBundle,
    );
    const userItems = {
      'File manually':
        'Follow the instructions on the cover page of each document to file (fees may apply) and store each one as required.',
      'Risk losing your documents':
        'Over the years, documents are often lost or accidentally destroyed, making all your wishes void.',
      'Pay each time you want to to make a change': `Changes are free for ${changePeriod}, but after that you’ll need to pay to create new or updated documents.`,
      'Let loved ones figure it out':
        'Let your loved ones figure it out and work with the right institutions to carry out your wishes.',
    };

    return (
      <React.Fragment>
        <SubscriptionLoader
          subscriptionPlan={''}
          analyticsEventProperties={{ cancelReason }}
          afterSubscriptionCreated={this.resetCancelFlow}
        >
          {this.renderUnsubscriptionInfo}
        </SubscriptionLoader>

        <Typography variant="intro">RESPONSIBILITIES</Typography>

        <TabDivider noMargin />

        <Spacer v={24} />

        {Object.entries(userItems).map(([title, content]) =>
          this.renderListItem(title, content, false),
        )}
      </React.Fragment>
    );
  }

  renderContent() {
    const { selected } = this.state;

    const companyName = getCompanyName(this.context.clientDataHolder.serverData);
    const tabs = [
      {
        name: `With ${companyName}`,
        content: this.renderWillingTabContent(),
        special: true,
      },
      { name: "I'll Handle It", content: this.renderUserTabContent() },
    ];

    return (
      <MaxPageWidth>
        <Spacer v={40} />

        <DimensionLimiter h={755}>
          <Typography variant="h1">
            How would you like your next steps to be managed and carried out?
          </Typography>
        </DimensionLimiter>

        <Spacer v={64} />

        <Tabs tabs={tabs} selected={selected} onSelect={value => this.selectTab(value)()} />

        <Footer />
      </MaxPageWidth>
    );
  }

  render() {
    return <WuiApp>{this.renderContent()}</WuiApp>;
  }
}

export default withIsMobileDisplay(SubscriptionOptions);
