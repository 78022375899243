import { useContext } from 'react';
import { useObserver } from 'mobx-react';
import { GlobalContext, ForSpouseContext } from 'contexts';

const useGlobalContext = () => {
  const global = useContext(GlobalContext);
  const forSpouse = useContext(ForSpouseContext);

  return useObserver(() => {
    const {
      clientData,
      serverData,
      analyticsEvents,
      experiments,
      clientDataHolder,
      history,
    } = global;
    const updateClientData = clientDataHolder.updateClientData.bind(clientDataHolder);

    const triggerValidation = (
      showValidationErrors = true,
      returnEvent = false,
      forceShowValidationErrors = false,
    ) => {
      // forceShowValidationErrors can be used to force the components to either turn on or off their validation
      //   displays regardless of current state. Formerly you could only turn them on and not off, which we
      //   still want to be the default.
      const event = {
        showValidationErrors,
        forceShowValidationErrors,
        isValid: true,
        firstControlWithError: null,
      };
      clientDataHolder.validateAllCallbacks.emit(event);

      if (returnEvent) {
        return event;
      }

      return event.isValid;
    };

    const firstUrlForSection = section => section.firstUrl(clientData, serverData, experiments);

    return {
      clientData,
      serverData,
      analyticsEvents,
      experiments,
      updateClientData,
      clientDataHolder,
      history,
      triggerValidation,
      firstUrlForSection,
      testator: forSpouse ? clientData.spouse : clientData,
    };
  });
};

export default useGlobalContext;
