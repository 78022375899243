import React from 'react';
import AutoValidationDisplay from 'ui/components/wizard/AutoValidationDisplay';
import PropTypes from 'prop-types';

export default class RadioGroup extends React.PureComponent {
  static propTypes = {
    obj: PropTypes.object,
    path: PropTypes.string.isRequired,
  };

  mapChild(child) {
    return React.cloneElement(child, {
      obj: this.props.obj,
      path: this.props.path,
    });
  }

  render() {
    let children;
    if (typeof this.props.children === 'undefined') {
      throw 'Empty RadioGroup';
    } else if (this.props.children instanceof Array) {
      children = React.Children.map(this.props.children, child => this.mapChild(child));
    } else {
      children = this.mapChild(this.props.children);
    }

    return (
      <div>
        {children}
        <div style={{ clear: 'both' }} />
        <AutoValidationDisplay obj={this.props.obj} path={this.props.path} />
      </div>
    );
  }
}
