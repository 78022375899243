import React from 'react';
import PropTypes from 'prop-types';

import Spouse from './Spouse';
import Layout from '@c/layout/Layout';
import Children from './Children';

import { Tip, Tips } from '@c/tips';
import { ProgressSections } from '@c/progress';
import { AppLocation } from 'utils/enums';

import MaritalStatusTip from '../MaritalStatusTip';

export default class Family extends React.Component {
  static contextTypes = {
    page: PropTypes.object,
    testator: PropTypes.object,
    experiments: PropTypes.object,
  };

  renderMarriedTip() {
    if (!this.context.testator.isMarried) {
      return null;
    }

    return (
      <Tip title="Married Couples">
        Spouses generally choose to create their wills at the same time. If you select this option,
        we'll ask each of you about your wishes and create two sets of documents.
      </Tip>
    );
  }

  render() {
    return (
      <Layout
        progressPage={ProgressSections.ABOUT_YOU.FAMILY}
        appLocation={AppLocation.FAMILY}
        showBackButton
      >
        <Tips>
          {this.context.experiments.delayedMaritalStatus && <MaritalStatusTip />}
          {this.renderMarriedTip()}
          <Tip title="Children">
            Your will should name all of your children - even if they're not receiving any property.
            This shows you're aware of who your family is, and is evidence that you are of sound
            mind to make a will.
            <br />
            <br />
            If any children are minors, you can name guardians for them later in the process.
            <br />
            <br />
            If you want to make sure a child doesn't receive anything, select the option to exclude
            that child.
          </Tip>
        </Tips>
        <Spouse />
        <Children />
      </Layout>
    );
  }
}
