import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Urls from 'urls';
import { API } from 'API';
import sentry from '@willing-shared/utils/sentry';
import { capitalize } from '@willing-shared/utils/text';
import { SubscriptionPlan } from 'utils/enums';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

export default class SubscriptionLoader extends ClientDataReferer {
  /*
   *   This component handles the logic of which subscription options should be shown and how to do API calls for
   *   subscribing, then passes this information to its children.
   */

  static propTypes = {
    children: PropTypes.func.isRequired,
    afterSubscriptionCreated: PropTypes.func,
    analyticsEventProperties: PropTypes.shape({}),
    subscriptionPlan: PropTypes.string.isRequired,
  };

  static defaultProps = {
    analyticsEventProperties: {},
    afterSubscriptionCreated: () => {},
  };

  constructor(...args) {
    super(...args);

    this.initialize(state => {
      this.state = state;
    });
  }

  componentDidUpdate(prevProps) {
    const { subscriptionPlan } = this.props;
    const { subscriptionPlan: prevSubscriptionPlan } = prevProps;

    if (subscriptionPlan !== prevSubscriptionPlan) {
      this.initialize(state => this.setState(state));
    }
  }

  initialize(setter) {
    const { subscriptionPlan } = this.props;

    setter({
      error: false,
      loaded: false,
      priceInfo: {},
      success: false,
      processing: false,
    });

    API.getPriceInfo('', '', subscriptionPlan).then(priceInfo =>
      this.setState({ loaded: true, priceInfo }),
    );
  }

  updateSubscription = isPaypal => async event => {
    if (typeof event.preventDefault === 'function') {
      event.preventDefault();
    }

    this.setState({ error: false, processing: true, success: false });

    try {
      if (!isPaypal) {
        await API.updateStripeSubscription(this.props.subscriptionPlan);
      } else {
        await API.updatePaypalSubscription(this.props.subscriptionPlan, event.orderID);
      }

      // This will force an update of the server data, which
      //   will change when the subscription is created.
      await this.clientDataHolder.persistClientData();

      const eventMethod = this.props.subscriptionPlan
        ? 'subscriptionCreated'
        : 'subscriptionCanceled';

      this.clientDataHolder.analyticsEvents[eventMethod](this.props.analyticsEventProperties);
      this.props.afterSubscriptionCreated();
      this.setState({ success: true });
    } catch (ex) {
      sentry.captureException(ex);
      this.setState({ error: true });
    } finally {
      this.setState({ processing: false });
    }
  };

  render() {
    if (!this.state.loaded) {
      return this.props.children({ loaded: false });
    }

    const { subscriptionPlan } = this.props;
    const { error, priceInfo, processing, success } = this.state;

    let mode = 'review';
    let actionProps = { to: this.clientDataHolder.dashboardUrl };

    const subscriptionPlanIsOwned = !subscriptionPlan || this.serverData.subscriptionPlan;

    // TODO: For now, we count having any subscription plan as owning
    //   the plan because we have only shown one to the user but with
    //   different price points. In the future, this should match the
    //   actual plan against what the user currently has. We compare
    //   the boolean values so that the actual plan doesn't matter, but
    //   the no subscription option is also correct.
    const ownsThisSubscriptionPlan =
      Boolean(subscriptionPlan) === Boolean(this.serverData.subscriptionPlan);

    if (this.serverData.stripeCustomerId && !ownsThisSubscriptionPlan) {
      mode = 'stripe';
      actionProps = {
        to: '#',
        onClick: this.updateSubscription(false),
      };
    } else if (this.serverData.hasPaypalPayment && !subscriptionPlan) {
      // for unsubscribing paypal subscribers
      mode = 'paypal';
      actionProps = {
        to: '#',
        onClick: this.updateSubscription(true),
      };
    } else if (!subscriptionPlanIsOwned) {
      mode = 'checkout';
      actionProps = {
        to: {
          pathname: Urls.subscriptionCheckout,
          search: `?subscriptionPlan=${subscriptionPlan}`,
        },
      };
    }

    const displayPrice = priceInfo.subscriptionPlanPrice / 100;
    const displayName = SubscriptionPlan.displayName(subscriptionPlan);

    const paymentDate = new Date();
    paymentDate.setDate(paymentDate.getDate() + priceInfo.trialPeriodDays);

    return this.props.children({
      mode,
      error,
      success,
      priceInfo,
      processing,
      displayName,
      displayPrice,
      loaded: true,
      subscriptionPlan,
      ownsThisSubscriptionPlan,
      firstPaymentDate: paymentDate.toLocaleDateString('en-US'),
      actionProps: {
        'data-value': subscriptionPlan,
        'data-path': `subscribeAction${capitalize(mode)}`,
        ...actionProps,
      },
    });
  }
}
