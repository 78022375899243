import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@wui/input/radioGroup';
import { useValidation, useDataBindings } from 'hooks';
import { propTypes, defaultProps } from 'prop-type-sets/BoundField';

const useStyles = makeStyles({
  helperText: {
    fontSize: 14,
    whiteSpace: 'pre-wrap',
  },
});

const BoundRadioGroup = ({ helperText, ...props }) => {
  const classes = useStyles();
  const { path, disabled } = props;

  const { domNode, getValidationErrors, hasValidationErrors } = useValidation(props);
  const [value, setValue] = useDataBindings(props);

  const errorText = hasValidationErrors ? getValidationErrors()[0] : null;

  return (
    <div ref={domNode}>
      <RadioGroup
        {...props}
        data-path={path}
        value={value}
        onChange={setValue}
        pulseFirstOption={hasValidationErrors}
        className={props.className}
      />

      <FormHelperText className={classes.helperText} error={hasValidationErrors}>
        {!disabled && (errorText || helperText)}
      </FormHelperText>
    </div>
  );
};

BoundRadioGroup.propTypes = {
  ...propTypes,
  helperText: PropTypes.string,
};

BoundRadioGroup.defaultProps = {
  ...defaultProps,
  helperText: '',
};

export default BoundRadioGroup;
