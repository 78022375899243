import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Child from './Child';
import AddButton from '../AddButton';
import FieldGroup from '../FieldGroup';
import BoundRadioGroup from '@c/BoundRadioGroup';

function initializeSpouseIsParent(clientData) {
  if (clientData.children.length === 0) {
    return null;
  }

  return clientData.spouse.children.length > 0;
}

function initializeChildrenCanBeMinors(clientData) {
  if (clientData.children.length === 0) {
    return null;
  }

  return clientData.children.some(c => c.isMinor);
}

function initializeChildrenHaveSameParents(clientData) {
  if (clientData.children.length === 0) {
    return null;
  }

  return clientData.allChildrenHaveBothLineage;
}

const styles = theme => ({
  buttonContainer: {
    marginTop: 20,
    cursor: 'pointer',
    alignItems: 'center',
    display: 'inline-flex',
  },
  disabled: theme.layout.disabledNode,
  button: {
    width: 40,
    height: 40,
    marginRight: 10,
  },
});

class Children extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  static contextTypes = {
    testator: PropTypes.object,
    clientDataHolder: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    // Initialize any delayed fields that are used on
    //   this page based on the current state.
    this.context.clientDataHolder.updateClientData(() => {
      this.t.setDelayedValues({
        'spouse.isParent': initializeSpouseIsParent,
        childrenCanBeMinors: initializeChildrenCanBeMinors,
        childrenHaveSameParents: initializeChildrenHaveSameParents,
      });
    });

    // If the user gets to this page without any children being in
    //   their list, we should make sure there is one (which will
    //   be disabled until they answer the needed questions). This
    //   can happen if somebody answered the `isParent` question in
    //   the old UI, and then started working with the new UI.
    this.addOrRemoveChildren();
  }

  // We use the testator and spouse a lot, so
  //   these helpers are useful.
  get t() {
    return this.context.testator;
  }
  get s() {
    return this.t.spouse;
  }

  addOrRemoveChildren() {
    this.context.clientDataHolder.updateClientData(() => {
      // If neither testator is a parent, then clear all
      //   children from the list.
      if (!this.t.eitherTestatorIsParent) {
        this.t.children.splice(0, this.t.children.length);

        // Otherwise, add a child if there isn't one in the list
        //   so that it is the record being modified when the
        //   user moves on to the next part of the form.
      } else if (!this.t.children.length) {
        this.t.addChild();
      }
    });
  }

  // The first question is never disabled. Otherwise,
  //   disabling a question is based on whether the
  //   previous question has a value.
  questionDisabled(questions, index) {
    return index !== 0 && this.t.getValue(questions[index - 1][1]) === null;
  }

  // Dynamically build a list of questions based on the current state.
  //   This pattern makes question display states easier to manage because
  //   each question can know what comes before it.
  // Each question is an array containing the following (in order):
  //   - The display text.
  //   - The path in the object to modify.
  //   - A callback to call after a change has been made.
  // Whether or not the question is disabled is dynamically added to the
  //   end of each question's array.
  get questions() {
    const questions = [
      ['Do you have any children?', 'isParent', this.addOrRemoveChildren.bind(this)],
    ];

    // If the user is married with children, or planning for their spouse,
    //   we ask questions about the spouse's children. We need to know
    //   lineages to know who is qualified to be assigned as a guardian.
    if (this.t.isMarried && (this.t.isPlanningForSpouse || this.t.isParent !== false)) {
      const spouseName = this.s.firstName || 'your spouse';

      questions.push([
        `Does ${spouseName} have any children?`,
        'spouse.isParent',
        this.addOrRemoveChildren.bind(this),
      ]);

      // If both testators are parents (or either question is not
      //   yet answered), we ask the following.
      if (this.t.isParent !== false && this.s.isParent !== false) {
        questions.push([
          'Do all the children have the same parents?',
          'childrenHaveSameParents',
          () => {},
        ]);
      }
    }

    // If either testator has children (or the questions to
    //   determine that have not been answered yet), ask the
    //   following.
    if (this.t.isParent !== false || (this.t.isPlanningForSpouse && this.s.isParent !== false)) {
      questions.push(['Are any under 18 years of age?', 'childrenCanBeMinors', () => {}]);
    }

    // Add the disabled state to each question.
    return questions.map((question, i) => {
      question.push(this.questionDisabled(questions, i));
      return question;
    });
  }

  renderQuestions(questions) {
    return questions.map(question => {
      const [label, path, onChange, disabled] = question;

      return (
        <BoundRadioGroup
          key={path}
          path={path}
          label={label}
          onChange={onChange}
          disabled={disabled}
        />
      );
    });
  }

  addChild() {
    this.context.clientDataHolder.updateClientData(() => this.t.addChild());
  }

  renderAddChildButton() {
    const children = this.t.children;

    // The button is disabled if there are no children
    //   in the list (the first is added based on the
    //   testators being parents or not automatically).
    //   It is also disabled if the last child has not
    //   been completely filled in (disinherited is the
    //   last field for a child).
    const disabled = children.length === 0 || children[children.length - 1].disinherited === null;

    return (
      <AddButton
        key="add"
        disabled={disabled}
        text="Add another child"
        onClick={this.addChild.bind(this)}
        dataPath="addChild"
      />
    );
  }

  get hideChildren() {
    const testatorHasNoChildren = this.t.isParent === false;

    if (this.t.isPlanningForSpouse) {
      return testatorHasNoChildren && this.s.isParent === false;
    }

    return testatorHasNoChildren;
  }

  renderChildren(questions) {
    if (this.hideChildren) {
      return null;
    }

    const childEntries = this.t.children.map((child, i) => {
      // The first child is disabled until the previous
      //   questions are filled in. Otherwise, the child
      //   can always be enabled and the individual fields
      //   within the child are enabled and disabled
      //   according to the state of the child before it.
      const disabled = i === 0 && this.questionDisabled(questions, questions.length);

      return <Child key={i} child={child} disabled={disabled} />;
    });

    // If there are no children to display, show a
    //   placeholder.
    if (!childEntries.length) {
      childEntries.push(<Child disabled={true} key="placeholder" />);
    }

    return childEntries.concat(this.renderAddChildButton());
  }

  // The questions are disabled if the testator
  //   is married but we don't know if they are
  //   planning for their spouse yet.
  get questionsDisabled() {
    return this.t.isMarried && this.t.isPlanningForSpouse === null;
  }

  render() {
    const questions = this.questions;

    return (
      <React.Fragment>
        <FieldGroup title="Your Children" disabled={this.questionsDisabled}>
          {this.renderQuestions(questions)}
        </FieldGroup>
        {this.renderChildren(questions)}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Children);
