import {
  AgentOwner,
  AssetOwnership,
  AssetType,
  BankAccountType,
  Relationship,
  ShareDistribution,
} from 'models/client-data/enums';
import { WArray, WBoolean, WInteger, WObject } from 'models/types';
import { Address, PersonMixin, AssetAddress } from 'models/client-data/mixins';
import { ClientDataObject } from 'models/type-system';
import { WNonEmptyString, WUUID } from '../hoc-types';
import {
  realEstateOwnedByBothStates,
  requiresBeneficiariesAddressStates,
  requiresBeneficiariesRelationshipStates,
} from 'utils';
import { capitalize } from '@willing-shared/utils/text';
import BequestStep from 'material-ui/sections/assets/BequestInterface/BequestStep';

const BeneficiaryMixin = superclass =>
  class extends PersonMixin(superclass) {
    static fields = {
      shareDistribution: new ShareDistribution(),
      sharePercentage: new WInteger(),
      address: new WObject(Address),
    };
  };

class DistributionValidationArray extends WArray {
  validate(value, addError) {
    super.validate(value, addError);

    if (value.some(i => i.sharePercentage !== null)) {
      let sum = 0;

      for (const item of value) {
        sum += item.sharePercentage || 0;
      }

      if (sum !== 100) {
        addError('The percentages must sum to 100.');
      }
    }
  }
}

export class AlternateBeneficiary extends BeneficiaryMixin(ClientDataObject) {
  static fields = {};
}

export class PrimaryBeneficiary extends BeneficiaryMixin(ClientDataObject) {
  // In arrays of beneficiaries, the same person might be selected
  //   multiple times for the same role by both the client and the
  //   spouse, so extra information is needed when determining the
  //   key.
  static keyFieldName(p) {
    return `${p.owner}-${p.personUUID}`;
  }

  static fields = {
    owner: new AgentOwner(), // TODO: Unify owners or make separate
    alternateBeneficiaries: new DistributionValidationArray(new WObject(AlternateBeneficiary)),
  };

  // If there is no name, then this is a placeholder
  //   beneficiary (for real estate) and should be
  //   ignored.
  get pojoArrayIgnores() {
    return !this.name;
  }
}

export class AssetOwner extends PersonMixin(ClientDataObject) {
  static fields = {};
}

export class Asset extends ClientDataObject {
  static keyFieldName = 'assetUUID';

  static fields = {
    pk: new WInteger(),
    assetUUID: new WUUID(),
    type: new AssetType(),
    deedLocked: new WBoolean(),
    clientShareDistribution: new ShareDistribution(),
    spouseShareDistribution: new ShareDistribution(),
    // Shared
    estimatedValue: new WInteger(),
    description: new WNonEmptyString(),

    // Bank/Savings account
    bank: new WNonEmptyString(),
    bankAccountType: new BankAccountType(),
    lastFour: new WNonEmptyString(),

    // Real estate
    address: new WObject(AssetAddress),
    isPrimaryResidence: new WBoolean(),

    // Vehicle
    vehicleYear: new WInteger(),
    vehicleMake: new WNonEmptyString(),
    vehicleModel: new WNonEmptyString(),

    owners: new WArray(new WObject(AssetOwner)),
    primaryBeneficiaries: new WArray(new WObject(PrimaryBeneficiary)),
  };

  static requiredFields(type) {
    switch (type) {
      case AssetType.REAL_ESTATE:
        return { address: 'Address' };
      case AssetType.CASH_GIFT:
        return { estimatedValue: 'Dollar Amount' };
      case AssetType.ART:
      case AssetType.OTHER:
      case AssetType.JEWELRY:
        return { description: 'Description' };
      case AssetType.BUSINESS:
        return { description: 'Business Name' };
      case AssetType.BANK_ACCOUNT:
        return {
          bankAccountType: 'Account Type',
          bank: 'Institution Name',
          lastFour: 'Account # Last Four',
        };
      case AssetType.LIFE_INSURANCE:
      case AssetType.INVESTMENT_ACCOUNT:
        return {
          bank: 'Institution Name',
          lastFour: 'Account # Last Four',
        };
      case AssetType.VEHICLE:
        return {
          vehicleYear: 'Year',
          vehicleMake: 'Make',
          vehicleModel: 'Model',
        };
      default:
        return [];
    }
  }

  static removeInvalidPrefixes(text) {
    if (!text) {
      return text;
    }

    return text.replace(/^all of my\s+|^all my\s+|^my\s+|^all\s+/i, '');
  }

  // If there are incomplete fields, then this is
  //   still being edited in the bequest interface
  //   and should be ignored.
  get pojoArrayIgnores() {
    return !this.hasAllRequiredFields;
  }

  get requiredFields() {
    return this.constructor.requiredFields(this.type);
  }

  removeOwnerByRelationship(relationship) {
    this.owners.splice(
      this.owners.findIndex(o => o.clientRelationship === relationship),
      1,
    );
  }

  removePrimaryBeneficiariesByOwner(owner) {
    let removedBeneficiaries = [];
    for (let i = this.primaryBeneficiaries.length - 1; i >= 0; i--) {
      if (this.primaryBeneficiaries[i].owner === owner) {
        removedBeneficiaries.push(...this.primaryBeneficiaries.splice(i, 1));
      }
    }
    return removedBeneficiaries;
  }

  isOwnedByRelationship(relationship) {
    for (const o of this.owners) {
      if (o.clientRelationship === relationship) {
        return true;
      }
    }

    return false;
  }

  // See the note on the backend implementation for
  //   `Asset.is_owned_by_third_party` in the
  //   backend code for the reasoning behind this
  //   implementation.
  get hasAdditionalOwners() {
    if (
      this.type === AssetType.REAL_ESTATE &&
      this.lastFour === AssetOwnership.ADDITIONAL_OWNERS_DATA
    ) {
      return true;
    }

    const ownerCount = this.owners.length;
    return ownerCount > 2 || (ownerCount === 2 && !this.isJointlyOwned);
  }

  static realEstateMustBeOwnedByBoth(state) {
    return realEstateOwnedByBothStates.includes(state);
  }

  get realEstateMustBeOwnedByBoth() {
    return this.constructor.realEstateMustBeOwnedByBoth(this.address.state);
  }

  static availableOwnershipTypes(clientData, state) {
    const testatorName = clientData.firstName || 'You';
    const spouseName = clientData.spouse.firstName || 'your spouse';
    const bothOption = {
      [AssetOwnership.BOTH]: `${testatorName} and ${spouseName}`,
    };
    const bothAndOthersOption = {
      [AssetOwnership.BOTH_AND_OTHERS]: `${testatorName}, ${spouseName}, and other(s)`,
    };

    if (!clientData.isMarried) {
      return {
        [AssetOwnership.CLIENT]: 'Just me',
        [AssetOwnership.CLIENT_AND_OTHERS]: 'Me and other(s)',
      };
    } else if (this.realEstateMustBeOwnedByBoth(state)) {
      return { ...bothOption, ...bothAndOthersOption };
    }

    const options = {
      ...bothOption,
      [AssetOwnership.CLIENT]: testatorName,
      [AssetOwnership.SPOUSE]: capitalize(spouseName),
      [AssetOwnership.CLIENT_AND_OTHERS]: `${testatorName} and other(s)`,
      [AssetOwnership.SPOUSE_AND_OTHERS]: `${capitalize(spouseName)} and other(s)`,
      ...bothAndOthersOption,
    };

    if (clientData.isPlanningForSpouse) {
      return options;
    }

    delete options[AssetOwnership.SPOUSE];
    delete options[AssetOwnership.SPOUSE_AND_OTHERS];
    return options;
  }

  ownershipTypeValid(clientData) {
    return Object.keys(this.availableOwnershipTypes(clientData)).includes(this.ownershipType);
  }

  availableOwnershipTypes(clientData) {
    return this.constructor.availableOwnershipTypes(clientData, this.address.state);
  }

  clearOwnership(clientData) {
    this.owners.length = 0;
    this.ownershipType = null;
    this.setOwnersBasedOnOwnershipType(clientData);
  }

  // This getter/setter is used so we can easily tie asset
  //   ownership to an existing control. Setting this
  //   value does not actually change any representation
  //   in the data that is persisted to the server - that
  //   must be taken care of after the change has been made
  //   to this property. The `setOwnersBasedOnOwnershipType`
  //   helps with doing this.
  get ownershipType() {
    if (this.pendingOwnershipType) {
      return this.pendingOwnershipType;
    }

    let ownershipType = null;
    if (this.isJointlyOwned) {
      ownershipType = AssetOwnership.BOTH;
    } else if (this.isOwnedByClient) {
      ownershipType = AssetOwnership.CLIENT;
    } else if (this.isOwnedBySpouse) {
      ownershipType = AssetOwnership.SPOUSE;
    }

    // Once we figure out the base owner, we can
    //   add additional owners if needed.
    if (ownershipType && this.hasAdditionalOwners) {
      ownershipType = AssetOwnership.addAdditionalOwners(ownershipType);
    }

    return ownershipType;
  }

  set ownershipType(value) {
    this.pendingOwnershipType = value;
  }

  setOwnersBasedOnOwnershipType(clientData, jointOwnershipType = null) {
    this.owners.splice(0, this.owners.length);
    this.ownershipType = jointOwnershipType || this.ownershipType;

    const [ownershipType, hasOthers] = AssetOwnership.splitOwnershipType(this.ownershipType);

    // See `hasAdditionalOwners` above.
    this.setRawValue('lastFour', hasOthers ? AssetOwnership.ADDITIONAL_OWNERS_DATA : null);

    if ([AssetOwnership.BOTH, AssetOwnership.CLIENT].includes(ownershipType)) {
      this.owners.push(new AssetOwner(clientData));
    }

    if ([AssetOwnership.BOTH, AssetOwnership.SPOUSE].includes(ownershipType)) {
      this.owners.push(new AssetOwner(clientData.spouse));
    }
  }

  get isOwnedByClient() {
    return this.isOwnedByRelationship(Relationship.SELF);
  }

  get isOwnedBySpouse() {
    return this.isOwnedByRelationship(Relationship.SPOUSE);
  }

  get isJointlyOwned() {
    return this.isOwnedByClient && this.isOwnedBySpouse;
  }

  get assetAddressor() {
    switch (this.type) {
      case AssetType.CASH_GIFT:
        return 'A';
      case AssetType.REAL_ESTATE:
        return 'The';
      default:
        return 'My';
    }
  }

  get assetDescription() {
    switch (this.type) {
      case AssetType.CASH_GIFT:
        return `cash gift of ${new Intl.NumberFormat('en-us', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        }).format(this.estimatedValue || '')}`;
      case AssetType.REAL_ESTATE:
        return `property at ${this.address.street || ''} in ${this.address.city || ''},
                ${this.address.state || ''}`;
      case AssetType.VEHICLE:
        return `${this.vehicleYear || ''} ${this.vehicleMake || ''} ${this.vehicleModel || ''}`;
      case AssetType.BANK_ACCOUNT:
        return `${this.bankAccountType} account at ${this.bank} 
                ${this.lastFour ? `ending in ${this.lastFour}` : ''}`;
      case AssetType.LIFE_INSURANCE:
        return `life insurance policy at ${this.description}
                ${this.lastFour ? `ending in ${this.lastFour}` : ''}`;
      case AssetType.BUSINESS:
        return `interest in ${this.description}`;
      default:
        return this.description || '';
    }
  }

  get fullAssetDescription() {
    if (!this.assetDescription) {
      return '';
    }

    return `${this.assetAddressor} ${this.assetDescription}`;
  }

  get needsBeneficiaryAddress() {
    return (
      this.type === AssetType.REAL_ESTATE &&
      requiresBeneficiariesAddressStates.includes(this.address.state)
    );
  }

  get needsBeneficiaryRelationship() {
    return (
      this.type === AssetType.REAL_ESTATE &&
      requiresBeneficiariesRelationshipStates.includes(this.address.state)
    );
  }

  get hasAddress() {
    return this.address.street && this.address.city && this.address.state && this.address.zip;
  }

  get isCompleteForRealEstate() {
    return (
      Boolean(this.hasAddress) && this.ownershipType !== null && this.isPrimaryResidence !== null
    );
  }

  get hasAllRequiredFields() {
    // If the asset doesn't have a type
    //   set, there is no way it is
    //   ready.
    if (!this.type) {
      return false;

      // Real estate is a special case because
      //   the address field is a sub-object.
    } else if (this.isRealEstate) {
      return this.hasAddress;
    }

    // Otherwise, make sure every required field has a value.
    return Object.keys(this.requiredFields).every(field => this[field]);
  }

  isCompleteForBequest(testator) {
    // If any required field is missing, the
    //   bequest can't be complete.
    if (!this.hasAllRequiredFields) {
      return false;
    }

    // Don't include placeholders because they
    //   can't be saved.
    const primaries = testator.getPrimaryBeneficiariesForAsset(this, false);

    // To be valid, the asset must have at least
    //   one primary beneficiary for the testator.
    if (!primaries.length) {
      return false;
    }

    // If the share distribution for the primaries does not make
    //   sense, then the bequest isn't ready.
    if (!ShareDistribution.valid(this[testator.assetShareDistributionKey], primaries)) {
      return false;
    }

    // Ensure each primary has either no alternates,
    //   or if it has alternates, make sure the
    //   share distribution is valid.
    return primaries.every(
      p =>
        p.alternateBeneficiaries.length === 0 ||
        ShareDistribution.valid(p.shareDistribution, p.alternateBeneficiaries),
    );
  }

  get isRealEstate() {
    return this.type === AssetType.REAL_ESTATE;
  }

  bequestStep(testator) {
    return BequestStep.fromAsset(this, testator);
  }
}
