import PropTypes from 'prop-types';

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useSnackbar } from 'notistack';

import App from '@/App';
import WuiTheme from '@willing-shared/WuiTheme';
import Grid from '@wui/layout/grid';
import Panel from '@wui/layout/panel';
import Layout from '@c/layout/Layout';
import Spacer from '@wui/layout/spacer';
import PanelTitle from '@wui/layout/panelTitle';
import Typography from '@wui/basics/typography';
import ChangePhoneNumber from './ChangePhoneNumber';
import PasswordIndicator from './PasswordIndicator';
import ChangeEmail from '@/pages/account/ChangeEmail';
import SubscriptionPicker from 'ui/SubscriptionPicker';
import { DocumentBundle, AppLocation } from 'utils/enums';
import ChangePassword from '@/pages/account/ChangePassword';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { formatPhoneNumber } from '@willing-shared/utils/text';
import StartAdminSessionButton from './StartAdminSessionButton';
import { getLoginSettingsUrl } from '@willing-shared/utils/tokens';
import { useGlobalContext, useScreenSize, useDocumentTitle } from 'hooks';
import { Urls } from 'urls';

import FileAndStore from '@a/images/file-and-store.png';

import ActionButton from './ActionButton';
import DocumentButton from './DocumentButton';
import Item from './Item';
import Updater from './Updater';

const Account = ({ title }) => {
  useDocumentTitle(title);
  const { enqueueSnackbar } = useSnackbar();
  const {
    clientData,
    serverData: { documentBundle, subscriptionPlan, isStaff, fromPortunus },
    clientData: {
      email,
      profile: { phoneNumber, needsPasswordReset },
    },
    history,
  } = useGlobalContext();
  const { isPhoneDisplay } = useScreenSize();

  useEffect(() => {
    if (needsPasswordReset) {
      enqueueSnackbar('Your password was reset and needs to be updated before you can continue.');
    }
  }, [needsPasswordReset, enqueueSnackbar]);

  const redirectTo = url => () => history.push(url);

  const documentBundleDisplay = documentBundle
    ? DocumentBundle.displayName(documentBundle)
    : 'None';
  const covidBundleDisplay = clientData.covidEmployer ? '' : documentBundleDisplay;

  const portunusOnClick = fromPortunus ? () => (window.location = getLoginSettingsUrl()) : null;

  return (
    <div>
      <App>
        <Layout wide appLocation={AppLocation.ACCOUNT}>
          <WuiTheme>
            <Grid
              container
              direction="row"
              wrap={isPhoneDisplay ? 'nowrap' : 'wrap'}
              alignItems="flex-end"
              spacing={4}
              reverseDirectionOnPhone
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h2">My Account</Typography>
                <Typography variant="body1">Manage your account settings.</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DimensionLimiter h="100%">
                  <img alt="" src={FileAndStore} />
                </DimensionLimiter>
              </Grid>
            </Grid>
            <Spacer v={24} />
            <Panel tableRow lessPadding>
              <PanelTitle>YOUR ESTATE PLAN</PanelTitle>
              <Grid container direction="row" alignItems="center">
                <Item label="Document Bundle" name={covidBundleDisplay} />
                <DocumentButton />
              </Grid>
            </Panel>
            {!clientData.covidEmployer && !fromPortunus && subscriptionPlan && (
              <Panel tableRow lessPadding>
                <Grid container direction="row" alignItems="center">
                  <Item label="Subscription Plan" name={subscriptionPlan ? 'Active' : 'Inactive'} />
                  <ActionButton
                    text={subscriptionPlan ? 'View' : 'Edit'}
                    onClick={redirectTo(SubscriptionPicker.url)}
                  />
                </Grid>
              </Panel>
            )}

            <Spacer v={32} />

            <Updater
              component={ChangeEmail}
              title="BASIC DETAILS"
              text="Edit"
              isLink
              dataPath="updateEmail"
              onClick={portunusOnClick}
            >
              <Item label="Email" name={email} />
            </Updater>

            <Updater
              component={ChangePassword}
              text="Edit"
              isLink
              dataPath="updatePassword"
              startOpen={needsPasswordReset}
              onClick={portunusOnClick}
            >
              <Item label="Password" name={<PasswordIndicator />} />
            </Updater>

            <Updater
              component={ChangePhoneNumber}
              text={phoneNumber ? 'Edit' : 'Add'}
              isLink={Boolean(phoneNumber)}
              dataPath="updatePhoneNumber"
            >
              <Item
                label="Phone Number"
                name={phoneNumber ? formatPhoneNumber(phoneNumber) : 'None'}
              />
            </Updater>

            <Spacer v={32} />

            {isStaff && <StartAdminSessionButton />}
          </WuiTheme>
        </Layout>
      </App>
    </div>
  );
};

Account.url = Urls.account;
Account.propTypes = {
  title: PropTypes.string,
};
export default observer(Account);
