import api from '@/api';
import sentry from '@/utils/sentry';
import env, { googleTagIdentifiers } from '@/utils/env';
import platforms from './platformInitializers';

// We want to distinguish events from separate
//   applications because some of the event
//   names might clash. To do this, we prefix
//   event names, or modify the event to use
//   this prefix.
let prefix = null;

const queuedActions = [];
let configLoaded = false;

// Store the gtag configuration so that we don't
//   have to repeat it every time a new call is
//   made.
const gtagConfigData = {};

function windowCall(variable, fn, ...args) {
  const tag = window[variable];

  if (!tag) {
    console.warn(`${variable}:`, ...[fn, args].filter(Boolean)); // eslint-disable-line no-console
    return;
  }

  const caller = fn ? tag[fn].bind(tag) : tag;

  if (!caller) {
    console.warn(`${variable}.${fn}:`, args); // eslint-disable-line no-console
    return;
  }

  caller(...args);
}

function gtag(...args) {
  windowCall('gtag', null, ...args);
}

// Keep the parameter the same as the gtag API
//   expects it.
// eslint-disable-next-line camelcase
function gtagConfig({ send_page_view = true, ...args }) {
  // The send_page_view parameter is not stored
  //   for later because it can change every time.
  Object.assign(gtagConfigData, args);

  const gaID = googleTagIdentifiers.mlpAnalytics;
  gtag('config', gaID, {
    send_page_view,
    ...gtagConfigData,
  });
}

function isEnabled() {
  return env.isProductionBuild();
}

function whenEnabled(name, wrapped) {
  return function checkWhenEnabled(...args) {
    if (isEnabled()) {
      if (configLoaded) {
        wrapped(...args);
      } else {
        queuedActions.push(() => wrapped(...args));
      }
    } else {
      console.warn(`analytics.${name}`, args); // eslint-disable-line no-console
    }
  };
}

const setUp = whenEnabled('setUp', initPrefix => {
  prefix = initPrefix;
  platforms.forEach(p => sentry.attempt(p));

  gtagConfig({
    send_page_view: false,
    optimize_id: googleTagIdentifiers.getOptimize(),
    custom_map: {
      dimension8: 'user_id_dimension',
    },
  });

  gtag('config', googleTagIdentifiers.adwords);
  queuedActions.forEach(action => action());
});

const init = initPrefix => {
  if (window.WillingConfig) {
    configLoaded = true;
    setUp(initPrefix);
  } else {
    api.config.get().then(({ data }) => {
      window.WillingConfig = data;
      configLoaded = true;
      setUp(initPrefix);
    });
  }
};

const identify = whenEnabled('identify', ({ googleAnalyticsId }) => {
  gtagConfig({
    user_id: googleAnalyticsId,
    user_id_dimension: googleAnalyticsId,
  });
});

const pageview = whenEnabled('pageview', page => {
  gtagConfig({ page_path: page });
  gtag('config', googleTagIdentifiers.adwords, { page_path: page });
});

const event = whenEnabled('event', gaEvent => {
  const prefixedEvent = {
    ...gaEvent,
  };

  if (prefix) {
    prefixedEvent.category = [prefix, prefixedEvent.category].filter(Boolean).join(' - ');
  }

  gtag('event', prefixedEvent.action, {
    value: prefixedEvent.value,
    event_label: prefixedEvent.label,
    event_category: prefixedEvent.category,
  });
});

export default {
  init,
  event,
  identify,
  pageview,
  isEnabled,
  gtagConfig,
};
