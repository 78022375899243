import mitekInit from 'vendor/MitekMobileWebAPI';

const captureErrorCode = {
  GOOD: 0, // Success
  NF: 1, // Not found
  OOF: 2, // Out of focus
  DARK: 3, // Bad lighting
  MINFILL: 4, // Min fill
  SKEW: 5, // Skewed image
  MINWIDTH: 6, // Too far away
  GLARE: 7, // Glare detected
  FOURCORNERS: 8, // Four Corners not detected
  CORRUPTIMAGE: 9, // Image is corrupt
  MINPADDING: 10, // Min Padding
  LOWCONTRAST: 11, // Low Contrast Background
  BUSYBACKGROUND: 12, // Busy Background
  IMAGETOOSMALL: 13, // Image too small
};

const desktopErrorMessages = {
  [captureErrorCode.DARK]: 'There is not enough light on your document.',
  [captureErrorCode.MINFILL]: 'Move the camera closer to your document.',
  [captureErrorCode.SKEW]: 'Document is skewed.  Hold camera directly over your document.',
  [captureErrorCode.MINPADDING]: 'Move the camera further away from your document.',
  [captureErrorCode.GLARE]: 'The image has glare.',
  [captureErrorCode.FOURCORNERS]: "We can't find the 4 corners of your document.",
  [captureErrorCode.CORRUPTIMAGE]: 'The image you provided is unreadable.',
  [captureErrorCode.IMAGETOOSMALL]: 'The image you provided is too small.',
};

const allErrorMessages = {
  ...desktopErrorMessages,
  [captureErrorCode.NF]: 'We were unable to detect the barcode from the back of your license.',
  [captureErrorCode.OOF]: 'The image is too blurry.',
  [captureErrorCode.LOWCONTRAST]: 'Center your document on a dark background.',
  [captureErrorCode.BUSYBACKGROUND]: 'The background is too busy.  Please use a solid background.',
};

const decodeDesktopErrorCodes = function (errors) {
  return errors.map(err => desktopErrorMessages[err]).filter(Boolean);
};

const decodeAllErrorCodes = function (errors) {
  return errors.map(err => allErrorMessages[err]).filter(Boolean);
};

export default {
  decodeDesktopErrorCodes,
  decodeAllErrorCodes,
  init: mitekInit,
};
