import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Hidden from '@material-ui/core/Hidden';
import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';

import Textbox from '@wui/input/textbox';
import Typography from '@wui/basics/typography';
import Spacer from '@wui/layout/spacer';
import Button from '@wui/input/button';
import GenericError from '@wui/basics/genericError';
import Panel from '@wui/layout/panel';
import CustomIcon from '@wui/basics/customIcon';
import Grid from '@wui/layout/grid';
import Link from '@wui/basics/link';

import CardBrandDisplay from './CardBrandDisplay';
import PaymentHandler from './PaymentHandler';

import NortonIcon from '@a/images/checkout-v6/norton.png';
import McAfeeIcon from '@a/images/checkout-v6/mcafee.png';

const styles = theme => ({
  // Used to modify other classes
  error: {},

  root: {
    padding: 32,

    [theme.breakpoints.notPhone]: {
      width: '100%',
      borderRadius: '3px',
      boxShadow: theme.customShadows.standard,
      border: `1px solid ${theme.palette.grey.panelBorder}`,
    },
  },
  stripeContainer: {
    border: `2px solid ${theme.palette.grey.textboxBorder}`,
    padding: 8,
    borderRadius: 5,

    '&$error': {
      borderColor: theme.palette.text.error,
    },
  },
});

class CheckoutForm extends PaymentHandler {
  static propTypes = {
    ...PaymentHandler.propTypes,
    couponSuccess: PropTypes.string,
    couponError: PropTypes.bool.isRequired,
    classes: PropTypes.shape({}).isRequired,
    isPaymentInfoPending: PropTypes.bool,
  };

  static defaultProps = {
    couponSuccess: null,
    includedRealEstate: [],
    isPaymentInfoPending: false,
  };

  componentDidMount() {
    super.componentDidMount();

    this.analyticsEvents.dataLayer.push({
      event: 'willing.page.loaded.checkout-form',
    });
    this.analyticsEvents.dispatchEvent('viewedPayment');
  }

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      cardError: '',
      expirationError: '',
      cvcError: '',

      cardComplete: false,
      expirationComplete: false,
      cvcComplete: false,
      brand: '',
      showCoupon: false,
    };
  }

  get stripeStyles() {
    const { theme } = this.props;
    return {
      base: {
        fontFamily: 'Verdana, sans-serif',
        fontSize: '24px',
        fontWeight: 'normal',
        color: theme.palette.grey.inputText,
        '::placeholder': {
          color: theme.palette.grey.placeholderText,
          fontWeight: '400',
        },
      },
      invalid: {
        color: theme.palette.grey.inputText,
      },
    };
  }

  setError = (e, path) => {
    if (e.error) {
      this.setState({ [path]: e.error.message });
    } else {
      this.setState({ [path]: '' });
    }
  };

  onChangeCardField = (e, type) => {
    const errorPath = `${type}Error`;
    const completePath = `${type}Complete`;
    const { complete } = e;

    // Tracked internally as well so we know when to disable the paybutton
    this.setState({ [completePath]: complete });

    switch (type) {
      case 'card':
        if (e.brand) {
          this.setState({ brand: e.brand });
        }
        if (complete) {
          this.cardExpiry._ref.click();
        }
        break;
      case 'expiration':
        if (complete) {
          this.cardCVC._ref.click();
        }
        break;
      default:
        return;
    }
    this.setError(e, errorPath);
  };

  get allFieldsComplete() {
    const { cardComplete, expirationComplete, cvcComplete } = this.state;

    return cardComplete && expirationComplete && cvcComplete;
  }

  get isPending() {
    const { isPaymentInfoPending } = this.props;
    const { isPaymentPending } = this.state;

    return isPaymentPending || isPaymentInfoPending;
  }

  renderCouponFeedback(icon, color, text) {
    return (
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <CustomIcon block src={icon} height={24} width={24} color={color} />
        </Grid>
        <Grid item>
          <Typography>{text}</Typography>
        </Grid>
      </Grid>
    );
  }

  renderCardFields = () => {
    const { classes } = this.props;
    const { cardError, expirationError, cvcError } = this.state;

    const genClassNames = error =>
      classNames(classes.stripeContainer, {
        [classes.error]: Boolean(error),
      });

    return (
      <React.Fragment>
        <div>
          <Typography variant="caption">Card Number</Typography>
          <div className={genClassNames(cardError)}>
            <CardNumberElement
              style={this.stripeStyles}
              ref={cardNumber => {
                this.cardNumber = cardNumber;
              }}
              onChange={e => this.onChangeCardField(e, 'card')}
              placeholder="Card Number"
            />
          </div>
        </div>

        <Spacer v={40} />

        <Grid container spacing={2} direction="row">
          <Grid item xs={6}>
            <Typography variant="caption">Expiration Date</Typography>
            <div className={genClassNames(expirationError)}>
              <CardExpiryElement
                style={this.stripeStyles}
                ref={cardExpiry => {
                  this.cardExpiry = cardExpiry;
                }}
                onChange={e => this.onChangeCardField(e, 'expiration')}
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="caption">CVC</Typography>
            <div className={genClassNames(cvcError)}>
              <CardCVCElement
                style={this.stripeStyles}
                ref={cardCVC => {
                  this.cardCVC = cardCVC;
                }}
                onChange={e => this.onChangeCardField(e, 'cvc')}
              />
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  handleApplyCouponKeyPress = e => {
    // If the user presses enter while the coupon code input field is focused,
    //   we apply the coupon and avoid submitting the checkout form.
    const { applyCoupon } = this.props;
    const { couponCode } = this.state;

    if (e.charCode === 13) {
      e.preventDefault();
      applyCoupon(couponCode);
    }
  };

  renderCouponFields = () => {
    const { applyCoupon, couponError, couponSuccess } = this.props;
    const { showCoupon, couponCode } = this.state;

    if (!showCoupon) {
      return null;
    }

    return (
      <React.Fragment>
        <Grid container direction="row" alignItems="center" alignContent="center" spacing={2}>
          <Grid item xs={8}>
            <Textbox
              onChange={e =>
                this.setState({
                  couponCode: e.target.value || '',
                })
              }
              value={couponCode}
              label="Gift Code"
              InputProps={{ onKeyPress: this.handleApplyCouponKeyPress }}
            />
          </Grid>

          <Grid item xs={4}>
            <Button
              processing={this.isPending}
              variant="outlined"
              noMinWidth
              onClick={() => applyCoupon(couponCode)}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
        {couponError &&
          this.renderCouponFeedback(
            ErrorIcon,
            theme => theme.palette.text.error,
            'Invalid coupon code',
          )}
        {couponSuccess &&
          this.renderCouponFeedback(
            CheckCircleIcon,
            theme => theme.palette.green.success,
            couponSuccess,
          )}
      </React.Fragment>
    );
  };

  renderPaymentButtons = () => (
    <Button
      type="submit"
      color="primary"
      variant="contained"
      onClick={this.getStripeSource}
      fullWidth
      processing={this.isPending}
      disabled={!this.allFieldsComplete}
    >
      Secure Pay
    </Button>
  );

  render() {
    const { classes } = this.props;
    const { brand, showCoupon, paymentError } = this.state;

    return (
      <React.Fragment>
        {paymentError && (
          <Panel paddingless>
            <GenericError message={paymentError === true ? null : paymentError} />
          </Panel>
        )}
        <form className={classes.root} onSubmit={this.getStripeSource}>
          <Hidden only="xs">
            <Typography variant="h3">Make a Payment</Typography>
            <Spacer v={8} />
          </Hidden>

          {this.renderCardFields()}
          <Spacer v={32} />

          <CardBrandDisplay brand={brand} />
          <Spacer v={24} />

          <Grid container spacing={1} direction="row" alignItems="center" alignContent="center">
            <Grid item xs={10}>
              <Typography variant="caption">
                All transactions are SSL (Secure Socket Layer) protected
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Icon>lock</Icon>
            </Grid>
          </Grid>

          <Spacer v={20} />
          <Link onClick={() => this.setState({ showCoupon: !showCoupon })} variant="body2">
            I have a gift code
          </Link>

          <Spacer v={8} />

          {this.renderCouponFields()}

          <Spacer v={32} />

          {this.renderPaymentButtons()}
        </form>
        <Hidden smDown>
          <Spacer v={32} />
          <Grid container spacing={4} justify="center" direction="row">
            <Grid item>
              <img alt="Norton secure seal" src={NortonIcon} height="30" />
            </Grid>
            <Grid item>
              <img alt="McAfee secure seal" src={McAfeeIcon} height="30" />
            </Grid>
          </Grid>
          <Spacer v={16} />
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CheckoutForm);
