import { capitalize } from '@willing-shared/utils/text';
import { PeopleBuilder, validatePersonNameUniqueness } from 'models/utils';
import { Relationship, AssetType, AgentOwner } from 'models/client-data/enums';

const isDuplicatePerson = (group, person) => {
  group = group.concat(person.alternateBeneficiaries || []);
  return (
    group.filter(
      otherPerson =>
        person.getRawValue('personUUID') === otherPerson.getRawValue('personUUID') &&
        (!otherPerson.getRawValue('owner') ||
          person.getRawValue('owner') === otherPerson.getRawValue('owner')),
    ).length > 1
  );
};

const OWNER_TO_CLIENT_RELATIONSHIP = {
  [AgentOwner.CLIENT]: Relationship.SELF,
  [AgentOwner.SPOUSE]: Relationship.SPOUSE,
};

const isOwnedBySelf = (person, ownerString) =>
  OWNER_TO_CLIENT_RELATIONSHIP[ownerString] === person.getRawValue('clientRelationship');

const filterInvalidPeople = (group, eligiblePersonUUIDs, ownerString) =>
  group.filter(
    person =>
      eligiblePersonUUIDs.includes(person.getRawValue('personUUID')) &&
      !isDuplicatePerson(group, person) &&
      !isOwnedBySelf(person, ownerString || person.owner),
  );

export const forceRename = (clientData, renamedPerson) => {
  // When these imports are at the top of the file it causes a circular
  //   import problem after building the frontend.
  const SectionGuardians = require('ui/sections/guardians/SectionGuardians').default;
  const SectionExecutors = require('ui/sections/executors/SectionExecutors').default;
  const SectionHealthcare = require('ui/sections/healthcare/SectionHealthcare').default;
  const SectionAssetBeneficiaries = require('ui/sections/asset-beneficiaries/SectionAssetBeneficiaries')
    .default;

  const mergePerson = (person, section) => {
    if (person.personUUID === renamedPerson.personUUID) {
      person.setRawValue('name', renamedPerson.name);
    }
    if (person.name === renamedPerson.name && person.personUUID !== renamedPerson.personUUID) {
      person.setRawValue('personUUID', renamedPerson.personUUID);
      person.setRawValue('clientRelationship', renamedPerson.clientRelationship);
      person.setRawValue('spouseRelationship', renamedPerson.spouseRelationship);
      if (renamedPerson.address && person.address) {
        person.setRawValue('address', renamedPerson.getRawValue('address'));
      }
      clientData.setSectionPagesInvalid(section);
    }
  };

  const renameAgents = (parentObject, agentType, eligiblePersonUUIDs, section, ownerString) => {
    let rawAgents = parentObject.getRawValue(agentType);
    rawAgents.map(p => mergePerson(p, section));
    rawAgents = filterInvalidPeople(rawAgents, eligiblePersonUUIDs, ownerString);
    parentObject.setRawValue(agentType, rawAgents);
  };

  const agentTypes = [
    ['guardians', SectionGuardians],
    ['executors', SectionExecutors],
    ['healthcareAgents', SectionHealthcare],
  ];

  agentTypes.forEach(([agentType, section]) => {
    const eligibleUUIDs = PeopleBuilder[`get${capitalize(agentType)}`](clientData, clientData).map(
      p => p.personUUID,
    );
    renameAgents(clientData, agentType, eligibleUUIDs, section);
  });

  const eligibleBennyUUIDs = PeopleBuilder.getAssetBeneficiaries(clientData).map(p => p.personUUID);
  const testatorUUIDs = [clientData.personUUID, clientData.spouse.personUUID];

  for (const asset of clientData.assets) {
    let eligibleUUIDs = eligibleBennyUUIDs;
    if (asset.type === AssetType.RESIDUAL_JOINT) {
      eligibleUUIDs = eligibleBennyUUIDs.filter(uuid => !testatorUUIDs.includes(uuid));
    }

    for (const primary of asset.getRawValue('primaryBeneficiaries')) {
      renameAgents(
        primary,
        'alternateBeneficiaries',
        eligibleUUIDs,
        SectionAssetBeneficiaries,
        primary.owner,
      );
    }
    renameAgents(asset, 'primaryBeneficiaries', eligibleUUIDs, SectionAssetBeneficiaries);

    if (asset.owners.length) {
      asset.owners.map(mergePerson);

      const uuidToOwner = asset.owners.reduce((acc, owner) => {
        acc[owner.getRawValue('personUUID')] = owner;
        return acc;
      }, {});

      asset.setRawValue('owners', Object.values(uuidToOwner));
    }
  }
};

export async function handleRename(clientDataReferer, person, getConfirmation) {
  const isDuplicateName =
    validatePersonNameUniqueness(clientDataReferer.clientData, person)().length > 0;
  const isDuplicateFamilyName =
    validatePersonNameUniqueness(clientDataReferer.clientData, person, true)().length > 0;
  if (!isDuplicateFamilyName && isDuplicateName) {
    const confirmed = await getConfirmation();
    if (confirmed) {
      clientDataReferer.updateClientData(() => forceRename(clientDataReferer.clientData, person));
    }
  }
}
