import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';

import Spacer from '@wui/layout/spacer';
import Grid from '@wui/layout/grid';
import Button from '@wui/input/button';
import Typography from '@wui/basics/typography';

// For some reason the usual import {ReactComponent as ...} pattern doesn't work with this
// TODO: Investigate that later
import ErrorIcon from 'icons/svg/icon-error.svg';

const useStyles = makeStyles({
  root: {
    maxWidth: 500,
  },
});

const Failure = ({ message, details, retry, buttonText, children }) => {
  const classes = useStyles();

  return (
    <Grid direction="column" alignItems="center" container>
      <div className={classes.root}>
        <Grid
          direction="column"
          justify="center"
          alignItems="center"
          alignContent="center"
          container
        >
          <img alt="Error" src={ErrorIcon} />

          <Spacer v={16} />

          <Typography align="center" variant="h4">
            {message}
          </Typography>

          {details && (
            <>
              <Spacer v={8} />

              <Typography variant="body1" align="center">
                {details}
              </Typography>
            </>
          )}

          <Spacer v={32} />

          {children}

          {children && <Spacer v={32} />}

          {retry && (
            <Button variant="contained" color="primary" onClick={retry}>
              {buttonText}
            </Button>
          )}
        </Grid>
      </div>
    </Grid>
  );
};

Failure.propTypes = {
  message: PropTypes.string.isRequired,
  details: PropTypes.node,
  children: PropTypes.node,
  retry: PropTypes.func,
  buttonText: PropTypes.string,
};

Failure.defaultProps = {
  children: null,
  details: null,
  retry: null,
  buttonText: 'Retry',
};

export default observer(Failure);
