import React from 'react';
import PropTypes from 'prop-types';
import ClientDataReferer from './client-data/ClientDataReferer';
import ValidationDisplay from 'ui/components/ValidationDisplay';
import { ClientDataObject } from 'models/type-system';

/**
 * A control which you can bind to a specific obj/path and supports getValue/setValue with
 * proper handling of callbacks. Another responsibility is to response to validateAllCallbacks and both prevent
 * wizard progress and display them.
 */
export class BoundControl extends ClientDataReferer {
  static propTypes = {
    showValidationErrors: PropTypes.bool,
    controlledValidation: PropTypes.bool,
    controlledShowValidation: PropTypes.bool,
    obj: PropTypes.instanceOf(ClientDataObject),
    path: PropTypes.string.isRequired,
  };

  static defaultProps = {
    showValidationErrors: true,
    controlledValidation: false,
    controlledShowValidation: false,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      showValidationErrors: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    if (!this.props.controlledValidation) {
      this.validateAllCallback = e => {
        if (!this.validationEnabled) {
          return;
        }

        e.isValid = e.isValid && this.getValidationErrors().length === 0;

        // Keep track of the first control being validated
        //   that has an error.
        if (!e.isValid && !e.firstControlWithError) {
          e.firstControlWithError = this;
        }

        if (e.showValidationErrors || e.forceShowValidationErrors) {
          this.setState({
            showValidationErrors: e.showValidationErrors,
          });
        }
      };
      this.clientDataHolder.validateAllCallbacks.subscribe(this.validateAllCallback);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    this.clientDataHolder.validateAllCallbacks.unsubscribe(this.validateAllCallback);
  }

  get validationEnabled() {
    return true;
  }

  readProps(props, context) {
    super.readProps(props, context);
    this.path = props.path;
    if (typeof props.obj !== 'undefined') {
      this.obj = props.obj;
    } else {
      this.obj = this.testator;
    }
  }

  getValue() {
    return this.obj.getValue(this.path);
  }

  getRawValue() {
    return this.obj.getRawValue(this.path);
  }

  getValidationErrors() {
    if (typeof this.path === 'undefined') {
      return [];
    }
    return this.obj.getValidationErrors(this.path);
  }

  setWithUpdate(method, value) {
    this.updateClientData(() => {
      this.obj[method](this.path, value);
    });
    this.obj.validate();
  }

  setValue(value) {
    this.setWithUpdate('setValue', value);
  }

  setRawValue(value) {
    this.setWithUpdate('setRawValue', value);
  }

  hasValidationErrors = () => {
    let showValidation;
    if (this.props.controlledValidation) {
      showValidation = this.props.controlledShowValidation;
    } else {
      showValidation = this.state.showValidationErrors;
    }
    return showValidation && this.getValidationErrors().length > 0;
  };

  renderValidation() {
    let showValidation;
    if (this.props.controlledValidation) {
      showValidation = this.props.controlledShowValidation;
    } else {
      showValidation = this.props.showValidationErrors && this.state.showValidationErrors;
    }
    if (!showValidation) {
      return null;
    }
    return <ValidationDisplay errors={this.getValidationErrors()} />;
  }
}
