import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import Card from 'ui/components/Card';
import { API } from 'API';
import { DocumentType } from 'utils/enums';
import { getEmail } from 'utils';
import { Urls } from 'urls';
import Popup from 'ui/components/Popup';
import PureButton from 'ui/components/pure-controls/PureButton';
import SectionSign from 'ui/sections/sign/SectionSign';

import './Storage.scss';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
export default class Storage extends ClientDataReferer {
  constructor(props, context) {
    super(props, context);

    this.state = {
      sections: [],
      mode: 'documents',
      evidenceUrls: {},
      showRevocationPopup: false,
    };
  }

  goToFile = async () => {
    this.updateClientData(() => {
      this.clientData.setSectionValid(SectionSign);
    });

    try {
      await this.clientDataHolder.persistClientData();
      this.props.history.push(Urls.file);
    } catch (error) {
      // TODO: Catch and display errors
      throw error;
    }
  };

  getSectionHeader = type => {
    switch (type) {
      case 'client':
        return `${this.clientData.firstNames} Documents`;
      case 'spouse':
        return `${this.clientData.spouse.firstNames} Documents`;
      case 'joint':
        return 'Joint Documents';
      default:
        return '';
    }
  };

  getOtherMode = () => (this.state.mode === 'documents' ? 'videos' : 'documents');

  switchModes = () => this.setState({ mode: this.getOtherMode() });

  async componentDidMount() {
    super.componentDidMount();

    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;

    const {
      data: { sections },
    } = await API.getNotarizationDocumentsInfo();
    const { data } = await API.getNotarizationCompletedSessionInfo();
    const d = new Date(data.notarizedAt * 1000);
    this.people = {
      ...data,
      notarizedAt: `
            ${
              d.getMonth() + 1
            }/${d.getDate()}/${d.getFullYear()}, ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}
            `.trim(),
    };

    for (let section of sections) {
      section.documents = section.documents
        .map(doc => DocumentType.displayName(doc.document_type))
        .filter(documentName => Boolean(documentName));

      section.section_type = this.getSectionHeader(section.section_type);
    }

    this.evidenceUrlsInterval = setInterval(this.getEvidenceUrls, 1000);
    this.getEvidenceUrls();
    this.setState({ sections });
  }

  get hasAllEvidenceUrls() {
    const {
      downloadDocumentsUrl,
      downloadVideoUrl,
      viewPdfUrl,
      viewVideoUrl,
    } = this.state.evidenceUrls;
    return downloadDocumentsUrl && downloadVideoUrl && viewPdfUrl && viewVideoUrl;
  }

  getEvidenceUrls = async () => {
    if (this.hasAllEvidenceUrls) {
      clearInterval(this.evidenceUrlsInterval);
      return;
    }

    const {
      data: { urls: evidenceUrls },
    } = await API.getNotarizationEvidenceUrls();

    // The evidence urls are signed S3 urls, so they are unique for each
    // request. To prevent re-rendering when we have loaded a url already,
    // we only update urls that are missing.
    for (let evidence of Object.keys(evidenceUrls)) {
      evidenceUrls[evidence] = this.state.evidenceUrls[evidence] || evidenceUrls[evidence];
    }

    this.setState({ evidenceUrls });
  };

  get hasBeenNotarized() {
    return this.serverData.hasNotarizedOnline;
  }

  get switcherCopy() {
    return this.state.mode === 'documents' ? 'View Signing Evidence' : 'View My Documents';
  }

  get evidenceTitle() {
    if (this.clientData.isPlanningForSpouse) {
      return `${this.clientData.firstName} and ${this.clientData.spouse.firstNames} Signing Evidence`;
    }

    return `${this.clientData.firstNames} Signing Evidence`;
  }

  renderPeopleInfo() {
    return (
      <div className="info-wrapper">
        <div>
          <span className="info-wrapper__header">Signer: </span>
          <span className="info-wrapper__body">{this.people.signer}</span>
        </div>
        <div>
          <span className="info-wrapper__header">Notary Name: </span>
          <span className="info-wrapper__body">{this.people.notary}</span>
        </div>
        <div>
          <span className="info-wrapper__header">Witness 1 Name: </span>
          <span className="info-wrapper__body">{this.people.witness1}</span>
        </div>
        <div>
          <span className="info-wrapper__header">Witness 2 Name: </span>
          <span className="info-wrapper__body">{this.people.witness2}</span>
        </div>
        <div>
          <span className="info-wrapper__header">Date and Time of Signing: </span>
          <span className="info-wrapper__body">{this.people.notarizedAt}</span>
        </div>
      </div>
    );
  }

  renderVideo() {
    const { evidenceUrls } = this.state;
    if (!evidenceUrls) {
      return (
        <React.Fragment>
          <div className="processing-video-wrapper">
            <div className="preserve-aspect-ratio">
              <div className="processing-video">
                <div className="processing-video__indicator" />
                <div>
                  <h4>Processing Video</h4>
                  <p>Please stand by...</p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <video className="video" controls>
          <source src={evidenceUrls.viewVideoUrl} type="video/mp4" />
        </video>
        <div>
          <a
            href={evidenceUrls.downloadVideoUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="icon icon--download eyebrow"
          >
            Download
          </a>
        </div>
      </React.Fragment>
    );
  }

  renderLeft() {
    const { mode, evidenceUrls } = this.state;

    if (mode === 'documents' || !this.hasBeenNotarized) {
      if (evidenceUrls.viewPdfUrl) {
        return <iframe src={evidenceUrls.viewPdfUrl} frameBorder="0" className="left" />;
      }
      return (
        <div className="left">
          <div className="preserve-aspect-ratio">
            <div className="processing-video">
              <div className="processing-video__indicator" />
              <div>
                <h4>Processing Documents</h4>
                <p>Please stand by...</p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="left left--padded">
        <h4>{this.evidenceTitle}</h4>
        {this.renderVideo()}
        {this.renderPeopleInfo()}
      </div>
    );
  }

  renderRight() {
    const { sections, mode, evidenceUrls } = this.state;

    if (mode === 'documents') {
      return (
        <React.Fragment>
          <p className="right__header">
            Here's your complete set of documents.
            <span className="desktop__item">Scroll down to see each one.</span>
            <span className="mobile__item">The following documents can be found in the PDF.</span>
          </p>

          <div className="mobile__download-button">
            <div className="button__right-part"></div>
            <a
              href={evidenceUrls.downloadDocumentsUrl}
              className="button__left-part"
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.clientData.name} - Documents.pdf
            </a>
          </div>

          {sections &&
            sections.map((section, i) => (
              <React.Fragment key={i}>
                <p className="right__list-header">{section.section_type}</p>
                <ul className="right__list">
                  {section.documents.map((d, j) => (
                    <li key={j}>
                      <span className="check" />
                      {d}
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            ))}

          <div className="right__revocation-box">
            <PureButton
              className="button--tertiary button--red"
              onClick={() => this.setState({ showRevocationPopup: true })}
            >
              Revoke My Documents
            </PureButton>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <p className="desktop__item right__header">
          The best proof that your documents were properly signed &mdash; by you!
        </p>

        <p className="desktop__item right__list-header">{this.evidenceTitle}</p>
        <ul className="desktop__item right__list">
          <li>
            <span className="check" />
            Video session
          </li>
          <li>
            <span className="check" />
            Notary Session Details
          </li>
        </ul>

        <p className="mobile__item right__header">
          The following pieces of evidence are available.
        </p>
        <div className="mobile__item">
          {this.renderVideo()}
          {this.renderPeopleInfo()}
        </div>
      </React.Fragment>
    );
  }

  get modeSwitchClass() {
    const results = ['bottom__mode-switch'];

    if (this.state.mode === 'videos') {
      results.push('bottom__mode-switch--low');
    }

    return results.join(' ');
  }

  get renderRevocationPopup() {
    const { showRevocationPopup } = this.state;
    const closeFunc = () => this.setState({ showRevocationPopup: false });

    if (!showRevocationPopup) {
      return null;
    }

    return (
      <div className="popup-fuzz">
        <Popup onCloseClick={closeFunc}>
          <h4 className="center">Revoke your documents</h4>

          <p className="center small">
            In order to revoke your documents, email us at{' '}
            <a style={{ textTransform: 'none' }} href={`mailto:${getEmail()}`}>
              {getEmail()}
            </a>
          </p>

          <PureButton className="button--primary button--pull-right" onClick={closeFunc}>
            OK
          </PureButton>
        </Popup>
      </div>
    );
  }

  render() {
    const { mode, evidenceUrls } = this.state;

    if (!this.hasBeenNotarized) {
      return <Redirect to={this.clientDataHolder.dashboardUrl} />;
    }

    return (
      <div className="storage-wrapper">
        {this.renderRevocationPopup}
        <Card className="card--storage storage">
          {this.renderLeft()}
          <div className="right">
            <div className="right__top">
              <Link to={this.clientDataHolder.dashboardUrl}>
                <div className="right__close" />
              </Link>
              {this.renderRight()}
            </div>

            <div className="right__bottom">
              <div className="bottom__label">Next Steps:</div>
              <div className={this.modeSwitchClass} onClick={this.switchModes}>
                {this.switcherCopy}
              </div>

              {mode === 'documents' && (
                <div className="bottom__icon-flow">
                  <a
                    href={evidenceUrls.viewPdfUrl}
                    className="icon icon--print"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Print
                  </a>

                  <a
                    href={evidenceUrls.downloadDocumentsUrl}
                    className="icon icon--download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download
                  </a>
                </div>
              )}

              <PureButton className="button--primary" onClick={this.goToFile}>
                Continue
              </PureButton>
            </div>
          </div>
        </Card>
      </div>
    );
  }
}
