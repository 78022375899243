import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Typography from '@wui/basics/typography';
import { withStyles } from '@material-ui/core/styles';

import SideBySide from 'material-ui/components/layout/SideBySide';

const styles = theme => ({
  root: {
    '& + &': {
      marginTop: 72,
    },
  },
  disabled: theme.layout.disabledNode,
});

class FieldGroup extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    variant: PropTypes.string,
    sideTitle: PropTypes.node,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    variant: 'h2',
  };

  render() {
    const { children, classes, disabled, sideTitle, variant } = this.props;

    const className = classNames(classes.root, {
      [classes.disabled]: disabled,
    });

    const disabledChildren = React.Children.map(children, child => {
      if (!child) {
        return child;
      }

      const childProps = {};

      // Only set disabled to true, not to false.
      //   This allows the children to set the value
      //   themselves as well.
      if (disabled) {
        childProps.disabled = true;
      }

      return React.cloneElement(child, childProps);
    });

    return (
      <div className={className}>
        <SideBySide
          left={
            <Typography variant={variant} component="span">
              {this.props.title}
            </Typography>
          }
          right={sideTitle}
        />
        {disabledChildren}
      </div>
    );
  }
}

export default withStyles(styles)(FieldGroup);
