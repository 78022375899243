import React from 'react';
import PropTypes from 'prop-types';

import { ProgressSections } from 'material-ui/components/progress';
import { getStateFullName, andList } from 'utils';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import AgentAddressInput from 'material-ui/components/AgentAddressInput';

export default class BeneficiaryAddressInput extends MuiWizardPage {
  static baseUrl = 'gift/address-required/';
  static progressPage = ProgressSections.YOUR_WISHES.ASSETS;

  static propTypes = {
    ...MuiWizardPage.propTypes,

    // This will also be visited in the Sign section, and there are some minor differences between the two
    inAssets: PropTypes.bool,
  };

  static defaultProps = {
    ...MuiWizardPage.defaultProps,
    inAssets: false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      ...this.state,
      skipStandardValidation: false,
    };

    // Check if we should show the skip button. We don't delay rendering
    //   for this because the layout is unchanged.
    this.analyticsEvents.dataLayer.push({
      event: 'willing.trigger.addresses-post-checkout-experiment',
    });
  }

  get subtext() {
    const states = this.relevantFullStateNames;
    const stateList = andList(states);

    return `${stateList} ${
      states.length > 1 ? 'require' : 'requires'
    } the address of anyone who is receiving real
        estate property, other than your spouse or minor children.`;
  }

  get relevantFullStateNames() {
    const results = [];

    for (const asset of this.testator.assets) {
      const isEligible =
        this.testator.getPrimaryBeneficiariesForAsset(asset).length === 0 ||
        asset.isOwnedByRelationship(this.testator.clientRelationship);
      if (isEligible && asset.needsBeneficiaryAddress) {
        results.push(getStateFullName(asset.address.state));
      }
    }

    return [...new Set(results)];
  }

  get shouldShowSkip() {
    return this.props.inAssets && this.context.experiments.bennyAddressPostCheckout;
  }

  // Using the callback to ensure that everything has been told to no longer validate before attempting transition
  skipPage = () => {
    // Loop inside of updateClientData to avoid constant rerendering
    this.updateClientData(() => {
      for (const person of this.testator.addressRequiredBeneficiaries) {
        // Reset all address values to avoid potentially invalid selections
        person.address.setRawValues({
          apt: '',
          city: '',
          zip: '',
          street: '',
          state: '',
        });
      }
    });

    this.setState({ skipStandardValidation: true }, () => super.goToNext());
  };

  renderPage() {
    const people = this.testator.addressRequiredBeneficiaries;
    const { skipStandardValidation } = this.state;

    return (
      <AgentAddressInput
        subtext={this.subtext}
        people={people}
        heading="Beneficiary Addresses"
        skipStandardValidation={skipStandardValidation}
      />
    );
  }
}
