export const MINIMUM_PASSWORD_LENGTH = 7;

// Validate that a password meets our minimum security requirements.
//   The `errors` argument can be an array, object or null. If it is
//   an array, the error will be appended to the array. If it is
//   an object, the error will be set as the value of the `password`
//   property. If it is null, the error will be returned.
//   The `modifier` argument can be used to modify the description
//   of the password being validated.
export function validatePasswordStrength(password, errors, modifier = '') {
  // Ensure the modifier has a trailing space so the
  //   message is rendered correctly.
  const spacedModifier = modifier ? `${modifier.trim()} ` : '';

  // Depending on the type of result output, setting
  //   the error requires a different operation.
  function set(error) {
    if (!errors) {
      return error;
    }

    if (Array.isArray(errors)) {
      errors.push(error);
    } else {
      errors.password = error;
    }

    return undefined;
  }

  if (!password) {
    return set(`Please enter a ${spacedModifier}password.`);
  }

  if (password.length < MINIMUM_PASSWORD_LENGTH) {
    return set(
      `Please enter a ${spacedModifier}password with at least ${MINIMUM_PASSWORD_LENGTH} characters.`,
    );
  }

  const hasAlphabetic = /[a-zA-Z]/;
  const hasNumeric = /[0-9]/;

  if (!hasAlphabetic.test(password) || !hasNumeric.test(password)) {
    return set(
      `Please enter a ${spacedModifier}password with both alphabetic and numeric characters.`,
    );
  }

  return undefined;
}

export function validateEmail(string) {
  /* eslint-disable */
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /* eslint-enable */
  return regex.test(string || '');
}
