import React from 'react';
import PropTypes from 'prop-types';
import { joinClass } from 'utils';

import './PureButton.scss';

export default class PureButton extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    dataPath: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => null,
  };

  render() {
    const className = joinClass('button', this.props.className);

    return (
      <button
        onClick={this.props.onClick}
        className={className}
        data-path={this.props.dataPath}
        id={this.props.id}
        disabled={this.props.disabled}
      >
        {this.props.children}
      </button>
    );
  }
}
