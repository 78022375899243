import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import SectionTabs from '@wui/layout/sectionTabs';
import TabDivider from '@wui/layout/tabDivider';
import Grid from '@wui/layout/grid';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';

import { ReactComponent as SignOnline } from '@a/images/sign-online.svg';

const TechCheckHeader = ({ selectedTab }) => {
  const tabs = ['Video', 'Speaker', 'Microphone'].map(checkType => ({
    name: `${checkType} Check`,
  }));

  return (
    <>
      <Grid container direction="row" spacing={2} wrap="nowrap" justify="space-between">
        <Grid item>
          <Typography variant="h2">System Check</Typography>

          <Spacer v={8} />

          <Typography variant="intro">
            We need to perform a systems check to make sure you have a great online signing
            experience. We will test your browser compatibility, internet connection, video quality,
            and audio quality.
          </Typography>
        </Grid>

        <Grid item>
          <SignOnline />
        </Grid>
      </Grid>

      <Spacer v={32} />

      <SectionTabs tabs={tabs} selected={selectedTab} />

      <TabDivider absolute noMargin />

      <Spacer v={64} />
    </>
  );
};

TechCheckHeader.propTypes = {
  selectedTab: PropTypes.number,
};

TechCheckHeader.defaultProps = {
  selectedTab: 0,
};

export default observer(TechCheckHeader);
