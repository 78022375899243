import React from 'react';
import WizardPage from 'ui/components/wizard/WizardPage';
import { ProgressSections } from 'material-ui/components/progress';
import Layout from 'material-ui/components/layout/Layout';
import WuiApp from 'material-ui/WuiApp';
import renderWuiSaveButton from '@c/layout/renderWuiSaveButton';
import { ForSpouseContext } from 'contexts';

export default class MuiWizardPage extends WizardPage {
  static enterNavigation = false;
  static isMaterial = true;
  static progressPage = null;
  static progressSection = null;
  static wide = false;
  static layoutProps = {};

  // Most of our pages are going to be the only one in their sections, making this a sane default
  static baseUrl = '';

  // Override in inheriting pages to change default section, or use the static member
  get progressSection() {
    // Clone it to "unfreeze" the object, so we can get ride of  guardians
    const section = Object.assign({}, ProgressSections.YOUR_WISHES);
    if (!this.clientData.hasMinorChildren) {
      delete section.GUARDIANS;
    }

    return section;
  }

  renderSaveButton = (buttonProps, state) => {
    const disabled = this.saveDisabled(state.nextPending);
    const tooltip =
      disabled && !state.nextPending ? 'Please ensure all values are filled in above.' : null;
    const props = { tooltip, ...buttonProps, disabled };

    return renderWuiSaveButton(props, state);
  };

  // Override in a child page to conditionally hide
  get showTestatorSwitcher() {
    return true;
  }

  renderPageContent() {
    const { appLocation: sectionAppLocation } = this.props;
    const { appLocation: pageAppLocation, progressPage, wide, layoutProps } = this.constructor;
    const progressSection = this.constructor.progressSection || this.progressSection;

    const additionalProps = {
      appLocation: pageAppLocation || sectionAppLocation,
      progressPage,
      progressSection,
      wide,
    };

    return (
      <ForSpouseContext.Provider value={this.constructor.forSpouse}>
        <WuiApp wrapContent={false}>
          <Layout
            testatorSwitcher={this.showTestatorSwitcher}
            buttonRenderer={renderWuiSaveButton}
            showBackButton={true}
            {...additionalProps}
            {...layoutProps}
          >
            {this.renderPage()}
          </Layout>
        </WuiApp>
      </ForSpouseContext.Provider>
    );
  }
}
