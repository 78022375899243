import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Urls from 'urls';
import { API } from 'API';
import { noOp } from 'utils/index';
import Form from '@wui/layout/form';
import Grid from '@wui/layout/grid';
import Button from '@wui/input/button';
import Textbox from '@wui/input/textbox';
import DimensionLimiter from '@wui/layout/dimensionLimiter';
import { validatePasswordStrength } from '@willing-shared/utils/validation';
import {
  noPasswordError,
  passwordMatchError,
  wrongPasswordError,
  passwordUnchangedError,
} from 'utils/constants/errors';

import { useGlobalContext, useResetState } from 'hooks';

const defaultErrorState = {
  currentPassword: null,
  newPassword: null,
  confirmPassword: null,
  genericError: null,
};

const ChangePassword = ({ onSuccess }) => {
  const {
    clientData: { email },
    history,
    clientDataHolder,
  } = useGlobalContext();

  const [currentPassword, setCurrentPassword, resetCurrentPassword] = useResetState('');
  const [newPassword, setNewPassword, resetNewPassword] = useResetState('');
  const [confirmPassword, setConfirmPassword, resetConfirmPassword] = useResetState('');
  const [processing, setProcessing] = useResetState(false);
  const [errors, setErrors, resetErrors] = useResetState(defaultErrorState);

  const setError = errorUpdate => {
    setErrors(Object.assign({}, defaultErrorState, errorUpdate));
    setProcessing(false);
  };

  const onSubmit = async event => {
    event.preventDefault();

    if (processing) {
      return;
    }

    if (!currentPassword) {
      setError({ currentPassword: noPasswordError });
      return;
    }
    const passwordStrengthError = validatePasswordStrength(newPassword);
    if (passwordStrengthError) {
      setError({ newPassword: passwordStrengthError });
      return;
    }
    if (currentPassword === newPassword) {
      setError({ newPassword: passwordUnchangedError });
      return;
    }
    if (newPassword !== confirmPassword) {
      setError({ confirmPassword: passwordMatchError });
      return;
    }

    setProcessing(true);
    try {
      await API.changePassword(email, currentPassword, newPassword);
    } catch (error) {
      const genericError = error.getErrorMessage({
        wrong_password: wrongPasswordError,
      });
      setError({ genericError });
      // The user has failed too many times and the backend has logged them out
      if (error.errorCode === 'not_authenticated') {
        clientDataHolder.onLogoutSuccessful();
      }
      return;
    }

    resetCurrentPassword();
    resetNewPassword();
    resetConfirmPassword();
    setProcessing(false);
    resetErrors();
    onSuccess();
  };

  const resetPassword = () => history.push(Urls.resetPassword);

  return (
    <Form error={errors.genericError} onSubmit={onSubmit}>
      <DimensionLimiter h={400}>
        <Textbox
          label="Current Password"
          type="password"
          name="currentPassword"
          error={errors.currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          value={currentPassword}
        />
        <Textbox
          label="New Password"
          type="password"
          name="newPassword"
          error={errors.newPassword}
          onChange={e => setNewPassword(e.target.value)}
          value={newPassword}
        />
        <Textbox
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          error={errors.confirmPassword}
          onChange={e => setConfirmPassword(e.target.value)}
          value={confirmPassword}
        />
      </DimensionLimiter>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm="auto">
          <Button type="submit" variant="contained" color="primary" processing={processing}>
            Change Password
          </Button>
        </Grid>
        <Grid item xs={12} sm="auto">
          <Button variant="outlined" processing={processing} onClick={resetPassword}>
            Forgot Password?
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

ChangePassword.propTypes = {
  onSuccess: PropTypes.func,
};

ChangePassword.defaultProps = {
  onSuccess: noOp,
};

export default observer(ChangePassword);
