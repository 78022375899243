import { AgentOwner, AgentType, MaritalStatus } from './enums';
import { Lineage, Relationship } from 'models/client-data/enums';
import { Spouse } from './spouse';
import { debugLog } from 'debug';
import { USAddress } from 'models/client-data/mixins';

function ensureSpouseFieldsPresent(clientData, pristine) {
  // If the marital status changed from married to anything else
  //   and information was entered for the spouse, then we need to
  //   clear the spouse information in order to create a new person.
  //   If we are changing from something else to married, we should
  //   keep the existing record and just add the spouse's information
  //   to it since the spouse record is already empty in that situation.
  //   If changing between anything that doesn't have a spouse, then we
  //   also keep the same empty record.

  const spouseWasSet = pristine.raw.spouse.name;
  const changed = clientData.maritalStatus !== pristine.maritalStatus;
  const wasMarried = pristine.maritalStatus === MaritalStatus.MARRIED;

  if (changed && wasMarried && spouseWasSet) {
    // The backend will create a new record before it deletes
    //   the old record. Since the table in the database has
    //   a unique constraint on the number of Spouse records
    //   that can be associated with a ClientData record, we
    //   need to keep the existing row, but clear the data.
    clientData.setRawValue(
      'spouse',
      new Spouse(
        {
          pk: pristine.spouse.pk,
        },
        true,
      ),
    );

    clientData.raw.spouse.spouse = clientData;
    clientData.setRawValue('isPlanningForSpouse', false);
  }
}

function ensureChildrenAbsentIfNotParent(clientData) {
  if (!clientData.eitherTestatorIsParent) {
    clientData.children.splice(0, clientData.children.length);
  }
}

function ensureChildrenNotMinorsIfDisabled(clientData) {
  // If the user explicitly selected that the children cannot
  //   be minors, then set them as adults.
  if (clientData.childrenCanBeMinors === false) {
    clientData.children.forEach(c => c.setAsAdult());
  }
}

function ensureChildrenParents(clientData) {
  clientData.children.forEach(child => {
    // If the user explicitly chose that all children
    //   have the same parents, set the lineage to
    //   both parents if they have both indicated
    //   that they are parents.
    if (
      clientData.childrenHaveSameParents === true &&
      clientData.isParent &&
      clientData.spouse.isParent
    ) {
      child.setRawValue('lineage', Lineage.BOTH);

      // If the spouse is a parent and the client is not, then
      //   all children must belong to the spouse.
    } else if (clientData.spouse.isParent && !clientData.isParent) {
      child.setRawValue('lineage', Lineage.SPOUSE);

      // If the spouse is explicitly set to not be a parent and
      //   the client is, then the children must belong to the
      //   client.
    } else if (clientData.spouse.isParent === false && clientData.isParent) {
      child.setRawValue('lineage', Lineage.CLIENT);

      // If not planning for spouse and children do not have
      //   same parents, then client is the parent.
    } else if (
      clientData.isParent &&
      clientData.spouse.isParent &&
      clientData.childrenHaveSameParents === false &&
      !clientData.isPlanningForSpouse
    ) {
      child.setRawValue('lineage', Lineage.CLIENT);
    }
  });
}

// When a child is removed, all existing instances of that child should have their
// relationships cleared as well
function ensureNonChildRelationships(clientData) {
  const childUUIDs = new Set(clientData.children.map(c => c.personUUID));

  clientData.walkPeople(null, person => {
    if (!childUUIDs.has(person.personUUID)) {
      if (person.clientRelationship === Relationship.CHILD) {
        person.setRawValue('clientRelationship', '');
      }
      if (person.spouseRelationship === Relationship.CHILD) {
        person.setRawValue('spouseRelationship', '');
      }
    }
  });
}

function removeGiftsOnBundle(clientData) {
  if (!clientData.needsResidualJointAsset) {
    if (clientData.residualJointAsset.removePrimaryBeneficiariesByOwner(AgentOwner.CLIENT).length) {
      debugLog('Removing client from RJA', false);
    }
  }

  if (!clientData.spouse.needsResidualJointAsset) {
    if (clientData.residualJointAsset.removePrimaryBeneficiariesByOwner(AgentOwner.SPOUSE).length) {
      debugLog('Removing spouse from RJA', false);
    }
  }
}

function removeDisinheritedBeneficiaries(clientData) {
  const disinherited = clientData.children.filter(c => c.disinherited).map(c => c.personUUID);

  // No need to loop through all beneficiaries if
  //   there are no disinherited children.
  if (!disinherited.length) {
    return;
  }

  const removeDisinherited = (list, primary = true) => {
    for (let i = 0; i < list.length; i++) {
      const beneficiary = list[i];

      if (disinherited.includes(beneficiary.personUUID)) {
        list.splice(i--, 1);
      } else if (primary) {
        removeDisinherited(beneficiary.alternateBeneficiaries, false);
      }
    }
  };

  clientData.assets.forEach(a => removeDisinherited(a.primaryBeneficiaries));
}

function prepopulateVerificationAddress(clientData, addressMap) {
  for (const obj of [clientData, clientData.spouse]) {
    const address = addressMap[obj.personUUID] || addressMap.primary;
    if (address && !obj.verificationAddress.addressComplete) {
      obj.setRawValue('verificationAddress', new USAddress(address.raw));
    }
  }
}

const copyAddress = (object, address) => {
  const addressObj = object.constructor.allFields.address.fromPOJO(address.toPOJO());
  object.setRawValue('address', addressObj);
};

export function mirrorAddresses(clientData) {
  // The order in which we add these dictates the priority if a
  //   change is made. We ask for the address in the assets
  //   section if the person is a beneficiary so we add
  //   beneficiaries last.
  const copyFrom = clientData.addressRequiredHealthcareAgents;
  if (clientData.isPlanningForSpouse) {
    copyFrom.push(...clientData.spouse.addressRequiredHealthcareAgents);
  }
  copyFrom.push(...clientData.addressRequiredBeneficiaries);
  if (clientData.isPlanningForSpouse) {
    copyFrom.push(...clientData.spouse.addressRequiredBeneficiaries);
  }
  const addressMap = {};

  // Get address of primary residence - some may have all marked no, so then grab the first real estate
  const primaryResidence = clientData.primaryResidence;

  if (primaryResidence) {
    addressMap.primary = primaryResidence.address;
  }

  const updateAddressMap = person => {
    if (person.hasAddress) {
      addressMap[person.personUUID] = person.address;
    }
  };

  // We go through the people we are copying from last so that
  //   updating the address of one of these people will copy
  //   to all instances of the person.
  clientData.walkPeople(null, updateAddressMap);
  copyFrom.forEach(updateAddressMap);

  // If p is not a child then isMinor will be undefined
  const spouseOrMinor = p =>
    [Relationship.SELF, Relationship.SPOUSE].includes(p.clientRelationship) || p.isMinor;
  const copyFn = person => {
    if (spouseOrMinor(person) && addressMap.primary) {
      copyAddress(person, addressMap.primary);
    } else if (addressMap[person.personUUID]) {
      copyAddress(person, addressMap[person.personUUID]);
    }
  };

  clientData.walkBeneficiaries(copyFn);
  clientData.healthcareAgents.forEach(copyFn);
  prepopulateVerificationAddress(clientData, addressMap);
}

function copyFieldFromPeople(clientData, field, people) {
  const fieldMap = {};

  const updateFieldMap = benny => {
    if (benny[field]) {
      fieldMap[benny.personUUID] = benny[field];
    }
  };

  clientData.walkPeople(null, updateFieldMap);
  people.forEach(updateFieldMap);

  const copyFn = person => {
    if (fieldMap[person.personUUID]) {
      person.setRawValue(field, fieldMap[person.personUUID]);
    }
  };

  clientData.walkBeneficiaries(copyFn);
}

function mirrorRelationships(clientData) {
  copyFieldFromPeople(
    clientData,
    'clientRelationship',
    clientData.relationshipRequiredBeneficiaries,
  );
  copyFieldFromPeople(clientData, 'gender', clientData.relationshipRequiredBeneficiaries);
  if (clientData.isPlanningForSpouse) {
    copyFieldFromPeople(
      clientData,
      'spouseRelationship',
      clientData.spouse.relationshipRequiredBeneficiaries,
    );
    copyFieldFromPeople(clientData, 'gender', clientData.spouse.relationshipRequiredBeneficiaries);
  }
}

function mirrorDob(clientData) {
  let dobMap = {};

  for (const child of clientData.children) {
    dobMap[child.personUUID] = child.getRawValue('dob').deepCopy();
  }

  if (clientData.isMarried) {
    dobMap[clientData.spouse.personUUID] = clientData.spouse.getRawValue('dob').deepCopy();
  }

  const copyFn = person => {
    if (dobMap[person.personUUID]) {
      person.setRawValue('dob', dobMap[person.personUUID]);
    }
  };

  if (Object.keys(dobMap).length > 0) {
    clientData.walkBeneficiaries(copyFn);
  }
}

function ensurePeopleNameConsistency(clientData) {
  const people = {};
  people[clientData.personUUID] = clientData;
  if (clientData.maritalStatus === MaritalStatus.MARRIED) {
    people[clientData.spouse.personUUID] = clientData.spouse;
  }
  for (const child of clientData.children) {
    people[child.personUUID] = child;
  }

  clientData.walkPeople(null, person => {
    const truePerson = people[person.personUUID];
    if (!truePerson) {
      return;
    }
    if (person.name !== truePerson.name) {
      person.setRawValue('name', truePerson.name);
    }
  });
}

function removeDuplicateAlternatesFromGroup(group) {
  for (const owner of [AgentOwner.CLIENT, AgentOwner.SPOUSE]) {
    const primary = group.find(agent => agent.type === AgentType.PRIMARY && agent.owner === owner);
    if (!primary) {
      continue;
    }
    for (let i = group.length - 1; i >= 0; i--) {
      const isDuplicate =
        group[i].type === AgentType.ALTERNATE &&
        group[i].owner === owner &&
        group[i].personUUID === primary.personUUID;
      if (isDuplicate) {
        group.splice(i, 1);
      }
    }
  }
}

function removeDuplicateAlternates(clientData) {
  for (const group of [clientData.guardians, clientData.healthcareAgents, clientData.executors]) {
    removeDuplicateAlternatesFromGroup(group);
  }
}

function unsetAvoidProbateIfNoRealEstate(clientData) {
  if (clientData.avoidProbate && !clientData.hasOwnRealEstate) {
    clientData.setRawValue('avoidProbate', false);
  }

  if (clientData.spouse.avoidProbate && !clientData.spouse.hasOwnRealEstate) {
    clientData.spouse.setRawValue('avoidProbate', false);
  }
}

function removeSpouseFromRealEstateOwnershipIfNotPlanningForSpouse(clientData) {
  // If we are planning for the spouse, then the spouse
  //   is allowed to be an owner of assets, so do
  //   nothing.
  if (clientData.isPlanningForSpouse) {
    return;
  }

  // First remove the spouse from any assets that they own. Don't remove
  //   them if they must be an owner (regardless of whether or not the
  //   testator is planning for the spouse).
  const ownedBySpouse = clientData.assets.filter(
    a => a.isOwnedBySpouse && !a.realEstateMustBeOwnedByBoth,
  );
  ownedBySpouse.forEach(a => a.removeOwnerByRelationship(Relationship.SPOUSE));

  // If the client doesn't own any of those assets, then they
  //   were just owned by the spouse or by the spouse and
  //   others. Either way, those assets can be removed because
  //   they no longer have an owner we are planning for.
  const unowned = ownedBySpouse.filter(a => !a.isOwnedByClient);
  unowned.forEach(a => clientData.removeAssetByInstance(a));
}

function ensureRealEstateOwnershipAllowed(clientData) {
  clientData.realEstates.forEach(r => {
    if (!r.ownershipTypeValid(clientData)) {
      r.clearOwnership(clientData);
    }
  });
}

export function applyConsistencyChecks(clientData, pristine) {
  ensureSpouseFieldsPresent(clientData, pristine);
  ensureChildrenAbsentIfNotParent(clientData);
  ensureChildrenNotMinorsIfDisabled(clientData);
  ensureChildrenParents(clientData);
  removeSpouseFromRealEstateOwnershipIfNotPlanningForSpouse(clientData);
  ensureRealEstateOwnershipAllowed(clientData);
  removeGiftsOnBundle(clientData);
  removeDisinheritedBeneficiaries(clientData);
  mirrorAddresses(clientData);
  mirrorRelationships(clientData);
  // this needs to come after mirrorRelationships or it undoes the changes.
  ensureNonChildRelationships(clientData);
  mirrorDob(clientData);
  ensurePeopleNameConsistency(clientData);
  removeDuplicateAlternates(clientData);
  unsetAvoidProbateIfNoRealEstate(clientData);
}
