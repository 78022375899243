import UUID4 from 'uuid/v4';
import { WString } from 'models/types';
import { namePattern } from 'utils';

import { validateEmail } from '@willing-shared/utils/validation';

export class WUSZip extends WString {
  validate(value, addError) {
    super.validate(value, addError);
    if (value.length !== 5) {
      addError('Please enter a valid 5 digit ZIP code');
    }
  }
}

export class WFullName extends WString {
  validate(value, addError) {
    super.validate(value, addError);

    if (!namePattern.test(value)) {
      addError('Please enter a properly capitalized full name.\nFor example: John Smith.');
    }

    if (value.toLowerCase().includes(' and ') || value.toLowerCase().includes(' or ')) {
      addError('Please enter only a single name.');
    }
  }
}

export class WEmail extends WString {
  convert(value) {
    if (value) {
      return value.trim();
    }

    return value;
  }

  validate(value, addError) {
    super.validate(value, addError);

    if (!validateEmail(value)) {
      addError('Please enter a valid email address.');
    }
  }
}

export class WEmailOrEmpty extends WEmail {
  validate(value, addError) {
    super.validate(value, addError);

    if (value === '') {
      return;
    }

    if (!validateEmail(value)) {
      addError('Please enter a valid email address.');
    }
  }
}

class WPhoneNumberOrEmpty extends WString {
  validate(value, addError) {
    super.validate(value, addError);

    if (value) {
      value = value.replace(/[-() ]/g, '');
      const match = value.match(/\d{10}/g);
      // TODO: Probably better to add ^ and $ to the regex instead of comparing to match[0]
      if (match === null || value !== match[0]) {
        addError('Please provide a 10-digit phone number.');
      }
    }
  }
}

export class WPhoneNumber extends WPhoneNumberOrEmpty {
  validate(value, addError) {
    super.validate(value, addError);

    if (value === '') {
      addError('Please provide a 10-digit phone number.');
    }
  }
}

export class WNonEmptyString extends WString {
  validate(value, addError) {
    super.validate(value, addError);
    if (!value || value.length === 0) {
      addError('Please enter a value.');
    }
  }
}

export class WUUID extends WString {
  constructor() {
    super(UUID4);
  }

  validate(value, addError) {
    super.validate(value, addError);
    if (value.length !== 36) {
      addError('Please enter a value.');
    }
  }
}

export class WCounty extends WNonEmptyString {
  convert(value) {
    const removeSuffix = ' county';
    if (value.toLowerCase().endsWith(removeSuffix)) {
      return value.slice(0, -removeSuffix.length);
    }
    return value;
  }
}
