import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import Hidden from '@material-ui/core/Hidden';

import {
  IconPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@wui/layout/expansionPanels';
import MobileCollapsedList from '@wui/layout/mobileCollapsedList';
import Spacer from '@wui/layout/spacer';
import Typography from '@wui/basics/typography';
import MobileListContainer from '@wui/layout/mobileListContainer';
import { DocumentBundle } from 'utils/enums';
import { getIncludedBenefitsForDocumentBundle, getBundleDescription } from './benefits';
import { useGlobalContext, useScreenSize } from 'hooks';

const BenefitsDisplay = ({ bundle }) => {
  const { clientData } = useGlobalContext();
  const { isTabletOrSmallerDisplay } = useScreenSize();
  const benefits = getIncludedBenefitsForDocumentBundle(bundle, clientData);

  return (
    <React.Fragment>
      <Typography variant="h2">{DocumentBundle.displayName(bundle)}</Typography>

      <Typography variant="intro">{getBundleDescription(bundle, clientData)}</Typography>

      <Spacer v={30} />

      <div>
        <MobileListContainer title="Benefits">
          <Hidden smDown>
            <Typography variant="subtitle1">Benefits</Typography>
          </Hidden>
          <MobileCollapsedList>
            {benefits.map(({ title, subitems, icon }, idx) => (
              <IconPanel icon={icon} key={idx}>
                <ExpansionPanelSummary>
                  <Typography variant={isTabletOrSmallerDisplay ? 'caption' : 'intro'}>
                    {title}
                  </Typography>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <ul style={{ padding: 0, margin: 0 }}>
                    {subitems.map((item, itemIdx) => (
                      <li key={itemIdx}>
                        <Typography variant={isTabletOrSmallerDisplay ? 'caption' : 'medium'}>
                          {item}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </ExpansionPanelDetails>
              </IconPanel>
            ))}
          </MobileCollapsedList>
        </MobileListContainer>
      </div>
    </React.Fragment>
  );
};

BenefitsDisplay.propTypes = {
  bundle: PropTypes.string.isRequired,
};

export default observer(BenefitsDisplay);
