import React from 'react';

import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage';
import { ProgressSections } from 'material-ui/components/progress';
import { getStateFullName, andList } from 'utils';

import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup';
import RelationshipInput from 'material-ui/components/RelationshipInput';

export default class BeneficiaryRelationshipInput extends MuiWizardPage {
  static progressPage = ProgressSections.YOUR_WISHES.ASSETS;
  static baseUrl = 'gift/relationships-required/';

  get caption() {
    const states = this.relevantFullStateNames;

    return `${andList(states)} ${
      states.length > 1 ? 'require' : 'requires'
    } your relationship to anyone
        who may be receiving your real estate, other than your spouse.`;
  }

  get relevantFullStateNames() {
    const results = [];

    for (const asset of this.testator.assets) {
      const assetIsEligible =
        this.testator.getPrimaryBeneficiariesForAsset(asset).length === 0 ||
        asset.isOwnedByRelationship(this.testator.clientRelationship);
      if (assetIsEligible && asset.needsBeneficiaryRelationship) {
        results.push(getStateFullName(asset.address.state));
      }
    }

    return results.filter((v, i, a) => a.indexOf(v) === i);
  }

  renderPage() {
    const people = this.testator.relationshipRequiredBeneficiaries;
    const subHeading = this.testator.isMarried ? this.testator.name : '';

    return (
      <SpacedFieldGroup
        masterHeading="Beneficiary Relationships"
        subHeading={subHeading}
        caption={this.caption}
      >
        {people.map((p, i) => (
          <RelationshipInput obj={p} sideTitle={`${i + 1} of ${people.length}`} key={i} />
        ))}
      </SpacedFieldGroup>
    );
  }
}
