import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Popup from 'ui/components/Popup';
import { DocumentBundle } from 'utils/enums';
import CheckoutPicker from 'ui/CheckoutPicker';
import PureButton from 'ui/components/pure-controls/PureButton';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';

export default class WarningPopup extends ClientDataReferer {
  static propTypes = {
    onCloseClick: PropTypes.func,
    open: PropTypes.bool,
  };

  static defaultProps = {
    open: false,
  };

  render() {
    const { open, onCloseClick } = this.props;
    if (!open) {
      return null;
    }

    const noopfn = () => null;
    const days = this.serverData.dataExpiresAfterDays;
    const changePeriod = DocumentBundle.changePeriod(DocumentBundle.SINGLE);

    return (
      <div className="popup-fuzz">
        <Popup onCloseClick={noopfn} showCloseIcon={false} className="center">
          <h4>Welcome back!</h4>
          <p className="small">
            We wanted to remind you that you have {days} days remaining to purchase your estate
            planning documents. If you do not pay and finish, after {days} days your account will be
            reset.
          </p>

          <p className="small">
            If you pay and finish, you can print and sign your documents whenever you're ready, and
            you can make changes for no charge for up to {changePeriod}.
          </p>

          <div className="button-flow button-flow--right">
            <PureButton
              onClick={onCloseClick}
              className="button--tertiary"
              dataPath="purchaseLater"
            >
              Continue and purchase later
            </PureButton>

            <Link to={CheckoutPicker.url} onClick={onCloseClick}>
              <PureButton onClick={noopfn} className="button--primary">
                Pay Now
              </PureButton>
            </Link>
          </div>
        </Popup>
      </div>
    );
  }
}
