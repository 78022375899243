import React from 'react';
import { Redirect } from 'react-router-dom';
import { Urls } from 'urls';
import { formatMoney } from 'utils';
import { CheckoutElement } from 'ui/components/payment/CheckoutElement';
import ClientDataReferer from 'ui/components/client-data/ClientDataReferer';
import SectionDocuments from 'ui/sections/documents/SectionDocuments';
import SubscriptionPicker from 'ui/SubscriptionPicker';
import SubscriptionPlanPricing from 'ui/pricing/SubscriptionPlanPricing';
import { SubscriptionPlan } from 'utils/enums';

import './SubscriptionCheckout.scss';
import './Checkout.scss';
import { DEFAULT_DOCUMENT_TITLE } from 'utils/constants';
export default class SubscriptionCheckout extends ClientDataReferer {
  static url = Urls.subscriptionCheckout;

  constructor(props, context) {
    super(props, context);

    const params = new URLSearchParams(this.props.location.search);
    const subscriptionPlan = params.get('subscriptionPlan');

    this.state = {
      subscriptionPlan: subscriptionPlan,
      priceInfo: { discountedPrice: 0 },
      subscriptionPlanAlreadyOwned: false,
      subscriptionPlanStatusUpdated: false,
      isPaid: false,
    };
  }

  updatePriceInfo = priceInfo => {
    /* NOTE: CheckoutForm calls this method on componentDidMount */
    const newState = { priceInfo };
    // update subscriptionPlanAlreadyOwned only on first call so we can get to the success screen
    if (!this.state.subscriptionPlanStatusUpdated) {
      Object.assign(newState, {
        subscriptionPlanAlreadyOwned: !priceInfo.discountedPrice,
        subscriptionPlanStatusUpdated: true,
      });
    }
    this.setState(newState);
  };

  get subscriptionPlanName() {
    return SubscriptionPlan.displayName(this.state.subscriptionPlan);
  }

  get subscriptionPlanPrice() {
    return formatMoney(this.state.priceInfo.subscriptionPlanPrice / 100);
  }

  get firstPaymentDate() {
    const { trialPeriodDays } = this.state.priceInfo;
    const paymentDate = new Date();
    paymentDate.setDate(paymentDate.getDate() + trialPeriodDays);

    return paymentDate.toLocaleDateString('en-US');
  }

  componentDidMount() {
    super.componentDidMount();
    document.title = this.props.title || DEFAULT_DOCUMENT_TITLE;
  }

  renderOrderSummary() {
    return (
      <React.Fragment>
        <div className="order-summary__header">Order Summary</div>
        <div className="order-summary__body">
          <div className="body__content">
            <div className="body__highlight">{this.subscriptionPlanName}</div>
            <div>{this.subscriptionPlanPrice} / Year</div>
          </div>
          <div className="body__content body__content--notice">
            {this.subscriptionPlanPrice} charged today. Cancel any time.
          </div>
        </div>
        <div className="order-summary__footer">
          <div>Charged today</div>
          <div>$0.00</div>
        </div>
        <div className="order-summary__body">
          <div className="body__content">
            <div>Charged on {this.firstPaymentDate}</div>
            <div>{this.subscriptionPlanPrice}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { subscriptionPlan, priceInfo, subscriptionPlanAlreadyOwned } = this.state;

    if (!this.serverData.subscriptionPlan) {
      return <Redirect to={Urls.planDashboard} />;
    }

    if (subscriptionPlanAlreadyOwned) {
      return <Redirect to={SectionDocuments.reviewUrl} />;
    }

    if (!subscriptionPlan) {
      return <Redirect to={SubscriptionPicker.url} />;
    }

    return (
      <div>
        <div className="close-bar" />
        <div className="checkout-wrapper">
          <div className="checkout-container checkout-container--subscription">
            <CheckoutElement
              subscriptionPlan={subscriptionPlan}
              updatePriceInfo={this.updatePriceInfo}
              setPaid={() => this.setState({ isPaid: true })}
            />
            <div>
              <div className="order-summary">
                {priceInfo.subscriptionPlanPrice ? this.renderOrderSummary() : ''}
              </div>
              <div className="list-box list-box__checkout">
                <ul>
                  <li className="unchecked">Benefits</li>
                  {Object.keys(SubscriptionPlanPricing.BENEFITS_PLUS).map(benefit => (
                    <li key={benefit}>{benefit}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
