import React from 'react';
import { MaritalStatus, Relationship } from 'models/client-data/enums';
import { andList, getStateFullName } from 'utils';
import MuiWizardPage from 'material-ui/components/layout/MuiWizardPage.jsx';
import Typography from '@wui/basics/typography';
import SpacedFieldGroup from 'material-ui/components/layout/SpacedFieldGroup.jsx';

export default class Info extends MuiWizardPage {
  static baseUrl = '';
  static layoutProps = { hideRight: true };
  static wide = true;

  get showTestatorSwitcher() {
    return false;
  }

  constructor(props, context) {
    super(props, context);

    const assets = [];

    for (const uuid of Object.keys(this.serverData.assets)) {
      if (this.serverData.assets[uuid].qcdCreationMethod === 'attorney') {
        assets.push(this.clientData.getAssetByUUID(uuid));
      }
    }

    this.assets = assets;
  }

  getAllBeneficiaries = asset => {
    const beneficiaries = [];

    beneficiaries.push(...this.testator.getPrimaryBeneficiariesForAsset(asset));

    if (this.testator.isPlanningForSpouse) {
      beneficiaries.push(...this.testator.spouse.getPrimaryBeneficiariesForAsset(asset));
    }

    return [
      ...new Set(
        beneficiaries
          .filter(b => ![Relationship.SPOUSE, Relationship.SELF].includes(b.clientRelationship))
          .map(b => b.name),
      ),
    ];
  };

  get numProperties() {
    return this.assets.length;
  }

  get properties() {
    return this.numProperties > 1 ? 'properties' : 'property';
  }

  get titleCopy() {
    return `Transferring your ${this.properties} into your trust.`;
  }

  get whatCopy() {
    const andSpouse =
      this.clientData.maritalStatus === MaritalStatus.MARRIED
        ? `and ${this.clientData.spouse.firstName}`
        : '';
    let initialBeneficiaries = this.getAllBeneficiaries(this.assets[0]);

    if (initialBeneficiaries.length === 0) {
      initialBeneficiaries = ['any beneficiaries'];
    }

    const results = [
      `Unless you ${andSpouse} transfer the ${this.assets[0].assetDescription} into a trust,
           ${andList(
             initialBeneficiaries,
           )} may need to get court approval to receive it after you ${andSpouse}
           pass away.`,
      'This is a big inconvenience, can take months, and cost thousands of dollars.',
    ];

    for (const property of this.assets.slice(1)) {
      let beneficiaries = this.getAllBeneficiaries(property);

      if (beneficiaries.length === 0) {
        beneficiaries = ['any beneficiaries'];
      }

      results.push(
        `The same is true for ${andList(beneficiaries)} with the ${property.assetDescription}`,
      );
    }

    return results;
  }

  get howCopy() {
    let states = [];

    for (const property of this.assets) {
      states.push(getStateFullName(property.address.state));
    }

    states = [...new Set(states)];

    return [
      `${andList(states)} ${
        states.length > 1 ? 'require' : 'requires'
      } an attorney to transfer property into
            a trust.`,
      `Find a ${andList(states)} attorney to transfer the ${this.properties} into your trust.`,
      'This usually costs $500-$1,000.',
    ];
  }

  renderPage() {
    return (
      <SpacedFieldGroup>
        <Typography variant="h3">Transferring your properties into your trust.</Typography>

        <Typography variant="h6">What is this?</Typography>
        {this.whatCopy.map((c, i) => (
          <Typography key={i}>{c}</Typography>
        ))}

        <Typography variant="h6">How do I do this?</Typography>
        {this.howCopy.map((c, i) => (
          <Typography key={i}>{c}</Typography>
        ))}
      </SpacedFieldGroup>
    );
  }
}
